import { elementSearchResult } from '@/models/elementSearchResult';
import { apiPath } from '@/redux/apiPath';
import { axiosInstance, axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { Point } from '@/types/map';
import { BioEntity, ElementSearchResult } from '@/types/models';
import { z } from 'zod';
import { ONE, ZERO } from '@/constants/common';
import { validateDataUsingZodSchema } from '../validateDataUsingZodSchema';

interface FirstVisibleParentArgs {
  bioEntity: BioEntity;
  considerZoomLevel: number;
}

export const getFirstVisibleParent = async ({
  bioEntity,
  considerZoomLevel,
}: FirstVisibleParentArgs): Promise<BioEntity> => {
  let parentId = bioEntity.complex;
  if (!parentId) {
    parentId = bioEntity.compartment;
  }
  if (parentId) {
    const parentResponse = await axiosInstanceNewAPI.get<BioEntity>(
      apiPath.getElementById(parentId, bioEntity.model),
    );
    const parent = parentResponse.data;
    if (
      parent.visibilityLevel !== null &&
      parseInt(parent.visibilityLevel, 10) > Math.ceil(considerZoomLevel)
    ) {
      return getFirstVisibleParent({
        bioEntity: parent,
        considerZoomLevel,
      });
    }
    return parent;
  }
  // eslint-disable-next-line no-console
  console.log(`Cannot find visible parent for object. (zoomLevel=${considerZoomLevel})`, bioEntity);
  return bioEntity;
};

interface Args {
  point: Point;
  currentModelId: number;
  shouldConsiderZoomLevel: boolean;
  considerZoomLevel?: number;
}

const FRACTIONAL_ZOOM_AT_WHICH_IMAGE_LAYER_CHANGE = 0.415;

export const getElementsByPoint = async ({
  point,
  currentModelId,
  shouldConsiderZoomLevel,
  considerZoomLevel,
}: Args): Promise<ElementSearchResult[] | undefined> => {
  let result: ElementSearchResult[];
  const response = await axiosInstance.get<ElementSearchResult[]>(
    apiPath.getSingleBioEntityContentsStringWithCoordinates(point, currentModelId),
  );
  const isDataValid = validateDataUsingZodSchema(response.data, z.array(elementSearchResult));

  if (!isDataValid) {
    return undefined;
  }
  result = response.data;

  if (shouldConsiderZoomLevel && result.length > ZERO && result[ZERO].type === 'ALIAS') {
    const elementResponse = await axiosInstanceNewAPI.get<BioEntity>(
      apiPath.getElementById(result[ZERO].id, result[ZERO].modelId),
    );
    const element = elementResponse.data;
    if (
      element.visibilityLevel != null &&
      parseInt(element.visibilityLevel, 10) - (ONE - FRACTIONAL_ZOOM_AT_WHICH_IMAGE_LAYER_CHANGE) >
        (considerZoomLevel || Number.MAX_SAFE_INTEGER)
    ) {
      const visibleParent = await getFirstVisibleParent({
        bioEntity: element,
        considerZoomLevel: considerZoomLevel || Number.MAX_SAFE_INTEGER,
      });
      let id: number;
      if (typeof visibleParent.id === 'string') {
        id = parseInt(visibleParent.id, 10);
      } else {
        id = visibleParent.id;
      }
      result = [
        {
          id,
          type: 'ALIAS',
          modelId: visibleParent.model,
        },
      ];
    }
  }

  return result;
};
