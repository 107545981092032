import { store } from '@/redux/store';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { MapInstance } from '@/types/map';
import { MapManager } from '../mapManager';
import { pointToProjection } from '../fitBounds/fitBounds.utils';
import { getPolygonPoints } from './getPolygonPoints';

const VALID_POLYGON_COORDINATES_LENGTH = 2;

export const getVisibleBioEntitiesPolygonCoordinates = ():
  | {
      polygonCoordinates: number[][];
      maxZoom: number;
      mapInstance: MapInstance | null;
    }
  | undefined => {
  const mapSize = mapDataSizeSelector(store.getState());
  const { maxZoom } = mapDataSizeSelector(store.getState());

  const polygonPoints = getPolygonPoints();

  const polygonCoordinates = polygonPoints.map(point => pointToProjection(point, mapSize));

  if (polygonCoordinates.length !== VALID_POLYGON_COORDINATES_LENGTH) {
    return undefined;
  }

  return {
    polygonCoordinates,
    maxZoom,
    mapInstance: MapManager.getMapInstance(),
  };
};
