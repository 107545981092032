import { pluginsDrawerSelector } from '@/redux/plugins/plugins.selectors';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { PluginContent } from './PluginContent';
import { PLUGINS_DRAWER_ROLE } from './PluginsDrawer.constants';
import { PluginsHeader } from './PluginsHeader';
import { PluginsTabs } from './PluginsTabs/PluginsTabs.component';

export const PluginsDrawer = (): JSX.Element => {
  const { isOpen } = useSelector(pluginsDrawerSelector);

  return (
    <div
      className={twMerge(
        'absolute bottom-0 right-0 top-[104px] z-20 flex h-calc-drawer w-[432px] translate-x-full transform flex-col border border-divide bg-white-pearl text-font-500 transition-all duration-500',
        isOpen && 'translate-x-0',
      )}
      role={PLUGINS_DRAWER_ROLE}
    >
      <PluginsTabs />
      <PluginsHeader />
      <PluginContent />
    </div>
  );
};
