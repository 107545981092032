import { ZERO } from '@/constants/common';
import {
  imageHandlersSelector,
  loadingConfigurationMainSelector,
} from '@/redux/configuration/configuration.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { useContext, useState } from 'react';
import { CheckboxItem } from '@/components/Map/Drawer/ExportDrawer/CheckboxFilter/CheckboxFilter.types';
import { CheckboxFilter } from '../../CheckboxFilter';
import { CollapsibleSection } from '../../CollapsibleSection';
import { ExportContext } from '../ExportCompound.context';

export const ImageFormat = (): React.ReactNode => {
  const { setImageFormats, data } = useContext(ExportContext);
  const currentImageFormats = data.imageFormats;
  const imageHandlers = useAppSelector(imageHandlersSelector);
  const loadingConfigurationMain = useAppSelector(loadingConfigurationMainSelector);
  const isPending = loadingConfigurationMain === 'pending';

  const mappedElementAnnotations = Object.entries(imageHandlers)
    .filter(([, handler]) => Boolean(handler))
    .map(([name, handler]) => ({
      id: handler,
      label: name,
    }));

  const options = useState<CheckboxItem[]>(mappedElementAnnotations);
  if (
    !isPending &&
    currentImageFormats.length === ZERO &&
    options.length > ZERO &&
    options[ZERO].length > ZERO
  ) {
    setImageFormats([options[ZERO][ZERO]]);
  }

  return (
    <CollapsibleSection title="Image format" dangerouslySetExpanded>
      {isPending && <p>Loading...</p>}
      {!isPending && mappedElementAnnotations.length > ZERO && (
        <CheckboxFilter
          options={mappedElementAnnotations}
          currentOptions={currentImageFormats}
          onCheckedChange={setImageFormats}
          type="radio"
          isSearchEnabled={false}
        />
      )}
    </CollapsibleSection>
  );
};
