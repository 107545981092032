/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { ONE, ZERO } from '@/constants/common';

const getFuncsInChuncks = <T>(
  funcs: (() => Promise<T>)[],
  chunkSize: number,
): (() => Promise<T>)[][] => {
  const localFunc = [...funcs];
  const chunks: (() => Promise<T>)[][] = [];

  while (localFunc.length) {
    chunks.push(localFunc.splice(ZERO, chunkSize));
  }

  return chunks;
};

export const runInSequence = async <T>(
  funcs: (() => Promise<T>)[],
  chunkSize: number = ONE,
): Promise<T[]> => {
  const chunks = getFuncsInChuncks(funcs, chunkSize);
  const results: T[] = [];

  for (const chunk of chunks) {
    const chunkResult = await Promise.all(chunk.map(func => func()));
    results.push(...chunkResult);
  }

  return results;
};
