import { OverlayDataAxis } from '../OverlayData.types';

const UNIQUE_KEYS: (keyof Pick<OverlayDataAxis, 'title' | 'value'>)[] = ['title', 'value'];

const SEPARATOR = ';';

export const getUniqueAxes = (nonUniqueAxes: OverlayDataAxis[]): OverlayDataAxis[] => {
  const getUniqueAxesKey = (axis: OverlayDataAxis): string =>
    UNIQUE_KEYS.map(key => axis[key]).join(SEPARATOR);

  const uniqueAxesObj = nonUniqueAxes.reduce(
    (obj, axis) => ({
      ...obj,
      [getUniqueAxesKey(axis)]: axis,
    }),
    {} as Record<string, OverlayDataAxis>,
  );

  return Object.values(uniqueAxesObj);
};
