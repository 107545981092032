/* eslint-disable no-magic-numbers */
export default function getCoordsY(
  y: number,
  absoluteY: number,
  relativeY: number,
  relativeWidthForY: number | null,
  height: number,
  width: number,
): number {
  return y + absoluteY + (height * relativeY) / 100 + (width * (relativeWidthForY || 0)) / 100;
}
