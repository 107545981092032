import { SIZE_OF_EMPTY_ARRAY, ZERO } from '@/constants/common';
import { FEATURE_TYPE, PIN_ICON_ANY, SURFACE_ANY } from '@/constants/features';
import {
  openBioEntityDrawerById,
  openCommentDrawerById,
  openReactionDrawerById,
} from '@/redux/drawer/drawer.slice';
import { AppDispatch } from '@/redux/store';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { FeatureLike } from 'ol/Feature';
import { Comment } from '@/types/models';
import { getCommentElement, getCommentReaction } from '@/redux/comment/thunks/getComments';

interface HandleFeaturesClickResult {
  shouldBlockCoordSearch: boolean;
}

export const handleFeaturesClick = (
  features: FeatureLike[],
  dispatch: AppDispatch,
  comments: Comment[],
): HandleFeaturesClickResult => {
  const pinFeatures = features.filter(feature => PIN_ICON_ANY.includes(feature.get('type')));
  const surfaceFeatures = features.filter(feature => SURFACE_ANY.includes(feature.get('type')));
  const shouldBlockCoordSearch = pinFeatures.length > SIZE_OF_EMPTY_ARRAY;

  pinFeatures.forEach(pin => {
    const pinId = pin.get('id') as string | number;
    PluginsEventBus.dispatchEvent('onPinIconClick', { id: pinId });

    if (pin.get('type') === FEATURE_TYPE.PIN_ICON_BIOENTITY) {
      dispatch(openBioEntityDrawerById(pinId));
    } else if (pin.get('type') === FEATURE_TYPE.PIN_ICON_COMMENT) {
      const filteredComments = comments.filter(comment => comment.id === pinId);
      if (filteredComments.length > ZERO) {
        const { elementId, modelId, type } = filteredComments[ZERO];
        if (type === 'ALIAS') {
          dispatch(getCommentElement({ elementId: Number(elementId), modelId }));
          dispatch(openBioEntityDrawerById(Number(elementId)));
        } else if (type === 'REACTION') {
          dispatch(getCommentReaction({ elementId: Number(elementId), modelId }));
          dispatch(openReactionDrawerById(Number(elementId)));
        } else if (type === 'POINT') {
          dispatch(openCommentDrawerById(Number(pinId)));
        } else {
          throw new Error(`Unknown comment type${type}`);
        }
      } else {
        throw new Error(`Cannot find comment with id ${pinId}`);
      }
    }
  });

  surfaceFeatures.forEach(surface => {
    const surfaceId = surface.get('id') as string | number;
    PluginsEventBus.dispatchEvent('onSurfaceClick', { id: surfaceId });
  });

  return {
    shouldBlockCoordSearch,
  };
};
