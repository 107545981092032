import { markerSchema } from '@/models/markerSchema';
import { addMarkerToMarkersData } from '@/redux/markers/markers.slice';
import { MarkerWithOptionalId } from '@/redux/markers/markers.types';
import { store } from '@/redux/store';
import { Marker } from '@/types/models';
import { v4 as uuidv4 } from 'uuid';

export const addSingleMarker = (markerWithoutId: MarkerWithOptionalId): Marker => {
  const { dispatch } = store;
  const marker = { id: uuidv4(), ...markerWithoutId } as Marker;
  markerSchema.parse(marker);

  dispatch(addMarkerToMarkersData(marker));

  return marker;
};
