import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { IconButton } from '@/shared/IconButton';
import { BACK_BUTTON_ROLE, CLOSE_BUTTON_ROLE } from './DrawerHeadingBackwardButton.constants';

export interface DrawerHeadingBackwardButtonProps {
  backwardFunction: () => void;
  children: React.ReactNode;
}

export const DrawerHeadingBackwardButton = ({
  backwardFunction,
  children,
}: DrawerHeadingBackwardButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleCloseDrawer = (): void => {
    dispatch(closeDrawer());
  };

  const onBackwardClick = (): void => {
    backwardFunction();
  };

  return (
    <div className="flex items-center justify-between border-b border-b-divide pl-4 pr-5">
      <div className="flex flex-row flex-nowrap items-center">
        <IconButton
          className="h-6 w-6 bg-white-pearl"
          icon="chevron-left"
          classNameIcon="fill-font-500 h-6 w-6"
          onClick={onBackwardClick}
          role={BACK_BUTTON_ROLE}
        />
        <div className="ml-2 py-8 text-xl">
          <span className="font-semibold">{children}</span>
        </div>
      </div>
      <IconButton
        className="bg-white-pearl"
        classNameIcon="fill-font-500"
        icon="close"
        role={CLOSE_BUTTON_ROLE}
        onClick={handleCloseDrawer}
      />
    </div>
  );
};
