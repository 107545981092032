import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapBackgroundTypeSelector } from '@/redux/map/map.selectors';
import { twMerge } from 'tailwind-merge';
import { MAP_BACKGROUND_TYPES } from '@/redux/map/map.constants';
import { setMapBackgroundType } from '@/redux/map/map.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { Select } from '@/shared/Select';

export const MapVectorBackgroundSelector = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const backgroundType = useAppSelector(mapBackgroundTypeSelector);

  const handleChange = (selectedBackgroundType: number): void => {
    dispatch(setMapBackgroundType(selectedBackgroundType));
  };

  return (
    <div className={twMerge('absolute right-6 top-[calc(64px+40px+24px)] z-10 flex')}>
      <Select
        options={MAP_BACKGROUND_TYPES}
        selectedId={backgroundType}
        onChange={handleChange}
        width={100}
      />
    </div>
  );
};
