import { OverlayBioEntityRender } from '@/types/OLrendering';
import { createSelector } from '@reduxjs/toolkit';
import { allSubmapConnectionsBioEntitySelector } from '@/redux/bioEntity/bioEntity.selectors';
import { mapModelIdSelector } from '@/redux/map/map.selectors';
import { currentSearchedBioEntityId } from '../drawer/drawer.selectors';
import { currentModelIdSelector } from '../models/models.selectors';
import {
  overlaysDataSelector,
  overlaysIdsAndOrderSelector,
  userOverlaysDataSelector,
  userOverlaysIdsAndOrderSelector,
} from '../overlays/overlays.selectors';
import { rootSelector } from '../root/root.selectors';
import {
  calculateOvarlaysOrder,
  getActiveOverlaysIdsAndOrder,
  getActiveUserOverlaysIdsAndOrder,
} from './overlayBioEntity.utils';

export const overlayBioEntitySelector = createSelector(
  rootSelector,
  state => state.overlayBioEntity,
);

export const overlayBioEntityDataSelector = createSelector(
  overlayBioEntitySelector,
  overlayBioEntity => overlayBioEntity.data,
);

export const activeOverlaysIdSelector = createSelector(
  overlayBioEntitySelector,
  state => state.overlaysId,
);

export const overlayBioEntitiesForCurrentModelSelector = createSelector(
  overlayBioEntityDataSelector,
  activeOverlaysIdSelector,
  currentModelIdSelector,
  allSubmapConnectionsBioEntitySelector,
  (data, activeOverlaysIds, currentModelId, submapConnections) => {
    const result: OverlayBioEntityRender[] = [];

    activeOverlaysIds.forEach(overlayId => {
      if (data[overlayId]?.[currentModelId]) {
        result.push(...data[overlayId][currentModelId]);
      }
    });

    submapConnections.forEach(submapConnection => {
      if (submapConnection.model === currentModelId) {
        const submapId = submapConnection?.submodel?.mapId;
        if (submapId) {
          activeOverlaysIds.forEach(overlayId => {
            if (data[overlayId]?.[submapId]) {
              data[overlayId][submapId].forEach(overlayBioEntityRender => {
                const newOverlayBioEntityRender = {
                  id: submapConnection.id,
                  modelId: submapConnection.model,
                  x1: submapConnection.x,
                  y2: submapConnection.y,
                  x2: submapConnection.x + submapConnection.width,
                  y1: submapConnection.y + submapConnection.height,
                  width: submapConnection.width,
                  height: submapConnection.height,
                  value: overlayBioEntityRender.value,
                  overlayId: overlayBioEntityRender.overlayId,
                  color: overlayBioEntityRender.color,
                  hexColor: overlayBioEntityRender.hexColor,
                  type: overlayBioEntityRender.type,
                  geneVariants: overlayBioEntityRender.geneVariants,
                  name: overlayBioEntityRender.name,
                };
                result.push(newOverlayBioEntityRender);
              });
            }
          });
        }
      }
    });
    return result;
  },
);

export const isOverlayActiveSelector = createSelector(
  [activeOverlaysIdSelector, (_, overlayId: number): number => overlayId],
  (overlaysId, overlayId) => overlaysId.includes(overlayId),
);

export const areOverlayBioEntitiesLoadedSelector = createSelector(
  [overlayBioEntityDataSelector, (_, overlayId: number): number => overlayId],
  (bioEntities, overlayId) => Boolean(bioEntities[overlayId]),
);

export const isOverlayLoadingSelector = createSelector(
  [overlayBioEntitySelector, mapModelIdSelector, (_, overlayId: number): number => overlayId],
  ({ overlaysId, data }, mapId, overlayId) => {
    let result = false;
    if (overlaysId.includes(overlayId)) {
      if (data[overlayId] && !Object.keys(data[overlayId]).length) {
        result = true;
      } else if (data[overlayId] && !data[overlayId][mapId]) {
        result = true;
      }
    }
    return result;
  },
);

export const activeOverlaysSelector = createSelector(
  rootSelector,
  overlaysDataSelector,
  userOverlaysDataSelector,
  (state, overlaysData, userOverlaysData) => {
    const activeOverlays = overlaysData.filter(overlay =>
      isOverlayActiveSelector(state, overlay.idObject),
    );
    const activeUserOverlays =
      userOverlaysData?.filter(overlay => isOverlayActiveSelector(state, overlay.idObject)) || [];

    return [...activeOverlays, ...activeUserOverlays];
  },
);

export const getOverlayOrderSelector = createSelector(
  overlaysIdsAndOrderSelector,
  userOverlaysIdsAndOrderSelector,
  activeOverlaysIdSelector,
  (overlaysIdsAndOrder, userOverlaysIdsAndOrder, activeOverlaysIds) => {
    const { activeOverlaysIdsAndOrder, maxOrderValue } = getActiveOverlaysIdsAndOrder(
      overlaysIdsAndOrder,
      activeOverlaysIds,
    );

    const activeUserOverlaysIdsAndOrder = getActiveUserOverlaysIdsAndOrder(
      userOverlaysIdsAndOrder,
      activeOverlaysIds,
      maxOrderValue,
    );

    return calculateOvarlaysOrder([...activeOverlaysIdsAndOrder, ...activeUserOverlaysIdsAndOrder]);
  },
);

export const overlaysOpenedIdsSelector = createSelector(
  rootSelector,
  state => state.overlayBioEntity.overlaysId,
);

export const overlaysOpenedSelector = createSelector(
  overlaysDataSelector,
  overlaysOpenedIdsSelector,
  (data, ids) => data.filter(entity => ids.includes(entity.idObject)),
);

export const overlaysBioEntityForCurrentBioEntityAndCurrentModelSelector = createSelector(
  overlayBioEntitiesForCurrentModelSelector,
  currentSearchedBioEntityId,
  (data, currentBioEntityId) => data.filter(entity => entity.id === currentBioEntityId),
);
