import { ZERO } from '@/constants/common';
import { MapModel } from '@/types/models';

const ZOOM_BASE = 6;

/*
 * Width of exported image for zoom=1 is 128, for zoom=2 is 256, for zoom=3 is 1024
 * So zoom level holds pattern of log2(width) with base of log2(128)=7
 * Zoom base defined in this file is 6 as we need to provide minumum zoom of 1
 */

export const getModelExportZoom = (exportWidth: number, model?: MapModel): number => {
  // log2 of zero is -Infty
  if (!model || model.width === ZERO) {
    return ZERO;
  }

  const { maxZoom, minZoom } = model;
  const exportZoom = Math.log2(exportWidth) - ZOOM_BASE;

  return Math.min(Math.max(exportZoom, minZoom), maxZoom);
};
