import { drugsDataListSelector } from '@/redux/drugs/drugs.selectors';
import { store } from '@/redux/store';
import { Drug } from '@/types/models';

export const getAllDrugs = (): Drug[] => {
  const { getState } = store;
  const drugs = drugsDataListSelector(getState());

  return drugs || [];
};
