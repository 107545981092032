import { BASE_MAP_IMAGES_URL } from '@/constants';
import { currentOverviewImageSelector } from '@/redux/project/project.selectors';
import { useSelector } from 'react-redux';

export const useOverviewImageUrl = (): string => {
  const currentImage = useSelector(currentOverviewImageSelector);

  if (!currentImage) {
    return '';
  }

  return `${BASE_MAP_IMAGES_URL}/map_images/${currentImage.filename}`;
};
