import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '../root/root.selectors';

export const entityNumberSelector = createSelector(rootSelector, state => state.entityNumber);

export const entityNumberDataSelector = createSelector(
  entityNumberSelector,
  entityNumber => entityNumber.data,
);

export const numberByEntityNumberIdSelector = createSelector(
  [entityNumberDataSelector, (_state, id: string): string => id],
  (entityNumber, id): number | undefined => entityNumber?.[id],
);
