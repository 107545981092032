import { allBioEntitiesElementsIdsSelector } from '@/redux/bioEntity/bioEntity.selectors';
import { currentSelectedSearchElement } from '@/redux/drawer/drawer.selectors';
import { selectTab } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { PluginsEventBus as EventBus } from '@/services/pluginsManager/pluginsEventBus';
import { ClickedPinIcon } from '@/services/pluginsManager/pluginsEventBus/pluginsEventBus.types';
import isUUID from 'is-uuid';
import { useCallback, useEffect } from 'react';

export type OnPinIconClick = ({ id }: ClickedPinIcon) => void;

export const useHandlePinIconClick = (): OnPinIconClick => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(currentSelectedSearchElement);
  const idsTabs = useAppSelector(allBioEntitiesElementsIdsSelector);

  const onPinIconClick = useCallback(
    ({ id }: ClickedPinIcon): void => {
      const newTab = idsTabs[id];
      const isTabAlreadySelected = newTab === currentTab;
      const isMarker = isUUID.anyNonNil(`${id}`);

      if (!newTab || isTabAlreadySelected || isMarker) {
        return;
      }

      dispatch(selectTab(idsTabs[id]));
    },
    [idsTabs, dispatch, currentTab],
  );

  useEffect(() => {
    EventBus.addLocalListener('onPinIconClick', onPinIconClick);

    return () => {
      EventBus.removeLocalListener('onPinIconClick', onPinIconClick);
    };
  }, [onPinIconClick]);

  return onPinIconClick;
};
