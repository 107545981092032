/* eslint-disable no-magic-numbers */
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { overlayBioEntitiesForCurrentModelSelector } from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { useCallback, useMemo } from 'react';
import type { OverlayBioEntityRender } from '@/types/OLrendering';
import { useGetOverlayColor } from './useGetOverlayColor';
import { getSubmapLinkRectangle } from './getSubmapLinkRectangle';
import { groupSubmapLinksRectanglesById } from './groupSubmapLinksRectanglesById';

export type SubmapLinkRectangle = OverlayBioEntityRender & {
  amount: number;
};

export type GroupedSubmapsLinksRectangles = {
  [id: string]: SubmapLinkRectangle[];
};

export const useBioEntitiesWithSubmapsLinks = (): OverlayBioEntityRender[] => {
  const { getOverlayBioEntityColorByAvailableProperties } = useGetOverlayColor();
  const bioEntities = useAppSelector(overlayBioEntitiesForCurrentModelSelector);
  const submapsLinks = useMemo(
    () => bioEntities.filter(bioEntity => bioEntity.type === 'submap-link'),
    [bioEntities],
  );
  const bioEntitiesWithoutSubmapsLinks = useMemo(
    () => bioEntities.filter(bioEntity => bioEntity.type !== 'submap-link'),
    [bioEntities],
  );

  const sortSubmapLinksRectanglesByColor = useCallback(
    (submapLinksRectangles: SubmapLinkRectangle[]): void => {
      submapLinksRectangles.sort((a, b) => {
        const firstSubmapLinkRectangleColor = getOverlayBioEntityColorByAvailableProperties(a);
        const secondSubmapLinkRectangleColor = getOverlayBioEntityColorByAvailableProperties(b);

        if (firstSubmapLinkRectangleColor === secondSubmapLinkRectangleColor) {
          return 0;
        }

        return firstSubmapLinkRectangleColor < secondSubmapLinkRectangleColor ? -1 : 1;
      });
    },
    [getOverlayBioEntityColorByAvailableProperties],
  );

  const calculateSubmapsLinksRectanglesPosition = useCallback(
    (
      groupedSubmapsLinksRectanglesById: GroupedSubmapsLinksRectangles,
    ): OverlayBioEntityRender[] => {
      const submapsLinksRectangles: SubmapLinkRectangle[] = [];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const id in groupedSubmapsLinksRectanglesById) {
        const submapLinksRectanglesGroup = groupedSubmapsLinksRectanglesById[id];

        sortSubmapLinksRectanglesByColor(submapLinksRectanglesGroup);

        const submapLinkRectanglesTotalHeight = submapLinksRectanglesGroup[0].height;
        const submapLinkRectanglesAmount = submapLinksRectanglesGroup.reduce(
          (accumulator: number, currentValue) => accumulator + currentValue.amount,
          0,
        );

        submapLinksRectanglesGroup.forEach((submapLinkRectangle, index) => {
          const ratio = submapLinkRectangle.amount / submapLinkRectanglesAmount;
          const rectangleHeight = ratio * submapLinkRectanglesTotalHeight;

          getSubmapLinkRectangle(
            submapsLinksRectangles,
            submapLinkRectangle,
            index,
            submapLinksRectanglesGroup,
            rectangleHeight,
          );
        });
      }

      return submapsLinksRectangles;
    },
    [sortSubmapLinksRectanglesByColor],
  );

  const groupedSubmapLinksRectanglesById = useMemo(
    () => groupSubmapLinksRectanglesById(submapsLinks),
    [submapsLinks],
  );
  const submapsLinksRectangles = useMemo(
    () => calculateSubmapsLinksRectanglesPosition(groupedSubmapLinksRectanglesById),
    [groupedSubmapLinksRectanglesById, calculateSubmapsLinksRectanglesPosition],
  );

  return [...submapsLinksRectangles, ...bioEntitiesWithoutSubmapsLinks];
};
