import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { getAllUserOverlaysByCreator } from '@/redux/overlays/overlays.thunks';
import { loadingUserSelector } from '@/redux/user/user.selectors';
import { login } from '@/redux/user/user.thunks';
import { Button } from '@/shared/Button';
import { Input } from '@/shared/Input';
import Link from 'next/link';
import React from 'react';
import { BASE_API_URL } from '@/constants';
import { orcidEndpointSelector } from '@/redux/oauth/oauth.selectors';
import Image from 'next/image';
import orcidLogoImg from '@/assets/images/orcid.png';

export const LoginModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const loadingUser = useAppSelector(loadingUserSelector);
  const isPending = loadingUser === 'pending';
  const [credentials, setCredentials] = React.useState({ login: '', password: '' });

  const orcidEndpoint = useAppSelector(orcidEndpointSelector);

  const isOrcidAvailable = orcidEndpoint !== undefined;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setCredentials(prevCredentials => ({ ...prevCredentials, [name]: value }));
  };

  const handleLoginViaOrcid = (): void => {
    window.location.href = `${BASE_API_URL}/..${orcidEndpoint}`;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await dispatch(login(credentials));
    dispatch(getAllUserOverlaysByCreator());
  };

  return (
    <div className="w-[400px] border border-t-[#E1E0E6] bg-white p-[24px]">
      <form onSubmit={handleSubmit}>
        <label className="mb-5 block text-sm font-semibold" htmlFor="login">
          Login:
          <Input
            type="text"
            name="login"
            id="login"
            placeholder="Your login here.."
            value={credentials.login}
            onChange={handleChange}
            className="mt-2.5 text-sm font-medium text-font-400"
          />
        </label>
        <label className="text-sm font-semibold" htmlFor="password">
          Password:
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Your password here.."
            value={credentials.password}
            onChange={handleChange}
            className="mt-2.5 text-sm font-medium text-font-400"
          />
        </label>
        <div className="mb-10 text-right">
          <Link href="/" className="ml-auto text-xs">
            Forgot password?
          </Link>
        </div>
        {isOrcidAvailable && (
          <Button
            variantStyles="quiet"
            className="mb-1 w-full justify-center text-base font-medium"
            onClick={handleLoginViaOrcid}
          >
            <Image src={orcidLogoImg} alt="orcid logo" height={32} width={32} className="mr-1.5" />
            Sign in with Orcid
          </Button>
        )}
        <Button
          type="submit"
          className="w-full justify-center text-base font-medium"
          disabled={isPending}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
