import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';

const PENDING_STATUS = 'pending';

export const searchSelector = createSelector(rootSelector, state => state.search);

export const searchValueSelector = createSelector(searchSelector, state => state.searchValue);

export const loadingSearchStatusSelector = createSelector(searchSelector, state => state.loading);

export const isPendingSearchStatusSelector = createSelector(
  loadingSearchStatusSelector,
  state => state === PENDING_STATUS,
);

export const perfectMatchSelector = createSelector(searchSelector, state => state.perfectMatch);
