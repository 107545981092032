import { SIZE_OF_EMPTY_ARRAY, ZERO } from '@/constants/common';
import { displayDrugsList } from '@/redux/drawer/drawer.slice';
import {
  drugsSelector,
  loadingDrugsStatusSelector,
  numberOfDrugsSelector,
} from '@/redux/drugs/drugs.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { AccordionItem, AccordionItemHeading, AccordionItemButton } from '@/shared/Accordion';
import { currentSelectedSearchElement } from '@/redux/drawer/drawer.selectors';

export const DrugsAccordion = (): JSX.Element => {
  const drugsNumber = useAppSelector(numberOfDrugsSelector);
  const drugsState = useAppSelector(loadingDrugsStatusSelector);
  const dispatch = useAppDispatch();

  const currentSearchElement = useAppSelector(currentSelectedSearchElement);
  const drugs = useAppSelector(drugsSelector);

  const isPending = drugsState === 'pending';
  const isSucceeded = drugsState === 'succeeded';
  const isDrugsEmpty = drugsNumber === SIZE_OF_EMPTY_ARRAY;

  const onAccordionClick = (): void => {
    dispatch(displayDrugsList());
  };

  const currentDrug = drugs.data.find(
    ({ searchQueryElement }) => searchQueryElement === currentSearchElement,
  );
  const list = (currentDrug?.data || []).map(drug =>
    drug.targets.filter(target => target.targetElements.length > ZERO),
  );
  let existingDrugTargets = 0;
  list.forEach(function (drugTargetList) {
    existingDrugTargets += drugTargetList.length;
  });

  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton
          variant="non-expandable"
          onClick={onAccordionClick}
          disabled={isPending || isDrugsEmpty}
        >
          Drug targets
          {isPending && ' (Loading...)'}
          {isSucceeded && ` (${existingDrugTargets} of ${drugsNumber} found in the map)`}
        </AccordionItemButton>
      </AccordionItemHeading>
    </AccordionItem>
  );
};
