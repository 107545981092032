import { Icon } from '@/shared/Icon';
import { twMerge } from 'tailwind-merge';

import type { ButtonHTMLAttributes } from 'react';
import type { IconTypes } from '@/types/iconTypes';

type VariantStyle = 'primary' | 'secondary' | 'ghost' | 'quiet';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variantStyles?: VariantStyle;
  icon?: IconTypes;
  isIcon?: boolean;
  isFrontIcon?: boolean;
}

const variants = {
  primary: {
    button:
      'text-white-pearl bg-primary-500 hover:bg-primary-600 active:bg-primary-700 disabled:bg-greyscale-700',
    icon: 'fill-white-pearl',
  },
  secondary: {
    button:
      'text-primary-500 bg-white-pearl ring-1 ring-inset ring-primary-500 hover:text-primary-600 hover:ring-primary-600 active:text-primary-700 active:ring-primary-700 disabled:text-font-400 disabled:ring-greyscale-500',
    icon: 'fill-primary-500 group-hover:fill-primary-600 group-active:fill-primary-700 group-disabled:fill-font-400',
  },
  ghost: {
    button:
      'text-font-500 bg-white-pearl ring-1 ring-inset ring-greyscale-600 hover:ring-greyscale-700 active:ring-font-500 disabled:text-font-400 disabled:ring-greyscale-500',
    icon: 'fill-font-500 group-disabled:fill-font-400',
  },
  quiet: {
    button:
      'text-font-500 bg-white-pearl hover:bg-greyscale-500 active:bg-greyscale-600 disabled:text-font-400 disabled:bg-white-pearl',
    icon: 'fill-font-500 group-disabled:fill-font-400',
  },
} as const;

export const Button = ({
  className = '',
  variantStyles = 'primary',
  icon = 'chevron-right',
  isIcon = false,
  isFrontIcon = false,
  children = 'Button',
  ...props
}: ButtonProps): JSX.Element => {
  const paddings = (): string => {
    if (!isIcon) return 'px-3';

    if (isFrontIcon) return 'pr-3 pl-2';

    return 'pr-2 pl-3';
  };

  return (
    <button
      className={twMerge(
        paddings(),
        'group flex items-center rounded-e rounded-s py-2',
        variants[variantStyles].button,
        className,
        isFrontIcon && 'flex-row-reverse',
      )}
      type="button"
      {...props}
    >
      {children}
      {isIcon && (
        <Icon
          className={twMerge(isFrontIcon ? 'mr-1' : 'ml-1', variants[variantStyles].icon)}
          name={icon}
        />
      )}
    </button>
  );
};

Button.displayName = 'Button';
