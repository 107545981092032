/* eslint-disable no-magic-numbers */
import { OverlayOrder } from '@/redux/overlayBioEntity/overlayBioEntity.utils';

export default function areOverlayOrdersNotEqual(
  overlaysOrder1: Array<OverlayOrder>,
  overlaysOrder2: Array<OverlayOrder>,
): boolean {
  if (overlaysOrder1 === overlaysOrder2) {
    return false;
  }

  if (overlaysOrder1.length !== overlaysOrder2.length) {
    return true;
  }

  for (let index = 0; index < overlaysOrder1.length; index += 1) {
    const obj1 = overlaysOrder1[index];
    const obj2 = overlaysOrder2[index];

    if (
      obj1.id !== obj2.id ||
      obj1.order !== obj2.order ||
      obj1.calculatedOrder !== obj2.calculatedOrder ||
      obj1.index !== obj2.index
    ) {
      return true;
    }
  }

  return false;
}
