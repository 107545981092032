import {
  ERROR_FAILED_TO_READ_FILE,
  ERROR_INVALID_TYPE_FILE_CONTENT,
} from '@/services/pluginsManager/errorMessages';

const getFileContentFromFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e): void => {
      if (e.target) {
        resolve(e.target.result as string);
      } else {
        reject(new Error(ERROR_FAILED_TO_READ_FILE));
      }
    };
  });
};

export const getOverlayContent = async (fileContent: string | File): Promise<string> => {
  if (typeof fileContent === 'string') {
    return fileContent;
  }
  if (fileContent instanceof File) {
    return getFileContentFromFile(fileContent);
  }
  throw new Error(ERROR_INVALID_TYPE_FILE_CONTENT);
};
