import { FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { targetElementLeanResponseSchema } from '@/models/targetElementLeanResponseSchema';
import { apiPath } from '@/redux/apiPath';
import { BIO_ENTITY_FETCHING_ERROR_PREFIX } from '@/redux/bioEntity/bioEntity.constants';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { BioEntityContent, BioEntityResponse } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { showToast } from '@/utils/showToast';
import { getErrorMessage } from '@/utils/getErrorMessage';

export const fetchElementData = async (
  searchQuery: string,
): Promise<BioEntityContent | undefined> => {
  try {
    const response = await axiosInstanceNewAPI.get<BioEntityResponse>(
      apiPath.getBioEntityContentsStringWithQuery({
        searchQuery,
        isPerfectMatch: true,
      }),
    );

    const isDataValid = validateDataUsingZodSchema(response.data, targetElementLeanResponseSchema);

    if (isDataValid && response.data.content?.[FIRST_ARRAY_ELEMENT]) {
      return response.data.content[FIRST_ARRAY_ELEMENT];
    }
  } catch (error) {
    const errorMessage = getErrorMessage({
      error,
      prefix: BIO_ENTITY_FETCHING_ERROR_PREFIX,
    });

    showToast({
      type: 'error',
      message: errorMessage,
    });
  }

  return undefined;
};
