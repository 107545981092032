/* eslint-disable no-magic-numbers */
import { PLUGINS_CONTENT_ELEMENT_ATTR_NAME, PLUGINS_CONTENT_ELEMENT_ID } from '@/constants/plugins';
import { registerPlugin } from '@/redux/plugins/plugins.thunks';
import { store } from '@/redux/store';
import md5 from 'crypto-js/md5';
import { v4 as uuidv4 } from 'uuid';
import { isPluginHashWithPrefix } from '@/utils/plugins/isPluginHashWithPrefix';
import { getPluginHashWithoutPrefix } from '@/utils/plugins/getPluginHashWithoutPrefix';
import { ONE, ZERO } from '@/constants/common';
import { minervaDefine } from '@/services/pluginsManager/map/minervaDefine';
import { PluginsContextMenu } from '@/services/pluginsManager/pluginContextMenu/pluginsContextMenu';
import { getOpenedPanel } from '@/services/pluginsManager/interface/getOpenedPanel';
import { hidePanel } from '@/services/pluginsManager/interface/hidePanel';
import { openPanel } from '@/services/pluginsManager/interface/openPanel';
import { bioEntitiesMethods } from './bioEntities';
import { getModels } from './map/models/getModels';
import { openMap } from './map/openMap';
import { getCenter } from './map/position/getCenter';
import { setCenter } from './map/position/setCenter';
import { triggerSearch } from './map/triggerSearch';
import { getZoom } from './map/zoom/getZoom';
import { setZoom } from './map/zoom/setZoom';
import { getCurrentOverviewImage } from './overviewImage/getCurrentOverviewImage';
import { getOverviewImages } from './overviewImage/getOverviewImages';
import { hideOverviewImageModal } from './overviewImage/hideOverviewImageModal';
import { selectOverviewImage } from './overviewImage/selectOverviewImage';
import { showOverviewImageModal } from './overviewImage/showOverviewImageModal';
import { PluginsEventBus } from './pluginsEventBus';
import type { PluginsManagerType } from './pluginsManager.types';
import { configurationMapper } from './pluginsManager.utils';
import { getDataOverlays } from './map/overlays/getDataOverlays';
import { getVisibleDataOverlays } from './map/overlays/getVisibleDataOverlays';
import { showDataOverlay } from './map/overlays/showDataOverlay';
import { hideDataOverlay } from './map/overlays/hideDataOverlay';
import { removeDataOverlay } from './map/overlays/removeDataOverlay';
import { addDataOverlay } from './map/overlays/addDataOverlay';
import { getApiUrls } from './project/data/getApiUrls';
import { getDisease } from './project/data/getDisease';
import { getName } from './project/data/getName';
import { getOrganism } from './project/data/getOrganism';
import { getProjectId } from './project/data/getProjectId';
import { getVersion } from './project/data/getVersion';
import { getBounds } from './map/data/getBounds';
import { fitBounds } from './map/fitBounds';
import { getOpenMapId } from './map/getOpenMapId';
import { setLegend } from './legend/setLegend';
import { removeLegend } from './legend/removeLegend';
import { ERROR_PLUGIN_URL_MISMATCH } from './errorMessages';

export const PluginsManager: PluginsManagerType = {
  hashedPlugins: {},
  setHashedPlugin({ pluginUrl, pluginScript }) {
    const hash = md5(pluginScript).toString();

    PluginsManager.hashedPlugins[pluginUrl] = hash;

    return hash;
  },
  activePlugins: {},
  pluginsOccurrences: {},

  unloadActivePlugin: hash => {
    const hashWithoutPrefix = getPluginHashWithoutPrefix(hash);

    PluginsManager.activePlugins[hashWithoutPrefix] =
      PluginsManager.activePlugins[hashWithoutPrefix]?.filter(el => el !== hash) || [];

    if (
      PluginsManager.activePlugins[hashWithoutPrefix].length === ZERO &&
      hashWithoutPrefix in PluginsManager.pluginsOccurrences
    ) {
      PluginsManager.pluginsOccurrences[hashWithoutPrefix] = ZERO;
    }
  },
  init() {
    window.minervaDefine = minervaDefine;
    window.minerva = {
      plugins: {
        registerPlugin: PluginsManager.registerPlugin,
      },
      data: {
        bioEntities: bioEntitiesMethods,
      },
      interface: {
        getOpenedPanel,
        hidePanel,
        openPanel,
      },
      map: {
        data: {
          getBounds,
          getOpenMapId,
          getModels,
        },
        fitBounds,
        openMap,
        triggerSearch,
        getZoom,
        setZoom,
        getCenter,
        setCenter,
      },
      overviewImage: {
        getCurrentOverviewImage,
        getOverviewImages,
        hideOverviewImageModal,
        selectOverviewImage,
        showOverviewImageModal,
      },
      overlays: {
        data: {
          getDataOverlays,
          getVisibleDataOverlays,
        },
        showDataOverlay,
        hideDataOverlay,
        removeDataOverlay,
        addDataOverlay,
      },
      project: {
        data: {
          getProjectId,
          getName,
          getVersion,
          getDisease,
          getOrganism,
          getApiUrls,
        },
      },
    };

    const unsubscribe = store.subscribe(() => {
      const configurationStore = store.getState().configuration.main.data;

      if (configurationStore) {
        const configuration = configurationMapper(configurationStore);

        window.minerva = {
          ...window.minerva,
          configuration,
        };
      }
    });

    return unsubscribe;
  },
  getExtendedPluginHash(hash: string) {
    let extendedHash = hash;

    if (!(hash in PluginsManager.activePlugins)) PluginsManager.activePlugins[hash] = [];

    if (PluginsManager.activePlugins[hash].includes(hash)) {
      const prefix = md5(uuidv4()).toString();
      extendedHash = `${prefix}-${hash}`;
    }

    PluginsManager.activePlugins[hash].push(extendedHash);

    return extendedHash;
  },
  getExtendedPluginName(hash, extendedHash, pluginName) {
    return PluginsManager.activePlugins[hash].length === 1 || !isPluginHashWithPrefix(extendedHash)
      ? pluginName
      : `${pluginName} (${PluginsManager.pluginsOccurrences[hash]})`;
  },
  updatePluginOccurrence(hash, extendedHash) {
    if (hash in PluginsManager.pluginsOccurrences && isPluginHashWithPrefix(extendedHash)) {
      PluginsManager.pluginsOccurrences[hash] += ONE;
    } else if (!(hash in PluginsManager.pluginsOccurrences)) {
      PluginsManager.pluginsOccurrences[hash] = ZERO;
    }
  },
  registerPlugin({ pluginName, pluginVersion, pluginUrl, withoutPanel }) {
    const hash = PluginsManager.hashedPlugins[pluginUrl];

    const extendedHash = PluginsManager.getExtendedPluginHash(hash);

    PluginsManager.updatePluginOccurrence(hash, extendedHash);

    if (!hash) {
      throw new Error(ERROR_PLUGIN_URL_MISMATCH);
    }

    const extendedPluginName = PluginsManager.getExtendedPluginName(hash, extendedHash, pluginName);

    store.dispatch(
      registerPlugin({
        hash: extendedHash,
        isPublic: false,
        extendedPluginName,
        pluginName,
        pluginUrl,
        pluginVersion,
        withoutPanel,
      }),
    );

    const element = PluginsManager.createAndGetPluginContent(
      {
        hash: extendedHash,
      },
      !!withoutPanel,
    );

    return {
      element,
      events: {
        addListener: PluginsEventBus.addListener.bind(this, extendedHash, pluginName),
        removeListener: PluginsEventBus.removeListener.bind(this, extendedHash),
        removeAllListeners: PluginsEventBus.removeAllListeners.bind(this, extendedHash),
      },
      contextMenu: {
        addMenu: PluginsContextMenu.addMenu.bind(this, extendedHash),
        updateMenu: PluginsContextMenu.updateMenu.bind(this, extendedHash),
        removeMenu: PluginsContextMenu.removeMenu.bind(this, extendedHash),
      },
      legend: {
        setLegend: setLegend.bind(this, extendedHash),
        removeLegend: removeLegend.bind(this, extendedHash),
      },
    };
  },
  createAndGetPluginContent({ hash }, detached) {
    const element = document.createElement('div');
    element.setAttribute(PLUGINS_CONTENT_ELEMENT_ATTR_NAME, hash);

    if (!detached) {
      const wrapper = document.querySelector(`#${PLUGINS_CONTENT_ELEMENT_ID}`);
      wrapper?.append(element);
    }

    return element;
  },
  removePluginContent({ hash }) {
    const elements = document.querySelectorAll(
      `div[${PLUGINS_CONTENT_ELEMENT_ATTR_NAME}="${hash}"]`,
    );

    elements.forEach(element => element.remove());
  },
};
