import { chemicalsDataListSelector } from '@/redux/chemicals/chemicals.selectors';
import { store } from '@/redux/store';
import { Chemical } from '@/types/models';

export const getAllChemicals = (): Chemical[] => {
  const { getState } = store;
  const chemicals = chemicalsDataListSelector(getState());

  return chemicals || [];
};
