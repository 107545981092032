import { MULTIICON_RATIO, PIN_SIZE } from '@/constants/canvas';
import { ONE, ZERO } from '@/constants/common';
import { createCanvas } from '@/utils/canvas/getCanvas';

const drawIconOnCanvas = (
  ctx: CanvasRenderingContext2D,
  icon: HTMLCanvasElement,
  index: number,
): void => {
  ctx.drawImage(icon, ZERO, index * PIN_SIZE.height * MULTIICON_RATIO);
};

export const getCavasMultiIcon = (icons: HTMLCanvasElement[]): HTMLCanvasElement => {
  const canvas = createCanvas({
    width: PIN_SIZE.width,
    height: PIN_SIZE.height * (ONE + (icons.length - ONE) * MULTIICON_RATIO),
  });

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return canvas;
  }

  icons.reverse().forEach((icon, index) => drawIconOnCanvas(ctx, icon, index));

  return canvas;
};
