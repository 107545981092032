import { activeOverlaysSelector } from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { useSelector } from 'react-redux';
import { OverlaySingleLegend } from './OverlaySingleLegend';

export const OverlaysLegends = (): JSX.Element => {
  const overlays = useSelector(activeOverlaysSelector);

  return (
    <div className="border-t border-t-divide p-6" data-testid="overlays-legends">
      {overlays.map(overlay => (
        <OverlaySingleLegend key={overlay.idObject} overlay={overlay} />
      ))}
    </div>
  );
};
