/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import BaseMultiPolygon from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/BaseMultiPolygon';
import { Coordinate } from 'ol/coordinate';
import Polygon from 'ol/geom/Polygon';
import { Stroke, Style } from 'ol/style';
import getFill from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getFill';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';
import getStroke from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStroke';
import { MapInstance } from '@/types/map';
import { Color } from '@/types/models';
import {
  MAP_ELEMENT_TYPES,
  TRANSPARENT_COLOR,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import VectorSource from 'ol/source/Vector';
import { MapSize } from '@/redux/map/map.types';

export interface CompartmentProps {
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  sboTerm: string;
  x: number;
  y: number;
  width: number;
  height: number;
  thickness: number;
  outerWidth: number;
  innerWidth: number;
  zIndex: number;
  text: string;
  fontSize: number;
  nameX: number;
  nameY: number;
  nameWidth: number;
  nameHeight: number;
  fontColor: Color;
  nameVerticalAlign: VerticalAlign;
  nameHorizontalAlign: HorizontalAlign;
  fillColor: Color;
  borderColor: Color;
  overlaysVisible: boolean;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  vectorSource: VectorSource;
  mapBackgroundType: number;
  mapSize: MapSize;
}

export default abstract class Compartment extends BaseMultiPolygon {
  outerCoords: Array<Coordinate> = [];

  innerCoords: Array<Coordinate> = [];

  outerWidth: number;

  innerWidth: number;

  thickness: number;

  overlaysVisible: boolean;

  constructor({
    id,
    complexId,
    compartmentId,
    sboTerm,
    x,
    y,
    width,
    height,
    thickness,
    outerWidth,
    innerWidth,
    zIndex,
    text,
    fontSize,
    nameX,
    nameY,
    nameWidth,
    nameHeight,
    fontColor,
    nameVerticalAlign,
    nameHorizontalAlign,
    fillColor,
    borderColor,
    overlaysVisible,
    pointToProjection,
    mapInstance,
    vectorSource,
    mapBackgroundType,
    mapSize,
  }: CompartmentProps) {
    super({
      type: MAP_ELEMENT_TYPES.COMPARTMENT,
      id,
      complexId,
      compartmentId,
      sboTerm,
      x,
      y,
      width,
      height,
      zIndex,
      text,
      fontSize,
      nameX,
      nameY,
      nameWidth,
      nameHeight,
      fontColor,
      nameVerticalAlign,
      nameHorizontalAlign,
      fillColor,
      borderColor,
      pointToProjection,
      overlaysVisible,
      vectorSource,
      mapBackgroundType,
      mapSize,
      mapInstance,
    });
    this.outerWidth = outerWidth;
    this.innerWidth = innerWidth;
    this.thickness = thickness;
    this.overlaysVisible = overlaysVisible;
    this.getCompartmentCoords();
    this.createPolygons();
    this.drawText();
    this.drawMultiPolygonFeature(mapInstance);
  }

  protected abstract getCompartmentCoords(): void;

  protected createPolygons(): void {
    const coverPolygon = new Polygon([this.outerCoords]);
    const coverStyle = new Style({
      geometry: coverPolygon,
      fill: getFill({ color: rgbToHex({ ...this.fillColor, alpha: 255 }) }),
    });
    coverPolygon.set('coverStyle', coverStyle);
    this.styles.push(
      new Style({
        geometry: coverPolygon,
      }),
    );

    const framePolygon = new Polygon([this.outerCoords, this.innerCoords]);
    framePolygon.set('type', MAP_ELEMENT_TYPES.COMPARTMENT);
    this.styles.push(
      new Style({
        geometry: framePolygon,
        fill: this.overlaysVisible
          ? getFill({ color: rgbToHex(TRANSPARENT_COLOR) })
          : getFill({ color: rgbToHex({ ...this.fillColor, alpha: 128 }) }),
        zIndex: this.zIndex,
      }),
    );
    this.polygons.push(framePolygon);

    const outerPolygon = new Polygon([this.outerCoords]);
    outerPolygon.set('type', MAP_ELEMENT_TYPES.COMPARTMENT);
    let outerPolygonStroke: Stroke | undefined;
    if (this.overlaysVisible) {
      outerPolygonStroke = getStroke({ width: this.outerWidth });
    } else {
      outerPolygonStroke = getStroke({ color: rgbToHex(this.borderColor), width: this.outerWidth });
    }
    outerPolygon.set('strokeStyle', outerPolygonStroke);
    this.styles.push(
      new Style({
        geometry: outerPolygon,
        stroke: outerPolygonStroke,
        zIndex: this.zIndex,
      }),
    );
    this.polygons.push(outerPolygon);

    const innerPolygon = new Polygon([this.innerCoords]);
    innerPolygon.set('type', MAP_ELEMENT_TYPES.COMPARTMENT);
    let innerPolygonStroke: Stroke | undefined;
    if (this.overlaysVisible) {
      innerPolygonStroke = getStroke({ width: this.innerWidth });
    } else {
      innerPolygonStroke = getStroke({ color: rgbToHex(this.borderColor), width: this.innerWidth });
    }
    innerPolygon.set('strokeStyle', innerPolygonStroke);
    this.styles.push(
      new Style({
        geometry: innerPolygon,
        stroke: innerPolygonStroke,
        fill: this.overlaysVisible
          ? getFill({ color: rgbToHex(TRANSPARENT_COLOR) })
          : getFill({ color: rgbToHex({ ...this.fillColor, alpha: 9 }) }),
        zIndex: this.zIndex,
      }),
    );
    this.polygons.push(innerPolygon);
  }
}
