import { queryDataParamsSelector } from '@/redux/root/query.selectors';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initDataAndMapLoadingFinished } from '../../redux/root/init.selectors';

export const useReduxBusQueryManager = (): void => {
  const router = useRouter();
  const queryData = useSelector(queryDataParamsSelector);
  const isDataLoaded = useSelector(initDataAndMapLoadingFinished);

  const handleChangeQuery = useCallback(
    () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      router.replace(
        {
          query: {
            ...queryData,
          },
        },
        undefined,
        {
          shallow: true,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryData],
  );

  useEffect(() => {
    if (!isDataLoaded) {
      return;
    }

    handleChangeQuery();
  }, [handleChangeQuery, isDataLoaded]);
};
