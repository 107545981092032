import { commentSchema } from '@/models/commentSchema';
import { apiPath } from '@/redux/apiPath';
import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { ThunkConfig } from '@/types/store';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Comment } from '@/types/models';
import { AddCommentProps } from '@/redux/comment/comment.types';
import { getError } from '@/utils/error-report/getError';

export const addComment = createAsyncThunk<Comment | null, AddCommentProps, ThunkConfig>(
  'project/addComment',
  async ({ email, content, modelId, position }) => {
    try {
      const payload = new URLSearchParams({ email, content });
      const response = await axiosInstance.post<Comment>(
        apiPath.addComment(modelId, Math.trunc(position.x), Math.trunc(position.y)),
        payload,
      );

      const isDataValid = validateDataUsingZodSchema(response.data, commentSchema);

      return isDataValid ? response.data : null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);
