import { z } from 'zod';

export const targetElementLeanResponseSchema = z.object({
  content: z.array(
    z.object({
      bioEntity: z.object({
        elementId: z.string(),
      }),
    }),
  ),
  totalPages: z.number(),
  totalElements: z.number(),
  numberOfElements: z.number(),
  size: z.number(),
  number: z.number(),
});
