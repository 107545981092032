/* eslint-disable no-magic-numbers */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { processOverlayContentChange } from '../UserOverlayForm.utils';

type FileUploadProps = {
  updateUserOverlayForm: (nameType: string, value: string) => void;
  handleChangeOverlayContent: (value: string) => void;
  handleChangeFile: (value: File) => void;
  uploadedFile: File | null;
};

export const FileUpload = ({
  handleChangeFile,
  handleChangeOverlayContent,
  updateUserOverlayForm,
  uploadedFile,
}: FileUploadProps): React.ReactNode => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: {
      'text/plain': ['.txt'],
    },
    onDrop: acceptedFiles => {
      handleChangeFile(acceptedFiles[0]);

      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e): void => {
          if (e.target) {
            const content = e.target?.result as string;
            handleChangeOverlayContent(content);
            processOverlayContentChange(content, updateUserOverlayForm);
          }
        };
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className="flex h-16 items-center justify-center rounded-lg bg-cultured"
      data-testid="dropzone"
    >
      <input {...getInputProps()} data-testid="dropzone-input" />
      <p className="text-xs font-semibold">
        {uploadedFile && uploadedFile.name}

        {isDragActive && !isDragReject && 'Drop the file here ...'}

        {!isDragActive && !uploadedFile && (
          <>
            Drag and drop here or <span className="text-[#004DE2]">browse</span>
          </>
        )}
        {isDragReject && 'Invalid file type. Please choose a supported format .txt'}
      </p>
    </div>
  );
};
