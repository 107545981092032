/* eslint-disable no-magic-numbers */
import { Point as PointType } from '@/types/map';
import { NewReaction, Segment } from '@/types/models';
import { distance } from 'ol/coordinate';
import { LineString, Point } from 'ol/geom';
import { getMaxClickDistance } from './getMaxClickDistance';

type ReactionLine = Segment;

type FindClosestReactionArgs = {
  reaction: NewReaction;
  searchDistance: string;
  maxZoom: number;
  zoom: number;
  point: PointType;
};

export function getReactionLineSegments(reaction: NewReaction): Segment[] {
  const result: Segment[] = [];
  result.push(...reaction.line.segments);
  reaction.reactants.forEach(reactant => {
    result.push(...reactant.line.segments);
  });
  reaction.products.forEach(product => {
    result.push(...product.line.segments);
  });
  reaction.modifiers.forEach(modifier => {
    result.push(...modifier.line.segments);
  });
  reaction.operators.forEach(operator => {
    result.push(...operator.line.segments);
  });
  return result;
}

export const findClosestReactionPoint = ({
  reaction,
  searchDistance,
  maxZoom,
  zoom,
  point,
}: FindClosestReactionArgs): ReactionLine | undefined => {
  const maxDistance = getMaxClickDistance(maxZoom, zoom, searchDistance);

  const clickedPoint = new Point([point.x, point.y]);

  const lines = getReactionLineSegments(reaction);

  const closestLine = lines.find(line => {
    const lineString = new LineString([
      [line.x1, line.y1],
      [line.x2, line.y2],
    ]);

    const closestPointOnLine = lineString.getClosestPoint(clickedPoint.getCoordinates());
    const distanceToLine = distance(closestPointOnLine, clickedPoint.getCoordinates());

    return distanceToLine <= maxDistance;
  });

  return closestLine;
};
