/* eslint-disable no-param-reassign */
import { ConnectDragSource, ConnectDropTarget, useDrag, useDrop } from 'react-dnd';

const ITEM_TYPE = 'card';

type UseDragAndDropProps = {
  index: number;
  onHover: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
};

type UseDragAndDropReturn = {
  isDragging: boolean;
  dragRef: ConnectDragSource;
  dropRef: ConnectDropTarget;
};

export const useDragAndDrop = ({
  index,
  onDrop,
  onHover,
}: UseDragAndDropProps): UseDragAndDropReturn => {
  const [{ isDragging }, dragRef] = useDrag({
    type: ITEM_TYPE,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: ITEM_TYPE,
    hover: (item: { index: number }) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      onHover(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    drop() {
      onDrop();
    },
  });

  return {
    isDragging,
    dragRef,
    dropRef,
  };
};
