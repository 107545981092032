/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { FeatureLike } from 'ol/Feature';
import { MapInstance } from '@/types/map';
import { apiPath } from '@/redux/apiPath';
import { BASE_NEW_API_URL } from '@/constants';
import Polygon from 'ol/geom/Polygon';
import { Point } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { FEATURE_TYPE } from '@/constants/features';
import getStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStyle';
import { WHITE_COLOR } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';

export type GlyphProps = {
  elementId: number;
  glyphId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
};

export default class Glyph {
  feature: Feature<Polygon>;

  style: Style = new Style({});

  imageScale: number = 1;

  polygonStyle: Style;

  width: number;

  height: number;

  x: number;

  y: number;

  widthOnMap: number;

  heightOnMap: number;

  pixelRatio: number = 1;

  pointToProjection: UsePointToProjectionResult;

  constructor({
    elementId,
    glyphId,
    x,
    y,
    width,
    height,
    zIndex,
    pointToProjection,
    mapInstance,
  }: GlyphProps) {
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.pointToProjection = pointToProjection;
    const point1 = this.pointToProjection({ x: 0, y: 0 });
    const point2 = this.pointToProjection({ x: this.width, y: this.height });
    this.widthOnMap = Math.abs(point2[0] - point1[0]);
    this.heightOnMap = Math.abs(point2[1] - point1[1]);
    const minResolution = mapInstance?.getView().getMinResolution();
    if (minResolution) {
      this.pixelRatio = this.widthOnMap / minResolution / this.width;
    }
    const polygon = new Polygon([
      [
        pointToProjection({ x, y }),
        pointToProjection({ x: x + width, y }),
        pointToProjection({ x: x + width, y: y + height }),
        pointToProjection({ x, y: y + height }),
        pointToProjection({ x, y }),
      ],
    ]);
    this.polygonStyle = getStyle({
      geometry: polygon,
      zIndex,
      borderColor: { ...WHITE_COLOR, alpha: 0 },
      fillColor: { ...WHITE_COLOR, alpha: 0 },
    });
    this.feature = new Feature({
      geometry: polygon,
      id: elementId,
      type: FEATURE_TYPE.GLYPH,
      zIndex,
      getImageScale: (resolution: number): number => {
        if (mapInstance) {
          return mapInstance.getView().getMinResolution() / resolution;
        }
        return 1;
      },
      getAnchorAndCoords: (): { anchor: Array<number>; coords: Coordinate } => {
        const center = mapInstance?.getView().getCenter();
        let anchorX = 0;
        let anchorY = 0;
        if (center) {
          anchorX =
            (center[0] - this.pointToProjection({ x: this.x, y: this.y })[0]) / this.widthOnMap;
          anchorY =
            -(center[1] - this.pointToProjection({ x: this.x, y: this.y })[1]) / this.heightOnMap;
        }
        return { anchor: [anchorX, anchorY], coords: center || [0, 0] };
      },
    });

    const img = new Image();
    img.onload = (): void => {
      const imageWidth = img.naturalWidth;
      const imageHeight = img.naturalHeight;
      this.imageScale = width / imageWidth;
      this.style = new Style({
        image: new Icon({
          anchor: [0, 0],
          img,
          size: [imageWidth, imageHeight],
        }),
        zIndex,
      });
      this.feature.setStyle(this.getStyle.bind(this));
    };
    img.src = `${BASE_NEW_API_URL}${apiPath.getGlyphImage(glyphId)}`;
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    const getImageScale = feature.get('getImageScale');
    const getAnchorAndCoords = feature.get('getAnchorAndCoords');
    let imageScale = 1;
    let anchor = [0, 0];
    let coords = this.pointToProjection({ x: this.x, y: this.y });
    if (getImageScale instanceof Function) {
      imageScale = getImageScale(resolution);
    }
    if (getAnchorAndCoords instanceof Function) {
      const anchorAndCoords = getAnchorAndCoords();
      anchor = anchorAndCoords.anchor;
      coords = anchorAndCoords.coords;
    }
    if (this.style.getImage()) {
      this.style.getImage()?.setScale(imageScale * this.pixelRatio * this.imageScale);
      (this.style.getImage() as Icon).setAnchor(anchor);
      this.style.setGeometry(new Point(coords));
    }
    return [this.style, this.polygonStyle];
  }
}
