import { twMerge } from 'tailwind-merge';
import { CheckboxItem } from '../CheckboxFilter.types';

interface Props {
  option: CheckboxItem;
  currentOptions: CheckboxItem[];
  type: 'checkbox' | 'radio';
  handleCheckboxChange(option: CheckboxItem): void;
  handleRadioChange(option: CheckboxItem): void;
}

export const OptionInput = ({
  option,
  currentOptions = [],
  type,
  handleCheckboxChange,
  handleRadioChange,
}: Props): React.ReactNode => {
  const isChecked = Boolean(currentOptions.find(currentOption => currentOption.id === option.id));

  const handleChange = (): void => {
    switch (type) {
      case 'checkbox':
        handleCheckboxChange(option);
        break;
      case 'radio':
        handleRadioChange(option);
        break;
      default:
        throw new Error(`${type} is unknown option input type`);
    }
  };

  return (
    <label className="flex items-center gap-x-2">
      <input
        type={type}
        className={twMerge(
          'h-4 w-4 shrink-0 accent-primary-500',
          type === 'radio' && 'rounded-full',
        )}
        onChange={handleChange}
        checked={isChecked}
      />
      <div className="break-all text-sm">{option.label}</div>
    </label>
  );
};
