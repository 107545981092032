import Image from 'next/image';
import spinnerIcon from '@/assets/vectors/icons/spinner.svg';

type LoadingIndicatorProps = {
  height?: number;
  width?: number;
};

const DEFAULT_HEIGHT = 16;
const DEFAULT_WIDTH = 16;

export const LoadingIndicator = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}: LoadingIndicatorProps): JSX.Element => (
  <Image
    src={spinnerIcon}
    alt="spinner icon"
    height={height}
    width={width}
    className="animate-spin"
    data-testid="loading-indicator"
  />
);
