import { modalSelector } from '@/redux/modal/modal.selector';
import { closeModal } from '@/redux/modal/modal.slice';
import { store } from '@/redux/store';

export const hideOverviewImageModal = (): void => {
  const { getState, dispatch } = store;
  const { modalName } = modalSelector(getState());

  if (modalName !== 'overview-images') {
    return;
  }

  dispatch(closeModal());
};
