/* eslint-disable no-magic-numbers */
import { Stroke } from 'ol/style';

export default function getStroke({
  color = '#000',
  width = 1,
  lineDash = [],
  lineCap = 'butt',
}: {
  color?: string;
  width?: number;
  lineDash?: Array<number>;
  lineCap?: string;
}): Stroke {
  return new Stroke({
    color,
    width,
    lineDash,
    lineCap: lineCap as CanvasLineCap,
  });
}
