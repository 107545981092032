import React from 'react';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { Button } from '@/shared/Button';

import { getSessionValid, logout, updateUser } from '@/redux/user/user.thunks';
import { closeModal, openSelectProjectModal } from '@/redux/modal/modal.slice';
import { userSelector } from '@/redux/user/user.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { termsOfServiceValSelector } from '@/redux/configuration/configuration.selectors';

export const ToSModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector(userSelector);

  const termsOfService = useAppSelector(termsOfServiceValSelector);

  const updateUserTosHandler = async (): Promise<void> => {
    if (userData) {
      const user = { ...userData, termsOfUseConsent: true };
      await dispatch(updateUser(user));
      await dispatch(getSessionValid());
      dispatch(closeModal());
      if (userData.orcidId && userData.orcidId !== '') {
        dispatch(openSelectProjectModal());
      }
    }
  };

  const logoutHandler = async (): Promise<void> => {
    await dispatch(logout());
    dispatch(closeModal());
  };

  return (
    <div className="w-[400px] border border-t-[#E1E0E6] bg-white p-[24px]">
      <div>
        I agree to the minerva{' '}
        <a href={termsOfService} target="_blank" className="underline">
          Terms of Service.
        </a>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        <div>
          <Button
            className="ring-transparent hover:ring-transparent"
            variantStyles="secondary"
            onClick={updateUserTosHandler}
          >
            OK
          </Button>
        </div>
        <div className="text-center">
          <Button className="block w-full" onClick={logoutHandler}>
            I disagree
          </Button>
        </div>
      </div>
    </div>
  );
};
