import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { authenticatedUserSelector } from '@/redux/user/user.selectors';
import { AuthenticatedUser } from './AuthenticatedUser';
import { UnauthenticatedUser } from './UnauthenticatedUser';

export const User = (): JSX.Element => {
  const authenticatedUser = useAppSelector(authenticatedUserSelector);

  return (
    <div className="relative">
      {authenticatedUser ? <AuthenticatedUser /> : <UnauthenticatedUser />}
    </div>
  );
};
