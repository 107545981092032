import { getBounds } from '@/services/pluginsManager/map/data/getBounds';
import { DEFAULT_IMAGE_SIZE, DEFAULT_MODEL_ASPECT_RATIOS } from '../ImageSize.constants';
import { ImageSize, ModelAspectRatios } from '../ImageSize.types';

export const getScreenAspectRatios = (): ModelAspectRatios => {
  const bounds = getBounds();
  if (!bounds) {
    return DEFAULT_MODEL_ASPECT_RATIOS;
  }
  const { x1, x2, y1, y2 } = bounds;
  const width = x2 - x1;
  const height = y2 - y1;

  return {
    vertical: height / width,
    horizontal: width / height,
  };
};

export const getScreenDimension = (): ImageSize => {
  const bounds = getBounds();
  if (!bounds) {
    return DEFAULT_IMAGE_SIZE;
  }
  const { x1, x2, y1, y2 } = bounds;
  const width = x2 - x1;
  const height = y2 - y1;

  return {
    width,
    height,
  };
};
