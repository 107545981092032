import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modelsDataSelector } from '@/redux/models/models.selectors';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { openMapAndSetActive, setActiveMap } from '@/redux/map/map.slice';
import { MapModel } from '@/types/models';
import { mapModelIdSelector, mapOpenedMapsSelector } from '@/redux/map/map.selectors';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { SubmapItem } from './SubmapItem/SubmapItem.component';

export const SubmapsDrawer = (): JSX.Element => {
  const models = useAppSelector(modelsDataSelector);
  const openedMaps = useAppSelector(mapOpenedMapsSelector);
  const currentModelId = useAppSelector(mapModelIdSelector);
  const dispatch = useAppDispatch();

  const isMapAlreadyOpened = (modelId: number): boolean =>
    openedMaps.some(map => map.modelId === modelId);

  const onSubmapOpenClick = (model: MapModel): void => {
    if (isMapAlreadyOpened(model.idObject)) {
      dispatch(setActiveMap({ modelId: model.idObject }));
    } else {
      dispatch(openMapAndSetActive({ modelId: model.idObject, modelName: model.name }));
    }
    if (currentModelId !== model.idObject) {
      PluginsEventBus.dispatchEvent('onSubmapClose', currentModelId);
      PluginsEventBus.dispatchEvent('onSubmapOpen', model.idObject);
    }
  };

  return (
    <div data-testid="submap-drawer" className="h-full max-h-full">
      <DrawerHeading title="Submaps" />
      <ul className="h-[calc(100%-93px)] max-h-[calc(100%-93px)] overflow-y-auto px-6">
        {models.map(model => (
          <SubmapItem
            key={model.idObject}
            modelName={model.name}
            onOpenClick={(): void => onSubmapOpenClick(model)}
          />
        ))}
      </ul>
    </div>
  );
};
