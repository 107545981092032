import { overlaySelector, userOverlaySelector } from '@/redux/overlays/overlays.selectors';
import { removeOverlay } from '@/redux/overlays/overlays.thunks';
import { store } from '@/redux/store';
import { ERROR_OVERLAY_ID_NOT_FOUND } from '../../errorMessages';

export const removeDataOverlay = (overlayId: number): void => {
  const { dispatch, getState } = store;
  const state = getState();

  const overlay = overlaySelector(state, overlayId);
  const userOverlay = userOverlaySelector(state, overlayId);

  const matchingOverlayId = overlay || userOverlay;

  if (!matchingOverlayId) throw new Error(ERROR_OVERLAY_ID_NOT_FOUND);

  dispatch(removeOverlay({ overlayId }));
};
