/* eslint-disable no-magic-numbers, no-param-reassign */
import type { SubmapLinkRectangle } from './useBioEntitiesWithSubmapLinks';

export const getSubmapLinkRectangle = (
  submapsLinksRectangles: SubmapLinkRectangle[],
  submapLinkRectangle: SubmapLinkRectangle,
  index: number,
  submapLinksRectanglesGroup: SubmapLinkRectangle[],
  rectangleHeight: number,
): void => {
  if (index === 0) {
    submapsLinksRectangles.push({
      ...submapLinkRectangle,
      amount: 0,
      value: Infinity,
    });
  }

  if (index !== 0) {
    submapLinkRectangle.y2 = submapLinksRectanglesGroup[index - 1].y1;
  }
  submapLinkRectangle.y1 = submapLinkRectangle.y2 + rectangleHeight;
  submapLinkRectangle.height = rectangleHeight;

  submapsLinksRectangles.push(submapLinkRectangle);
};
