import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '@/redux/root/root.selectors';
import { currentModelIdSelector } from '@/redux/models/models.selectors';

export const layersSelector = createSelector(rootSelector, state => state.layers);

export const layersStateForCurrentModelSelector = createSelector(
  layersSelector,
  currentModelIdSelector,
  (state, currentModelId) => state[currentModelId],
);

export const layersLoadingSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.loading,
);

export const layersVisibilityForCurrentModelSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.data?.layersVisibility || {},
);

export const layersForCurrentModelSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.data?.layers || [],
);
