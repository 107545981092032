import { z } from 'zod';
import { positionSchema } from './positionSchema';

export const markerTypeSchema = z.union([
  z.literal('pin'),
  z.literal('surface'),
  z.literal('line'),
]);

const markerBaseSchema = z.object({
  type: markerTypeSchema,
  id: z.string(),
  color: z.string(),
  opacity: z.number(),
  number: z.number().optional(),
  modelId: z.number().optional(),
});

const markerWithPositionBaseSchema = markerBaseSchema.extend({
  x: z.number(),
  y: z.number(),
});

export const markerPinSchema = markerWithPositionBaseSchema.extend({
  width: z.number().optional(),
  height: z.number().optional(),
});

export const markerSurfaceSchema = markerWithPositionBaseSchema.extend({
  width: z.number(),
  height: z.number(),
});

export const markerLineSchema = markerBaseSchema.extend({
  start: positionSchema,
  end: positionSchema,
});

export const markerWithPositionSchema = z.union([markerPinSchema, markerSurfaceSchema]);

export const markerSchema = z.union([markerPinSchema, markerSurfaceSchema, markerLineSchema]);
