import { Icon } from '@/shared/Icon';
import { Variant } from './AccordionItemButton.types';

export const getIcon = (variant: Variant): JSX.Element => {
  const variantsIcons: Record<Variant, JSX.Element> = {
    expandable: <Icon name="chevron-down" className="arrow-button h-6 w-6 fill-font-500" />,
    'non-expandable': <Icon name="chevron-right" className="h-6 w-6 fill-font-500" />,
  };

  return variantsIcons[variant];
};
