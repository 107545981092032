import { useLoadPlugin } from '@/components/Map/Drawer/AvailablePluginsDrawer/LoadPlugin/hooks/useLoadPlugin';
import { FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { setCurrentDrawerPluginHash } from '@/redux/plugins/plugins.slice';
import { Icon } from '@/shared/Icon';
import { MinervaPlugin } from '@/types/models';
import { RELOAD_PLUGIN_DRAWER_BUTTON_ROLE } from './PluginHeaderInfo.constants';

interface Props {
  plugin: MinervaPlugin;
}

export const PluginHeaderInfo = ({ plugin }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const { reloadPlugin } = useLoadPlugin({
    hash: plugin.hash,
    pluginUrl: plugin.urls[FIRST_ARRAY_ELEMENT],
    onPluginLoaded: () => {
      if (!plugin.withoutPanel) {
        dispatch(setCurrentDrawerPluginHash(plugin.hash));
      }
    },
  });

  return (
    <>
      <p className="truncate">
        Plugin: <b title={plugin.name}>{plugin.name}</b>
      </p>
      <button type="button" onClick={reloadPlugin} role={RELOAD_PLUGIN_DRAWER_BUTTON_ROLE}>
        <Icon name="reload" />
      </button>
    </>
  );
};
