import { PLUGINS_CONTENT_ELEMENT_ID } from '@/constants/plugins';
import { selectedDrawerPluginSelector } from '@/redux/plugins/plugins.selectors';
import { useSelector } from 'react-redux';
import { getPluginContentStyle } from './utils/getPluginContentStyle';

export const PluginContent = (): JSX.Element => {
  const selectedPlugin = useSelector(selectedDrawerPluginSelector);

  return (
    <>
      <style jsx global>
        {`
          ${getPluginContentStyle(selectedPlugin)}
        `}
      </style>
      <div
        id={PLUGINS_CONTENT_ELEMENT_ID}
        data-testid="drawer-plugins-content"
        className="h-[calc(100%-166px)] max-h-[calc(100%-166px)] overflow-y-auto"
      />
    </>
  );
};
