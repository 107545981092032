import { useContext, useState } from 'react';
import { Button } from '@/shared/Button';
import Image from 'next/image';
import spinnerIcon from '@/assets/vectors/icons/spinner.svg';
import { ExportContext } from '../ExportCompound.context';

export const DownloadNetwork = (): React.ReactNode => {
  const { handleDownloadNetwork } = useContext(ExportContext);
  const [downloadingNetwork, setDownloadingNetwork] = useState<boolean>(false);

  const handleDownloadNetworkWrapper = async (): Promise<void> => {
    setDownloadingNetwork(true);
    await handleDownloadNetwork();
    setDownloadingNetwork(false);
  };

  return (
    <div className="mt-6">
      <Button onClick={handleDownloadNetworkWrapper}>
        {downloadingNetwork && (
          <Image
            src={spinnerIcon}
            alt="spinner icon"
            height={12}
            width={12}
            className="mr-2 animate-spin"
          />
        )}
        Download
      </Button>
    </div>
  );
};
