import { bioEntityDataListSelector } from '@/redux/bioEntity/bioEntity.selectors';
import { store } from '@/redux/store';
import { BioEntityContent } from '@/types/models';

export const getAllBioEntities = (): BioEntityContent[] => {
  const { getState } = store;
  const bioEntities = bioEntityDataListSelector(getState());

  return bioEntities || [];
};
