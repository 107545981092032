/* eslint-disable no-magic-numbers */
import { OverlayBioEntityRender } from '@/types/OLrendering';
import Feature from 'ol/Feature';
import type Polygon from 'ol/geom/Polygon';
import { createFeatureFromExtent } from './createFeatureFromExtent';
import { getOverlaySubmapLinkRectangleFeatureStyle } from './getOverlaySubmapLinkRectangleFeatureStyle';

export const createOverlaySubmapLinkRectangleFeature = (
  [xMin, yMin, xMax, yMax]: number[],
  color: string | null,
  entityId: OverlayBioEntityRender['id'],
): Feature<Polygon> => {
  const feature = createFeatureFromExtent([xMin, yMin, xMax, yMax], entityId);
  feature.setStyle(getOverlaySubmapLinkRectangleFeatureStyle(color));
  return feature;
};
