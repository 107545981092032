import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '../root/root.selectors';

export const statisticsSelector = createSelector(rootSelector, state => state.statistics);

export const loadingStatisticsSelector = createSelector(statisticsSelector, state => state.loading);

export const statisticsDataSelector = createSelector(
  statisticsSelector,
  statistics => statistics?.data,
);
