import { NewReaction } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import { commentReactionSelector } from '@/redux/comment/comment.selectors';
import { currentDrawerReactionIdSelector } from '../drawer/drawer.selectors';
import { currentModelIdSelector } from '../models/models.selectors';
import { rootSelector } from '../root/root.selectors';
import { getReferencesWithoutEmptyLink } from './utils/getFilteredReferences';
import { getReferencesGroupedBySource } from './utils/getGroupedReferences';

export const reactionsSelector = createSelector(rootSelector, state => state.reactions);

export const reactionsDataSelector = createSelector(
  reactionsSelector,
  reactions => reactions?.data || [],
);

export const allReactionsSelectorOfCurrentMap = createSelector(
  reactionsDataSelector,
  currentModelIdSelector,
  (reactions, currentModelId): NewReaction[] => {
    return reactions.filter(({ model }) => model === currentModelId);
  },
);

export const currentDrawerReactionSelector = createSelector(
  reactionsDataSelector,
  commentReactionSelector,
  currentDrawerReactionIdSelector,
  (reactions, commentReaction, currentDrawerReactionId) => {
    if (commentReaction && commentReaction.id === currentDrawerReactionId) {
      return commentReaction;
    }

    return reactions.find(({ id }) => id === currentDrawerReactionId);
  },
);

export const currentDrawerReactionGroupedReferencesSelector = createSelector(
  currentDrawerReactionSelector,
  reaction => {
    const referencesFiltered = getReferencesWithoutEmptyLink(reaction);
    return getReferencesGroupedBySource(referencesFiltered);
  },
);
