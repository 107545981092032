import { Color } from '@/types/models';

export class DataOverlayEntry {
  bioEntityId: number;

  bioEntityType: string;

  bioEntityModelId: number;

  color: Color | null;

  value: number | null;

  constructor(
    bioEntityId: number,
    bioEntityType: string,
    bioEntityModelId: number,
    color: Color | null,
    value: number | null,
  ) {
    this.bioEntityId = bioEntityId;
    this.color = color;
    this.value = value;
    this.bioEntityType = bioEntityType;
    this.bioEntityModelId = bioEntityModelId;
  }
}
