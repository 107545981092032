import { PluginsManager } from '@/services/pluginsManager';
import axios from 'axios';
import { ChangeEvent, useMemo, useState, KeyboardEvent } from 'react';
import { ENTER_KEY_CODE, ZERO } from '@/constants/common';
import { showToast } from '@/utils/showToast';
import { getErrorMessage } from '@/utils/getErrorMessage';
import md5 from 'crypto-js/md5';
import {
  PLUGIN_LOADING_ERROR_PREFIX,
  PLUGIN_LOADING_TWICE_ERROR,
} from '../../AvailablePluginsDrawer.constants';

type UseLoadPluginReturnType = {
  handleChangePluginUrl: (event: ChangeEvent<HTMLInputElement>) => void;
  handleLoadPlugin: () => Promise<void>;
  isPending: boolean;
  pluginUrl: string;
  handleKeyPress: (event: KeyboardEvent<HTMLInputElement>) => Promise<void>;
};

export const useLoadPluginFromUrl = (): UseLoadPluginReturnType => {
  const [pluginUrl, setPluginUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isPending = useMemo(
    () => !pluginUrl || isLoading || !URL.canParse(pluginUrl),
    [pluginUrl, isLoading],
  );

  const handleLoadPlugin = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await axios(pluginUrl);
      let pluginScript = response.data;

      const hash = md5(pluginScript).toString();

      if (
        Array.isArray(PluginsManager.activePlugins[hash]) &&
        PluginsManager.activePlugins[hash].length > ZERO
      ) {
        setIsLoading(false);
        showToast({
          type: 'error',
          message: PLUGIN_LOADING_TWICE_ERROR,
        });
        return;
      }

      PluginsManager.setHashedPlugin({
        pluginUrl,
        pluginScript,
      });

      pluginScript += `//# sourceURL=${pluginUrl}`;

      /* eslint-disable no-new-func */
      const loadPlugin = new Function(pluginScript);

      loadPlugin();

      setPluginUrl('');
    } catch (error) {
      const errorMessage = getErrorMessage({ error, prefix: PLUGIN_LOADING_ERROR_PREFIX });
      showToast({
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.code === ENTER_KEY_CODE && !isPending) {
      await handleLoadPlugin();
    }
  };

  const handleChangePluginUrl = (event: ChangeEvent<HTMLInputElement>): void => {
    setPluginUrl(event.target.value);
  };

  return {
    handleChangePluginUrl,
    handleLoadPlugin,
    handleKeyPress,
    isPending,
    pluginUrl,
  };
};
