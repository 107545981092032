import { ModelElement } from '@/types/models';
import MapElement from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/MapElement';
import CompartmentCircle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/CompartmentCircle';
import CompartmentSquare from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/CompartmentSquare';
import CompartmentPathway from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/CompartmentPathway';
import Glyph from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/Glyph';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import { MapInstance } from '@/types/map';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { BioShapesDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import { OverlayOrder } from '@/redux/overlayBioEntity/overlayBioEntity.utils';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import VectorSource from 'ol/source/Vector';
import { MapSize } from '@/redux/map/map.types';

export default function processModelElements(
  modelElements: Array<ModelElement>,
  shapes: BioShapesDict,
  lineTypes: LineTypeDict,
  groupedElementsOverlays: Record<string, Array<OverlayBioEntityRender>>,
  overlaysOrder: Array<OverlayOrder>,
  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties,
  vectorSource: VectorSource,
  mapInstance: MapInstance,
  pointToProjection: UsePointToProjectionResult,
  mapBackgroundType: number,
  mapSize: MapSize,
): Array<MapElement | CompartmentCircle | CompartmentSquare | CompartmentPathway | Glyph> {
  const overlaysVisible = Boolean(overlaysOrder.length);
  const validElements: Array<
    MapElement | CompartmentCircle | CompartmentSquare | CompartmentPathway | Glyph
  > = [];
  modelElements.forEach((element: ModelElement) => {
    if (element.glyph) {
      const glyph = new Glyph({
        elementId: element.id,
        glyphId: element.glyph.id,
        x: element.x,
        y: element.y,
        width: element.width,
        height: element.height,
        zIndex: element.z,
        pointToProjection,
        mapInstance,
      });
      validElements.push(glyph);
      return;
    }

    if (element.sboTerm === 'SBO:0000290') {
      const compartmentProps = {
        id: element.id,
        sboTerm: element.sboTerm,
        complexId: element.complex,
        compartmentId: element.compartment,
        x: element.x,
        y: element.y,
        nameX: element.nameX,
        nameY: element.nameY,
        nameHeight: element.nameHeight,
        nameWidth: element.nameWidth,
        width: element.width,
        height: element.height,
        zIndex: element.z,
        innerWidth: element.innerWidth,
        outerWidth: element.outerWidth,
        thickness: element.thickness,
        fontColor: element.fontColor,
        fillColor: element.fillColor,
        borderColor: element.borderColor,
        nameVerticalAlign: element.nameVerticalAlign as VerticalAlign,
        nameHorizontalAlign: element.nameHorizontalAlign as HorizontalAlign,
        text: element.name,
        fontSize: element.fontSize,
        overlaysVisible,
        pointToProjection,
        mapInstance,
        vectorSource,
        mapBackgroundType,
        mapSize,
      };
      if (element.shape === 'OVAL_COMPARTMENT') {
        validElements.push(new CompartmentCircle(compartmentProps));
      } else if (element.shape === 'SQUARE_COMPARTMENT') {
        validElements.push(new CompartmentSquare(compartmentProps));
      } else if (element.shape === 'PATHWAY') {
        validElements.push(new CompartmentPathway(compartmentProps));
      }
      return;
    }
    const elementShapes = shapes[element.sboTerm];
    if (elementShapes) {
      validElements.push(
        new MapElement({
          id: element.id,
          complexId: element.complex,
          compartmentId: element.compartment,
          sboTerm: element.sboTerm,
          shapes: elementShapes,
          x: element.x,
          y: element.y,
          nameX: element.nameX,
          nameY: element.nameY,
          nameHeight: element.nameHeight,
          nameWidth: element.nameWidth,
          width: element.width,
          height: element.height,
          zIndex: element.z,
          lineWidth: element.lineWidth,
          lineType: element.borderLineType,
          fontColor: element.fontColor,
          fillColor: element.fillColor,
          borderColor: element.borderColor,
          nameVerticalAlign: element.nameVerticalAlign as VerticalAlign,
          nameHorizontalAlign: element.nameHorizontalAlign as HorizontalAlign,
          homodimer: element.homodimer,
          activity: element.activity,
          text: element.name,
          fontSize: element.fontSize,
          pointToProjection,
          mapInstance,
          vectorSource,
          modifications: element.modificationResidues,
          lineTypes,
          bioShapes: shapes,
          overlays: groupedElementsOverlays[element.id],
          overlaysOrder,
          overlaysVisible,
          getOverlayColor,
          mapBackgroundType,
          mapSize,
        }),
      );
    }
  });
  return validElements;
}
