import { DEFAULT_ZOOM, OPTIONS } from '@/constants/map';
import { searchDistanceValSelector } from '@/redux/configuration/configuration.selectors';
import { resultDrawerOpen } from '@/redux/drawer/drawer.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import {
  mapDataLastZoomValue,
  mapDataMaxZoomValue,
  mapDataSizeSelector,
} from '@/redux/map/map.selectors';
import {
  currentModelSelector,
  currentModelIdSelector,
  vectorRenderingSelector,
} from '@/redux/models/models.selectors';
import { MapInstance } from '@/types/map';
import { View } from 'ol';
import { unByKey } from 'ol/Observable';
import { Coordinate } from 'ol/coordinate';
import { Pixel } from 'ol/pixel';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { allCommentsSelectorOfCurrentMap } from '@/redux/comment/comment.selectors';
import { currentBackgroundSelector } from '@/redux/backgrounds/background.selectors';
import {
  PATHWAYS_AND_COMPARTMENTS_BACKGROUND,
  SEMANTIC_BACKGROUND,
} from '@/redux/backgrounds/backgrounds.constants';
import { TWO } from '@/constants/common';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { onMapRightClick } from './mapRightClick/onMapRightClick';
import { onMapSingleClick } from './mapSingleClick/onMapSingleClick';
import { onMapPositionChange } from './onMapPositionChange';
import { onPointerMove } from './onPointerMove';
import { useHandlePinIconClick } from './pinIconClick/useHandlePinIconClick';

interface UseOlMapListenersInput {
  view: View;
  mapInstance: MapInstance;
}

export const useOlMapListeners = ({ view, mapInstance }: UseOlMapListenersInput): void => {
  const mapSize = useSelector(mapDataSizeSelector);
  const model = useSelector(currentModelSelector);
  const modelId = useSelector(currentModelIdSelector);
  const modelMinZoom = model?.minZoom || TWO;
  const lastZoom = useSelector(mapDataLastZoomValue) || TWO;
  const background = useSelector(currentBackgroundSelector);
  const searchDistance = useSelector(searchDistanceValSelector);
  const maxZoom = useSelector(mapDataMaxZoomValue);
  const isResultDrawerOpen = useSelector(resultDrawerOpen);
  const coordinate = useRef<Coordinate>([]);
  const pixel = useRef<Pixel>([]);
  const dispatch = useAppDispatch();

  const comments = useSelector(allCommentsSelectorOfCurrentMap);
  const vectorRendering = useAppSelector(vectorRenderingSelector);
  useHandlePinIconClick();

  const handleRightClick = useDebouncedCallback(
    onMapRightClick(
      mapSize,
      modelId,
      dispatch,
      background?.name === SEMANTIC_BACKGROUND ||
        background?.name === PATHWAYS_AND_COMPARTMENTS_BACKGROUND,
      lastZoom - modelMinZoom,
    ),
    OPTIONS.clickPersistTime,
    {
      leading: false,
    },
  );

  const handleChangeCenter = useDebouncedCallback(
    onMapPositionChange(mapSize, dispatch),
    OPTIONS.queryPersistTime,
    { leading: false },
  );

  const handleMapSingleClick = useDebouncedCallback(
    onMapSingleClick(
      mapSize,
      modelId,
      dispatch,
      searchDistance,
      maxZoom,
      lastZoom || DEFAULT_ZOOM,
      isResultDrawerOpen,
      comments,
      background?.name === SEMANTIC_BACKGROUND ||
        background?.name === PATHWAYS_AND_COMPARTMENTS_BACKGROUND,
      lastZoom - modelMinZoom,
    ),
    OPTIONS.clickPersistTime,
    { leading: false },
  );

  useEffect(() => {
    const key = view.on('change:center', handleChangeCenter);
    return () => unByKey(key);
  }, [view, handleChangeCenter]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    const key = mapInstance.on('pointermove', event => onPointerMove(mapInstance, event));

    // eslint-disable-next-line consistent-return
    return () => unByKey(key);
  }, [mapInstance]);

  useEffect(() => {
    if (!mapInstance || vectorRendering) {
      return;
    }

    const key = mapInstance.on('singleclick', event =>
      handleMapSingleClick({ coordinate: event.coordinate, pixel: event.pixel }, mapInstance),
    );

    // eslint-disable-next-line consistent-return
    return () => unByKey(key);
  }, [mapInstance, handleMapSingleClick, vectorRendering]);

  useEffect(() => {
    if (!mapInstance || vectorRendering) {
      return;
    }

    const rightClickEvent = (e: MouseEvent): Promise<void> | undefined => {
      e.preventDefault();

      coordinate.current = mapInstance.getEventCoordinate(e);
      pixel.current = mapInstance.getEventPixel(e);

      return handleRightClick(coordinate.current, pixel.current);
    };

    mapInstance.getViewport().addEventListener('contextmenu', rightClickEvent);

    // eslint-disable-next-line consistent-return
    return () => mapInstance.getViewport().removeEventListener('contextmenu', rightClickEvent);
  }, [mapInstance, handleRightClick, vectorRendering]);
};
