import { ZERO } from '@/constants/common';
import { HALF } from '@/constants/dividers';
import { FEATURE_TYPE } from '@/constants/features';
import { BioEntity, MarkerWithPosition } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import isUUID from 'is-uuid';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { PinType } from '@/types/pin';

export const getPinFeature = (
  {
    x,
    y,
    width,
    height,
    id,
  }: Pick<BioEntity, 'id' | 'width' | 'height' | 'x' | 'y'> | MarkerWithPosition,
  pointToProjection: UsePointToProjectionResult,
  pinType: PinType,
): Feature => {
  const isMarker = isUUID.anyNonNil(`${id}`);

  const point = {
    x: x + (width || ZERO) / HALF,
    y: y + (height || ZERO) / HALF,
  };

  let type = null;

  if (pinType === 'comment') {
    type = FEATURE_TYPE.PIN_ICON_COMMENT;
  } else {
    type = isMarker ? FEATURE_TYPE.PIN_ICON_MARKER : FEATURE_TYPE.PIN_ICON_BIOENTITY;
  }

  const feature = new Feature({
    geometry: new Point(pointToProjection(point)),
    id,
    type,
  });

  return feature;
};
