import { twMerge } from 'tailwind-merge';
import { Accordion as Ac } from 'react-accessible-accordion';
import { DivAttributes } from 'react-accessible-accordion/dist/types/helpers/types';

type ID = string | number;

type AccordionProps = Pick<DivAttributes, Exclude<keyof DivAttributes, 'onChange'>> & {
  children: React.ReactNode;
  preExpanded?: ID[];
  allowMultipleExpanded?: boolean;
  allowZeroExpanded?: boolean;
  onChange?(args: ID[]): void;
  className?: string;
};

export const Accordion = ({
  children,
  preExpanded,
  allowMultipleExpanded,
  allowZeroExpanded,
  onChange,
  className,
  ...rest
}: AccordionProps): JSX.Element => (
  <Ac
    preExpanded={preExpanded}
    allowMultipleExpanded={allowMultipleExpanded}
    allowZeroExpanded={allowZeroExpanded}
    onChange={onChange}
    className={twMerge('text-base', className)}
    {...rest}
  >
    {children}
  </Ac>
);
