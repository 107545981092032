/* eslint-disable no-magic-numbers */
import { OPTIONS } from '@/constants/map';
import { currentBackgroundImagePathSelector } from '@/redux/backgrounds/background.selectors';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { projectDataSelector } from '@/redux/project/project.selectors';
import { Point } from '@/types/map';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import { Extent, boundingExtent } from 'ol/extent';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import { XYZ } from 'ol/source';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMapTileUrl } from './getMapTileUrl';

// useOlMapTileLayer returns visual tile layer of the map
// it makes it possible to view the map, scroll, zoom etc.
export const useOlMapTileLayer = (): BaseLayer => {
  const mapSize = useSelector(mapDataSizeSelector);
  const currentBackgroundImagePath = useSelector(currentBackgroundImagePathSelector);
  const project = useSelector(projectDataSelector);
  const pointToProjection = usePointToProjection();

  const tileExtent = useMemo((): Extent => {
    const topLeftPoint: Point = {
      x: mapSize.width,
      y: mapSize.height,
    };

    const bottomRightPoint: Point = {
      x: 0,
      y: 0,
    };

    return boundingExtent([topLeftPoint, bottomRightPoint].map(pointToProjection));
  }, [pointToProjection, mapSize]);

  const sourceUrl = useMemo(
    () => getMapTileUrl({ projectDirectory: project?.directory, currentBackgroundImagePath }),
    [project?.directory, currentBackgroundImagePath],
  );

  const source = useMemo(
    () =>
      new XYZ({
        url: sourceUrl,
        maxZoom: mapSize.maxZoom,
        minZoom: mapSize.minZoom,
        tileSize: Math.max(mapSize.tileSize, 1),
        wrapX: OPTIONS.wrapXInTileLayer,
      }),
    [sourceUrl, mapSize.maxZoom, mapSize.minZoom, mapSize.tileSize],
  );

  const tileLayer = useMemo(
    (): TileLayer<XYZ> =>
      new TileLayer({
        visible: true,
        source,
        extent: tileExtent,
      }),
    [source, tileExtent],
  );

  return tileLayer;
};
