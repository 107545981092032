import { ModificationResidue } from '@/types/models';

type ModificationResidueItemProps = Pick<ModificationResidue, 'state' | 'name'>;

export const ModificationResidueItem = ({
  state,
  name,
}: ModificationResidueItemProps): JSX.Element => (
  <li>
    <span>
      {state} {name && <span>at position {name}</span>}
    </span>
  </li>
);
