/* eslint-disable no-magic-numbers */
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useSelector } from 'react-redux';
import {
  allCommentsSelectorOfCurrentMap,
  commentSelector,
} from '@/redux/comment/comment.selectors';
import { getCommentsFeatures } from '@/components/Map/MapViewer/utils/config/commentsLayer/getCommentsFeatures';
import { useMemo } from 'react';

export const useOlMapCommentsLayer = (): VectorLayer<VectorSource<Feature<Geometry>>> => {
  const pointToProjection = usePointToProjection();
  const comments = useSelector(allCommentsSelectorOfCurrentMap);
  const isVisible = useSelector(commentSelector).isOpen;

  const elementsFeatures = useMemo(
    () =>
      [
        getCommentsFeatures(isVisible ? comments : [], {
          pointToProjection,
        }),
      ].flat(),
    [comments, pointToProjection, isVisible],
  );

  const vectorSource = useMemo(() => {
    return new VectorSource({
      features: [...elementsFeatures],
    });
  }, [elementsFeatures]);

  const pinsLayer = useMemo(
    () =>
      new VectorLayer({
        source: vectorSource,
      }),
    [vectorSource],
  );

  return pinsLayer;
};
