import { useSelect } from 'downshift';
import { twMerge } from 'tailwind-merge';
import React, { useMemo } from 'react';
import { MapOverlay } from '@/types/models';
import { Icon } from '@/shared/Icon';
import { useUserOverlayActions } from './hooks/useUserOverlayActions';
import { ACTION_TYPES } from './UserOverlayActions.constants';

type UserOverlayActionsProps = {
  overlay: MapOverlay;
};

export const UserOverlayActions = ({ overlay }: UserOverlayActionsProps): React.ReactNode => {
  const actions = useMemo(() => Object.values(ACTION_TYPES), []);
  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: actions,
  });

  const { handleActionClick } = useUserOverlayActions(overlay);

  return (
    <div className="relative">
      <div
        className="flex cursor-pointer justify-between bg-white p-2"
        {...getToggleButtonProps()}
        data-testid="actions-button"
      >
        <Icon name="three-dots" className="h-[22px] w-[4px]" />
      </div>
      <ul
        className={twMerge(
          `absolute right-0 top-0 z-10 w-28 rounded-lg border border-[#DBD9D9] bg-white px-2.5 text-center shadow-md`,
          !isOpen && 'hidden',
        )}
        {...getMenuProps()}
      >
        {isOpen &&
          actions.map((item, index) => (
            <li
              key={item}
              {...getItemProps({
                item,
                index,
                onClick: () => handleActionClick(item),
              })}
              className='relative cursor-pointer px-2.5 py-4 text-xs before:absolute before:bottom-0 before:left-1/2 before:top-full before:block before:h-px before:w-11/12 before:-translate-x-1/2 before:bg-[#E3E3E3] before:content-[""] before:last-of-type:hidden'
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};
