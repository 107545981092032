import { Reference } from '@/types/models';
import { AnnotationItem } from '@/components/Map/Drawer/BioEntityDrawer/AnnotationItem/AnnotationItem.component';
import React from 'react';
import { ZERO } from '@/constants/common';

type AnnotationItemListProps = {
  references: Reference[];
};

export const AnnotationItemList = ({ references }: AnnotationItemListProps): React.ReactNode => {
  const isReferenceAvailable = references.length > ZERO;
  return (
    <div>
      <h3 className="font-semibold">
        Annotations: {!isReferenceAvailable && <span className="font-normal">No annotations</span>}
      </h3>
      <div className="flex max-h-full flex-col gap-2 overflow-x-hidden pt-2">
        {isReferenceAvailable &&
          references.map(reference => (
            <AnnotationItem
              key={reference.id}
              type={reference.type}
              link={reference.link}
              resource={reference.resource}
            />
          ))}
      </div>
    </div>
  );
};
