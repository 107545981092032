import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import {
  layersForCurrentModelSelector,
  layersVisibilityForCurrentModelSelector,
} from '@/redux/layers/layers.selectors';
import { Switch } from '@/shared/Switch';
import { setLayerVisibility } from '@/redux/layers/layers.slice';
import { currentModelIdSelector } from '@/redux/models/models.selectors';

export const LayersDrawer = (): JSX.Element => {
  const layersForCurrentModel = useAppSelector(layersForCurrentModelSelector);
  const layersVisibilityForCurrentModel = useAppSelector(layersVisibilityForCurrentModelSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const dispatch = useAppDispatch();

  return (
    <div data-testid="layers-drawer" className="h-full max-h-full">
      <DrawerHeading title="Layers" />
      <div className="flex h-[calc(100%-93px)] max-h-[calc(100%-93px)] flex-col overflow-y-auto px-6">
        {layersForCurrentModel.map(layer => (
          <div key={layer.details.id} className="flex items-center justify-between border-b p-4">
            <h1>{layer.details.name}</h1>
            <Switch
              isChecked={layersVisibilityForCurrentModel[layer.details.id]}
              onToggle={value =>
                dispatch(
                  setLayerVisibility({
                    modelId: currentModelId,
                    visible: value,
                    layerId: layer.details.id,
                  }),
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
