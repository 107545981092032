/* eslint-disable no-magic-numbers */
export const expandHexToFullFormatIfItsShorthanded = (hexString: string): string => {
  const SHORT_HAND_REGEX = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHexString = hexString.replace(SHORT_HAND_REGEX, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });
  const fullHexStringWithPrefix = fullHexString.startsWith('#')
    ? fullHexString
    : `#${fullHexString}`;
  return fullHexStringWithPrefix;
};

const FULL_HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export const hexToRgb = (hexString: string): { r: number; g: number; b: number } | null => {
  const fullHexString = expandHexToFullFormatIfItsShorthanded(hexString);

  const result = FULL_HEX_REGEX.exec(fullHexString);

  if (!result) {
    return null;
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};
