import { twMerge } from 'tailwind-merge';
import { BackgroundSelector } from './BackgroundsSelector';

// top-[calc(64px+40px+24px)] -> TOP_BAR_HEIGHT+MAP_NAVIGATION_HEIGHT+DISTANCE_FROM_MAP_NAVIGATION

export const MapAdditionalOptions = (): JSX.Element => (
  <div className={twMerge('absolute right-6 top-[calc(64px+40px+24px)] z-10 flex')}>
    <BackgroundSelector />
  </div>
);
