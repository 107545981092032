import { Point as PointType } from '@/types/map';
import { BioEntity } from '@/types/models';
import { getMaxClickDistance } from './getMaxClickDistance';

export const findClosestBioEntityPoint = (
  bioEntityContents: BioEntity[],
  searchDistance: string,
  maxZoom: number,
  zoom: number,
  point: PointType,
): BioEntity | undefined => {
  const maxDistance = getMaxClickDistance(maxZoom, zoom, searchDistance);

  const matchingBioEntityFound = bioEntityContents.find(bio => {
    const { x, y, width, height } = bio;

    const minX = x - maxDistance;
    const maxX = x + width + maxDistance;
    const minY = y - maxDistance;
    const maxY = y + height + maxDistance;

    const withinXRange = point.x >= minX && point.x <= maxX;
    const withinYRange = point.y >= minY && point.y <= maxY;

    return withinXRange && withinYRange;
  });

  return matchingBioEntityFound;
};
