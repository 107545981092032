/* eslint-disable no-magic-numbers */
import Style from 'ol/style/Style';
import { Extent, getCenter } from 'ol/extent';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import getWrappedTextWithFontSize from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getWrappedTextWithFontSize';
import { Point } from 'ol/geom';
import getTextStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextStyle';
import { MapSize } from '@/redux/map/map.types';

export default function getCoverStyles(
  coverStyle: Style,
  largestExtent: Extent,
  text: string,
  scale: number,
  zIndex: number,
  mapSize: MapSize,
): Array<Style> {
  const styles: Array<Style> = [];
  coverStyle.setZIndex(zIndex);
  styles.push(coverStyle);

  if (text) {
    const [lng1, lat1] = toLonLat([largestExtent[0], largestExtent[1]]);
    const [lng2, lat2] = toLonLat([largestExtent[2], largestExtent[3]]);
    const point1 = latLngToPoint([lat1, lng1], mapSize);
    const point2 = latLngToPoint([lat2, lng2], mapSize);
    const maxWidth = point2.x - point1.x;
    const maxHeight = Math.abs(Math.abs(point2.y) - Math.abs(point1.y));
    const { text: brokenText, fontSize: calculatedFontSize } = getWrappedTextWithFontSize({
      text,
      maxWidth: maxWidth * scale * 0.9,
      maxHeight: maxHeight * scale * 0.9,
    });
    const center = getCenter(largestExtent);
    const textGeometry = new Point([center[0], center[1]]);

    const textStyle = getTextStyle({
      text: brokenText.trim(),
      fontSize: calculatedFontSize,
      color: '#000',
      zIndex,
      horizontalAlign: 'CENTER',
    });
    textStyle.setGeometry(textGeometry);
    styles.push(textStyle);
  }

  return styles;
}
