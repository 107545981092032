import { MapModel } from '@/types/models';
import { DEFAULT_MODEL_ASPECT_RATIOS } from '../ImageSize.constants';
import { ModelAspectRatios } from '../ImageSize.types';

export const getModelAspectRatios = (
  model: Pick<MapModel, 'width' | 'height'> | undefined,
): ModelAspectRatios => {
  if (!model) {
    return DEFAULT_MODEL_ASPECT_RATIOS;
  }

  return {
    vertical: model.height / model.width,
    horizontal: model.width / model.height,
  };
};
