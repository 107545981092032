import { CollapsibleSection } from '../../CollapsibleSection';
import { useImageSize } from './utils/useImageSize';

export const ImageSize = (): React.ReactNode => {
  const { width, height, handleChangeHeight, handleChangeWidth } = useImageSize();

  return (
    <CollapsibleSection title="Image size" dangerouslySetExpanded>
      <div className="flex flex-col gap-4">
        <label className="flex h-9 items-center gap-4">
          <span className="w-12">Width:&nbsp;</span>
          <input
            className="w-full rounded-[64px] border border-transparent bg-cultured px-4 py-2.5 text-xs font-medium text-font-400 outline-none  hover:border-greyscale-600 focus:border-greyscale-600"
            name="width"
            value={width}
            type="number"
            aria-label="export graphics width input"
            onChange={(e): void => {
              handleChangeWidth(Number(e.target.value));
            }}
          />
        </label>
        <label className="flex h-9 items-center gap-4">
          <span className="w-12">Height:&nbsp;</span>
          <input
            className="w-full rounded-[64px] border border-transparent bg-cultured px-4 py-2.5 text-xs font-medium text-font-400 outline-none  hover:border-greyscale-600 focus:border-greyscale-600"
            name="height"
            value={height}
            type="number"
            aria-label="export graphics height input"
            onChange={(e): void => handleChangeHeight(Number(e.target.value))}
          />
        </label>
      </div>
    </CollapsibleSection>
  );
};
