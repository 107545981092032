import { ONE_DECIMAL, ONE_HUNDRED, TWO, ZERO } from '@/constants/common';
import { HALF } from '@/constants/dividers';
import { addAlphaToHexString } from '@/utils/convert/addAlphaToHexString';
import { OverlayDataAxis } from '../../OverlayData.types';

interface OverlayAxisProps {
  title: string;
  background: {
    color: string;
  };
  bar: {
    color: string;
    percentage: number;
  };
  value: {
    title: string;
    isPositive: boolean;
    isNegative: boolean;
    isUndefined: boolean;
  };
}

const FULL_WIDTH = 100;
const DEFAULT_VALUE_TITLE = '-';

const getBarPercentage = (value?: number): number => {
  const valueNormalized = value || ZERO;
  const isValueUndefined = typeof value === 'undefined';

  const valuePositivePercentage = Math.abs(valueNormalized) * ONE_HUNDRED;
  const valuePositivePercentageHalf = valuePositivePercentage / HALF; // 100% = full width of posivie OR negative chart SIDE

  return isValueUndefined ? FULL_WIDTH : valuePositivePercentageHalf; // axis without value = 100% both sides width
};

const getValueTitle = (axis: OverlayDataAxis): string => {
  if (axis?.geneVariants) {
    return axis.geneVariants.length.toString();
  }

  return axis.value ? axis.value.toFixed(TWO) : DEFAULT_VALUE_TITLE;
};

export const getOverlayAxisData = (axis: OverlayDataAxis): OverlayAxisProps => {
  return {
    title: axis.title,
    background: {
      color: addAlphaToHexString(axis.color, ONE_DECIMAL),
    },
    bar: {
      color: axis.color,
      percentage: getBarPercentage(axis.value),
    },
    value: {
      title: getValueTitle(axis),
      isUndefined: typeof axis.value === 'undefined',
      isPositive: axis.value ? axis.value > ZERO : false,
      isNegative: axis.value ? axis.value < ZERO : false,
    },
  };
};
