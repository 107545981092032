import {
  CELL_DESIGNER_SBML_HANDLER_NAME_ID,
  GPML_HANDLER_NAME_ID,
  SBGN_ML_HANDLER_NAME_ID,
  SBML_HANDLER_NAME_ID,
} from '@/redux/configuration/configuration.constants';

export const SUBMAP_DOWNLOAD_HANDLERS_NAMES: Record<string, string> = {
  [GPML_HANDLER_NAME_ID]: 'GPML',
  [SBML_HANDLER_NAME_ID]: 'SBML',
  [CELL_DESIGNER_SBML_HANDLER_NAME_ID]: 'CellDesigner SBML',
  [SBGN_ML_HANDLER_NAME_ID]: 'SBGN-ML',
};
