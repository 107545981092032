/* eslint-disable no-magic-numbers */
import lensIcon from '@/assets/vectors/icons/lens.svg';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { CheckboxItem } from './CheckboxFilter.types';
import { OptionInput } from './OptionInput';

type CheckboxFilterProps = {
  options: CheckboxItem[];
  currentOptions: CheckboxItem[];
  onFilterChange?: (filteredItems: CheckboxItem[]) => void;
  onCheckedChange?: (filteredItems: CheckboxItem[]) => void;
  isSearchEnabled?: boolean;
  type?: 'checkbox' | 'radio';
};

export const CheckboxFilter = ({
  options,
  currentOptions = [],
  onFilterChange,
  onCheckedChange,
  isSearchEnabled = true,
  type = 'checkbox',
}: CheckboxFilterProps): React.ReactNode => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<CheckboxItem[]>(options);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<CheckboxItem[]>([]);

  const filterOptions = (term: string): void => {
    const filteredItems = options.filter(item =>
      item.label.toLowerCase().includes(term.toLowerCase()),
    );

    setFilteredOptions(filteredItems);
    onFilterChange?.(filteredItems);
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    filterOptions(newSearchTerm);
  };

  const handleCheckboxChange = (option: CheckboxItem): void => {
    const newCheckedCheckboxes = checkedCheckboxes.some(item => item.id === option.id)
      ? checkedCheckboxes.filter(item => item.id !== option.id)
      : [...checkedCheckboxes, option];

    setCheckedCheckboxes(newCheckedCheckboxes);
    onCheckedChange?.(newCheckedCheckboxes);
  };

  const handleRadioChange = (option: CheckboxItem): void => {
    setCheckedCheckboxes([option]);
    onCheckedChange?.([option]);
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <div className="relative" data-testid="checkbox-filter">
      {isSearchEnabled && (
        <div className="relative" data-testid="search">
          <input
            name="search-input"
            aria-label="search-input"
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search..."
            className="h-9 w-full rounded-[64px] border border-transparent bg-cultured px-4 py-2.5 text-xs font-medium text-font-400 outline-none  hover:border-greyscale-600 focus:border-greyscale-600"
          />

          <Image
            src={lensIcon}
            alt="lens icon"
            height={16}
            width={16}
            className="absolute right-4 top-2.5"
          />
        </div>
      )}

      <div
        className={twMerge(
          'mb-6 max-h-[300px] overflow-y-auto py-2.5 pr-2.5',
          isSearchEnabled && 'mt-6',
        )}
      >
        {filteredOptions.length === 0 ? (
          <p className="w-full text-sm text-font-400">No matching elements found.</p>
        ) : (
          <ul>
            {filteredOptions.map(option => (
              <li key={option.id} className="mb-5 flex items-center gap-x-2">
                <OptionInput
                  option={option}
                  currentOptions={currentOptions}
                  handleRadioChange={handleRadioChange}
                  handleCheckboxChange={handleCheckboxChange}
                  type={type}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
