import { TWO } from '@/constants/common';
import { GeneVariant } from '@/types/models';
import { twMerge } from 'tailwind-merge';

interface Props {
  data: GeneVariant[];
}

export const GeneVariantsTable = ({ data }: Props): JSX.Element => {
  return (
    <table className="rounded-lg text-xs shadow-tableBorderDivide">
      <tr className="border-b border-divide text-left text-[#6A6977]">
        <th className="py-4 pl-4 pt-5 font-light ">Contig</th>
        <th className="py-4 pt-5 font-light">Position</th>
        <th className="py-4 pt-5 font-light">From</th>
        <th className="py-4 pt-5 font-light">To</th>
        <th className="py-4 pr-4 pt-5 font-light">rsID</th>
      </tr>
      {data.map((variant, index) => {
        const isOdd = index % TWO;
        const isEven = !isOdd;

        return (
          <tr key={variant.position} className={twMerge('font-semibold', isEven && 'bg-[#F3F3F3]')}>
            <td className="py-4 pl-4">{variant.contig}</td>
            <td className="py-4">{variant.position}</td>
            <td className="py-4">{variant.originalDna}</td>
            <td className="py-4">{variant.modifiedDna}</td>
            <td className="py-4 pr-4">{variant.variantIdentifier}</td>
          </tr>
        );
      })}
    </table>
  );
};
