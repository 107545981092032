import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';

export const autocompleteSearchSelector = createSelector(
  rootSelector,
  state => state.autocompleteSearch,
);

export const autocompleteDrugSelector = createSelector(
  rootSelector,
  state => state.autocompleteDrug,
);

export const autocompleteChemicalSelector = createSelector(
  rootSelector,
  state => state.autocompleteChemical,
);
