/* eslint-disable no-magic-numbers */
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { setCurrentDrawerPluginHash } from '@/redux/plugins/plugins.slice';
import { Button } from '@/shared/Button';
import { MinervaPlugin } from '@/types/models';
import { useLoadPlugin } from './hooks/useLoadPlugin';

export interface Props {
  plugin: MinervaPlugin;
}

export const LoadPlugin = ({ plugin }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const { isPluginActive, togglePlugin, isPluginLoading } = useLoadPlugin({
    hash: plugin.hash,
    pluginUrl: plugin.urls[0],
    onPluginLoaded: () => {
      if (!plugin.withoutPanel) {
        dispatch(setCurrentDrawerPluginHash(plugin.hash));
      }
    },
  });

  return (
    <div className="flex w-full items-center justify-between text-sm">
      <span className="text-cetacean-blue">
        {plugin.name} ({plugin.version})
      </span>
      <Button
        variantStyles="secondary"
        className="h-10 self-end rounded-e rounded-s text-xs font-medium"
        onClick={togglePlugin}
        data-testid="toggle-plugin"
        disabled={isPluginLoading}
      >
        {isPluginActive ? 'Unload' : 'Load'}
      </Button>
    </div>
  );
};
