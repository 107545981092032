import { ZERO } from '@/constants/common';
import { miramiTypesSelector } from '@/redux/configuration/configuration.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  loadingStatisticsSelector,
  statisticsDataSelector,
} from '@/redux/statistics/statistics.selectors';
import { useContext } from 'react';
import { CheckboxFilter } from '../../CheckboxFilter';
import { CollapsibleSection } from '../../CollapsibleSection';
import { ExportContext } from '../ExportCompound.context';
import { AnnotationsType } from './Annotations.types';
import { getAnnotationsCheckboxElements } from './Annotations.utils';

type AnnotationsProps = {
  type: AnnotationsType;
};

export const Annotations = ({ type }: AnnotationsProps): React.ReactNode => {
  const { setAnnotations, data } = useContext(ExportContext);
  const currentAnnotations = data.annotations;
  const loadingStatistics = useAppSelector(loadingStatisticsSelector);
  const statistics = useAppSelector(statisticsDataSelector);
  const miramiTypes = useAppSelector(miramiTypesSelector);
  const isPending = loadingStatistics === 'pending';
  const checkboxElements = getAnnotationsCheckboxElements({ type, statistics, miramiTypes });

  return (
    <CollapsibleSection title="Select annotations">
      {isPending && <p>Loading...</p>}
      {!isPending && checkboxElements && checkboxElements.length > ZERO && (
        <CheckboxFilter
          options={checkboxElements}
          currentOptions={currentAnnotations}
          onCheckedChange={setAnnotations}
        />
      )}
    </CollapsibleSection>
  );
};
