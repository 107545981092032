import { Button } from '@/shared/Button';
import { Input } from '@/shared/Input';
import { useLoadPluginFromUrl } from './hooks/useLoadPluginFromUrl';

export const LoadPluginFromUrl = (): JSX.Element => {
  const { handleChangePluginUrl, handleLoadPlugin, isPending, pluginUrl, handleKeyPress } =
    useLoadPluginFromUrl();

  return (
    <div className="flex w-full">
      <label className="flex w-full flex-col gap-2 text-sm text-cetacean-blue">
        <span>URL:</span>
        <Input
          className="h-10 w-full flex-none bg-cultured p-3"
          type="url"
          value={pluginUrl}
          onKeyDown={handleKeyPress}
          onChange={handleChangePluginUrl}
          data-testid="load-plugin-input-url"
        />
      </label>
      <Button
        variantStyles="secondary"
        className="h-10 self-end rounded-e rounded-s text-xs font-medium"
        onClick={handleLoadPlugin}
        disabled={isPending}
        data-testid="load-plugin-button"
      >
        Load
      </Button>
    </div>
  );
};
