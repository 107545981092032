import { BioEntityContent } from '@/types/models';
import { BioEntitiesPinsListItem } from './BioEntitiesPinsListItem';

interface BioEntitiesPinsListProps {
  bioEnititesPins: BioEntityContent[];
}

export const BioEntitiesPinsList = ({ bioEnititesPins }: BioEntitiesPinsListProps): JSX.Element => {
  return (
    <ul className="h-[calc(100%-224px)] max-h-[calc(100%-224px)] overflow-auto px-6 py-2">
      {bioEnititesPins &&
        bioEnititesPins.map(result => (
          <BioEntitiesPinsListItem
            key={result.bioEntity.name}
            name={result.bioEntity.name}
            pin={result.bioEntity}
          />
        ))}
    </ul>
  );
};
