import { selectCookieBanner } from '@/redux/cookieBanner/cookieBanner.selector';
import { acceptCookies, showBanner } from '@/redux/cookieBanner/cookieBanner.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { Button } from '@/shared/Button';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import {
  cookiePolicyUrlSelector,
  matomoUrlSelector,
} from '@/redux/configuration/configuration.selectors';
import { OptionInput } from '@/components/Map/Drawer/ExportDrawer/CheckboxFilter/OptionInput';
import { CheckboxItem } from '@/components/Map/Drawer/ExportDrawer/CheckboxFilter/CheckboxFilter.types';
import { ZERO } from '@/constants/common';
import { injectMatomoTracking } from '@/utils/injectMatomoTracking';
import {
  USER_ACCEPTED_COOKIES_COOKIE_NAME,
  USER_ACCEPTED_COOKIES_COOKIE_VALUE,
  USER_ACCEPTED_COOKIES_DATE,
  USER_ACCEPTED_MATOMO_COOKIES_COOKIE_NAME,
} from './CookieBanner.constants';

const selectMatomoOption: CheckboxItem = {
  id: 'select-matomo',
  label: 'I agree to collect my data by matomo',
};

export const CookieBanner = (): React.ReactNode => {
  const dispatch = useAppDispatch();
  const { visible, accepted } = useAppSelector(selectCookieBanner);
  const cookiePolicyUrl = useAppSelector(cookiePolicyUrlSelector);
  const matomoUrl = useAppSelector(matomoUrlSelector);

  const [options, setOptions] = useState([selectMatomoOption]);

  useEffect(() => {
    const userAcceptedCookieDate = localStorage.getItem(USER_ACCEPTED_COOKIES_DATE);
    let isAccepted =
      localStorage.getItem(USER_ACCEPTED_COOKIES_COOKIE_NAME) ===
        USER_ACCEPTED_COOKIES_COOKIE_VALUE.ACCEPTED &&
      localStorage.getItem(USER_ACCEPTED_MATOMO_COOKIES_COOKIE_NAME) !== null &&
      localStorage.getItem(USER_ACCEPTED_MATOMO_COOKIES_COOKIE_NAME) !== undefined;
    if (!userAcceptedCookieDate) {
      isAccepted = false;
    } else {
      const whenAccepted = Date.parse(userAcceptedCookieDate);
      const now = Date.now();

      // eslint-disable-next-line no-magic-numbers
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      const diffDays = Math.round(Math.abs((now - whenAccepted) / oneDay));
      // eslint-disable-next-line no-magic-numbers
      if (diffDays > 365) {
        isAccepted = false;
      }
    }

    if (isAccepted) {
      dispatch(acceptCookies());
    } else {
      dispatch(showBanner());
    }
  }, [dispatch]);

  const handleAcceptCookies = (): void => {
    dispatch(acceptCookies());
    localStorage.setItem(
      USER_ACCEPTED_COOKIES_COOKIE_NAME,
      USER_ACCEPTED_COOKIES_COOKIE_VALUE.ACCEPTED,
    );
    localStorage.setItem(USER_ACCEPTED_COOKIES_DATE, new Date().toISOString().split('T')[ZERO]);
    if (options.length > ZERO) {
      localStorage.setItem(
        USER_ACCEPTED_MATOMO_COOKIES_COOKIE_NAME,
        USER_ACCEPTED_COOKIES_COOKIE_VALUE.ACCEPTED,
      );
      injectMatomoTracking(matomoUrl);
    } else {
      localStorage.setItem(
        USER_ACCEPTED_MATOMO_COOKIES_COOKIE_NAME,
        USER_ACCEPTED_COOKIES_COOKIE_VALUE.DECLINED,
      );
    }
  };

  const selectMatomo = (data: CheckboxItem): void => {
    if (options.length > ZERO) {
      setOptions([]);
    } else {
      setOptions([data]);
    }
  };

  if (!visible || accepted) {
    return null;
  }

  return (
    <div className="absolute bottom-8 left-1/2 z-10 -translate-x-1/2 rounded-lg bg-white p-6 drop-shadow">
      <h4 className="text-2xl font-bold">We use cookies!</h4>
      <p className="my-4 leading-loose">
        Minerva platform uses essential cookies to ensure its proper operation.
        {cookiePolicyUrl && (
          <p>
            For any queries in relation to our policy on cookies and your choices, please{' '}
            <Link href={cookiePolicyUrl} className="font-semibold text-[#1C00DE]">
              read here
            </Link>
          </p>
        )}
        <OptionInput
          option={selectMatomoOption}
          currentOptions={options}
          handleRadioChange={() => {}}
          handleCheckboxChange={selectMatomo}
          type="checkbox"
        />
      </p>
      <Button
        className="h-10 w-36 justify-center"
        onClick={handleAcceptCookies}
        aria-label="accept cookies"
      >
        Ok
      </Button>
    </div>
  );
};
