import { Icon } from '@/shared/Icon';
import { ReferenceGroup as ReferenceGroupType } from '@/types/reference';
import { DEFAULT_REFERENCE_SOURCE } from '../ReactionDrawer.constants';

export interface Props {
  group: ReferenceGroupType;
}

export const ReferenceGroup = ({ group: { source, references } }: Props): JSX.Element => (
  <>
    <h3 className="font-semibold">Source: {source || DEFAULT_REFERENCE_SOURCE}</h3>
    {references.map(({ id, link, type }) => (
      <a key={id} href={link} target="_blank">
        <div className="flex justify-between">
          <span>{`${type} (${id})`}</span>
          <Icon name="arrow" className="h-6 w-6 fill-font-500" />
        </div>
      </a>
    ))}
  </>
);
