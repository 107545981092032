import { MinervaPlugin } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import { isPluginHashWithPrefix } from '@/utils/plugins/isPluginHashWithPrefix';
import { rootSelector } from '../root/root.selectors';

export const pluginsSelector = createSelector(rootSelector, state => state.plugins);

export const pluginsListSelector = createSelector(pluginsSelector, plugins => {
  return plugins.list;
});

export const pluginsListDataSelector = createSelector(pluginsListSelector, pluginsList => {
  return pluginsList.data;
});

export const pluginsDrawerSelector = createSelector(pluginsSelector, plugins => {
  return plugins.drawer;
});

export const publicPluginsListSelector = createSelector(
  pluginsListDataSelector,
  pluginsListData => {
    return (pluginsListData || []).filter(plugin => plugin.isPublic);
  },
);

export const activePluginsSelector = createSelector(pluginsSelector, state => state.activePlugins);

export const activePluginsIdSelector = createSelector(
  activePluginsSelector,
  state => state.pluginsId,
);

export const activePluginsDataSelector = createSelector(
  activePluginsSelector,
  plugins => plugins.data,
);

export const allActivePluginsSelector = createSelector(
  activePluginsDataSelector,
  activePluginsIdSelector,
  (data, pluginsId) => {
    const result: MinervaPlugin[] = [];

    pluginsId.forEach(pluginId => {
      const element = data[pluginId];
      if (element) {
        result.push(element);
      }
    });

    return result;
  },
);

export const allActivePluginsWithPanelSelector = createSelector(allActivePluginsSelector, data => {
  return data.filter(plugin => !plugin.withoutPanel);
});

export const privateActivePluginsSelector = createSelector(
  allActivePluginsSelector,
  activePlugins => {
    return (activePlugins || []).filter(plugin => !plugin.isPublic);
  },
);

export const publicActivePluginsSelector = createSelector(
  allActivePluginsSelector,
  activePlugins => {
    return (activePlugins || []).filter(
      plugin => plugin.isPublic && isPluginHashWithPrefix(plugin.hash),
    );
  },
);

export const isPluginActiveSelector = createSelector(
  [activePluginsIdSelector, (_, activePlugin: string): string => activePlugin],
  (activePlugins, activePlugin) => activePlugins.includes(activePlugin),
);

export const isPluginLoadingSelector = createSelector(
  [activePluginsSelector, (_, activePlugins: string): string => activePlugins],
  ({ data, pluginsId }, pluginId) =>
    pluginsId.includes(pluginId) && data[pluginId] && !Object.keys(data[pluginId]).length,
);

export const currentDrawerPluginHashSelector = createSelector(
  pluginsDrawerSelector,
  drawer => drawer.currentPluginHash,
);

export const selectedDrawerPluginSelector = createSelector(
  allActivePluginsSelector,
  currentDrawerPluginHashSelector,
  (allActivePlugins, currentDrawerPluginHash) =>
    allActivePlugins.find(plugin => plugin.hash === currentDrawerPluginHash),
);

export const isPluginSelectedSelector = createSelector(
  [selectedDrawerPluginSelector, (_, pluginHash: string): string => pluginHash],
  (selectedPlugin, pluginHash) => selectedPlugin?.hash === pluginHash,
);

export const publicPluginsListWithoutActiveSelector = createSelector(
  publicPluginsListSelector,
  allActivePluginsSelector,
  (publicPlugins, allActivePlugins) => {
    const activePluginsHashes = allActivePlugins.map(p => p.hash);

    return (publicPlugins || []).filter(plugin => !activePluginsHashes.includes(plugin.hash));
  },
);
