import { MapSize } from '@/redux/map/map.types';
import { Point } from '@/types/map';
import { ElementSearchResult } from '@/types/models';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { getElementsByPoint } from '@/utils/search/getElementsByCoordinates';
import { Coordinate } from 'ol/coordinate';
import { toLonLat } from 'ol/proj';

interface GetSearchResultsInput {
  coordinate: Coordinate;
  mapSize: MapSize;
  modelId: number;
  shouldConsiderZoomLevel: boolean;
  considerZoomLevel?: number;
}

export const getSearchResults = async ({
  coordinate,
  mapSize,
  modelId,
  shouldConsiderZoomLevel,
  considerZoomLevel,
}: GetSearchResultsInput): Promise<{
  searchResults: ElementSearchResult[] | undefined;
  point: Point;
}> => {
  const [lng, lat] = toLonLat(coordinate);
  const point = latLngToPoint([lat, lng], mapSize);
  const searchResults = await getElementsByPoint({
    point,
    currentModelId: modelId,
    shouldConsiderZoomLevel,
    considerZoomLevel,
  });

  return {
    searchResults,
    point,
  };
};
