import { TabButton } from '../TabButton';
import { TAB_NAMES } from './TabNavigator.constants';
import { TabNames } from './TabNavigator.types';

type TabNavigatorProps = {
  activeTab: TabNames;
  onTabChange: (tabName: TabNames) => void;
};

export const TabNavigator = ({ activeTab, onTabChange }: TabNavigatorProps): React.ReactNode => (
  <div className="flex gap-5">
    {Object.entries(TAB_NAMES).map(([label, tabName]) => (
      <TabButton
        key={tabName}
        handleChangeTab={(): void => onTabChange(tabName)}
        label={label}
        active={activeTab === tabName}
      />
    ))}
  </div>
);
