type RGBColor = {
  r: number;
  g: number;
  b: number;
};

type InterpolateColorProps = {
  leftColor: RGBColor;
  rightColor: RGBColor;
  position: number;
};

export const lerpRGBColor = ({
  leftColor,
  rightColor,
  position,
}: InterpolateColorProps): RGBColor => {
  const result = {} as RGBColor;

  Object.keys(leftColor).forEach(key => {
    const numericKey = key as keyof RGBColor;
    result[numericKey] = Math.round(
      leftColor[numericKey] + (rightColor[numericKey] - leftColor[numericKey]) * position,
    );
  });

  return result;
};
