import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { isActiveLegendSelector } from '@/redux/legend/legend.selectors';
import { setActiveLegendId } from '@/redux/legend/legend.slice';
import { Button } from '@/shared/Button';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type LegendTypeProps = { id: string; name: string };

export const LegendTab = ({ id, name }: LegendTypeProps): React.ReactNode => {
  const dispatch = useAppDispatch();
  const isActiveLegend = useAppSelector(state => isActiveLegendSelector(state, id));

  const handleLegendTabClick = (): void => {
    dispatch(setActiveLegendId(id));
  };

  return (
    <Button
      className={twMerge('h-10 whitespace-nowrap', isActiveLegend ? 'bg-[#EBF4FF]' : 'font-normal')}
      variantStyles={isActiveLegend ? 'secondary' : 'ghost'}
      onClick={handleLegendTabClick}
    >
      {name}
    </Button>
  );
};
