import { roundToTwoDigits } from '@/utils/number/roundToTwoDigits';

type GetLatitudeCoordinatesProps = {
  width: number;
  nOverlays: number;
  /** bottom left corner of entity drawn on the map */
  xMin: number;
  overlayIndexBasedOnOrder: number;
};

type PolygonLatitudeCoordinates = {
  xMin: number;
  xMax: number;
};

export const getPolygonLatitudeCoordinates = ({
  width,
  nOverlays,
  xMin,
  overlayIndexBasedOnOrder,
}: GetLatitudeCoordinatesProps): PolygonLatitudeCoordinates => {
  const polygonWidth = width / nOverlays;
  const newXMin = xMin + polygonWidth * overlayIndexBasedOnOrder;
  const xMax = newXMin + polygonWidth;
  return { xMin: roundToTwoDigits(newXMin), xMax: roundToTwoDigits(xMax) };
};
