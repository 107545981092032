import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { openLoginModal } from '@/redux/modal/modal.slice';
import { userRoleSelector } from '@/redux/user/user.selectors';
import { logout } from '@/redux/user/user.thunks';
import { IconTypes } from '@/types/iconTypes';
import { USER_ROLE } from '@/constants/user';
import { useMemo } from 'react';
import { CURRENT_PROJECT_ADMIN_PANEL_URL } from '@/constants';
import { getOAuth } from '@/redux/oauth/oauth.thunks';
import { ADMIN_CURATOR_ACTIONS, BASE_ACTIONS } from '../User.constants';

type UseUserActionsReturnType = {
  actions: string[];
  handleActionClick: (action: string) => void;
  iconName: IconTypes;
  openModalLogin: () => void;
};

export const useUserActions = (): UseUserActionsReturnType => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(userRoleSelector);

  const actions = useMemo(() => {
    return userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.CURATOR
      ? Object.values(ADMIN_CURATOR_ACTIONS)
      : Object.values(BASE_ACTIONS);
  }, [userRole]);

  const iconName: IconTypes = useMemo(() => {
    return userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.CURATOR ? 'manage-user' : 'user';
  }, [userRole]);

  const openModalLogin = (): void => {
    dispatch(getOAuth());
    dispatch(openLoginModal());
  };

  const handleLogOut = (): void => {
    dispatch(logout());
  };

  const goToThePanelAdmin = (): void => {
    window.location.href = CURRENT_PROJECT_ADMIN_PANEL_URL;
  };

  const handleActionClick = (action: string): void => {
    switch (action) {
      case ADMIN_CURATOR_ACTIONS.LOG_OUT:
        handleLogOut();
        break;
      case ADMIN_CURATOR_ACTIONS.SWITCH_ACCOUNT:
        openModalLogin();
        break;
      case ADMIN_CURATOR_ACTIONS.ADMIN_PANEL:
        goToThePanelAdmin();
        break;
      default:
        throw new Error(`${action} is not valid`);
    }
  };

  return {
    actions,
    iconName,
    handleActionClick,
    openModalLogin,
  };
};
