import { OverlayBioEntityRender } from '@/types/OLrendering';
import Feature from 'ol/Feature';
import type Polygon from 'ol/geom/Polygon';
import { Fill, Stroke, Style } from 'ol/style';
import { createFeatureFromExtent } from './createFeatureFromExtent';

const getBioEntityOverlayFeatureStyle = (color: string): Style =>
  new Style({
    fill: new Fill({ color }),
    stroke: new Stroke({ color: 'black', width: 1 }),
  });

export const createOverlayGeometryFeature = (
  [xMin, yMin, xMax, yMax]: number[],
  color: string,
  entityId: OverlayBioEntityRender['id'],
): Feature<Polygon> => {
  const feature = createFeatureFromExtent([xMin, yMin, xMax, yMax], entityId);
  feature.setStyle(getBioEntityOverlayFeatureStyle(color));
  return feature;
};
