import { mapModelIdSelector, mapOpenedMapsSelector } from '@/redux/map/map.selectors';
import { openMapAndSetActive, setActiveMap } from '@/redux/map/map.slice';
import { modelsDataSelector } from '@/redux/models/models.selectors';
import { store } from '@/redux/store';
import { PluginsEventBus } from '../pluginsEventBus';
import { ERROR_MAP_NOT_FOUND } from '../errorMessages';

export type OpenMapArgs = {
  id: number;
};

export const openMap = ({ id }: OpenMapArgs): void => {
  const { getState, dispatch } = store;
  const models = modelsDataSelector(getState());
  const openedMaps = mapOpenedMapsSelector(getState());
  const mapToOpen = models.find(model => model.idObject === id);
  const currentModelId = mapModelIdSelector(getState());

  if (!mapToOpen) throw new Error(ERROR_MAP_NOT_FOUND);

  const isMapAlreadyOpened = openedMaps.some(map => map.modelId === mapToOpen.idObject);

  if (isMapAlreadyOpened) {
    dispatch(setActiveMap({ modelId: mapToOpen.idObject }));
  } else {
    dispatch(openMapAndSetActive({ modelId: mapToOpen.idObject, modelName: mapToOpen.name }));
  }

  if (currentModelId !== mapToOpen.idObject) {
    PluginsEventBus.dispatchEvent('onSubmapClose', currentModelId);
    PluginsEventBus.dispatchEvent('onSubmapOpen', mapToOpen.idObject);
  }
};
