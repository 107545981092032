import { MinervaPlugin } from '@/types/models';
import {
  HIDE_ALL_ELEMENTS_STYLE,
  SHOW_SELECTED_PLUGIN_ELEMENT_STYLE,
} from '../PluginContent.constants';

export const getPluginContentStyle = (selectedPlugin?: MinervaPlugin): string => {
  if (!selectedPlugin) {
    return HIDE_ALL_ELEMENTS_STYLE;
  }

  return `
    ${HIDE_ALL_ELEMENTS_STYLE}
    ${SHOW_SELECTED_PLUGIN_ELEMENT_STYLE(selectedPlugin.hash)}
  `;
};
