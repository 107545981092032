import { store } from '@/redux/store';
import { openedDrawerSelector } from '@/redux/drawer/drawer.selectors';
import { DrawerName } from '@/types/drawerName';
import { closeDrawer } from '@/redux/drawer/drawer.slice';

export const hidePanel = (panelName: DrawerName | undefined): void => {
  const { getState, dispatch } = store;
  const drawerName = openedDrawerSelector(getState());

  if (panelName === drawerName || panelName === undefined) {
    dispatch(closeDrawer());
  }
};
