import { ReferenceFiltered, ReferenceGrouped } from '@/types/reference';
import { groupBy } from '@/utils/array/groupBy';

export const getReferencesGroupedBySource = (references: ReferenceFiltered[]): ReferenceGrouped => {
  const referencesGroupedObject = groupBy(references, ref => ref.annotatorClassName);

  return Object.entries(referencesGroupedObject).map(([source, refs]) => ({
    source,
    references: refs,
  }));
};
