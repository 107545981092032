import { apiPath } from '@/redux/apiPath';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { openLicenseModal, openPublicationsModal } from '@/redux/modal/modal.slice';
import { mainMapModelDescriptionSelector } from '@/redux/models/models.selectors';
import {
  diseaseLinkSelector,
  diseaseNameSelector,
  organismLinkSelector,
  organismNameSelector,
  projectNameSelector,
  projectSelector,
  versionSelector,
} from '@/redux/project/project.selectors';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { LinkButton } from '@/shared/LinkButton';
import { useEffect } from 'react';
import './ProjectInfoDrawer.styles.css';
import { BASE_API_URL } from '@/constants';

export const ProjectInfoDrawer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const diseaseName = useAppSelector(diseaseNameSelector);
  const diseaseLink = useAppSelector(diseaseLinkSelector);
  const organismLink = useAppSelector(organismLinkSelector);
  const organismName = useAppSelector(organismNameSelector);
  const projectName = useAppSelector(projectNameSelector);
  const project = useAppSelector(projectSelector).data;
  const version = useAppSelector(versionSelector);
  const description = useAppSelector(mainMapModelDescriptionSelector);

  const sourceDownloadLink = BASE_API_URL + apiPath.getSourceFile();

  let licenseName: string = '';
  if (project) {
    licenseName = project.license ? project.license.name : project.customLicenseName;
  }
  const licenseExists = licenseName !== '';

  useEffect(() => {
    // dispatch(getPublications());
  }, [dispatch]);

  const onPublicationsClick = (): void => {
    dispatch(openPublicationsModal());
  };

  const onLicenseClick = (): void => {
    dispatch(openLicenseModal(licenseName));
  };

  return (
    <div data-testid="export-drawer" className="h-full max-h-full">
      <DrawerHeading title="Project info" />
      <div className="h-[calc(100%-93px)] max-h-[calc(100%-93px)] overflow-y-auto px-6">
        <p className="mt-6">
          Name: <span className="font-semibold">{projectName}</span>
        </p>
        <p className="mt-4">
          Version: <span className="font-semibold">{version}</span>
        </p>
        <div className="mt-4">Data:</div>
        <ul className="list-disc pl-6 ">
          <li className="mt-2 text-hyperlink-blue">
            <button type="button" onClick={onPublicationsClick} className="text-base font-semibold">
              Publications
            </button>
          </li>
          <li className="mt-2 text-hyperlink-blue">
            <a
              href="https://minerva.pages.uni.lu/doc/"
              target="_blank"
              rel="noopener noreferrer"
              className="font-semibold hover:underline"
            >
              Manual
            </a>
          </li>
          {diseaseName && (
            <li className="mt-2 text-hyperlink-blue">
              <span className="text-black">Disease: </span>
              <a
                href={diseaseLink}
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold hover:underline"
              >
                {diseaseName}
              </a>
            </li>
          )}
          {licenseExists && (
            <li className="mt-2 text-hyperlink-blue">
              <span className="text-black">License: </span>
              <button
                type="button"
                onClick={onLicenseClick}
                className="w-full truncate text-base font-semibold"
                title={licenseName}
              >
                {licenseName}
              </button>
            </li>
          )}
          {organismName && (
            <li className="mt-2 text-hyperlink-blue">
              <span className="text-black">Organism: </span>
              <a
                href={organismLink}
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold hover:underline"
              >
                {organismName}
              </a>
            </li>
          )}
        </ul>
        <LinkButton className="mt-6" href={sourceDownloadLink} download="sourceFile.txt">
          Download source file
        </LinkButton>
        {description && (
          <div
            data-testid="project-description"
            className="anchor-tag mt-7 rounded-lg bg-cultured px-4 py-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  );
};
