import { DEFAULT_ZOOM } from '@/constants/map';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { setMapPosition, varyPositionZoom } from '@/redux/map/map.slice';
import { currentModelIdSelector, modelByIdSelector } from '@/redux/models/models.selectors';
import { SetBoundsResult, useSetBounds } from '@/utils/map/useSetBounds';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MAP_ZOOM_IN_DELTA, MAP_ZOOM_OUT_DELTA } from '../MappAdditionalActions.constants';
import { useVisibleBioEntitiesPolygonCoordinates } from './useVisibleBioEntitiesPolygonCoordinates';

interface UseAddtionalActionsResult {
  zoomIn(): void;
  zoomOut(): void;
  zoomInToBioEntities(): void;
}

export const useAddtionalActions = (): UseAddtionalActionsResult => {
  const dispatch = useDispatch();
  const setBounds = useSetBounds();
  const polygonCoordinates = useVisibleBioEntitiesPolygonCoordinates();
  const currentMapModelId = useAppSelector(currentModelIdSelector);
  const currentModel = useAppSelector(state => modelByIdSelector(state, currentMapModelId));

  const zoomInToBioEntities = (): SetBoundsResult | undefined => {
    if (polygonCoordinates) {
      return setBounds(polygonCoordinates);
    }

    if (currentModel) {
      const HALF = 2;
      const defaultPosition = {
        x: currentModel?.defaultCenterX ?? currentModel.width / HALF,
        y: currentModel?.defaultCenterY ?? currentModel.height / HALF,
        z: currentModel?.defaultZoomLevel ?? DEFAULT_ZOOM,
      };

      dispatch(setMapPosition(defaultPosition));
    }

    return undefined;
  };

  const varyZoomByDelta = useCallback(
    (delta: number) => {
      dispatch(varyPositionZoom({ delta }));
    },
    [dispatch],
  );

  return {
    zoomIn: () => varyZoomByDelta(MAP_ZOOM_IN_DELTA),
    zoomOut: () => varyZoomByDelta(MAP_ZOOM_OUT_DELTA),
    zoomInToBioEntities,
  };
};
