import { ZERO } from '@/constants/common';
import {
  compartmentPathwaysDataSelector,
  loadingCompartmentPathwaysSelector,
} from '@/redux/compartmentPathways/compartmentPathways.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { useContext } from 'react';
import { CheckboxFilter } from '../../CheckboxFilter';
import { CollapsibleSection } from '../../CollapsibleSection';
import { ExportContext } from '../ExportCompound.context';
import { getCompartmentPathwaysCheckboxElements } from '../utils/getCompartmentPathwaysCheckboxElements';

export const IncludedCompartmentPathways = (): React.ReactNode => {
  const { setIncludedCompartmentPathways, data } = useContext(ExportContext);
  const currentIncludedCompartmentPathways = data.includedCompartmentPathways;
  const loadingCompartmentPathways = useAppSelector(loadingCompartmentPathwaysSelector);
  const isPending = loadingCompartmentPathways === 'pending';
  const compartmentPathways = useAppSelector(compartmentPathwaysDataSelector);
  const checkboxElements = getCompartmentPathwaysCheckboxElements('included', compartmentPathways);

  return (
    <CollapsibleSection title="Select included compartment / pathways">
      {isPending && <p>Loading...</p>}
      {!isPending && checkboxElements && checkboxElements.length > ZERO && (
        <CheckboxFilter
          options={checkboxElements}
          currentOptions={currentIncludedCompartmentPathways}
          onCheckedChange={setIncludedCompartmentPathways}
        />
      )}
    </CollapsibleSection>
  );
};
