import { projectSchema } from '@/models/projectSchema';
import { store } from '@/redux/store';
import { Project } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { ERROR_PROJECT_NOT_FOUND } from '../../errorMessages';

type GetOrganismReturnType = Project['organism'] | undefined;

export const getOrganism = (): GetOrganismReturnType => {
  const project = store.getState().project.data;

  if (!project) throw new Error(ERROR_PROJECT_NOT_FOUND);

  const isDataValid = validateDataUsingZodSchema(project, projectSchema);

  return isDataValid ? project.organism : undefined;
};
