/* eslint-disable no-magic-numbers */
export const OVERLAY_TYPES = [
  {
    id: 'GENERIC',
    label: 'GENERIC',
  },
  {
    id: 'GENETIC_VARIANT',
    label: 'GENETIC_VARIANT',
  },
];

export const OVERLAY_GROUPS = [
  {
    id: 'WITHOUT_GROUP',
    label: 'Without group',
  },
];

export const DEFAULT_GROUP = OVERLAY_GROUPS[0];

export const DEFAULT_TYPE = OVERLAY_TYPES[0];
