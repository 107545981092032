import { BASE_API_URL, PROJECT_ID } from '@/constants';
import { currentBackgroundSelector } from '@/redux/backgrounds/background.selectors';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { currentModelSelector } from '@/redux/models/models.selectors';
import { useSelector } from 'react-redux';

export type GetSubmapDownloadUrl = ({ handler }: { handler: string }) => string;

export const useGetSubmapDownloadUrl = (): GetSubmapDownloadUrl => {
  const model = useSelector(currentModelSelector);
  const background = useSelector(currentBackgroundSelector);
  const mapSize = useSelector(mapDataSizeSelector);

  const getSubmapDownloadUrl: GetSubmapDownloadUrl = ({ handler }) => {
    const allParamsValid = [model?.idObject, background?.id, mapSize.maxZoom, handler].reduce(
      (a, b) => Boolean(a) && Boolean(b),
      true,
    );
    if (!allParamsValid) {
      return '';
    }

    return `${BASE_API_URL}/projects/${PROJECT_ID}/models/${model?.idObject}:downloadModel?backgroundOverlayId=${background?.id}&handlerClass=${handler}&zoomLevel=${mapSize.maxZoom}`;
  };

  return getSubmapDownloadUrl;
};
