import { CheckboxItem } from '../../CheckboxFilter/CheckboxFilter.types';

export const extractAndParseNumberIdFromCompartment = (compartment: CheckboxItem): number => {
  const [, id] = compartment.id.split('-');
  const numberId = Number(id);

  if (Number.isNaN(numberId) || id === '') throw new Error('compartment id is not a number');

  return numberId;
};
