/* eslint-disable no-magic-numbers */

type OverlayDataCallback = {
  (nameType: string, value: string): void;
};

const OVERLAY_INFO_INDICATOR = '#';
const ASSIGNMENT_OPERATOR = '=';

export const processOverlayContentChange = (
  fileContent: string,
  callback: OverlayDataCallback,
): void => {
  const content = fileContent.trim();
  const lines = content.split('\n');

  lines.forEach(line => {
    const isOverlayInfoLine = line.indexOf(OVERLAY_INFO_INDICATOR) === 0;
    const hasAssignment = line.indexOf(ASSIGNMENT_OPERATOR) > 0;

    if (isOverlayInfoLine && hasAssignment) {
      const nameType = line.substring(1, line.indexOf(ASSIGNMENT_OPERATOR)).trim();
      const value = line.substring(line.indexOf(ASSIGNMENT_OPERATOR) + 1).trim();
      callback(nameType, value);
    }
  });
};
