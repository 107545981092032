import { ZERO } from '@/constants/common';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { MarkerSurface } from '@/types/models';
import { addAlphaToHexString } from '@/utils/convert/addAlphaToHexString';

export const parseSurfaceMarkersToBioEntityRender = (
  markers: MarkerSurface[],
): OverlayBioEntityRender[] => {
  return markers.map(({ id, modelId, x, y, width, height, color, opacity }) => ({
    type: 'rectangle',
    id,
    x1: x,
    y1: y + height,
    x2: x + width,
    y2: y,
    width,
    height,
    value: opacity,
    modelId: modelId || ZERO, // ignored in next steps
    overlayId: ZERO, // ignored in next steps
    color: null, // replaced by hexColor in next steps
    hexColor: addAlphaToHexString(color, opacity),
  }));
};
