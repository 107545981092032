/* eslint-disable no-magic-numbers */
import { twMerge } from 'tailwind-merge';
import React, { useMemo } from 'react';
import { Icon } from '@/shared/Icon';

type UserOverlayInfoProps = {
  description: string;
  name: string;
};

export const UserOverlayInfo = ({ description, name }: UserOverlayInfoProps): React.ReactNode => {
  const isOverflowPossibility = useMemo(() => name.length > 25, [name]);

  return (
    <div className="flex items-center gap-x-2.5">
      <span className="text-sm">{name}</span>

      <div className="group relative" data-testid="info">
        <Icon name="info" className="h-4 w-4 fill-black" />

        <div
          className={twMerge(
            'absolute bottom-0 left-0 top-auto z-20 hidden min-w-[200px] max-w-xs rounded-lg bg-white px-3  py-4 drop-shadow-md group-hover:block',
            isOverflowPossibility && 'min-w-[100px] max-w-[200px]',
          )}
        >
          <p className="text-xs">{description}</p>
        </div>
      </div>
    </div>
  );
};
