import { openContextMenu } from '@/redux/contextMenu/contextMenu.slice';
import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { Coordinate } from 'ol/coordinate';
import { Pixel } from 'ol/pixel';
import { updateLastRightClick } from '@/redux/map/map.slice';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { getSearchResults } from '../mapSingleClick/getSearchResults';
import { handleDataReset } from '../mapSingleClick/handleDataReset';
import { handleSearchResultForRightClickAction } from './handleSearchResultForRightClickAction';

/* prettier-ignore */
export const onMapRightClick =
  (mapSize: MapSize, modelId: number,dispatch: AppDispatch,     shouldConsiderZoomLevel:boolean,
    considerZoomLevel?:number, ) => async (coordinate: Coordinate, pixel: Pixel): Promise<void> => {
    const [lng, lat] = toLonLat(coordinate);
    const point = latLngToPoint([lat, lng], mapSize);

    dispatch(updateLastRightClick({coordinates:point, modelId}));

    dispatch(handleDataReset);
    dispatch(openContextMenu(pixel));

    const { searchResults } = await getSearchResults({ coordinate, mapSize, modelId, shouldConsiderZoomLevel, considerZoomLevel });
    if (!searchResults || searchResults.length === SIZE_OF_EMPTY_ARRAY) {
      return;
    }

    handleSearchResultForRightClickAction({ searchResults, dispatch });
  };
