import { ConfigurationFormatSchema } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '../root/root.selectors';
import { configurationAdapter } from './configuration.adapter';
import {
  CELL_DESIGNER_SBML_HANDLER_NAME_ID,
  GPML_HANDLER_NAME_ID,
  LEGEND_FILE_NAMES_IDS,
  MAX_COLOR_VAL_NAME_ID,
  MIN_COLOR_VAL_NAME_ID,
  NEUTRAL_COLOR_VAL_NAME_ID,
  OVERLAY_OPACITY_NAME_ID,
  PDF_HANDLER_NAME_ID,
  PNG_IMAGE_HANDLER_NAME_ID,
  SBGN_ML_HANDLER_NAME_ID,
  SBML_HANDLER_NAME_ID,
  SIMPLE_COLOR_VAL_NAME_ID,
  SVG_IMAGE_HANDLER_NAME_ID,
  SEARCH_DISTANCE_NAME_ID,
  REQUEST_ACCOUNT_EMAIL,
  TERMS_OF_SERVICE_ID,
  COOKIE_POLICY_URL,
  MATOMO_URL,
  LEFT_LOGO_IMG,
  LEFT_LOGO_LINK,
  LEFT_LOGO_TEXT,
  RIGHT_LOGO_IMG,
  RIGHT_LOGO_LINK,
  RIGHT_LOGO_TEXT,
} from './configuration.constants';

import { ConfigurationHandlersIds, ConfigurationImageHandlersIds } from './configuration.types';

const configurationSelector = createSelector(rootSelector, state => state.configuration);
const configurationOptionsSelector = createSelector(configurationSelector, state => state.options);
const configurationMainSelector = createSelector(configurationSelector, state => state.main.data);

const configurationAdapterSelectors = configurationAdapter.getSelectors();

export const minColorValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, MIN_COLOR_VAL_NAME_ID)?.value,
);

export const maxColorValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, MAX_COLOR_VAL_NAME_ID)?.value,
);

export const neutralColorValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, NEUTRAL_COLOR_VAL_NAME_ID)?.value,
);

export const overlayOpacitySelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, OVERLAY_OPACITY_NAME_ID)?.value,
);

export const simpleColorValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, SIMPLE_COLOR_VAL_NAME_ID)?.value,
);

export const searchDistanceValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, SEARCH_DISTANCE_NAME_ID)?.value,
);

export const matomoUrlSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, MATOMO_URL)?.value,
);

export const adminEmailValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, REQUEST_ACCOUNT_EMAIL)?.value,
);

export const termsOfServiceValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, TERMS_OF_SERVICE_ID)?.value,
);

export const cookiePolicyUrlSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, COOKIE_POLICY_URL)?.value,
);

export const defaultLegendImagesSelector = createSelector(configurationOptionsSelector, state =>
  LEGEND_FILE_NAMES_IDS.map(
    legendNameId => configurationAdapterSelectors.selectById(state, legendNameId)?.value,
  ).filter(legendImage => Boolean(legendImage)),
);

export const elementTypesSelector = createSelector(
  configurationMainSelector,
  state => state?.elementTypes,
);

export const modelFormatsSelector = createSelector(
  configurationMainSelector,
  state => state?.modelFormats,
);

export const modelFormatsEntriesSelector = createSelector(
  modelFormatsSelector,
  (modelFormats): Record<string, ConfigurationFormatSchema> => {
    return Object.fromEntries(
      (modelFormats || [])
        .flat()
        .filter((format: ConfigurationFormatSchema): format is ConfigurationFormatSchema =>
          Boolean(format),
        )
        .map((format: ConfigurationFormatSchema) => [format.name, format]),
    );
  },
);

export const formatsHandlersSelector = createSelector(
  modelFormatsEntriesSelector,
  (formats): ConfigurationHandlersIds => {
    return {
      [GPML_HANDLER_NAME_ID]: formats[GPML_HANDLER_NAME_ID]?.handler,
      [SBML_HANDLER_NAME_ID]: formats[SBML_HANDLER_NAME_ID]?.handler,
      [CELL_DESIGNER_SBML_HANDLER_NAME_ID]: formats[CELL_DESIGNER_SBML_HANDLER_NAME_ID]?.handler,
      [SBGN_ML_HANDLER_NAME_ID]: formats[SBGN_ML_HANDLER_NAME_ID]?.handler,
    };
  },
);

export const imageFormatsSelector = createSelector(
  configurationMainSelector,
  state => state?.imageFormats,
);

export const imageFormatsEntriesSelector = createSelector(
  imageFormatsSelector,
  (modelFormats): Record<string, ConfigurationFormatSchema> => {
    return Object.fromEntries(
      (modelFormats || []).flat().map((format: ConfigurationFormatSchema) => [format.name, format]),
    );
  },
);

export const imageHandlersSelector = createSelector(
  imageFormatsEntriesSelector,
  (formats): ConfigurationImageHandlersIds => {
    return {
      [PNG_IMAGE_HANDLER_NAME_ID]: formats[PNG_IMAGE_HANDLER_NAME_ID]?.handler,
      [PDF_HANDLER_NAME_ID]: formats[PDF_HANDLER_NAME_ID]?.handler,
      [SVG_IMAGE_HANDLER_NAME_ID]: formats[SVG_IMAGE_HANDLER_NAME_ID]?.handler,
    };
  },
);

export const miramiTypesSelector = createSelector(
  configurationMainSelector,
  state => state?.miriamTypes,
);

export const loadingConfigurationMainSelector = createSelector(
  configurationSelector,
  state => state?.main?.loading,
);

export const leftLogoImgValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, LEFT_LOGO_IMG)?.value,
);
export const leftLogoLinkValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, LEFT_LOGO_LINK)?.value,
);
export const leftLogoTextValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, LEFT_LOGO_TEXT)?.value,
);

export const rightLogoImgValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, RIGHT_LOGO_IMG)?.value,
);
export const rightLogoLinkValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, RIGHT_LOGO_LINK)?.value,
);
export const rightLogoTextValSelector = createSelector(
  configurationOptionsSelector,
  state => configurationAdapterSelectors.selectById(state, RIGHT_LOGO_TEXT)?.value,
);
