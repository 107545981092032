import { mapModelSchema } from '@/models/modelSchema';
import { store } from '@/redux/store';
import { MapModel } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { z } from 'zod';

export const getModels = (): MapModel[] => {
  const models = store.getState().models.data;

  const isDataValid = validateDataUsingZodSchema(models, z.array(mapModelSchema));

  return isDataValid ? models : [];
};
