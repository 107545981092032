import { emptyBackgroundIdSelector } from '@/redux/backgrounds/background.selectors';
import { setMapBackground } from '@/redux/map/map.slice';
import { store } from '@/redux/store';

export const setBackgroundtoEmptyIfAvailable = (): void => {
  const { dispatch, getState } = store;
  const emptyBackgroundId = emptyBackgroundIdSelector(getState());

  if (emptyBackgroundId) {
    dispatch(setMapBackground(emptyBackgroundId));
  }
};
