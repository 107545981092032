import { Marker, MarkerLine, MarkerSurface, MarkerWithPosition } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import { currentModelIdSelector } from '../models/models.selectors';
import { rootSelector } from '../root/root.selectors';
import { isMarkerLine, isMarkerSurface } from './markers.utils';

export const markersSelector = createSelector(rootSelector, state => state.markers);

export const markersDataSelector = createSelector(markersSelector, markers => markers.data);

export const markersDataOfCurrentMapSelector = createSelector(
  markersDataSelector,
  currentModelIdSelector,
  (markers, modelId) => markers.filter(p => (p.modelId ? p.modelId === modelId : true)),
);

export const markersPinsDataSelector = createSelector(markersDataSelector, markersData =>
  markersData.filter(m => m.type === 'pin'),
);

export const markersPinsOfCurrentMapDataSelector = createSelector(
  markersDataOfCurrentMapSelector,
  (markersData): MarkerWithPosition[] =>
    markersData
      .filter(m => m.type === 'pin')
      .filter((marker: Marker): marker is MarkerWithPosition =>
        Boolean('x' in marker && 'y' in marker),
      ),
);

export const markersSufraceSelector = createSelector(markersDataSelector, markersData =>
  markersData.filter(m => m.type === 'surface'),
);

export const markersSufraceOfCurrentMapDataSelector = createSelector(
  markersDataOfCurrentMapSelector,
  (markers): MarkerSurface[] => markers.filter(isMarkerSurface),
);

export const markersLinesCurrentMapDataSelector = createSelector(
  markersDataOfCurrentMapSelector,
  (markers): MarkerLine[] => markers.filter(isMarkerLine),
);
