import { BASE_MAP_IMAGES_URL } from '@/constants';

export const getMapTileUrl = ({
  projectDirectory,
  currentBackgroundImagePath,
}: {
  projectDirectory?: string;
  currentBackgroundImagePath: string;
}): string => {
  if (!projectDirectory) {
    return '';
  }

  return `${BASE_MAP_IMAGES_URL}/map_images/${projectDirectory}/${currentBackgroundImagePath}/{z}/{x}/{y}.PNG`;
};
