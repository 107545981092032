import { getSearchValuesArrayAndTrimToSeven } from '@/components/FunctionalArea/TopBar/SearchBar/SearchBar.utils';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { getSearchData } from '@/redux/search/search.thunks';
import { store } from '@/redux/store';
import { displaySearchDrawerWithSelectedDefaultTab } from './displaySearchDrawerWithSelectedDefaultTab';
import { searchFitBounds } from './searchFitBounds';

export const searchByQuery = (
  query: string,
  perfectSearch: boolean | undefined,
  hasFitBounds?: boolean,
): void => {
  const { dispatch } = store;
  const searchValues = getSearchValuesArrayAndTrimToSeven(query);
  const isPerfectMatch = !!perfectSearch;

  dispatch(resetReactionsData());

  dispatch(getSearchData({ searchQueries: searchValues, isPerfectMatch }))
    ?.unwrap()
    .then(() => {
      if (hasFitBounds) {
        searchFitBounds();
      }
    });

  displaySearchDrawerWithSelectedDefaultTab(searchValues);
};
