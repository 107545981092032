import { PIN_PATH2D, PIN_SIZE, TEXT_COLOR } from '@/constants/canvas';
import { HALF, ONE_AND_HALF, QUARTER, THIRD, TWO_AND_HALF } from '@/constants/dividers';
import { DEFAULT_FONT_FAMILY } from '@/constants/font';
import { Point } from '@/types/map';
import { createCanvas } from '@/utils/canvas/getCanvas';
import { getFontSizeToFit } from '@/utils/canvas/getFontSizeToFit';

const SMALL_TEXT_VALUE = 1;
const MEDIUM_TEXT_VALUE = 10;
const BIG_TEXT_VALUE = 100;

export interface GetCanvasIconArgs {
  color: string;
  value: number;
  textColor?: string;
}

export const drawPinOnCanvas = (
  { color }: Pick<GetCanvasIconArgs, 'color'>,
  ctx: CanvasRenderingContext2D,
): void => {
  const path = new Path2D(PIN_PATH2D);
  ctx.fillStyle = color;
  ctx.fill(path);
};

export const getTextWidth = (value: number): number => {
  switch (true) {
    case value === SMALL_TEXT_VALUE:
      return PIN_SIZE.width / QUARTER;
    case value < MEDIUM_TEXT_VALUE:
      return PIN_SIZE.width / THIRD;
    case value < BIG_TEXT_VALUE:
      return PIN_SIZE.width / HALF;
    default:
      return PIN_SIZE.width / ONE_AND_HALF;
  }
};

export const getTextPosition = (textWidth: number, textHeight: number): Point => ({
  x: (PIN_SIZE.width - textWidth) / HALF,
  y: (PIN_SIZE.height - textHeight) / TWO_AND_HALF,
});

export const drawNumberOnCanvas = (
  { value, textColor }: Pick<GetCanvasIconArgs, 'value' | 'textColor'>,
  ctx: CanvasRenderingContext2D,
): void => {
  const text = `${value}`;
  const textMetrics = ctx.measureText(text);

  const textWidth = getTextWidth(value);
  const fontSize = getFontSizeToFit(ctx, text, DEFAULT_FONT_FAMILY, textWidth);
  const textHeight = textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent;
  const { x, y } = getTextPosition(textWidth, textHeight);

  ctx.fillStyle = textColor || TEXT_COLOR;
  ctx.textBaseline = 'top';
  ctx.font = `${fontSize}px ${DEFAULT_FONT_FAMILY}`;
  ctx.fillText(text, x, y);
};

export const getCanvasIcon = (
  args: Omit<GetCanvasIconArgs, 'value'> & { value?: number },
): HTMLCanvasElement => {
  const canvas = createCanvas(PIN_SIZE);
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return canvas;
  }

  drawPinOnCanvas(args, ctx);

  if (args?.value !== undefined) {
    drawNumberOnCanvas({ value: args.value, textColor: args?.textColor }, ctx);
  }

  return canvas;
};
