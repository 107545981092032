import { Color, ShapeRelAbs, ShapeRelAbsBezierPoint } from '@/types/models';

export const VECTOR_MAP_LAYER_TYPE = 'vectorMapLayer';

export const COMPLEX_SBO_TERMS = ['SBO:0000253', 'SBO:0000297', 'SBO:0000289'];

export const TEXT_CUTOFF_SCALE = 0.34;
export const OUTLINE_CUTOFF_SCALE = 0.18;
export const COMPLEX_CONTENTS_CUTOFF_SCALE = 0.215;
export const REACTION_ELEMENT_CUTOFF_SCALE = 0.125;

export const WHITE_COLOR: Color = {
  alpha: 255,
  rgb: 16777215,
};

export const BLACK_COLOR: Color = {
  alpha: 255,
  rgb: -16777216,
};

export const TRANSPARENT_COLOR: Color = {
  alpha: 0,
  rgb: 0,
};

export const REACTION_ELEMENT_TYPES = {
  OPERATOR: 'OPERATOR',
  SQUARE: 'SQUARE',
  LINE: 'LINE',
  ARROW: 'ARROW',
};

export const MAP_ELEMENT_TYPES = {
  TEXT: 'TEXT',
  MODIFICATION: 'MODIFICATION',
  ACTIVITY_BORDER: 'ACTIVITY_BORDER',
  ENTITY: 'ENTITY',
  OVERLAY: 'OVERLAY',
  COMPARTMENT: 'COMPARTMENT',
};

export const LAYER_ELEMENT_TYPES = {
  TEXT: 'TEXT',
  OVAL: 'OVAL',
  RECT: 'RECT',
  LINE: 'LINE',
  ARROW: 'ARROW',
};

export const COMPARTMENT_SQUARE_POINTS: Array<ShapeRelAbs | ShapeRelAbsBezierPoint> = [
  {
    type: 'REL_ABS_POINT',
    absoluteX: 10.0,
    absoluteY: 0.0,
    relativeX: 0.0,
    relativeY: 0.0,
    relativeHeightForX: null,
    relativeWidthForY: null,
  },
  {
    type: 'REL_ABS_POINT',
    absoluteX: -10.0,
    absoluteY: 0.0,
    relativeX: 100.0,
    relativeY: 0.0,
    relativeHeightForX: null,
    relativeWidthForY: null,
  },
  {
    type: 'REL_ABS_BEZIER_POINT',
    absoluteX1: 0.0,
    absoluteY1: 10.0,
    relativeX1: 100.0,
    relativeY1: 0.0,
    relativeHeightForX1: null,
    relativeWidthForY1: null,
    absoluteX2: -5.0,
    absoluteY2: 0.0,
    relativeX2: 100.0,
    relativeY2: 0.0,
    relativeHeightForX2: null,
    relativeWidthForY2: null,
    absoluteX3: 0.0,
    absoluteY3: 5.0,
    relativeX3: 100.0,
    relativeY3: 0.0,
    relativeHeightForX3: null,
    relativeWidthForY3: null,
  },
  {
    type: 'REL_ABS_POINT',
    absoluteX: 0.0,
    absoluteY: -10.0,
    relativeX: 100.0,
    relativeY: 100.0,
    relativeHeightForX: null,
    relativeWidthForY: null,
  },
  {
    type: 'REL_ABS_BEZIER_POINT',
    absoluteX1: -10.0,
    absoluteY1: 0.0,
    relativeX1: 100.0,
    relativeY1: 100.0,
    relativeHeightForX1: null,
    relativeWidthForY1: null,
    absoluteX2: 0.0,
    absoluteY2: -5.0,
    relativeX2: 100.0,
    relativeY2: 100.0,
    relativeHeightForX2: null,
    relativeWidthForY2: null,
    absoluteX3: -5.0,
    absoluteY3: 0.0,
    relativeX3: 100.0,
    relativeY3: 100.0,
    relativeHeightForX3: null,
    relativeWidthForY3: null,
  },
  {
    type: 'REL_ABS_POINT',
    absoluteX: 10.0,
    absoluteY: 0.0,
    relativeX: 0.0,
    relativeY: 100.0,
    relativeHeightForX: null,
    relativeWidthForY: null,
  },
  {
    type: 'REL_ABS_BEZIER_POINT',
    absoluteX1: 0.0,
    absoluteY1: -10.0,
    relativeX1: 0.0,
    relativeY1: 100.0,
    relativeHeightForX1: null,
    relativeWidthForY1: null,
    absoluteX2: 5.0,
    absoluteY2: 0.0,
    relativeX2: 0.0,
    relativeY2: 100.0,
    relativeHeightForX2: null,
    relativeWidthForY2: null,
    absoluteX3: 0.0,
    absoluteY3: -5.0,
    relativeX3: 0.0,
    relativeY3: 100.0,
    relativeHeightForX3: null,
    relativeWidthForY3: null,
  },
  {
    type: 'REL_ABS_POINT',
    absoluteX: 0.0,
    absoluteY: 10.0,
    relativeX: 0.0,
    relativeY: 0.0,
    relativeHeightForX: null,
    relativeWidthForY: null,
  },
  {
    type: 'REL_ABS_BEZIER_POINT',
    absoluteX1: 10.0,
    absoluteY1: 0.0,
    relativeX1: 0.0,
    relativeY1: 0.0,
    relativeHeightForX1: null,
    relativeWidthForY1: null,
    absoluteX2: 0.0,
    absoluteY2: 5.0,
    relativeX2: 0.0,
    relativeY2: 0.0,
    relativeHeightForX2: null,
    relativeWidthForY2: null,
    absoluteX3: 5.0,
    absoluteY3: 0.0,
    relativeX3: 0.0,
    relativeY3: 0.0,
    relativeHeightForX3: null,
    relativeWidthForY3: null,
  },
];

export const COMPARTMENT_CIRCLE_CENTER: ShapeRelAbs = {
  type: 'REL_ABS_POINT',
  absoluteX: 0.0,
  absoluteY: 0.0,
  relativeX: 50.0,
  relativeY: 50.0,
  relativeHeightForX: null,
  relativeWidthForY: null,
};

export const COMPARTMENT_CIRCLE_RADIUS: ShapeRelAbs = {
  type: 'REL_ABS_RADIUS',
  absoluteX: 0.0,
  absoluteY: 0.0,
  relativeX: 50.0,
  relativeY: 50.0,
  relativeHeightForX: null,
  relativeWidthForY: null,
};
