enum ReactionTypeEnum {
  'SBO:0000013' = 'Catalysis',
  'SBO:0000180' = 'Dissociation',
  'SBO:0000177' = 'Heterodimer association',
  'SBO:0000537' = 'Inhibition',
  'SBO:0000205' = 'Known Transition omitted',
  'SBO:0000594' = 'Modulation',
  'SBO:0000407' = 'Negative influence',
  'SBO:0000459' = 'Physical stimulation',
  'SBO:0000171' = 'Positive influence',
  'SBO:0000632' = 'Reduced modulation',
  'SBO:0000411' = 'Reduced physical stimulation',
  'SBO:0000533' = 'Reduced trigger',
  'SBO:0000176' = 'State transition',
  'SBO:0000183' = 'Transcription',
  'SBO:0000184' = 'Translation',
  'SBO:0000185' = 'Transport',
  'SBO:0000461' = 'Trigger',
  'SBO:0000178' = 'Truncation',
  'SBO:0000462' = 'Unknown catalysis',
  'SBO:0000536' = 'Unknown inhibition',
  'SBO:0000169' = 'Unknown negative influence',
  'SBO:0000172' = 'Unknown positive influence',
  'SBO:0000631' = 'Unknown reduced modulation',
  'SBO:0000170' = 'Unknown reduced physical stimulation',
  'SBO:0000534' = 'Unknown reduced trigger',
  'SBO:0000396' = 'Unknown transition',
}

export default ReactionTypeEnum;
