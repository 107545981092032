import { store } from '@/redux/store';
import { ERROR_INVALID_MODEL_ID_TYPE_FOR_RETRIEVAL } from '../errorMessages';

export const getOpenMapId = (): number => {
  const currentMap = store.getState().map.data;
  const openMapId = currentMap.modelId;

  if (typeof openMapId !== 'number') {
    throw new Error(ERROR_INVALID_MODEL_ID_TYPE_FOR_RETRIEVAL);
  }

  return openMapId;
};
