import { SearchBar } from '@/components/FunctionalArea/TopBar/SearchBar';

import { openOverlaysDrawer, openSubmapsDrawer } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  projectDataSelector,
  projectDefaultOverviewImageIdSelector,
  projectOverviewImagesSelector,
} from '@/redux/project/project.selectors';
import { Button } from '@/shared/Button';
import { ZERO } from '@/constants/common';
import { useSelector } from 'react-redux';
import { openOverviewImagesModalById } from '@/redux/modal/modal.slice';
import { User } from './User';
import { ClearAnchorsButton } from './ClearAnchorsButton';

export const TopBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(projectDataSelector);
  const defaultOverviewImageId = useSelector(projectDefaultOverviewImageIdSelector);
  const overviewImages = useSelector(projectOverviewImagesSelector);
  const overviewImagesProvided = overviewImages.length > ZERO;

  const handleBrowseOverviewImagesClick = (): void => {
    dispatch(openOverviewImagesModalById(defaultOverviewImageId));
  };

  const onSubmapsClick = (): void => {
    dispatch(openSubmapsDrawer());
  };

  const onOverlaysClick = (): void => {
    dispatch(openOverlaysDrawer());
  };

  return (
    <div className="flex h-16 w-full flex-row items-center justify-between overflow-x-auto border-b border-font-500 border-opacity-[0.12] bg-white py-4 pr-6 xl:overflow-x-visible">
      <div className="flex flex-row items-center">
        <div className="relative flex w-[88px] justify-center">
          <User />
        </div>

        <SearchBar />
        <ClearAnchorsButton />
        <Button
          icon="plus"
          isIcon
          isFrontIcon
          className="ml-5 mr-4"
          onClick={onSubmapsClick}
          title="Submaps"
        >
          Submaps
        </Button>
        <Button icon="plus" isIcon isFrontIcon onClick={onOverlaysClick} title="Overlays">
          Overlays
        </Button>
      </div>
      <div className="flex items-center">
        {overviewImagesProvided && (
          <Button
            className="mx-4 flex-none"
            onClick={handleBrowseOverviewImagesClick}
            variantStyles="secondary"
          >
            Browse overview images
          </Button>
        )}
        <div className="flex-none bg-primary-100 px-4 py-1 text-xs leading-6 text-primary-500">
          {currentProject?.name || ''}
        </div>
      </div>
    </div>
  );
};
