/* eslint-disable no-magic-numbers */
import { Drawer } from '@/components/Map/Drawer';
import { Legend } from '@/components/Map/Legend';
import { MapViewer } from '@/components/Map/MapViewer';
import { MapLoader } from '@/components/Map/MapLoader/MapLoader.component';
import { MapVectorBackgroundSelector } from '@/components/Map/MapVectorBackgroundSelector/MapVectorBackgroundSelector.component';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { vectorRenderingSelector } from '@/redux/models/models.selectors';
import { MapAdditionalLogos } from '@/components/Map/MapAdditionalLogos';
import { MapAdditionalActions } from './MapAdditionalActions';
import { MapAdditionalOptions } from './MapAdditionalOptions';
import { PluginsDrawer } from './PluginsDrawer';

export const Map = (): JSX.Element => {
  const vectorRendering = useAppSelector(vectorRenderingSelector);
  return (
    <div
      className="relative z-0 h-screen w-full overflow-hidden bg-black"
      data-testid="map-container"
    >
      <MapViewer />
      {!vectorRendering && <MapAdditionalOptions />}
      {vectorRendering && <MapVectorBackgroundSelector />}
      <Drawer />
      <PluginsDrawer />
      <Legend />
      <MapAdditionalActions />
      <MapAdditionalLogos />
      <MapLoader />
    </div>
  );
};
