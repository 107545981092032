import { useContext } from 'react';
import { ExportContext } from '@/components/Map/Drawer/ExportDrawer/ExportCompound/ExportCompound.context';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentModelIdSelector, currentModelNameSelector } from '@/redux/models/models.selectors';

export const CurrentView = (): React.ReactNode => {
  const { setCurrentView, data, setModels } = useContext(ExportContext);

  const currentMapModelId = useAppSelector(currentModelIdSelector);
  const currentMapModelName = useAppSelector(currentModelNameSelector);

  return (
    <div className="flex flex-col gap-4 border-b">
      <label className="flex h-9 items-center gap-4">
        <span>Current view:&nbsp;</span>
        <input
          className="rounded-[64px] border border-transparent bg-cultured px-4 py-2.5 text-xs font-medium text-font-400 outline-none  hover:border-greyscale-600 focus:border-greyscale-600"
          name="width"
          checked={data.currentView.value}
          type="checkbox"
          aria-label="export graphics width input"
          onChange={(e): void => {
            setCurrentView({ value: e.target.checked });
            if (e.target.checked) {
              setModels([
                {
                  id: `${currentMapModelId}`,
                  label: currentMapModelName,
                },
              ]);
            }
          }}
        />
      </label>
    </div>
  );
};
