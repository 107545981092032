import { addOverlay } from '@/redux/overlays/overlays.thunks';
import { projectIdSelector } from '@/redux/project/project.selectors';
import { store } from '@/redux/store';
import {
  ERROR_OVERLAY_NAME_NOT_PROVIDED,
  ERROR_PROJECT_ID_NOT_FOUND,
} from '@/services/pluginsManager/errorMessages';
import { DEFAULT_FILE_NAME, DEFAULT_TYPE } from './addDataOverlay.constants';
import { getOverlayContent } from './addDataOverlay.utils';

type AddDataOverlayArgs = {
  name: string;
  description?: string;
  filename?: string;
  fileContent: string;
  type?: string;
};

export const addDataOverlay = async ({
  name,
  description,
  filename,
  fileContent,
  type,
}: AddDataOverlayArgs): Promise<void> => {
  const { dispatch, getState } = store;
  const projectId = projectIdSelector(getState());

  if (!projectId) throw new Error(ERROR_PROJECT_ID_NOT_FOUND);

  if (!name) throw new Error(ERROR_OVERLAY_NAME_NOT_PROVIDED);

  const content = await getOverlayContent(fileContent);

  dispatch(
    addOverlay({
      content,
      description: description || '',
      filename: filename || DEFAULT_FILE_NAME,
      name,
      projectId,
      type: type || DEFAULT_TYPE,
    }),
  );
};
