import { ZERO } from '@/constants/common';
import {
  compartmentPathwaysDataSelector,
  loadingCompartmentPathwaysSelector,
} from '@/redux/compartmentPathways/compartmentPathways.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { useContext } from 'react';
import { CheckboxFilter } from '../../CheckboxFilter';
import { CollapsibleSection } from '../../CollapsibleSection';
import { ExportContext } from '../ExportCompound.context';
import { getCompartmentPathwaysCheckboxElements } from '../utils/getCompartmentPathwaysCheckboxElements';

export const ExcludedCompartmentPathways = (): React.ReactNode => {
  const { setExcludedCompartmentPathways, data } = useContext(ExportContext);
  const currentExcludedCompartmentPathways = data.excludedCompartmentPathways;
  const loadingCompartmentPathways = useAppSelector(loadingCompartmentPathwaysSelector);
  const isPending = loadingCompartmentPathways === 'pending';
  const compartmentPathways = useAppSelector(compartmentPathwaysDataSelector);
  const checkboxElements = getCompartmentPathwaysCheckboxElements('excluded', compartmentPathways);
  const isCheckboxFilterVisible = !isPending && checkboxElements && checkboxElements.length > ZERO;

  return (
    <CollapsibleSection title="Select excluded compartment / pathways">
      {isPending && <p>Loading...</p>}
      {isCheckboxFilterVisible && (
        <CheckboxFilter
          options={checkboxElements}
          currentOptions={currentExcludedCompartmentPathways}
          onCheckedChange={setExcludedCompartmentPathways}
        />
      )}
    </CollapsibleSection>
  );
};
