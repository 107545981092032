import { Button } from '@/shared/Button';
import { MapOverlay } from '@/types/models';
import { twMerge } from 'tailwind-merge';
import Image from 'next/image';
import spinnerIcon from '@/assets/vectors/icons/spinner.svg';
import { useOverlay } from '../../../hooks/useOverlay';
import { UserOverlayActions } from './UserOverlayActions';
import { UserOverlayInfo } from './UserOverlayInfo/UserOverlayInfo.component';
import { useDragAndDrop } from './hooks/useDragAndDrop';

type OverlayListItemProps = {
  index: number;
  moveUserOverlay: (dragIndex: number, hoverIndex: number) => void;
  userOverlay: MapOverlay;
  updateUserOverlaysOrder: () => void;
};

export const UserOverlayListItem = ({
  index,
  moveUserOverlay,
  userOverlay,
  updateUserOverlaysOrder,
}: OverlayListItemProps): JSX.Element => {
  const { toggleOverlay, isOverlayActive, isOverlayLoading } = useOverlay(userOverlay.idObject);
  const { dragRef, dropRef, isDragging } = useDragAndDrop({
    onDrop: updateUserOverlaysOrder,
    onHover: moveUserOverlay,
    index,
  });

  return (
    <li
      ref={node => dragRef(dropRef(node))}
      className={twMerge(
        'flex flex-row flex-nowrap items-center justify-between overflow-visible py-4 pl-10 pr-5',
        isDragging ? 'opacity-0' : 'opacity-100',
      )}
    >
      <UserOverlayInfo description={userOverlay.description} name={userOverlay.name} />
      <div className="flex flex-row flex-nowrap items-center">
        <Button
          variantStyles="ghost"
          className="mr-4 max-h-8 flex-none gap-1.5"
          onClick={toggleOverlay}
          data-testid="toggle-overlay-button"
        >
          {isOverlayLoading && (
            <Image
              src={spinnerIcon}
              alt="spinner icon"
              height={12}
              width={12}
              className="animate-spin"
            />
          )}
          {isOverlayActive || isOverlayLoading ? 'Hide' : 'View'}
        </Button>
        <UserOverlayActions overlay={userOverlay} />
      </div>
    </li>
  );
};
