import { LinePoint } from '@/types/reactions';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { SimpleGeometry } from 'ol/geom';
import { getLineFeature } from '../reactionsLayer/getLineFeature';
import { getOverlayLineFeatureStyle } from './getOverlayLineFeatureStyle';

interface Options {
  color: string;
  pointToProjection: UsePointToProjectionResult;
}

export const createOverlayLineFeature = (
  points: LinePoint,
  { color, pointToProjection }: Options,
): Feature<SimpleGeometry> => {
  const feature = getLineFeature(points, pointToProjection);

  feature.setStyle(getOverlayLineFeatureStyle(color));

  return feature;
};
