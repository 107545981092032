/* eslint-disable no-magic-numbers */
import { LINE_COLOR, LINE_WIDTH } from '@/constants/canvas';
import { markersLinesCurrentMapDataSelector } from '@/redux/markers/markers.selectors';
import { allReactionsSelectorOfCurrentMap } from '@/redux/reactions/reactions.selector';
import { NewReaction } from '@/types/models';
import { LinePoint } from '@/types/reactions';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createOverlayLineFeature } from '@/components/Map/MapViewer/utils/config/overlaysLayer/createOverlayLineFeature';
import { Geometry } from 'ol/geom';
import { getReactionLineSegments } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/findClosestReactionPoint';
import { getLineFeature } from './getLineFeature';

const getReactionsLines = (reactions: NewReaction[]): LinePoint[] =>
  reactions
    .map(reaction => getReactionLineSegments(reaction))
    .flat()
    .map(segment => [
      {
        x: segment.x1,
        y: segment.y1,
      },
      { x: segment.x2, y: segment.y2 },
    ]);

export const useOlMapReactionsLayer = (): VectorLayer<VectorSource<Feature<Geometry>>> => {
  const pointToProjection = usePointToProjection();
  const reactions = useSelector(allReactionsSelectorOfCurrentMap);
  const markers = useSelector(markersLinesCurrentMapDataSelector);
  const reactionsLines = getReactionsLines(reactions);

  const reactionsLinesFeatures = useMemo(
    () => reactionsLines.map(linePoint => getLineFeature(linePoint, pointToProjection)),
    [reactionsLines, pointToProjection],
  );

  const markerLinesFeatures = useMemo(
    () =>
      markers.map(marker =>
        createOverlayLineFeature([marker.start, marker.end] as LinePoint, {
          color: marker.color,
          pointToProjection,
        }),
      ),
    [markers, pointToProjection],
  );

  const vectorSource = useMemo(() => new VectorSource(), []);

  useEffect(() => {
    vectorSource.clear();
    vectorSource.addFeatures(reactionsLinesFeatures);
    vectorSource.addFeatures(markerLinesFeatures);
  }, [reactionsLinesFeatures, markerLinesFeatures, vectorSource]);

  return useMemo(
    () =>
      new VectorLayer({
        source: vectorSource,
        style: new Style({
          fill: new Fill({ color: LINE_COLOR }),
          stroke: new Stroke({ color: LINE_COLOR, width: LINE_WIDTH }),
        }),
      }),
    [vectorSource],
  );
};
