import { openReactionDrawerById, selectTab } from '@/redux/drawer/drawer.slice';
import { AppDispatch } from '@/redux/store';
import { searchFitBounds } from '@/services/pluginsManager/map/triggerSearch/searchFitBounds';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { BioEntity, ModelElement, NewReaction } from '@/types/models';
import { FEATURE_TYPE } from '@/constants/features';
import { setMultipleBioEntityContents } from '@/redux/bioEntity/bioEntity.slice';
import { addNumbersToEntityNumberData } from '@/redux/entityNumber/entityNumber.slice';
import { setReactions } from '@/redux/reactions/reactions.slice';
import { mapReactionToBioEntity } from '@/utils/bioEntity/mapReactionToBioEntity';
import getModelElementsIdsFromReaction from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/getModelElementsIdsFromReaction';
import { mapModelElementToBioEntity } from '@/utils/bioEntity/mapModelElementToBioEntity';

/* prettier-ignore */
export const clickHandleReaction =
  (dispatch: AppDispatch, hasFitBounds = false) =>
    ( modelElements: Array<ModelElement>, reactions: Array<NewReaction>, reactionId: number, modelId: number): void => {

      const reactionBioEntities: Array<BioEntity> = [];
      const reaction = reactions.find(newReaction => newReaction.id === reactionId);
      if(!reaction) {
        return;
      }
      const modelElementsIds = getModelElementsIdsFromReaction(reaction);
      modelElementsIds.forEach(modelElementId => {
        const modelElement = modelElements.find(element =>
          element.id === modelElementId
        );
        if(!modelElement) {
          return;
        }
        reactionBioEntities.push(mapModelElementToBioEntity(modelElement));
      });

      dispatch(openReactionDrawerById(reactionId));

      dispatch(selectTab(''));

      const bioEntityReaction = mapReactionToBioEntity(reaction);
      dispatch(setMultipleBioEntityContents(reactionBioEntities));
      dispatch(addNumbersToEntityNumberData(reactionBioEntities.map(reactionBioEntity => reactionBioEntity.elementId)));
      dispatch(setReactions([reaction]));

      const result = reactionBioEntities.map((bioEntity) => {return { bioEntity, perfect: true };});
      result.push({ bioEntity: bioEntityReaction, perfect: true });
      PluginsEventBus.dispatchEvent('onSearch', {
        type: 'reaction',
        searchValues: [{ id: reactionId, modelId, type: FEATURE_TYPE.REACTION }],
        results: [result]
      });

      if (hasFitBounds) {
        searchFitBounds();
      }
    };
