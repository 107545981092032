export const getTypeBySBOTerm = (sbo: string | undefined, shape?: string | null): string => {
  switch (sbo) {
    case 'SBO:0000334':
      return 'Antisense RNA';
    case 'SBO:0000253':
    case 'SBO:0000297':
      return 'Complex';
    case 'SBO:0000289':
      return 'Hypothetical Complex';
    case 'SBO:0000291':
      return 'Degraded';
    case 'SBO:0000298':
      return 'Drug';
    case 'SBO:0000243':
      return 'Gene';
    case 'SBO:0000252':
    case 'SBO:0000421':
    case 'SBO:0000284':
    case 'SBO:0000244':
      return 'Protein';
    case 'SBO:0000327':
      return 'Ion';
    case 'SBO:0000358':
      return 'Phenotype';
    case 'SBO:0000278':
      return 'RNA';
    case 'SBO:0000247':
    case 'SBO:0000299':
      return 'Simple molecule';
    case 'SBO:0000285':
      return 'Unknown';
    case 'SBO:0000290':
      switch (shape) {
        case 'PATHWAY':
          return 'Pathway';
        case 'SQUARE_COMPARTMENT':
        case 'CIRCLE_COMPARTMENT':
          return 'Compartment';
        default:
          return '---';
      }
    default:
      return '---';
  }
};
