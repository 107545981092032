import { MULTIICON_RATIO, PIN_SIZE } from '@/constants/canvas';
import { ONE, ZERO } from '@/constants/common';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { GetCanvasIconArgs, getCanvasIcon } from '../getCanvasIcon';
import { getCavasMultiIcon } from '../getCanvasMultiIcon';

interface Args {
  pins: GetCanvasIconArgs[];
}

export const getMultipinStyle = ({ pins }: Args): Style => {
  const icons = pins.map(({ color, value, textColor }) =>
    getCanvasIcon({
      color,
      value,
      textColor,
    }),
  );

  const img = getCavasMultiIcon(icons);

  return new Style({
    image: new Icon({
      displacement: [ZERO, PIN_SIZE.height * (ONE + (icons.length - ONE) * MULTIICON_RATIO)],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      img,
    }),
  });
};
