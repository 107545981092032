import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { CLOSE_BUTTON_ROLE } from '@/shared/DrawerHeadingBackwardButton/DrawerHeadingBackwardButton.constants';
import { IconButton } from '@/shared/IconButton';
import { PerfectMatchSwitch } from './PerfectMatchSwitch';

export const SearchDrawerHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleCloseDrawer = (): void => {
    dispatch(closeDrawer());
  };

  return (
    <div
      data-testid="search-drawer-header"
      className="flex flex-row flex-nowrap items-center justify-between p-6 text-xl font-bold"
    >
      <p>Search results</p>
      <div className="flex flex-row items-center">
        <PerfectMatchSwitch />
        <IconButton
          className="bg-white-pearl"
          classNameIcon="fill-font-500"
          icon="close"
          role={CLOSE_BUTTON_ROLE}
          onClick={handleCloseDrawer}
        />
      </div>
    </div>
  );
};
