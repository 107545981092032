import { Comment } from '@/types/models';
import React from 'react';

interface CommentItemProps {
  comment: Comment;
}

export const CommentItem = (commentItemProps: CommentItemProps): JSX.Element => {
  const { comment } = commentItemProps;
  return (
    <div className="border border-slate-400">
      <div className="p-4 font-bold"> #{comment.id} </div>
      <div className="p-4"> {comment.content} </div>
    </div>
  );
};
