/* eslint-disable @next/next/no-img-element */
import { twMerge } from 'tailwind-merge';
import {
  leftLogoImgValSelector,
  leftLogoLinkValSelector,
  leftLogoTextValSelector,
  rightLogoImgValSelector,
  rightLogoLinkValSelector,
  rightLogoTextValSelector,
} from '@/redux/configuration/configuration.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { LinkButton } from '@/shared/LinkButton';

export const MapAdditionalLogos = (): JSX.Element => {
  const leftLink = useAppSelector(leftLogoLinkValSelector);
  const leftText = useAppSelector(leftLogoTextValSelector);
  const leftImage = useAppSelector(leftLogoImgValSelector);

  const rightLink = useAppSelector(rightLogoLinkValSelector);
  const rightText = useAppSelector(rightLogoTextValSelector);
  const rightImage = useAppSelector(rightLogoImgValSelector);

  return (
    <div className={twMerge('absolute bottom-6 left-[102px] grid grid-cols-2 gap-4')}>
      {leftLink && (
        <LinkButton
          type="button"
          className="flex h-auto max-h-20 w-auto max-w-20 cursor-pointer items-center justify-center border-0 bg-gray-200 bg-opacity-20 hover:bg-gray-300 hover:bg-opacity-30"
          data-testid="location-button"
          title={leftText}
          href={leftLink}
          target="_blank"
        >
          <img alt={leftText} src={leftImage} />
        </LinkButton>
      )}
      {rightLink && (
        <LinkButton
          type="button"
          className="flex h-auto max-h-20 w-auto max-w-20 cursor-pointer items-center justify-center border-0 bg-gray-200 bg-opacity-20 hover:bg-gray-300 hover:bg-opacity-30"
          data-testid="location-button"
          title={rightText}
          href={rightLink}
          target="_blank"
        >
          <img alt={rightText} src={rightImage} />
        </LinkButton>
      )}
    </div>
  );
};
