/* eslint-disable no-magic-numbers */

import { Color } from '@/types/models';

export function rgbToHex(color: Color): string {
  const positiveRgb = color.rgb < 0 ? color.rgb + 0x100000000 : color.rgb;
  const hexRgb = positiveRgb.toString(16).slice(-6).padStart(6, '0').toUpperCase();
  const hexAlpha = color.alpha.toString(16).padStart(2, '0').toUpperCase();
  return `#${hexRgb}${hexAlpha}`;
}
