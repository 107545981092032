/* eslint-disable no-magic-numbers */
import { DEFAULT_CENTER_POINT } from '@/constants/map';
import { MapSize } from '@/redux/map/map.types';
import { LatLng, Point } from '@/types/map';
import { boundNumber } from '../number/boundNumber';
import { degreesToRadians } from '../number/degreesToRadians';
import { getPointOffset } from './getPointOffset';

const FULL_CIRCLE_DEGREES = 360;
const SIN_Y_LIMIT = 0.9999;

export interface Options {
  rounded?: boolean;
}

export const latLngToPoint = (
  [lat, lng]: LatLng,
  mapSize: MapSize,
  options: Options = {},
): Point => {
  const { pointOrigin, zoomFactor } = getPointOffset(DEFAULT_CENTER_POINT, mapSize);
  const pixelsPerLonDegree = mapSize.tileSize / FULL_CIRCLE_DEGREES;
  const pixelsPerLonRadian = mapSize.tileSize / (2 * Math.PI);
  const sinY = boundNumber(Math.sin(degreesToRadians(lat)), -SIN_Y_LIMIT, SIN_Y_LIMIT);

  const point = {
    x: pointOrigin.x + lng * pixelsPerLonDegree,
    y: pointOrigin.y + 0.5 * Math.log((1 + sinY) / (1 - sinY)) * -pixelsPerLonRadian,
  };

  const getFinalPointValue = (pointValue: number): number => {
    const pointValueFactored = pointValue * zoomFactor;
    return options?.rounded ? Math.round(pointValueFactored) : pointValueFactored;
  };

  return {
    x: getFinalPointValue(point.x),
    y: getFinalPointValue(point.y),
  };
};
