import { AppDispatch } from '@/redux/store';
import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { clearBioEntities } from '@/redux/bioEntity/bioEntity.slice';

export const handleReactionSearchClickFailure = (
  dispatch: AppDispatch,
  isResultDrawerOpen: boolean,
): void => {
  if (isResultDrawerOpen) {
    dispatch(closeDrawer());
  }
  dispatch(resetReactionsData());
  dispatch(clearBioEntities());
};
