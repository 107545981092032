import { ZERO } from '@/constants/common';
import { currentSearchedBioEntityDrugsSelector } from '@/redux/drawer/drawer.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { BioEntitiesPinsListItem } from '../../SearchDrawerWrapper/BioEntitiesResultsList/BioEntitiesPinsList/BioEntitiesPinsListItem';

export const DrugsList = (): JSX.Element => {
  const drugs = useAppSelector(currentSearchedBioEntityDrugsSelector);
  const drugsData = drugs.data || [];
  const isPending = drugs.loading === 'pending';

  if (isPending) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      {drugsData.map(drug => (
        <BioEntitiesPinsListItem key={`${drug.id}`} pin={drug} name={drug.name} />
      ))}
      {drugsData.length === ZERO && 'List is empty'}
    </div>
  );
};
