import { Publication } from '@/types/models';
import { StandarizedPublication } from '@/types/publications';
import { runInSequence } from '@/utils/promise/runInSequence';
import { mapBasePublicationToStandarized } from './mapBasePublicationToStandarized';

interface Args {
  modelNameIdMap: Record<number, string>;
  publications: Publication[];
}

const SEQUENCE_CHUNK_SIZE = 250;

export const getStandarizedPublications = async ({
  publications,
  modelNameIdMap,
}: Args): Promise<StandarizedPublication[]> => {
  const getStandarizedPublicationsFuncs = publications.map(
    publication => () =>
      mapBasePublicationToStandarized(publication, {
        modelNameIdMap,
      }),
  );

  const standarizedPublications = await runInSequence<StandarizedPublication>(
    getStandarizedPublicationsFuncs,
    SEQUENCE_CHUNK_SIZE,
  );

  return standarizedPublications;
};
