import { pointSchema } from '@/models/pointSchema';
import { setMapPosition } from '@/redux/map/map.slice';
import { store } from '@/redux/store';
import { Point } from '@/types/map';

export const setCenter = (position: Point): void => {
  const { dispatch } = store;
  pointSchema.parse(position);

  dispatch(setMapPosition(position));
};
