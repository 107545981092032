import { MapNavigation } from '@/components/FunctionalArea/MapNavigation';
import { NavBar } from '@/components/FunctionalArea/NavBar';
import { TopBar } from '@/components/FunctionalArea/TopBar';

export const FunctionalArea = (): JSX.Element => (
  <>
    <div className="absolute left-0 top-0 z-[11] w-full">
      <TopBar />
    </div>
    <div className="absolute left-[88px] top-16 z-10 w-[calc(100%-88px)]">
      <MapNavigation />
    </div>
    <div className="absolute left-0 top-16 z-10 flex h-[calc(100%-64px)]">
      <NavBar />
    </div>
  </>
);
