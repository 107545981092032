/* eslint-disable no-magic-numbers */
import { OverlayBioEntityRender } from '@/types/OLrendering';

export default function calculateOverlayDimensions(
  overlay: OverlayBioEntityRender & { amount: number },
  index: number,
  totalAmount: number,
  totalHeight: number,
  entityOverlays: Array<OverlayBioEntityRender>,
): OverlayBioEntityRender {
  const ratio = overlay.amount / totalAmount;
  const overlayHeight = ratio * totalHeight;
  const overlayEntity = { ...overlay, height: overlayHeight };
  if (index !== 0) {
    overlayEntity.y2 = entityOverlays[index - 1].y1;
  }
  overlayEntity.y1 = overlayEntity.y2 + overlayHeight;

  return overlayEntity;
}
