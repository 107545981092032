/* eslint-disable no-magic-numbers */
import Style from 'ol/style/Style';
import { Stroke } from 'ol/style';

export default function getScaledElementStyle(
  style: Style,
  strokeStyle: Stroke | undefined,
  scale: number,
): Style {
  if (strokeStyle) {
    const lineDash = strokeStyle.getLineDash();
    let newLineDash: Array<number> = [];
    if (lineDash) {
      newLineDash = lineDash.map(width => width * scale);
    }
    const newStrokeStyle = new Stroke({
      color: strokeStyle.getColor(),
      width: (strokeStyle.getWidth() || 1) * scale,
      lineDash: newLineDash,
    });

    style.setStroke(newStrokeStyle);
  }
  style.getText()?.setScale(scale);
  return style;
}
