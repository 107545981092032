import { DEFAULT_FETCH_DATA } from '@/constants/fetchData';
import { rootSelector } from '@/redux/root/root.selectors';
import { assertNever } from '@/utils/assertNever';
import { createSelector } from '@reduxjs/toolkit';
import { RESULT_DRAWERS } from './drawer.constants';

export const drawerSelector = createSelector(rootSelector, state => state.drawer);

export const isDrawerOpenSelector = createSelector(drawerSelector, state => state.isOpen);

export const searchDrawerStateSelector = createSelector(
  drawerSelector,
  state => state.searchDrawerState,
);

export const currentStepDrawerStateSelector = createSelector(
  searchDrawerStateSelector,
  state => state.currentStep,
);

export const selectedValueDrawerSelector = createSelector(
  searchDrawerStateSelector,
  state => state.selectedValue,
);

export const stepTypeDrawerSelector = createSelector(
  searchDrawerStateSelector,
  state => state.stepType,
);

export const currentSelectedSearchElement = createSelector(
  searchDrawerStateSelector,
  state => state.selectedSearchElement,
);

export const currentStepTypeSelector = createSelector(
  searchDrawerStateSelector,
  state => state.stepType,
);

export const bioEntityDrawerStateSelector = createSelector(
  drawerSelector,
  state => state.bioEntityDrawerState,
);

export const commentDrawerStateSelector = createSelector(
  drawerSelector,
  state => state.commentDrawerState,
);

export const currentSearchedBioEntityId = createSelector(
  bioEntityDrawerStateSelector,
  state => state.bioentityId,
);

export const currentDrawerCommentId = createSelector(
  commentDrawerStateSelector,
  state => state.commentId,
);

export const currentSearchedBioEntityDrugsSelector = createSelector(
  bioEntityDrawerStateSelector,
  currentSearchedBioEntityId,
  (state, currentBioEntityId) =>
    currentBioEntityId && state.drugs[currentBioEntityId]
      ? state.drugs[currentBioEntityId]
      : DEFAULT_FETCH_DATA,
);

export const currentSearchedBioEntityChemicalsSelector = createSelector(
  bioEntityDrawerStateSelector,
  currentSearchedBioEntityId,
  (state, currentBioEntityId) =>
    currentBioEntityId && state.chemicals[currentBioEntityId]
      ? state.chemicals[currentBioEntityId]
      : DEFAULT_FETCH_DATA,
);

export const resultListSelector = createSelector(
  rootSelector,
  currentStepTypeSelector,
  currentSelectedSearchElement,
  (state, selectedType, currentSearchElement) => {
    const { drugs, chemicals } = state;

    switch (selectedType) {
      case 'drugs': {
        const currentDrugs = drugs.data.find(
          ({ searchQueryElement }) => searchQueryElement === currentSearchElement,
        );

        return (currentDrugs?.data || []).map(drug => ({
          id: drug.id,
          name: drug.name,
          data: drug,
        }));
      }
      case 'chemicals': {
        const currentChemical = chemicals.data.find(
          ({ searchQueryElement }) => searchQueryElement === currentSearchElement,
        );
        return (currentChemical?.data || []).map(chemical => ({
          id: chemical.id.id,
          name: chemical.name,
          data: chemical,
        }));
      }
      case 'bioEntity':
        return undefined;
      case 'none':
        return undefined;
      default:
        return assertNever(selectedType);
    }
  },
);

export const bioEnititiesResultListSelector = createSelector(rootSelector, state => {
  return state.drawer.searchDrawerState.listOfBioEnitites;
});

export const reactionDrawerStateSelector = createSelector(
  drawerSelector,
  state => state.reactionDrawerState,
);

export const currentDrawerReactionIdSelector = createSelector(
  reactionDrawerStateSelector,
  state => state?.reactionId,
);

export const overlayDrawerStateSelector = createSelector(
  drawerSelector,
  state => state.overlayDrawerState,
);

export const currentStepOverlayDrawerStateSelector = createSelector(
  overlayDrawerStateSelector,
  state => state.currentStep,
);

export const searchDrawerOpenSelector = createSelector(
  drawerSelector,
  state => state.isOpen && state.drawerName === 'search',
);

export const resultDrawerOpen = createSelector(drawerSelector, drawer => {
  return drawer.isOpen && RESULT_DRAWERS.includes(drawer.drawerName);
});

export const openedDrawerSelector = createSelector(drawerSelector, drawer => {
  if (drawer.isOpen) {
    return drawer.drawerName;
  }
  return undefined;
});
