import { ZERO } from '@/constants/common';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { loadingModelsSelector, modelsDataSelector } from '@/redux/models/models.selectors';
import { useContext, useState } from 'react';
import { CheckboxItem } from '@/components/Map/Drawer/ExportDrawer/CheckboxFilter/CheckboxFilter.types';
import { CheckboxFilter } from '../../CheckboxFilter';
import { CollapsibleSection } from '../../CollapsibleSection';
import { ExportContext } from '../ExportCompound.context';

export const Submap = (): React.ReactNode => {
  const { setModels, data } = useContext(ExportContext);
  const currentSelectedModels = data.models;
  const models = useAppSelector(modelsDataSelector);
  const loadingModels = useAppSelector(loadingModelsSelector);
  const isPending = loadingModels === 'pending';

  const mappedElementAnnotations = models.map(({ idObject, name }) => ({
    id: `${idObject}`,
    label: name,
  }));

  const options = useState<CheckboxItem[]>(mappedElementAnnotations);
  if (
    !isPending &&
    currentSelectedModels.length === ZERO &&
    options.length > ZERO &&
    options[ZERO].length > ZERO
  ) {
    setModels([options[ZERO][ZERO]]);
  }

  return (
    <CollapsibleSection title="Diagram" dangerouslySetExpanded>
      {isPending && <p>Loading...</p>}
      {!isPending && mappedElementAnnotations && mappedElementAnnotations.length > ZERO && (
        <CheckboxFilter
          options={mappedElementAnnotations}
          currentOptions={currentSelectedModels}
          onCheckedChange={setModels}
          type="radio"
        />
      )}
    </CollapsibleSection>
  );
};
