import { BASE_API_URL, BASE_NEW_API_URL } from '@/constants';

type ApiUrls = {
  baseApiUrl: string;
  baseNewApiUrl: string;
};

export const getApiUrls = (): ApiUrls => ({
  baseApiUrl: BASE_API_URL,
  baseNewApiUrl: BASE_NEW_API_URL,
});
