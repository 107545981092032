/* eslint-disable no-magic-numbers */
import Polygon from 'ol/geom/Polygon';
import { Stroke, Style } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';
import { MultiPolygon } from 'ol/geom';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import { MapInstance } from '@/types/map';
import getTextStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextStyle';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';
import getTextCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextCoords';
import { Color } from '@/types/models';
import {
  COMPLEX_CONTENTS_CUTOFF_SCALE,
  COMPLEX_SBO_TERMS,
  MAP_ELEMENT_TYPES,
  OUTLINE_CUTOFF_SCALE,
  TEXT_CUTOFF_SCALE,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import VectorSource from 'ol/source/Vector';
import MapBackgroundsEnum from '@/redux/map/map.enums';
import { Extent } from 'ol/extent';
import { MapSize } from '@/redux/map/map.types';
import getCoverStyles from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getCoverStyles';
import handleSemanticView from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/handleSemanticView';

export interface BaseMapElementProps {
  type: string;
  sboTerm: string;
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  text: string;
  fontSize: number;
  nameX: number;
  nameY: number;
  nameWidth: number;
  nameHeight: number;
  fontColor: Color;
  nameVerticalAlign: VerticalAlign;
  nameHorizontalAlign: HorizontalAlign;
  fillColor: Color;
  borderColor: Color;
  pointToProjection: UsePointToProjectionResult;
  overlaysVisible: boolean;
  vectorSource: VectorSource;
  mapBackgroundType: number;
  mapSize: MapSize;
  mapInstance: MapInstance;
}

export default abstract class BaseMultiPolygon {
  type: string;

  sboTerm: string;

  id: number;

  complexId?: number | null;

  compartmentId: number | null;

  x: number;

  y: number;

  width: number;

  height: number;

  zIndex: number;

  text: string;

  fontSize: number;

  nameX: number;

  nameY: number;

  nameWidth: number;

  nameHeight: number;

  fontColor: Color;

  nameVerticalAlign: VerticalAlign;

  nameHorizontalAlign: HorizontalAlign;

  fillColor: Color;

  borderColor: Color;

  polygons: Array<Polygon> = [];

  styles: Array<Style> = [];

  feature: Feature = new Feature();

  pointToProjection: UsePointToProjectionResult;

  overlaysVisible: boolean;

  vectorSource: VectorSource;

  mapBackgroundType: number;

  mapSize: MapSize;

  mapExtentCache: Map<number, [number, number, number, number]> = new Map<
    number,
    [number, number, number, number]
  >();

  minResolution: number;

  constructor({
    type,
    sboTerm,
    id,
    complexId,
    compartmentId,
    x,
    y,
    width,
    height,
    zIndex,
    text,
    fontSize,
    nameX,
    nameY,
    nameWidth,
    nameHeight,
    fontColor,
    nameVerticalAlign,
    nameHorizontalAlign,
    fillColor,
    borderColor,
    pointToProjection,
    overlaysVisible,
    vectorSource,
    mapBackgroundType,
    mapSize,
    mapInstance,
  }: BaseMapElementProps) {
    this.type = type;
    this.sboTerm = sboTerm;
    this.id = id;
    this.complexId = complexId;
    this.compartmentId = compartmentId;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.zIndex = zIndex;
    this.text = text;
    this.fontSize = fontSize;
    this.nameX = nameX;
    this.nameY = nameY;
    this.nameWidth = nameWidth;
    this.nameHeight = nameHeight;
    this.fontColor = fontColor;
    this.nameVerticalAlign = nameVerticalAlign;
    this.nameHorizontalAlign = nameHorizontalAlign;
    this.fillColor = fillColor;
    this.borderColor = borderColor;
    this.overlaysVisible = overlaysVisible;
    this.pointToProjection = pointToProjection;
    this.vectorSource = vectorSource;
    this.mapBackgroundType = mapBackgroundType;
    this.mapSize = mapSize;

    const maxZoom = mapInstance?.getView().get('originalMaxZoom');
    this.minResolution = mapInstance?.getView().getResolutionForZoom(maxZoom) || 1;
  }

  protected abstract createPolygons(): void;

  protected drawText(): void {
    if (this.text) {
      const textCoords = getTextCoords({
        x: this.nameX,
        y: this.nameY,
        width: this.nameWidth,
        height: this.nameHeight,
        fontSize: this.fontSize,
        verticalAlign: this.nameVerticalAlign,
        horizontalAlign: this.nameHorizontalAlign,
        pointToProjection: this.pointToProjection,
      });
      const textPolygon = new Polygon([[textCoords, textCoords]]);
      textPolygon.set('type', MAP_ELEMENT_TYPES.TEXT);
      const textStyle = getTextStyle({
        text: this.text,
        fontSize: this.fontSize,
        color: rgbToHex(this.fontColor),
        zIndex: this.zIndex,
        horizontalAlign: this.nameHorizontalAlign,
      });
      textStyle.setGeometry(textPolygon);
      textPolygon.set('style', textStyle);
      this.styles.push(textStyle);
      this.polygons.push(textPolygon);
    }
  }

  protected drawMultiPolygonFeature(mapInstance: MapInstance): void {
    this.feature = new Feature({
      geometry: new MultiPolygon(this.polygons),
      zIndex: this.zIndex,
      getMapExtent: (resolution: number): [number, number, number, number] | undefined => {
        if (this.mapExtentCache.has(resolution)) {
          return this.mapExtentCache.get(resolution);
        }

        const view = mapInstance?.getView();
        const center = view?.getCenter();
        const size = mapInstance?.getSize();

        if (!size || !center) {
          return undefined;
        }

        const extentWidth = size[0] * resolution;
        const extentHeight = size[1] * resolution;

        const extent: [number, number, number, number] = [
          center[0] - extentWidth / 2,
          center[1] - extentHeight / 2,
          center[0] + extentWidth / 2,
          center[1] + extentHeight / 2,
        ];

        this.mapExtentCache.set(resolution, extent);
        return extent;
      },
      id: this.id,
      complexId: this.complexId,
      compartmentId: this.compartmentId,
      type: this.type,
    });
    this.feature.setId(this.id);
    this.feature.setStyle(this.getStyle.bind(this));
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    if (!(feature instanceof Feature)) {
      return undefined;
    }
    const styles: Array<Style> = [];
    const scale = this.minResolution / resolution;
    let cover = false;
    let largestExtent: Extent | null;

    if (
      this.complexId &&
      !COMPLEX_SBO_TERMS.includes(this.sboTerm) &&
      scale < COMPLEX_CONTENTS_CUTOFF_SCALE
    ) {
      feature.set('hidden', true);
      return [];
    }
    feature.set('hidden', false);

    let hide = false;
    if (this.mapBackgroundType === MapBackgroundsEnum.SEMANTIC && scale < TEXT_CUTOFF_SCALE) {
      const semanticViewData = handleSemanticView(
        this.vectorSource,
        feature,
        resolution,
        this.compartmentId,
        this.complexId,
      );
      cover = semanticViewData.cover;
      hide = semanticViewData.hide;
      largestExtent = semanticViewData.largestExtent;

      if (hide) {
        return undefined;
      }
    }

    let type: string;
    let coverStyle: Style | undefined;
    let strokeStyle: Stroke | undefined;

    this.styles.forEach(style => {
      const styleGeometry = style.getGeometry();
      if (styleGeometry instanceof Polygon) {
        type = styleGeometry.get('type');
        coverStyle = styleGeometry.get('coverStyle');
        strokeStyle = styleGeometry.get('strokeStyle');
      }

      if (cover) {
        if (coverStyle && largestExtent) {
          styles.push(
            ...getCoverStyles(
              coverStyle,
              largestExtent,
              this.text,
              scale,
              this.zIndex + 100000,
              this.mapSize,
            ),
          );
        }
        return;
      }

      if (
        [MAP_ELEMENT_TYPES.MODIFICATION, MAP_ELEMENT_TYPES.TEXT].includes(type) &&
        scale < TEXT_CUTOFF_SCALE
      ) {
        return;
      }

      const textStyle = style.getText();
      if (type === MAP_ELEMENT_TYPES.TEXT && textStyle) {
        textStyle.setScale(scale);
      }
      if (strokeStyle) {
        const lineWidth = strokeStyle.getWidth() || 1;
        if (
          !this.overlaysVisible &&
          scale < OUTLINE_CUTOFF_SCALE &&
          !COMPLEX_SBO_TERMS.includes(this.sboTerm) &&
          this.type !== MAP_ELEMENT_TYPES.COMPARTMENT
        ) {
          style.setStroke(null);
        } else {
          const lineDash = strokeStyle.getLineDash();
          let newLineDash: Array<number> = [];
          if (lineDash) {
            newLineDash = lineDash.map(width => width * scale);
          }
          const newStrokeStyle = new Stroke({
            color: strokeStyle.getColor(),
            width: lineWidth * scale,
            lineDash: newLineDash,
          });

          style.setStroke(newStrokeStyle);
        }
      }
      styles.push(style);
    });

    return styles;
  }
}
