/* eslint-disable @next/next/no-img-element */
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentLegendImagesSelector } from '@/redux/legend/legend.selectors';

export const LegendImages: React.FC = () => {
  const imageUrls = useAppSelector(currentLegendImagesSelector);

  return (
    <div
      data-testid="legend-images"
      className="flex items-center justify-between overflow-x-auto border-b border-b-divide px-6 py-8"
    >
      {imageUrls.map(imageUrl => (
        <img key={imageUrl} src={imageUrl} alt={imageUrl} className="h-[400px] max-h-[50vh]" />
      ))}
    </div>
  );
};
