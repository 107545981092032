/* eslint-disable no-magic-numbers */
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { getLayersForModel } from '@/redux/layers/layers.thunks';
import {
  layersForCurrentModelSelector,
  layersLoadingSelector,
  layersVisibilityForCurrentModelSelector,
} from '@/redux/layers/layers.selectors';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import { MapInstance } from '@/types/map';
import { LineString, MultiPolygon, Point } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import Layer from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/layer/Layer';
import { arrowTypesSelector, lineTypesSelector } from '@/redux/shapes/shapes.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';

export const useOlMapAdditionalLayers = (
  mapInstance: MapInstance,
): Array<
  VectorLayer<
    VectorSource<Feature<Point> | Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>>
  >
> => {
  const dispatch = useAppDispatch();
  const currentModelId = useSelector(currentModelIdSelector);

  const layersForCurrentModel = useAppSelector(layersForCurrentModelSelector);
  const layersLoading = useAppSelector(layersLoadingSelector);
  const layersVisibilityForCurrentModel = useAppSelector(layersVisibilityForCurrentModelSelector);

  const lineTypes = useSelector(lineTypesSelector);
  const arrowTypes = useSelector(arrowTypesSelector);
  const pointToProjection = usePointToProjection();

  useEffect(() => {
    if (!currentModelId) {
      return;
    }
    if (!['succeeded', 'pending'].includes(layersLoading)) {
      dispatch(getLayersForModel(currentModelId));
    }
  }, [currentModelId, dispatch, layersLoading]);

  const vectorLayers = useMemo(() => {
    return layersForCurrentModel.map(layer => {
      const additionalLayer = new Layer({
        texts: layer.texts,
        rects: layer.rects,
        ovals: layer.ovals,
        lines: layer.lines,
        visible: layer.details.visible,
        layerId: layer.details.id,
        lineTypes,
        arrowTypes,
        mapInstance,
        pointToProjection,
      });
      return additionalLayer.vectorLayer;
    });
  }, [arrowTypes, lineTypes, mapInstance, layersForCurrentModel, pointToProjection]);

  useEffect(() => {
    vectorLayers.forEach(layer => {
      const layerId = layer.get('id');
      if (layerId && layersVisibilityForCurrentModel[layerId] !== undefined) {
        layer.setVisible(layersVisibilityForCurrentModel[layerId]);
      }
    });
  }, [layersVisibilityForCurrentModel, vectorLayers]);

  return vectorLayers;
};
