import { useSelect } from 'downshift';
import { IconButton } from '@/shared/IconButton';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { userSelector } from '@/redux/user/user.selectors';
import { openToSModal } from '@/redux/modal/modal.slice';
import { termsOfServiceValSelector } from '@/redux/configuration/configuration.selectors';
import { useUserActions } from '../hooks/useUserActions';

export const AuthenticatedUser = (): React.ReactNode => {
  const { actions, handleActionClick, iconName } = useUserActions();

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: actions,
  });

  const dispatch = useAppDispatch();
  const { userData } = useAppSelector(userSelector);
  const termsOfService = useAppSelector(termsOfServiceValSelector);

  if (userData && !userData.termsOfUseConsent && termsOfService) {
    dispatch(openToSModal());
  }

  return (
    <>
      <IconButton
        {...getToggleButtonProps()}
        icon={iconName}
        title="User"
        className="bg-transparent hover:bg-transparent active:bg-transparent"
        classNameIcon="w-5 h-6"
        data-testid="authenticated-button"
      />

      <ul
        className={twMerge(
          `absolute left-1/2 top-full w-40 rounded-lg border border-[#DBD9D9] bg-white px-2.5 shadow-md`,
          !isOpen && 'hidden',
        )}
        {...getMenuProps()}
      >
        {isOpen &&
          actions.map((item, index) => (
            <li
              key={item}
              {...getItemProps({
                item,
                index,
                onClick: () => handleActionClick(item),
              })}
              className='relative cursor-pointer px-1 py-4 text-xs before:absolute before:bottom-0 before:left-1/2 before:top-full before:block before:h-px before:w-11/12 before:-translate-x-1/2 before:bg-[#E3E3E3] before:content-[""] before:last-of-type:hidden'
            >
              {item}
            </li>
          ))}
      </ul>
    </>
  );
};
