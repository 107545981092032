import { STEP } from '@/constants/searchDrawer';
import { currentStepOverlayDrawerStateSelector } from '@/redux/drawer/drawer.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { GeneralOverlays } from './GeneralOverlays';
import { OverlaysLegends } from './OverlaysLegends';
import { UserOverlayForm } from './UserOverlayForm';
import { UserOverlays } from './UserOverlays';

export const OverlaysDrawer = (): JSX.Element => {
  const currentStep = useAppSelector(currentStepOverlayDrawerStateSelector);

  return (
    <div data-testid="overlays-drawer" className="h-full max-h-full">
      {currentStep === STEP.FIRST && (
        <>
          <DrawerHeading title="Overlays" />
          <div className="h-[calc(100%-93px)] max-h-[calc(100%-93px)] overflow-y-auto">
            <GeneralOverlays />
            <UserOverlays />
            <OverlaysLegends />
          </div>
        </>
      )}
      {currentStep === STEP.SECOND && <UserOverlayForm />}
    </div>
  );
};
