/* eslint-disable no-magic-numbers */
import { ConfigurationMiramiTypes, Statistics } from '@/types/models';
import { ANNOTATIONS_TYPE } from '../ExportCompound.constant';
import { AnnotationsType } from './Annotations.types';

type CheckboxElement = { id: string; label: string };

type CheckboxElements = CheckboxElement[];

type GetAnnotationsCheckboxElements = {
  type: AnnotationsType;
  statistics: Statistics | undefined;
  miramiTypes: ConfigurationMiramiTypes | undefined;
};

const sortByCount = (countA: number, countB: number): number => {
  return countA > countB ? -1 : 1;
};

const mapToCheckboxElement = (
  annotation: string,
  miramiTypes: ConfigurationMiramiTypes,
): CheckboxElement => ({
  id: annotation,
  label: miramiTypes[annotation].commonName,
});

const filterAnnotationsByCount = (annotations: Record<string, number>): string[] => {
  return Object.keys(annotations).filter(annotation => annotations[annotation] > 0);
};

export const getAnnotationsCheckboxElements = ({
  type,
  statistics,
  miramiTypes,
}: GetAnnotationsCheckboxElements): CheckboxElements => {
  if (!statistics || !miramiTypes) return [];

  const annotations =
    type === ANNOTATIONS_TYPE.ELEMENTS
      ? statistics.elementAnnotations
      : statistics.reactionAnnotations;

  const availableAnnotations = filterAnnotationsByCount(annotations);

  return availableAnnotations
    .sort((firstAnnotation, secondAnnotation) =>
      sortByCount(annotations[firstAnnotation], annotations[secondAnnotation]),
    )
    .map(annotation => mapToCheckboxElement(annotation, miramiTypes));
};
