import { setPluginLegend } from '@/redux/legend/legend.slice';
import { store } from '@/redux/store';

export const setLegend = (pluginId: string, legend: string[]): void => {
  const { dispatch } = store;

  dispatch(
    setPluginLegend({
      pluginId,
      legend,
    }),
  );
};
