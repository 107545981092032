import { projectOverviewImagesSelector } from '@/redux/project/project.selectors';
import { store } from '@/redux/store';
import { OverviewImageView } from '@/types/models';

export const getOverviewImages = (): OverviewImageView[] => {
  const { getState } = store;
  const overviewImages = projectOverviewImagesSelector(getState());

  return overviewImages;
};
