import { LATLNG_FALLBACK } from '@/constants/map';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { Point } from '@/types/map';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { pointToLngLat } from './pointToLatLng';

export type UsePointToProjectionResult = (point: Point) => Coordinate;

type UsePointToProjection = () => UsePointToProjectionResult;

export const usePointToProjection: UsePointToProjection = () => {
  const mapSize = useSelector(mapDataSizeSelector);

  const pointToProjection = useCallback(
    (point: Point): Coordinate => {
      const [lng, lat] = pointToLngLat(point, mapSize);
      const projection = fromLonLat([lng, lat]);
      const projectionRounded = projection.map(v => Math.round(v));
      const isValid = !projectionRounded.some(v => Number.isNaN(v));

      return isValid ? projectionRounded : LATLNG_FALLBACK;
    },
    [mapSize],
  );

  return pointToProjection;
};
