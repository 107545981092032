import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { allLegendsNamesAndIdsSelector } from '@/redux/legend/legend.selectors';
import React from 'react';
import { LegendTab } from './LegendTab/LegendTab.component';

export const LegendTabs = (): React.ReactNode => {
  const allLegendsNamesAndIds = useAppSelector(allLegendsNamesAndIdsSelector);

  return (
    <div className="flex h-10 w-full flex-row flex-nowrap justify-start border-b border-b-divide bg-white-pearl text-xs">
      {allLegendsNamesAndIds.map(({ id, name }) => (
        <LegendTab name={name} id={id} key={id} />
      ))}
    </div>
  );
};
