import { PLUGINS_CONTENT_ELEMENT_ATTR_NAME } from '@/constants/plugins';

export const HIDE_ALL_ELEMENTS_STYLE = `
    div[${PLUGINS_CONTENT_ELEMENT_ATTR_NAME}] {
        display: none;
    }
`;

export const SHOW_SELECTED_PLUGIN_ELEMENT_STYLE = (hash: string): string => `
    div[${PLUGINS_CONTENT_ELEMENT_ATTR_NAME}='${hash}'] {
        display: unset;
    }
`;
