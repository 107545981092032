import { isOverlayActiveSelector } from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { removeOverlayBioEntityForGivenOverlay } from '@/redux/overlayBioEntity/overlayBioEntity.slice';
import { overlaySelector, userOverlaySelector } from '@/redux/overlays/overlays.selectors';
import { store } from '@/redux/store';
import { PluginsEventBus } from '../../pluginsEventBus';
import { ERROR_OVERLAY_ID_NOT_ACTIVE, ERROR_OVERLAY_ID_NOT_FOUND } from '../../errorMessages';

export const hideDataOverlay = (overlayId: number): void => {
  const { dispatch, getState } = store;
  const state = getState();
  const isOverlayActive = isOverlayActiveSelector(state, overlayId);
  const overlay = overlaySelector(state, overlayId);
  const userOverlay = userOverlaySelector(state, overlayId);

  const matchingOverlay = overlay || userOverlay;

  if (!matchingOverlay) throw new Error(ERROR_OVERLAY_ID_NOT_FOUND);

  if (!isOverlayActive) {
    throw new Error(ERROR_OVERLAY_ID_NOT_ACTIVE);
  }

  dispatch(removeOverlayBioEntityForGivenOverlay({ overlayId }));

  PluginsEventBus.dispatchEvent('onHideOverlay', matchingOverlay);
};
