/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  getDefaultSearchTab,
  getSearchValuesArrayAndTrimToSeven,
} from '@/components/FunctionalArea/TopBar/SearchBar/SearchBar.utils';
import { getCanvasIcon } from '@/components/Map/MapViewer/utils/config/getCanvasIcon';
import { PINS_COLORS } from '@/constants/canvas';
import { DEFAULT_MAX_ZOOM } from '@/constants/map';
import { openSearchDrawerWithSelectedTab } from '@/redux/drawer/drawer.slice';
import { numberByEntityNumberIdSelector } from '@/redux/entityNumber/entityNumber.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { setMapPosition } from '@/redux/map/map.slice';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { getSearchData } from '@/redux/search/search.thunks';
import { twMerge } from 'tailwind-merge';
import { getTypeBySBOTerm } from '@/utils/bioEntity/getTypeBySBOTerm';
import { ZERO } from '@/constants/common';
import React from 'react';
import { AnnotationItemList } from '@/components/Map/Drawer/BioEntityDrawer/AnnotationItem/AnnotationItemList.component';
import { PinListBioEntity } from './BioEntitiesPinsListItem.types';
import { isPinWithCoordinates } from './BioEntitiesPinsListItem.utils';

interface BioEntitiesPinsListItemProps {
  name: string;
  pin: PinListBioEntity;
}

export const BioEntitiesPinsListItem = ({
  name,
  pin,
}: BioEntitiesPinsListItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const pinHasCoords = isPinWithCoordinates(pin);
  const pinIconValue = useAppSelector(state =>
    numberByEntityNumberIdSelector(state, pin.elementId || ''),
  );
  const pinIconCanvas = getCanvasIcon({
    color: PINS_COLORS.bioEntity,
    value: pinIconValue,
  });

  const handleCenterMapToPin = (): void => {
    if (!pinHasCoords) {
      return;
    }

    dispatch(
      setMapPosition({
        x: pin.x,
        y: pin.y,
        z: DEFAULT_MAX_ZOOM,
      }),
    );
  };

  const handleSearchMapForPin = (): void => {
    const searchValues = getSearchValuesArrayAndTrimToSeven(name);
    dispatch(resetReactionsData());
    dispatch(getSearchData({ searchQueries: searchValues, isPerfectMatch: true }));
    dispatch(openSearchDrawerWithSelectedTab(getDefaultSearchTab(searchValues)));
  };

  const type = getTypeBySBOTerm(pin.sboTerm);

  return (
    <div className="mb-4 flex w-full flex-col gap-3 rounded-lg border-[1px] border-solid border-greyscale-500 p-4">
      <div className="flex w-full flex-row items-center gap-2">
        <button
          type="button"
          onClick={handleCenterMapToPin}
          className={twMerge('mr-2 shrink-0', !pinHasCoords && 'cursor-default')}
          data-testid="center-to-pin-button"
        >
          <img src={pinIconCanvas.toDataURL()} alt="pin icon" />
        </button>
        <p>
          {type ? `${type}: ` : ''}
          <span
            className="w-full cursor-pointer font-bold underline"
            onClick={handleSearchMapForPin}
          >
            {name}
          </span>
        </p>
      </div>
      {pin.fullName && (
        <p className="font-bold leading-6">
          Full name:{' '}
          <span className="w-full font-normal" data-testid="bio-entity-name">
            {pin.fullName}
          </span>
        </p>
      )}
      {pin.symbol && (
        <p className="font-bold leading-6">
          Symbol:{' '}
          <span className="w-full font-normal" data-testid="bio-entity-symbol">
            {pin.symbol}
          </span>
        </p>
      )}
      {pin.synonyms.length > ZERO && (
        <p className="font-bold leading-6">
          Synonyms: <span className="w-full font-normal">{pin.synonyms.join(', ')}</span>
        </p>
      )}
      <AnnotationItemList references={pin.references} />
    </div>
  );
};
