import { ZERO } from '@/constants/common';

export const injectMatomoTracking = (url: string | undefined): void => {
  if (url !== '' && url !== undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line no-underscore-dangle,no-multi-assign
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[ZERO];
    g.async = true;
    g.src = url;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    s.parentNode.insertBefore(g, s);
  }
};
