/* eslint-disable no-magic-numbers */ /* due to math nature of this algo */
import { LATLNG_FALLBACK, VALID_MAP_SIZE_SCHEMA } from '@/constants/map';
import { MapSize } from '@/redux/map/map.types';
import { LatLng, Point } from '@/types/map';
import { radiansToDegrees } from '../number/radiansToDegrees';
import { getPointOffset } from './getPointOffset';

const FULL_CIRCLE_DEGREES = 360;

const getIsMapSizeValid = (mapSize?: MapSize): boolean => {
  const parseResult = VALID_MAP_SIZE_SCHEMA.safeParse(mapSize);

  if (parseResult.success === false) {
    // TODO: need to rething way of handling parsing errors, for now let's leave it to console.log
    // eslint-disable-next-line no-console
    console.error('Error parsing map size', parseResult.error);
  }

  return parseResult.success;
};

export const pointToLngLat = (point: Point, mapSize?: MapSize): LatLng => {
  const isMapSizeValid = getIsMapSizeValid(mapSize);
  if (!isMapSizeValid || !mapSize) {
    return LATLNG_FALLBACK;
  }
  const { x: xOffset, y: yOffset } = getPointOffset(point, mapSize);
  const pixelsPerLonDegree = mapSize.tileSize / FULL_CIRCLE_DEGREES;
  const pixelsPerLonRadian = mapSize.tileSize / (2 * Math.PI);
  const lng = xOffset / pixelsPerLonDegree;
  const latRadians = yOffset / -pixelsPerLonRadian;
  const lat = radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);

  return [lng, lat];
};
