import { BioEntity, ModelElement } from '@/types/models';

export function mapModelElementToBioEntity(modelElement: ModelElement): BioEntity {
  return {
    id: modelElement.id,
    name: modelElement.name,
    model: modelElement.model,
    elementId: modelElement.elementId,
    references: modelElement.references,
    z: modelElement.z,
    notes: modelElement.notes,
    symbol: modelElement.symbol,
    homodimer: modelElement.homodimer,
    nameX: modelElement.nameX,
    nameY: modelElement.nameY,
    nameWidth: modelElement.nameWidth,
    nameHeight: modelElement.nameHeight,
    nameVerticalAlign: modelElement.nameVerticalAlign,
    nameHorizontalAlign: modelElement.nameHorizontalAlign,
    width: modelElement.width,
    height: modelElement.height,
    visibilityLevel: modelElement.visibilityLevel,
    transparencyLevel: modelElement.transparencyLevel,
    synonyms: modelElement.synonyms,
    formerSymbols: modelElement.formerSymbols,
    fullName: modelElement.fullName,
    abbreviation: modelElement.abbreviation,
    formula: modelElement.formula,
    glyph: modelElement.glyph,
    activity: modelElement.activity,
    hypothetical: modelElement.hypothetical,
    boundaryCondition: modelElement.boundaryCondition,
    constant: modelElement.constant,
    initialAmount: modelElement.initialAmount,
    initialConcentration: modelElement.initialConcentration,
    charge: modelElement.charge,
    substanceUnits: modelElement.substanceUnits,
    onlySubstanceUnits: modelElement.onlySubstanceUnits,
    modificationResidues: modelElement.modificationResidues,
    complex: modelElement.complex,
    submodel: modelElement.submodel,
    x: modelElement.x,
    y: modelElement.y,
    lineWidth: modelElement.lineWidth,
    fontColor: modelElement.fontColor,
    fontSize: modelElement.fontSize,
    fillColor: modelElement.fillColor,
    borderColor: modelElement.borderColor,
    sboTerm: modelElement.sboTerm,
  } as BioEntity;
}
