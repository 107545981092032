/* eslint-disable no-magic-numbers */
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import { OverlayBioEntityRender } from '@/types/OLrendering';

export default function sortElementOverlayByColor(
  elementOverlay: Array<OverlayBioEntityRender & { amount: number }>,
  getColor: GetOverlayBioEntityColorByAvailableProperties,
): void {
  elementOverlay.sort((a, b) => {
    const colorA = getColor(a);
    const colorB = getColor(b);
    if (colorA === colorB) {
      return 0;
    }
    if (colorA < colorB) {
      return -1;
    }
    return 1;
  });
}
