import { ONE } from '@/constants/common';
import { TargetElement } from '@/types/models';
import { ElementLink } from './ElementLink';

interface Props {
  targets: TargetElement[];
}

export const ElementsOnMapCell = ({ targets }: Props): JSX.Element => {
  return (
    <div className="inline">
      {targets.map((target, index) => {
        const isLastElement = index + ONE === targets.length;

        return (
          <>
            <ElementLink key={target.id} target={target} />
            {isLastElement ? '' : ', '}
          </>
        );
      })}
    </div>
  );
};
