import { twMerge } from 'tailwind-merge';
import { useEffect, useState } from 'react';

type VariantStyle = 'primary' | 'secondary' | 'ghost' | 'quiet';

export interface SwitchProps {
  variantStyles?: VariantStyle;
  isChecked?: boolean;
  onToggle?: (checked: boolean) => void;
}

const variants = {
  primary: {
    switch: 'bg-greyscale-700',
    circle: 'bg-white-pearl',
  },
  secondary: {
    switch: 'bg-primary-100',
    circle: 'bg-white-pearl',
  },
  ghost: {
    switch: 'bg-greyscale-600',
    circle: 'bg-white-pearl',
  },
  quiet: {
    switch: 'bg-greyscale-500',
    circle: 'bg-white-pearl',
  },
} as const;

export const Switch = ({
  variantStyles = 'primary',
  isChecked = false,
  onToggle,
}: SwitchProps): JSX.Element => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleToggle = (): void => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onToggle) {
      onToggle(newChecked);
    }
  };

  return (
    <button
      type="button"
      className={twMerge(
        'relative inline-flex h-5 w-10 cursor-pointer rounded-full transition-colors duration-300 ease-in-out',
        variants[variantStyles].switch,
        checked ? 'bg-primary-600' : '',
      )}
      onClick={handleToggle}
    >
      <span
        className={twMerge(
          'absolute left-0 top-0 h-5 w-5 rounded-full transition-transform duration-300 ease-in-out',
          variants[variantStyles].circle,
          checked ? 'translate-x-6' : 'translate-x-0',
        )}
      />
    </button>
  );
};

Switch.displayName = 'Switch';
