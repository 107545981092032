import { createSelector } from '@reduxjs/toolkit';
import { backgroundsSelector } from '../backgrounds/background.selectors';
import { mapSelector } from '../map/map.selectors';
import { modelsSelector } from '../models/models.selectors';
import { overlaysSelector } from '../overlays/overlays.selectors';
import { projectSelector } from '../project/project.selectors';

export const initDataLoadingInitialized = createSelector(
  projectSelector,
  backgroundsSelector,
  modelsSelector,
  overlaysSelector,
  (...selectors) => selectors.every(selector => selector.loading !== 'idle'),
);

export const initDataLoadingFinishedSelector = createSelector(
  projectSelector,
  backgroundsSelector,
  modelsSelector,
  overlaysSelector,
  (...selectors) => selectors.every(selector => selector.loading === 'succeeded'),
);

export const initDataAndMapLoadingFinished = createSelector(
  projectSelector,
  backgroundsSelector,
  modelsSelector,
  overlaysSelector,
  mapSelector,
  (...selectors) => selectors.every(selector => selector.loading === 'succeeded'),
);
