/* eslint-disable no-magic-numbers */
import { Extent } from 'ol/extent';

export default function getDividedExtents(
  extentsArray: Array<Extent>,
  dividingExtent: Extent,
): Array<Extent> {
  const minX2 = dividingExtent[0];
  const minY2 = dividingExtent[1];
  const maxX2 = dividingExtent[2];
  const maxY2 = dividingExtent[3];
  const dividedExtents: Array<Extent> = [];

  extentsArray.forEach(extent => {
    const [minX1, minY1, maxX1, maxY1] = [...extent];

    const intersects = minX1 < maxX2 && maxX1 > minX2 && minY1 < maxY2 && maxY1 > minY2;
    if (intersects) {
      if (minX2 <= minX1 && minY2 <= minY1 && maxX2 >= maxX1 && maxY2 >= maxY1) {
        return;
      }
      if (minX2 > minX1) {
        const leftExtent = [minX1, minY1, minX2, maxY1];
        dividedExtents.push(leftExtent);
      }
      if (minX2 < maxX1) {
        const rightExtent = [maxX2, minY1, maxX1, maxY1];
        dividedExtents.push(rightExtent);
      }
      if (maxY2 < maxY1) {
        const topExtent = [minX1, maxY2, maxX1, maxY1];
        dividedExtents.push(topExtent);
      }
      if (minY2 > minY1) {
        const bottomExtent = [minX1, minY1, maxX1, minY2];
        dividedExtents.push(bottomExtent);
      }
    } else {
      dividedExtents.push(extent);
    }
  });
  return dividedExtents;
}
