import React from 'react';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { projectSelector } from '@/redux/project/project.selectors';

export const LicenseModal = (): React.ReactNode => {
  const project = useAppSelector(projectSelector).data;

  let licenseDescription = '';
  if (project) {
    licenseDescription = project.license
      ? `<a href="${project.license.url}" target="_blank">Link</a><br/><br/>${project.license.content}`
      : `<a href="${project.customLicenseUrl}" target="_blank">Link: ${project.customLicenseUrl}</a>`;
  }
  return (
    <div className="w-full overflow-auto border border-t-[#E1E0E6] bg-white p-[24px]">
      <div dangerouslySetInnerHTML={{ __html: licenseDescription }} />
    </div>
  );
};
