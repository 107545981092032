const HEX_BASE_NUMBER = 16;
const WHITE_HEX = 0xffffff;
const EXPECTED_HEX_CHARS_NUMBER = 6;

export const convertDecimalToHexColor = (value: number): string => {
  // eslint-disable-next-line no-bitwise
  const trimmedValue = value & WHITE_HEX;
  let colorStr = trimmedValue.toString(HEX_BASE_NUMBER);
  while (colorStr.length < EXPECTED_HEX_CHARS_NUMBER) {
    colorStr = `0${colorStr}`;
  }
  return `#${colorStr}`;
};
