import { PIN_SIZE } from '@/constants/canvas';
import { ZERO } from '@/constants/common';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { getCanvasIcon } from '../getCanvasIcon';

export const getPinStyle = ({
  value,
  color,
  textColor,
}: {
  value?: number;
  color: string;
  textColor?: string;
}): Style =>
  new Style({
    image: new Icon({
      displacement: [ZERO, PIN_SIZE.height],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      img: getCanvasIcon({
        color,
        value,
        textColor,
      }),
    }),
  });
