import { FIRST_ARRAY_ELEMENT, SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { openReactionDrawerById, selectTab } from '@/redux/drawer/drawer.slice';
import { getReactionsByIds } from '@/redux/reactions/reactions.thunks';
import { AppDispatch } from '@/redux/store';
import { searchFitBounds } from '@/services/pluginsManager/map/triggerSearch/searchFitBounds';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { Point } from '@/types/map';
import { BioEntity, ElementSearchResult } from '@/types/models';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { apiPath } from '@/redux/apiPath';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { getMultiBioEntityByIds } from '@/redux/bioEntity/thunks/getMultiBioEntity';
import { newReactionSchema } from '@/models/newReactionSchema';
import getModelElementsIdsFromReaction from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/getModelElementsIdsFromReaction';
import { handleReactionSearchClickFailure } from './handleReactionSearchClickFailure';
import { findClosestReactionPoint } from './findClosestReactionPoint';

type SearchConfig = {
  point: Point;
  searchDistance?: string;
  maxZoom: number;
  zoom: number;
  hasFitBounds?: boolean;
  isResultDrawerOpen: boolean;
};

/* prettier-ignore */
export const handleReactionResults =
  (dispatch: AppDispatch, closestSearchResult: ElementSearchResult, searchConfig?: SearchConfig) =>
    async ({ id, modelId }: ElementSearchResult): Promise<void> => {
      const data = await dispatch(getReactionsByIds({ ids: [{ id, modelId }] }));
      const payload = data?.payload;
      if (!data || !payload || typeof payload === 'string' || payload.data.length === SIZE_OF_EMPTY_ARRAY) {
        return;
      }

      const reaction = payload.data[FIRST_ARRAY_ELEMENT];
      const bioEntitiesIds = getModelElementsIdsFromReaction(reaction);

      if (searchConfig && searchConfig.searchDistance) {
        const { maxZoom, point, searchDistance, zoom, isResultDrawerOpen } = searchConfig;
        const matchingReactionFound = findClosestReactionPoint({
          reaction, searchDistance, maxZoom, zoom, point
        });

        if (!matchingReactionFound) {
          handleReactionSearchClickFailure(dispatch, isResultDrawerOpen);

          return;
        }
      }

      dispatch(openReactionDrawerById(reaction.id));

      dispatch(selectTab(''));

      const response = await axiosInstanceNewAPI.get<BioEntity>(apiPath.getNewReaction(reaction.model, reaction.id));
      const isDataValid = validateDataUsingZodSchema(response.data, newReactionSchema);

      if (isDataValid) {
        const reactionNewApi = response.data;

        const bioEntities = await dispatch(
          getMultiBioEntityByIds({
            elementsToFetch: bioEntitiesIds.map((bioEntityId) => {
              return {
                elementId: bioEntityId,
                modelId,
                type: 'ALIAS'
              };
            })
          })
        ).unwrap();

        if (bioEntities) {
          const result = bioEntities.map((bioEntity) => {return { bioEntity, perfect: true };});
          result.push({ bioEntity: reactionNewApi, perfect: true });
          PluginsEventBus.dispatchEvent('onSearch', {
            type: 'reaction',
            searchValues: [closestSearchResult],
            results: [result]
          });

          if (searchConfig && searchConfig.hasFitBounds) {
            searchFitBounds();
          }
        }
      }
    };
