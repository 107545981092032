import { ZOOM_ERRORS } from '@/constants/errors';
import { zPointSchema } from '@/models/pointSchema';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { setLastPositionZoom } from '@/redux/map/map.slice';
import { store } from '@/redux/store';

export const setZoom = (zoom: number): void => {
  const { dispatch, getState } = store;
  const { minZoom, maxZoom } = mapDataSizeSelector(getState());
  zPointSchema.parse(zoom);

  if (zoom < minZoom) {
    throw Error(ZOOM_ERRORS.ZOOM_VALUE_TOO_LOW(minZoom));
  }

  if (zoom > maxZoom) {
    throw Error(ZOOM_ERRORS.ZOOM_VALUE_TOO_HIGH(maxZoom));
  }

  dispatch(setLastPositionZoom({ zoom }));
};
