import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { selectedDrawerPluginSelector } from '@/redux/plugins/plugins.selectors';
import { closePluginsDrawer } from '@/redux/plugins/plugins.slice';
import { IconButton } from '@/shared/IconButton';
import { useSelector } from 'react-redux';
import { CLOSE_PLUGINS_DRAWER_BUTTON_ROLE } from '../PluginsDrawer.constants';
import { PluginHeaderInfo } from './PluginHeaderInfo';
import { PluginOpenButton } from './PluginOpenButton';

export const PluginsHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentPlugin = useSelector(selectedDrawerPluginSelector);

  const onCloseButtonClick = (): void => {
    dispatch(closePluginsDrawer());
  };

  return (
    <div
      className="flex flex-col items-start gap-4 border-b border-b-divide bg-white-pearl p-6"
      data-testid="drawer-plugins-header"
    >
      <div className="flex w-full justify-between">
        <div className="flex max-w-[90%] items-center gap-2 text-xl">
          {currentPlugin ? <PluginHeaderInfo plugin={currentPlugin} /> : <>No plugin selected</>}
        </div>
        <IconButton
          className="h-auto w-auto bg-white-pearl p-0"
          classNameIcon="fill-font-500"
          icon="close"
          role={CLOSE_PLUGINS_DRAWER_BUTTON_ROLE}
          onClick={onCloseButtonClick}
        />
      </div>
      <PluginOpenButton />
    </div>
  );
};
