import { VALID_MAP_SIZE_SCHEMA } from '@/constants/map';
import { MapSize } from '@/redux/map/map.types';
import { Point } from '@/types/map';

interface GetPointOffsetResults extends Point {
  pointOrigin: Point;
  pointShifted: Point;
  zoomFactor: number;
}

const SHIFT_MULTIPLIER = 2;
const DIVIDE_TO_CENTER = 2;

export const getPointOffset = (point: Point, mapSize: MapSize): GetPointOffsetResults => {
  // parse throws error if map size may lead to invalid results
  VALID_MAP_SIZE_SCHEMA.parse(mapSize);

  const longestSide = Math.max(mapSize.width, mapSize.height);
  const minZoomShifted = mapSize.minZoom * SHIFT_MULTIPLIER ** mapSize.minZoom;
  const zoomFactor = longestSide / (mapSize.tileSize / minZoomShifted) / DIVIDE_TO_CENTER;

  const pointOrigin: Point = {
    x: mapSize.tileSize / DIVIDE_TO_CENTER,
    y: mapSize.tileSize / DIVIDE_TO_CENTER,
  };

  const pointShifted: Point = {
    x: point.x / zoomFactor,
    y: point.y / zoomFactor,
  };

  return {
    x: pointShifted.x - pointOrigin.x,
    y: pointShifted.y - pointOrigin.y,
    pointOrigin,
    pointShifted,
    zoomFactor,
  };
};
