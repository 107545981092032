import { PluginsContextMenuType } from '@/services/pluginsManager/pluginContextMenu/pluginsContextMenu.types';
import { v4 as uuidv4 } from 'uuid';
import { ZERO } from '@/constants/common';

export const PluginsContextMenu: PluginsContextMenuType = {
  menuItems: [],
  addMenu: (hash, name, style, enabled, callback) => {
    const uuid = uuidv4();
    PluginsContextMenu.menuItems.push({
      hash,
      callback,
      enabled,
      name,
      style,
      id: uuid,
    });
    return uuid;
  },
  removeMenu: (hash, id) => {
    PluginsContextMenu.menuItems = PluginsContextMenu.menuItems.filter(
      item => item.hash !== hash || item.id !== id,
    );
  },
  updateMenu: (hash, id, name, style, enabled) => {
    const originalItems = PluginsContextMenu.menuItems.filter(
      item => item.hash === hash && item.id === id,
    );
    if (originalItems.length > ZERO) {
      originalItems[ZERO].name = name;
      originalItems[ZERO].style = style;
      originalItems[ZERO].enabled = enabled;
    } else {
      throw new Error(`Cannot find menu item with id=${id}`);
    }
  },
};
