import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { bioEnititiesResultListSelector } from '@/redux/drawer/drawer.selectors';
import { DrawerHeadingBackwardButton } from '@/shared/DrawerHeadingBackwardButton';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { displayGroupedSearchResults } from '@/redux/drawer/drawer.slice';
import { currentModelNameSelector } from '@/redux/models/models.selectors';
import { BioEntitiesPinsList } from './BioEntitiesPinsList';

export const BioEntitiesResultsList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const bioEntityData = useAppSelector(bioEnititiesResultListSelector);
  const mapName = useAppSelector(currentModelNameSelector);

  const navigateToGroupedSearchResults = (): void => {
    dispatch(displayGroupedSearchResults());
  };

  return (
    <div className="h-full">
      <DrawerHeadingBackwardButton backwardFunction={navigateToGroupedSearchResults}>
        {mapName}
      </DrawerHeadingBackwardButton>
      <BioEntitiesPinsList bioEnititesPins={bioEntityData} />
    </div>
  );
};
