import { DrawerHeading } from '@/shared/DrawerHeading';
import { useSelector } from 'react-redux';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { CommentItem } from '@/components/Map/Drawer/BioEntityDrawer/Comments/CommentItem.component';
import { ZERO } from '@/constants/common';
import { currentDrawerCommentId } from '@/redux/drawer/drawer.selectors';
import { allCommentsSelectorOfCurrentMap } from '@/redux/comment/comment.selectors';

export const CommentDrawer = (): React.ReactNode => {
  const commentId = useSelector(currentDrawerCommentId);

  const commentsData = useAppSelector(allCommentsSelectorOfCurrentMap);

  const comments = commentsData.filter(commentEntry => commentEntry.id === commentId);

  if (comments.length === ZERO) {
    return null;
  }

  return (
    <div className="h-full max-h-full" data-testid="reaction-drawer">
      <DrawerHeading title={<span className="font-normal">Area: </span>} />
      <div className="flex h-[calc(100%-93px)] max-h-[calc(100%-93px)] flex-col gap-6 overflow-y-auto p-6">
        <div className="font-bold"> Comments</div>
        {comments.map(comment => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
      </div>
    </div>
  );
};
