import { searchedBioEntityElementUniProtIdSelector } from '@/redux/bioEntity/bioEntity.selectors';
import { contextMenuSelector } from '@/redux/contextMenu/contextMenu.selector';
import { closeContextMenu } from '@/redux/contextMenu/contextMenu.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { openAddCommentModal, openMolArtModalById } from '@/redux/modal/modal.slice';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { FIRST_ARRAY_ELEMENT, SECOND_ARRAY_ELEMENT, ZERO } from '@/constants/common';
import { PluginsContextMenu } from '@/services/pluginsManager/pluginContextMenu/pluginsContextMenu';
import { BioEntity, NewReaction } from '@/types/models';
import { ClickCoordinates } from '@/services/pluginsManager/pluginContextMenu/pluginsContextMenu.types';
import { currentModelSelector } from '@/redux/models/models.selectors';
import { mapDataLastPositionSelector } from '@/redux/map/map.selectors';
import { DEFAULT_ZOOM } from '@/constants/map';
import { OutsideClickWrapper } from '@/shared/OutsideClickWrapper';

export const ContextMenu = (): React.ReactNode => {
  const pluginContextMenu = PluginsContextMenu.menuItems;
  const model = useAppSelector(currentModelSelector);
  const lastPosition = useAppSelector(mapDataLastPositionSelector);
  const dispatch = useAppDispatch();
  const { isOpen, coordinates } = useAppSelector(contextMenuSelector);
  const unitProtId = useAppSelector(searchedBioEntityElementUniProtIdSelector);

  const isUnitProtIdAvailable = (): boolean => unitProtId !== undefined;

  const getUnitProtId = (): string | undefined => {
    return isUnitProtIdAvailable() ? unitProtId : 'no UnitProt ID available';
  };

  const closeContextMenuFunction = (): void => {
    dispatch(closeContextMenu());
  };

  const handleOpenMolArtClick = (): void => {
    if (isUnitProtIdAvailable()) {
      closeContextMenuFunction();
      dispatch(openMolArtModalById(unitProtId));
    }
  };

  const handleAddCommentClick = (): void => {
    closeContextMenuFunction();
    dispatch(openAddCommentModal());
  };

  const modelId = model ? model.idObject : ZERO;

  const handleCallback = (
    callback: (coordinates: ClickCoordinates, element: BioEntity | NewReaction | undefined) => void,
  ) => {
    return () => {
      closeContextMenuFunction();
      return callback(
        {
          modelId,
          x: coordinates[FIRST_ARRAY_ELEMENT],
          y: coordinates[SECOND_ARRAY_ELEMENT],
          zoom: lastPosition.z ? lastPosition.z : DEFAULT_ZOOM,
        },
        undefined,
      );
    };
  };

  return (
    <OutsideClickWrapper onOutsideClick={closeContextMenuFunction}>
      <div
        className={twMerge(
          'absolute z-10 rounded-lg border border-[#DBD9D9] bg-white p-4',
          isOpen ? '' : 'hidden',
        )}
        style={{
          left: `${coordinates[FIRST_ARRAY_ELEMENT]}px`,
          top: `${coordinates[SECOND_ARRAY_ELEMENT]}px`,
        }}
        data-testid="context-modal"
      >
        <button
          className={twMerge(
            'w-full cursor-pointer text-left text-xs font-normal',
            !isUnitProtIdAvailable() ? 'cursor-not-allowed text-greyscale-700' : '',
          )}
          onClick={handleOpenMolArtClick}
          type="button"
          data-testid="open-molart"
        >
          Open MolArt ({getUnitProtId()})
        </button>
        <hr />
        <button
          className={twMerge('w-full cursor-pointer text-left text-xs font-normal')}
          onClick={handleAddCommentClick}
          type="button"
          data-testid="add-comment"
        >
          Add comment
        </button>
        {pluginContextMenu.length && <hr />}

        {pluginContextMenu.map(contextMenuEntry => (
          <button
            key={contextMenuEntry.id}
            id={contextMenuEntry.id}
            className={twMerge(
              'cursor-pointer text-xs font-normal',
              contextMenuEntry.style,
              !contextMenuEntry.enabled ? 'cursor-not-allowed text-greyscale-700' : '',
            )}
            onClick={handleCallback(contextMenuEntry.callback)}
            type="button"
            data-testid={contextMenuEntry.id}
          >
            {contextMenuEntry.name}
          </button>
        ))}
      </div>
    </OutsideClickWrapper>
  );
};
