import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Coordinate } from 'ol/coordinate';
import getCoordsX from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getCoordsX';
import getCoordsY from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getCoordsY';
import { ShapeRelAbsBezierPoint } from '@/types/models';

export default function getCurveCoords({
  x,
  y,
  point,
  height,
  width,
  pointToProjection,
}: {
  x: number;
  y: number;
  point: ShapeRelAbsBezierPoint;
  height: number;
  width: number;
  pointToProjection: UsePointToProjectionResult;
}): { p1: Coordinate; p2: Coordinate; p3: Coordinate } {
  const p3 = pointToProjection({
    x: getCoordsX(x, point.absoluteX1, point.relativeX1, point.relativeHeightForX1, height, width),
    y: getCoordsY(y, point.absoluteY1, point.relativeY1, point.relativeWidthForY1, height, width),
  });
  const p1 = pointToProjection({
    x: getCoordsX(x, point.absoluteX2, point.relativeX2, point.relativeHeightForX2, height, width),
    y: getCoordsY(y, point.absoluteY2, point.relativeY2, point.relativeWidthForY2, height, width),
  });
  const p2 = pointToProjection({
    x: getCoordsX(x, point.absoluteX3, point.relativeX3, point.relativeHeightForX3, height, width),
    y: getCoordsY(y, point.absoluteY3, point.relativeY3, point.relativeWidthForY3, height, width),
  });
  return { p1, p2, p3 };
}
