import { CURRENT_PROJECT_ADMIN_PANEL_URL } from '@/constants';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { closeModal } from '@/redux/modal/modal.slice';
import { Button } from '@/shared/Button';
import React from 'react';

export const LoggedInMenuModal = (): React.ReactNode => {
  const dispatch = useAppDispatch();

  const closeLoggedInMenuModal = (): void => {
    dispatch(closeModal());
  };

  const goToTheAdminPanel = (): void => {
    window.location.href = CURRENT_PROJECT_ADMIN_PANEL_URL;
  };
  return (
    <div className="flex justify-center gap-5 border border-t-[#E1E0E6] bg-white p-6">
      <Button
        variantStyles="secondary"
        className="w-full justify-center"
        onClick={closeLoggedInMenuModal}
      >
        Back to the map
      </Button>
      <Button className="w-full justify-center" onClick={goToTheAdminPanel}>
        Go to the admin panel
      </Button>
    </div>
  );
};
