import { getMultiBioEntity } from '@/redux/bioEntity/bioEntity.thunks';
import { setCurrentSelectedBioEntityId } from '@/redux/contextMenu/contextMenu.slice';
import { AppDispatch } from '@/redux/store';
import { ElementSearchResult } from '@/types/models';

/* prettier-ignore */
export const handleBioEntityResults =
  (dispatch: AppDispatch) =>
    async ({ id }: ElementSearchResult): Promise<void> => {

      dispatch(setCurrentSelectedBioEntityId(id));
      dispatch(
        getMultiBioEntity({
          searchQueries: [id.toString()],
          isPerfectMatch: true
        }),
      );
    };
