import {
  bioEntitiesPerModelSelector,
  bioEntityIsContentTabOpenedSelector,
  loadingBioEntityStatusSelector,
  numberOfBioEntitiesSelector,
} from '@/redux/bioEntity/bioEntity.selectors';
import { toggleIsContentTabOpened } from '@/redux/bioEntity/bioEntity.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@/shared/Accordion';
import { BioEntitiesSubmapItem } from './BioEntitiesSubmapItem';

export const BioEntitiesAccordion = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const bioEntitiesNumber = useAppSelector(numberOfBioEntitiesSelector);
  const bioEntitiesState = useAppSelector(loadingBioEntityStatusSelector);
  const bioEntitiesPerModel = useAppSelector(bioEntitiesPerModelSelector);
  const isContentTabOpened = useAppSelector(bioEntityIsContentTabOpenedSelector);

  const toggleTabOpened = (): void => {
    dispatch(toggleIsContentTabOpened(!isContentTabOpened));
  };

  return (
    <AccordionItem dangerouslySetExpanded={isContentTabOpened}>
      <AccordionItemHeading>
        <AccordionItemButton onClick={toggleTabOpened}>
          Content {bioEntitiesState === 'pending' && ' (Loading...)'}
          {bioEntitiesState === 'succeeded' && ` (${bioEntitiesNumber})`}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {bioEntitiesPerModel.map(model => (
          <BioEntitiesSubmapItem
            key={model.modelName}
            mapName={model.modelName}
            mapId={model.modelId}
            numberOfEntities={model.numberOfEntities}
            bioEntities={model.bioEntities}
          />
        ))}
      </AccordionItemPanel>
    </AccordionItem>
  );
};
