import { Button } from '@/shared/Button';
import { DownloadSubmap } from './DownloadSubmap';

interface SubmapItemProps {
  modelName: string;
  onOpenClick: () => void;
}

export const SubmapItem = ({ modelName, onOpenClick }: SubmapItemProps): JSX.Element => (
  <div className="flex flex-row flex-nowrap items-center justify-between py-2">
    {modelName}
    <div className="flex flex-row flex-nowrap items-center">
      <DownloadSubmap />
      <Button
        variantStyles="secondary"
        className="h-6"
        data-testid={`${modelName}-open`}
        onClick={onOpenClick}
      >
        Open
      </Button>
    </div>
  </div>
);
