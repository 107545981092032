import { Manrope } from '@next/font/google';

export const manrope = Manrope({
  variable: '--font-manrope',
  display: 'swap',
  weight: ['400', '700'],
  subsets: ['latin'],
});

export const DEFAULT_FONT_FAMILY = manrope.style.fontFamily;
