import { currentOverviewImageSelector } from '@/redux/project/project.selectors';
import { store } from '@/redux/store';
import { OverviewImageView } from '@/types/models';

export const getCurrentOverviewImage = (): OverviewImageView | undefined => {
  const { getState } = store;
  const overviewImage = currentOverviewImageSelector(getState());

  return overviewImage;
};
