import React, { TextareaHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type StyleVariant = 'primary';

type TextareaProps = {
  className?: string;
  styleVariant?: StyleVariant;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const styleVariants = {
  primary:
    'w-full resize-none rounded-lg border border-transparent bg-cultured px-2 py-2.5 text-xs font-semibold outline-none  hover:border-greyscale-600 focus:border-greyscale-600',
} as const;

export const Textarea = ({
  className = '',
  styleVariant = 'primary',
  ...props
}: TextareaProps): React.ReactNode => (
  <textarea {...props} className={twMerge(styleVariants[styleVariant], className)} />
);
