import { allVisibleBioEntitiesSelector } from '@/redux/bioEntity/bioEntity.selectors';
import { store } from '@/redux/store';
import { isPointValid } from '@/utils/point/isPointValid';

type Points = {
  x: number;
  y: number;
}[];

export const getPolygonPoints = (): Points => {
  const allVisibleBioEntities = allVisibleBioEntitiesSelector(store.getState());
  const allX = allVisibleBioEntities.map(({ x }) => x);
  const allY = allVisibleBioEntities.map(({ y }) => y);

  const minX = Math.min(...allX);
  const maxX = Math.max(...allX);

  const minY = Math.min(...allY);
  const maxY = Math.max(...allY);

  const points = [
    {
      x: minX,
      y: maxY,
    },
    {
      x: maxX,
      y: minY,
    },
  ];

  return points.filter(isPointValid);
};
