import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { store } from '@/redux/store';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { toLonLat } from 'ol/proj';
import { ZERO } from '@/constants/common';
import { MapManager } from '../mapManager';

type GetBoundsReturnType =
  | {
      x1: number;
      x2: number;
      y1: number;
      y2: number;
    }
  | undefined;

export const getBounds = (): GetBoundsReturnType => {
  const mapInstance = MapManager.getMapInstance();

  if (!mapInstance) return undefined;

  const [minx, miny, maxx, maxy] = mapInstance.getView().calculateExtent(mapInstance.getSize());

  const mapSize = mapDataSizeSelector(store.getState());

  const [lngX1, latY1] = toLonLat([minx, maxy]);
  const [lngX2, latY2] = toLonLat([maxx, miny]);

  const { x: x1, y: y1 } = latLngToPoint([latY1, lngX1], mapSize, { rounded: true });
  const { x: x2, y: y2 } = latLngToPoint([latY2, lngX2], mapSize, { rounded: true });

  return {
    x1: x1 > x2 ? ZERO : x1, // handle lat,lng overflow
    y1: y1 > y2 ? ZERO : y1, // handle lat,lng overflow
    x2,
    y2,
  };
};
