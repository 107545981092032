import { Configuration } from '@/types/models';

export const configurationMapper = (data: Configuration): unknown => ({
  annotators: data.annotators,
  elementTypes: data.elementTypes,
  miramiTypes: data.miriamTypes,
  mapTypes: data.mapTypes,
  modelConverters: data.modelFormats,
  modificationStateTypes: data.modificationStateTypes,
  options: data.options,
  overlayTypes: data.overlayTypes,
  privilegeTypes: data.privilegeTypes,
  reactionTypes: data.reactionTypes,
});
