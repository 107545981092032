import { useContext, useState } from 'react';
import { Button } from '@/shared/Button';
import Image from 'next/image';
import spinnerIcon from '@/assets/vectors/icons/spinner.svg';
import { ExportContext } from '../ExportCompound.context';

export const DownloadElements = (): React.ReactNode => {
  const { handleDownloadElements } = useContext(ExportContext);
  const [downloadingElements, setDownloadingElements] = useState<boolean>(false);

  const handleDownloadElementsWrapper = async (): Promise<void> => {
    setDownloadingElements(true);
    await handleDownloadElements();
    setDownloadingElements(false);
  };

  return (
    <div className="mt-6">
      <Button onClick={handleDownloadElementsWrapper}>
        {downloadingElements && (
          <Image
            src={spinnerIcon}
            alt="spinner icon"
            height={12}
            width={12}
            className="mr-2 animate-spin"
          />
        )}
        Download
      </Button>
    </div>
  );
};
