/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLoadPlugin } from '@/components/Map/Drawer/AvailablePluginsDrawer/LoadPlugin/hooks/useLoadPlugin';
import { FIRST_ARRAY_ELEMENT, ZERO } from '@/constants/common';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { setCurrentDrawerPluginHash } from '@/redux/plugins/plugins.slice';
import { Button } from '@/shared/Button';
import { Icon } from '@/shared/Icon';
import { MinervaPlugin } from '@/types/models';
import { twMerge } from 'tailwind-merge';

interface Props {
  plugin: MinervaPlugin;
}

export const PluginSingleTab = ({ plugin }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const { unloadPlugin, isPluginSelected } = useLoadPlugin({
    hash: plugin.hash,
    pluginUrl: plugin.urls[FIRST_ARRAY_ELEMENT],
  });

  const onPluginTabClick = (): void => {
    if (!plugin.withoutPanel) {
      dispatch(setCurrentDrawerPluginHash(plugin.hash));
    }
  };

  const onPluginUnload = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    unloadPlugin();
  };

  return (
    <Button
      className={twMerge(
        'h-10 whitespace-nowrap',
        isPluginSelected ? 'bg-[#EBF4FF]' : 'font-normal',
      )}
      variantStyles={isPluginSelected ? 'secondary' : 'ghost'}
      onClick={(): void => onPluginTabClick()}
    >
      {plugin.name}
      <div
        onClick={(event): void => onPluginUnload(event)}
        data-testid="close-icon"
        role="button"
        tabIndex={ZERO}
      >
        <Icon name="close" className="ml-3 h-5 w-5 fill-font-400" />
      </div>
    </Button>
  );
};
