import { convertDecimalToHexColor } from './convertDecimalToHex';
import { addAlphaToHexString } from './addAlphaToHexString';

type GetHexStringColorFromRGBIntWithAlphaProps = {
  rgbInt: number;
  alpha: number;
};

export const getHexStringColorFromRGBIntWithAlpha = ({
  rgbInt,
  alpha,
}: GetHexStringColorFromRGBIntWithAlphaProps): string => {
  const hexStringColor = convertDecimalToHexColor(rgbInt);
  const hexStringColorWithAlpha = addAlphaToHexString(hexStringColor, alpha);
  return hexStringColorWithAlpha;
};
