import { createSelector } from '@reduxjs/toolkit';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { rootSelector } from '../root/root.selectors';

export const newReactionsSelector = createSelector(rootSelector, state => state.newReactions);

export const newReactionsStateForCurrentModelSelector = createSelector(
  newReactionsSelector,
  currentModelIdSelector,
  (state, currentModelId) => state[currentModelId],
);

export const newReactionsLoadingSelector = createSelector(
  newReactionsStateForCurrentModelSelector,
  state => state?.loading,
);

export const newReactionsForCurrentModelSelector = createSelector(
  newReactionsStateForCurrentModelSelector,
  state => state?.data || [],
);
