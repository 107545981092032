import { getDefaultSearchTab } from '@/components/FunctionalArea/TopBar/SearchBar/SearchBar.utils';
import { isDrawerOpenSelector } from '@/redux/drawer/drawer.selectors';
import { openSearchDrawerWithSelectedTab, selectTab } from '@/redux/drawer/drawer.slice';
import { store } from '@/redux/store';

export const displaySearchDrawerWithSelectedDefaultTab = (searchValues: string[]): void => {
  const { dispatch, getState } = store;
  const isDrawerOpen = isDrawerOpenSelector(getState());
  const defaultSearchTab = getDefaultSearchTab(searchValues);

  if (!isDrawerOpen) {
    dispatch(openSearchDrawerWithSelectedTab(defaultSearchTab));
  } else {
    dispatch(selectTab(defaultSearchTab));
  }
};
