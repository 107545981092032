/* eslint-disable no-magic-numbers */
import Style from 'ol/style/Style';
import getStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStyle';
import { Feature } from 'ol';
import { MultiPolygon } from 'ol/geom';
import { Arrow, Color } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import getShapePolygon from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/getShapePolygon';
import Polygon from 'ol/geom/Polygon';
import { WHITE_COLOR } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import { ArrowTypeDict } from '@/redux/shapes/shapes.types';
import getStroke from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStroke';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';

export default function getArrowFeature({
  arrowTypes,
  arrow,
  x,
  y,
  zIndex,
  rotation,
  lineWidth,
  color,
  pointToProjection,
}: {
  arrowTypes: ArrowTypeDict;
  arrow: Arrow;
  x: number;
  y: number;
  zIndex: number;
  rotation: number;
  lineWidth: number;
  color: Color;
  pointToProjection: UsePointToProjectionResult;
}): undefined | Feature<MultiPolygon> {
  const arrowShapes = arrowTypes[arrow.arrowType];
  if (!arrowShapes) {
    return undefined;
  }
  const arrowStyles: Array<Style> = [];
  const arrowPolygons: Array<Polygon> = [];
  arrowShapes.forEach(shape => {
    const arrowPolygon = getShapePolygon({
      shape,
      x,
      y: y - arrow.length / 2,
      width: arrow.length,
      height: arrow.length,
      pointToProjection,
    });
    const style = getStyle({
      geometry: arrowPolygon,
      zIndex,
      borderColor: color,
      fillColor: shape.fill === false ? WHITE_COLOR : color,
      lineWidth,
    });
    arrowPolygon.set(
      'strokeStyle',
      getStroke({
        color: rgbToHex(color),
        width: lineWidth,
      }),
    );
    arrowPolygon.rotate(rotation, pointToProjection({ x, y }));
    arrowStyles.push(style);
    arrowPolygons.push(arrowPolygon);
  });
  const arrowFeature = new Feature({
    geometry: new MultiPolygon(arrowPolygons),
    style: arrowStyles,
    zIndex,
  });
  arrowFeature.setStyle(arrowStyles);
  return arrowFeature;
}
