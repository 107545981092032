import { HALF } from '@/constants/dividers';
import { DEFAULT_TILE_SIZE } from '@/constants/map';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { MapInstance } from '@/types/map';
import { FitOptions } from 'ol/View';
import { Coordinate } from 'ol/coordinate';
import { Extent, boundingExtent } from 'ol/extent';
import { useSelector } from 'react-redux';
import { useMapInstance } from '../context/mapInstanceContext';

export interface SetBoundsResult {
  extent: Extent;
  options: FitOptions;
}

type SetBounds = (coordinates: Coordinate[]) => SetBoundsResult | undefined;

const BOUNDS_PADDING = DEFAULT_TILE_SIZE / HALF;
const DEFAULT_PADDING = [BOUNDS_PADDING, BOUNDS_PADDING, BOUNDS_PADDING, BOUNDS_PADDING];

/* prettier-ignore */
export const handleSetBounds =
    (mapInstance: MapInstance, maxZoom: number, coordinates: Coordinate[]): SetBoundsResult | undefined => {
      if (!mapInstance) {
        return undefined;
      }

      const extent = boundingExtent(coordinates);

      const options: FitOptions = {
        size: mapInstance.getSize(),
        padding: DEFAULT_PADDING,
        maxZoom,
      };

      mapInstance.getView().fit(extent, options);
      return { extent, options };
    };

export const useSetBounds = (): SetBounds => {
  const { mapInstance } = useMapInstance();
  const { maxZoom } = useSelector(mapDataSizeSelector);

  const setBounds = (coordinates: Coordinate[]): SetBoundsResult | undefined =>
    handleSetBounds(mapInstance, maxZoom, coordinates);

  return setBounds;
};
