/* eslint-disable no-magic-numbers */
import { useOlMapOverlaysLayer } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useOlMapOverlaysLayer';
import { MapConfig } from '../../MapViewer.types';
import { useOlMapTileLayer } from './useOlMapTileLayer';

export const useOlMapLayers = (): MapConfig['layers'] => {
  const overlaysLayer = useOlMapOverlaysLayer();
  const tileLayer = useOlMapTileLayer();

  return [tileLayer, overlaysLayer];
};
