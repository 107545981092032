import { handleDataReset } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/handleDataReset';
import { handleSearchResultAction } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/handleSearchResultAction';
import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { store } from '@/redux/store';
import { getElementsByPoint } from '@/utils/search/getElementsByCoordinates';
import { mapDataLastZoomValue, mapDataMaxZoomValue } from '@/redux/map/map.selectors';
import { searchDistanceValSelector } from '@/redux/configuration/configuration.selectors';
import { DEFAULT_ZOOM } from '@/constants/map';
import { resultDrawerOpen } from '@/redux/drawer/drawer.selectors';
import { Coordinates } from './triggerSearch.types';

export const searchByCoordinates = async (
  coordinates: Coordinates,
  modelId: number,
  shouldConsiderZoomLevel: boolean,
  hasFitBounds?: boolean,
  fitBoundsZoom?: number,
  considerZoomLevel?: number,
): Promise<void> => {
  const { dispatch, getState } = store;
  // side-effect below is to prevent complications with data update - old data may conflict with new data
  // so we need to reset all the data before updating
  dispatch(handleDataReset);

  const maxZoom = mapDataMaxZoomValue(getState());
  const lastZoom = mapDataLastZoomValue(getState());
  const searchDistance = searchDistanceValSelector(getState());
  const isResultDrawerOpen = resultDrawerOpen(getState());

  const searchResults = await getElementsByPoint({
    point: coordinates,
    currentModelId: modelId,
    shouldConsiderZoomLevel,
    considerZoomLevel,
  });

  if (!searchResults || searchResults?.length === SIZE_OF_EMPTY_ARRAY) {
    return;
  }

  handleSearchResultAction({
    searchResults,
    dispatch,
    hasFitBounds,
    zoom: fitBoundsZoom || lastZoom || DEFAULT_ZOOM,
    maxZoom,
    point: coordinates,
    searchDistance,
    isResultDrawerOpen,
  });
};
