import { ONE, ZERO } from '@/constants/common';
import { WHITE_HEX_OPACITY_0 } from '@/constants/hexColors';
import {
  maxColorValSelector,
  minColorValSelector,
  neutralColorValSelector,
  overlayOpacitySelector,
  simpleColorValSelector,
} from '@/redux/configuration/configuration.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { addAlphaToHexString } from '@/utils/convert/addAlphaToHexString';
import { getHexStringColorFromRGBIntWithAlpha } from '@/utils/convert/getHexStringColorFromRGBIntWithAlpha';
import { getHexTricolorGradientColorWithAlpha } from '@/utils/convert/getHexTricolorGradientColorWithAlpha';
import { useCallback, useMemo } from 'react';

export type GetOverlayBioEntityColorByAvailableProperties = (
  entity: OverlayBioEntityRender,
) => string;

type UseTriColorLerpReturn = {
  getOverlayBioEntityColorByAvailableProperties: GetOverlayBioEntityColorByAvailableProperties;
};

interface Props {
  forceOpacityValue?: number;
}

export const useGetOverlayColor = ({ forceOpacityValue }: Props = {}): UseTriColorLerpReturn => {
  const minColorValHexString = useAppSelector(minColorValSelector) || '';
  const maxColorValHexString = useAppSelector(maxColorValSelector) || '';
  const neutralColorValHexString = useAppSelector(neutralColorValSelector) || '';
  const simpleColorValue = useAppSelector(simpleColorValSelector) || WHITE_HEX_OPACITY_0;
  const overlayOpacityDefaultValue = useAppSelector(overlayOpacitySelector);
  const overlayOpacityValue = forceOpacityValue || overlayOpacityDefaultValue || ONE;

  const getHex3ColorGradientColorWithAlpha = useCallback(
    (position: number) =>
      getHexTricolorGradientColorWithAlpha({
        leftColor: minColorValHexString,
        middleColor: neutralColorValHexString,
        rightColor: maxColorValHexString,
        position,
        alpha: Number(overlayOpacityValue),
      }),
    [minColorValHexString, neutralColorValHexString, maxColorValHexString, overlayOpacityValue],
  );

  const getHexColorFromRGBIntWithAlpha = useCallback(
    (rgbInt: number) =>
      getHexStringColorFromRGBIntWithAlpha({ rgbInt, alpha: Number(overlayOpacityValue) }),
    [overlayOpacityValue],
  );

  const defaultColorHex = useMemo(
    () => addAlphaToHexString(simpleColorValue, Number(overlayOpacityValue)),
    [simpleColorValue, overlayOpacityValue],
  );

  /**
   * Entity might have 3 properties that indicates color:
   * value - which value from [-1,1] range and needs to interpolate between minColorVal, neutralColorVal and maxColorVal
   * color - which is integer representation of color and needs to be converted to hex string
   * defaultColor - which is hex string representation of color. It occurs when color and value are not available
   */
  const getOverlayBioEntityColorByAvailableProperties = useCallback(
    (entity: OverlayBioEntityRender) => {
      if (typeof entity.value === 'number') {
        return getHex3ColorGradientColorWithAlpha(entity.value || ZERO);
      }
      if (entity.color) {
        return getHexColorFromRGBIntWithAlpha(entity.color.rgb);
      }
      return defaultColorHex;
    },
    [getHex3ColorGradientColorWithAlpha, getHexColorFromRGBIntWithAlpha, defaultColorHex],
  );

  return { getOverlayBioEntityColorByAvailableProperties };
};
