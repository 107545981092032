import { markersDataSelector } from '@/redux/markers/markers.selectors';
import { store } from '@/redux/store';
import { Marker } from '@/types/models';

export const getAllMarkers = (): Marker[] => {
  const { getState } = store;
  const markers = markersDataSelector(getState());

  return markers;
};
