/* eslint-disable no-magic-numbers */
import { Line, Operator, ReactionProduct, Shape } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { Circle, LineString, MultiLineString, MultiPolygon } from 'ol/geom';
import getStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStyle';
import Polygon from 'ol/geom/Polygon';
import Style from 'ol/style/Style';
import {
  REACTION_ELEMENT_CUTOFF_SCALE,
  REACTION_ELEMENT_TYPES,
  TEXT_CUTOFF_SCALE,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import { FeatureLike } from 'ol/Feature';
import { MapInstance } from '@/types/map';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';
import { ArrowTypeDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import { FEATURE_TYPE } from '@/constants/features';
import getScaledElementStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getScaledElementStyle';
import VectorSource from 'ol/source/Vector';
import getStroke from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStroke';
import { Stroke } from 'ol/style';
import getLineSegments from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getLineSegments';
import getRotation from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getRotation';
import getArrowFeature from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/getArrowFeature';
import getShapePolygon from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/getShapePolygon';
import getTextStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextStyle';

export interface ReactionProps {
  id: number;
  line: Line;
  products: Array<ReactionProduct>;
  reactants: Array<ReactionProduct>;
  modifiers: Array<ReactionProduct>;
  operators: Array<Operator>;
  zIndex: number;
  lineTypes: LineTypeDict;
  arrowTypes: ArrowTypeDict;
  shapes: Array<Shape>;
  pointToProjection: UsePointToProjectionResult;
  vectorSource: VectorSource;
  mapInstance: MapInstance;
}

export default class Reaction {
  id: number;

  line: Line;

  products: Array<ReactionProduct>;

  reactants: Array<ReactionProduct>;

  modifiers: Array<ReactionProduct>;

  operators: Array<Operator>;

  zIndex: number;

  lineTypes: LineTypeDict;

  arrowTypes: ArrowTypeDict;

  shapes: Array<Shape>;

  pointToProjection: UsePointToProjectionResult;

  vectorSource: VectorSource;

  lineFeature: Feature<MultiLineString> = new Feature();

  reactionFeatures: Array<Feature<MultiPolygon> | Feature<Circle>> = [];

  lineStyles: Array<Style> = [];

  minResolution: number;

  constructor({
    id,
    line,
    products,
    reactants,
    modifiers,
    operators,
    zIndex,
    lineTypes,
    arrowTypes,
    shapes,
    pointToProjection,
    vectorSource,
    mapInstance,
  }: ReactionProps) {
    this.id = id;
    this.line = line;
    this.products = products;
    this.reactants = reactants;
    this.modifiers = modifiers;
    this.operators = operators;
    this.zIndex = zIndex;
    this.lineTypes = lineTypes;
    this.arrowTypes = arrowTypes;
    this.shapes = shapes;
    this.pointToProjection = pointToProjection;
    this.vectorSource = vectorSource;

    const maxZoom = mapInstance?.getView().get('originalMaxZoom');
    this.minResolution = mapInstance?.getView().getResolutionForZoom(maxZoom) || 1;

    this.drawReaction();
  }

  private drawReaction(): void {
    const reactionSquareFeature = this.getReactionSquare();
    this.reactionFeatures.push(reactionSquareFeature);

    const lineStringElements: Array<LineString> = [];
    let lineStringWithArrows = this.getLineStringWithArrows(this.line);
    lineStringElements.push(lineStringWithArrows.lineString);
    this.reactionFeatures.push(...lineStringWithArrows.arrowsFeatures);
    [...this.products, ...this.reactants, ...this.modifiers].forEach(element => {
      lineStringWithArrows = this.getLineStringWithArrows(element.line);
      lineStringElements.push(lineStringWithArrows.lineString);
      this.reactionFeatures.push(...lineStringWithArrows.arrowsFeatures);
    });
    [...this.operators].forEach(operator => {
      lineStringWithArrows = this.getLineStringWithArrows(operator.line);
      lineStringElements.push(lineStringWithArrows.lineString);
      this.reactionFeatures.push(...lineStringWithArrows.arrowsFeatures);
      this.reactionFeatures.push(this.getOperator(operator));
    });

    const multiLineString = new MultiLineString(lineStringElements);

    this.lineFeature = new Feature<MultiLineString>({
      geometry: multiLineString,
      id: this.id,
      type: FEATURE_TYPE.REACTION,
      elementType: REACTION_ELEMENT_TYPES.LINE,
      zIndex: this.zIndex,
    });
    this.lineFeature.setStyle(this.getLineStyle.bind(this));
  }

  private getLineStringWithArrows(line: Line): {
    lineString: LineString;
    arrowsFeatures: Array<Feature<MultiPolygon>>;
  } {
    const arrowsFeatures: Array<Feature<MultiPolygon>> = [];
    const points = getLineSegments(line, this.pointToProjection);

    if (line.startArrow.arrowType !== 'NONE') {
      const firstSegment = line.segments[0];
      let startArrowRotation = getRotation(
        [firstSegment.x1, firstSegment.y1],
        [firstSegment.x2, firstSegment.y2],
      );
      startArrowRotation += Math.PI;
      const shortenedX1 = firstSegment.x1 - line.startArrow.length * Math.cos(startArrowRotation);
      const shortenedY1 = firstSegment.y1 + line.startArrow.length * Math.sin(startArrowRotation);
      points[0] = this.pointToProjection({ x: shortenedX1, y: shortenedY1 });
      const startArrowFeature = getArrowFeature({
        arrowTypes: this.arrowTypes,
        arrow: line.startArrow,
        x: shortenedX1,
        y: shortenedY1,
        zIndex: this.zIndex,
        rotation: startArrowRotation,
        lineWidth: line.width,
        color: line.color,
        pointToProjection: this.pointToProjection,
      });
      if (startArrowFeature) {
        startArrowFeature.set('elementType', REACTION_ELEMENT_TYPES.ARROW);
        startArrowFeature.setStyle(this.getReactionObjectStyle.bind(this));
        arrowsFeatures.push(startArrowFeature);
      }
    }

    if (line.endArrow.arrowType !== 'NONE') {
      const lastSegment = line.segments[line.segments.length - 1];
      const endArrowRotation = getRotation(
        [lastSegment.x1, lastSegment.y1],
        [lastSegment.x2, lastSegment.y2],
      );
      const shortenedX2 = lastSegment.x2 - line.endArrow.length * Math.cos(endArrowRotation);
      const shortenedY2 = lastSegment.y2 + line.endArrow.length * Math.sin(endArrowRotation);
      points[points.length - 1] = this.pointToProjection({ x: shortenedX2, y: shortenedY2 });
      const endArrowFeature = getArrowFeature({
        arrowTypes: this.arrowTypes,
        arrow: line.endArrow,
        x: shortenedX2,
        y: shortenedY2,
        zIndex: this.zIndex,
        rotation: endArrowRotation,
        lineWidth: line.width,
        color: line.color,
        pointToProjection: this.pointToProjection,
      });
      if (endArrowFeature) {
        endArrowFeature.set('elementType', REACTION_ELEMENT_TYPES.ARROW);
        endArrowFeature.setStyle(this.getReactionObjectStyle.bind(this));
        arrowsFeatures.push(endArrowFeature);
      }
    }

    const lineString = new LineString(points);

    const lineDash = this.lineTypes[line.lineType] || [];
    const lineStyle = getStyle({
      geometry: lineString,
      borderColor: line.color,
      lineWidth: line.width,
      lineDash,
      zIndex: this.zIndex,
    });
    lineString.set(
      'strokeStyle',
      getStroke({
        color: rgbToHex(line.color),
        width: line.width,
        lineDash,
      }),
    );
    this.lineStyles.push(lineStyle);

    return { lineString, arrowsFeatures };
  }

  private getReactionSquare(): Feature<MultiPolygon> {
    const polygons: Array<Polygon> = [];
    const styles: Array<Style> = [];
    const firstSegment = this.line.segments[0];
    const squareRotation = getRotation(
      [firstSegment.x1, firstSegment.y1],
      [firstSegment.x2, firstSegment.y2],
    );
    const squareX = (firstSegment.x1 + firstSegment.x2) / 2;
    const squareY = (firstSegment.y1 + firstSegment.y2) / 2;
    this.shapes.forEach(shape => {
      const squarePolygon = getShapePolygon({
        shape,
        x: squareX - 5,
        y: squareY - 5,
        width: 10,
        height: 10,
        pointToProjection: this.pointToProjection,
      });
      const squareStyle = getStyle({
        geometry: squarePolygon,
        fillColor: WHITE_COLOR,
        lineWidth: this.line.width,
        borderColor: this.line.color,
        zIndex: this.zIndex + 1,
      });
      squarePolygon.set(
        'strokeStyle',
        getStroke({
          color: rgbToHex(this.line.color),
          width: this.line.width,
        }),
      );
      squarePolygon.rotate(
        squareRotation,
        this.pointToProjection({
          x: squareX,
          y: squareY,
        }),
      );
      polygons.push(squarePolygon);
      styles.push(squareStyle);
    });
    const squareFeature = new Feature({
      geometry: new MultiPolygon(polygons),
      style: styles,
      lineWidth: this.line.width,
      id: this.id,
      type: FEATURE_TYPE.REACTION,
      elementType: REACTION_ELEMENT_TYPES.SQUARE,
      zIndex: this.zIndex,
    });
    squareFeature.setStyle(this.getReactionObjectStyle.bind(this));
    return squareFeature;
  }

  protected getOperator(operator: Operator): Feature<Circle> {
    const firstSegment = operator.line.segments[0];
    let zIndex: number;
    let radius: number;

    if (operator.operatorText) {
      zIndex = this.zIndex + 1;
      radius = Math.abs(
        this.pointToProjection({ x: 0, y: 0 })[0] - this.pointToProjection({ x: 6, y: 0 })[0],
      );
    } else {
      zIndex = this.zIndex;
      radius = Math.abs(
        this.pointToProjection({ x: 0, y: 0 })[0] - this.pointToProjection({ x: 1.8, y: 0 })[0],
      );
    }

    const circle = new Circle(
      this.pointToProjection({ x: firstSegment.x1, y: firstSegment.y1 }),
      radius,
    );

    const circleStyle = getStyle({
      geometry: circle,
      zIndex,
      lineWidth: 1,
      borderColor: operator.line.color,
      fillColor: operator.line.color,
    });
    circle.set(
      'strokeStyle',
      getStroke({
        color: rgbToHex(operator.line.color),
      }),
    );

    if (operator.operatorText) {
      circleStyle.getFill()?.setColor(rgbToHex(WHITE_COLOR));
      const textStyle = getTextStyle({
        text: operator.operatorText,
        fontSize: 10,
        color: '#000000FF',
        zIndex: this.zIndex,
        horizontalAlign: 'CENTER',
      }).getText();
      if (textStyle) {
        circleStyle.setText(textStyle);
      }
    }
    const circleFeature = new Feature({
      geometry: circle,
      style: circleStyle,
      lineWidth: 1,
      id: this.id,
      type: FEATURE_TYPE.REACTION,
      elementType: REACTION_ELEMENT_TYPES.OPERATOR,
      fontSize: 10,
      zIndex: this.zIndex,
    });
    circleFeature.setStyle(this.getReactionObjectStyle.bind(this));
    return circleFeature;
  }

  protected isAnyOfElementsHidden(): boolean {
    return [...this.products, ...this.reactants, ...this.modifiers].some(reactionElement => {
      const feature = this.vectorSource.getFeatureById(reactionElement.element);
      return feature && feature.get('hidden');
    });
  }

  protected getLineStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    if (!(feature instanceof Feature)) {
      return undefined;
    }
    if (this.isAnyOfElementsHidden()) {
      feature.set('hidden', true);
      return undefined;
    }
    feature.set('hidden', false);

    const styles: Array<Style> = [];
    const scale = this.minResolution / resolution;
    const type = feature.get('elementType');
    let strokeStyle: Stroke | undefined;

    if (type === REACTION_ELEMENT_TYPES.OPERATOR && scale < TEXT_CUTOFF_SCALE) {
      return [];
    }

    this.lineStyles.forEach(style => {
      const styleGeometry = style.getGeometry();
      if (styleGeometry instanceof Polygon || styleGeometry instanceof LineString) {
        strokeStyle = styleGeometry.get('strokeStyle');
      }
      styles.push(getScaledElementStyle(style, strokeStyle, scale));
    });

    return styles;
  }

  protected getReactionObjectStyle(
    feature: FeatureLike,
    resolution: number,
  ): Style | Array<Style> | void {
    if (!(feature instanceof Feature)) {
      return undefined;
    }
    if (this.isAnyOfElementsHidden()) {
      feature.set('hidden', true);
      return undefined;
    }
    feature.set('hidden', false);

    const styles: Array<Style> = [];
    const style = feature.get('style');
    const scale = this.minResolution / resolution;
    let strokeStyle: Stroke | undefined;

    if (scale <= REACTION_ELEMENT_CUTOFF_SCALE) {
      return [];
    }

    const stylesToProcess: Array<Style> = [];
    if (style instanceof Style) {
      stylesToProcess.push(style);
    } else if (Array.isArray(style)) {
      stylesToProcess.push(...style);
    }
    stylesToProcess.forEach(singleStyle => {
      const styleGeometry = singleStyle.getGeometry();
      if (styleGeometry instanceof Polygon || styleGeometry instanceof LineString) {
        strokeStyle = styleGeometry.get('strokeStyle');
      }
      styles.push(getScaledElementStyle(singleStyle, strokeStyle, scale));
    });

    return styles;
  }
}
