import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  areOverlayBioEntitiesLoadedSelector,
  isOverlayActiveSelector,
  isOverlayLoadingSelector,
} from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { getOverlayBioEntityForAllModels } from '@/redux/overlayBioEntity/overlayBioEntity.thunk';
import { BASE_API_URL } from '@/constants';
import { apiPath } from '@/redux/apiPath';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { overlaySelector, userOverlaySelector } from '@/redux/overlays/overlays.selectors';
import {
  addOverlayToOverlaysId,
  removeOverlayFromOverlaysId,
} from '@/redux/overlayBioEntity/overlayBioEntity.slice';
import { useEmptyBackground } from './useEmptyBackground';

type UseOverlay = {
  toggleOverlay: () => void;
  downloadOverlay: () => void;
  isOverlayActive: boolean;
  isOverlayLoading: boolean;
};

export const useOverlay = (overlayId: number): UseOverlay => {
  const dispatch = useAppDispatch();
  const isOverlayActive = useAppSelector(state => isOverlayActiveSelector(state, overlayId));
  const isOverlayLoading = useAppSelector(state => isOverlayLoadingSelector(state, overlayId));
  const { setBackgroundtoEmptyIfAvailable } = useEmptyBackground();
  const overlay = useAppSelector(state => overlaySelector(state, overlayId));
  const areOverlayBioEntitiesLoaded = useAppSelector(state =>
    areOverlayBioEntitiesLoadedSelector(state, overlayId),
  );
  const userOverlay = useAppSelector(state => userOverlaySelector(state, overlayId));

  const dispatchPluginEvents = (): void => {
    const eventData = overlay || userOverlay;

    if (!eventData) return;

    if (isOverlayActive) {
      PluginsEventBus.dispatchEvent('onHideOverlay', eventData);
    } else {
      PluginsEventBus.dispatchEvent('onShowOverlay', eventData);
    }
  };

  const toggleOverlay = async (): Promise<void> => {
    if (isOverlayActive) {
      dispatch(removeOverlayFromOverlaysId(overlayId));
    } else {
      if (areOverlayBioEntitiesLoaded) {
        dispatch(addOverlayToOverlaysId(overlayId));
      } else {
        await dispatch(getOverlayBioEntityForAllModels({ overlayId }));
      }
      setBackgroundtoEmptyIfAvailable();
    }

    dispatchPluginEvents();
  };

  const downloadOverlay = (): void => {
    window.open(`${BASE_API_URL}/${apiPath.downloadOverlay(overlayId)}`, '_blank');
  };

  return { toggleOverlay, isOverlayActive, isOverlayLoading, downloadOverlay };
};
