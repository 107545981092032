import { Button } from '@/shared/Button';
import Image from 'next/image';
import spinnerIcon from '@/assets/vectors/icons/spinner.svg';
import { useOverlay } from '../../hooks/useOverlay';

interface OverlayListItemProps {
  name: string;
  overlayId: number;
}

export const OverlayListItem = ({ name, overlayId }: OverlayListItemProps): JSX.Element => {
  const { toggleOverlay, isOverlayActive, isOverlayLoading, downloadOverlay } =
    useOverlay(overlayId);

  return (
    <li className="flex flex-row flex-nowrap justify-between pl-5 [&:not(:last-of-type)]:mb-4">
      <span>{name}</span>
      <div className="flex flex-row flex-nowrap">
        <Button
          variantStyles="ghost"
          className="mr-4 max-h-8 flex-none gap-1.5"
          onClick={toggleOverlay}
        >
          {isOverlayLoading && (
            <Image
              src={spinnerIcon}
              alt="spinner icon"
              height={12}
              width={12}
              className="animate-spin"
            />
          )}
          {isOverlayActive || isOverlayActive ? 'Hide' : 'View'}
        </Button>
        <Button className="max-h-8" variantStyles="ghost" onClick={downloadOverlay}>
          Download
        </Button>
      </div>
    </li>
  );
};
