/* eslint-disable no-magic-numbers */
import {
  allBioEntitiesWithTypeOfCurrentMapSelector,
  allVisibleBioEntitiesIdsSelector,
} from '@/redux/bioEntity/bioEntity.selectors';
import { entityNumberDataSelector } from '@/redux/entityNumber/entityNumber.selectors';
import { markersPinsOfCurrentMapDataSelector } from '@/redux/markers/markers.selectors';
import { BioEntity } from '@/types/models';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getBioEntitiesFeatures } from './getBioEntitiesFeatures';
import { getMarkersFeatures } from './getMarkersFeatures';
import { getMultipinsBioEntities } from './getMultipinsBioEntities';
import { getMultipinBioEntititesIds } from './getMultipinsBioEntitiesIds';
import { getMultipinFeatures } from './getMultipinsFeatures';

export const useOlMapPinsLayer = (): VectorLayer<VectorSource<Feature<Geometry>>> => {
  const pointToProjection = usePointToProjection();
  const activeIds = useSelector(allVisibleBioEntitiesIdsSelector);
  const bioEntities = useSelector(allBioEntitiesWithTypeOfCurrentMapSelector);
  const markersEntities = useSelector(markersPinsOfCurrentMapDataSelector);
  const entityNumber = useSelector(entityNumberDataSelector);
  const multiPinsBioEntities = useMemo(
    () =>
      getMultipinsBioEntities({
        bioEntities,
      }),
    [bioEntities],
  );
  const multipinsIds = getMultipinBioEntititesIds(multiPinsBioEntities);
  const isMultiPin = useCallback(
    (b: BioEntity): boolean => multipinsIds.includes(b.id),
    [multipinsIds],
  );

  const elementsFeatures = useMemo(
    () =>
      [
        getBioEntitiesFeatures(
          bioEntities.filter(b => !isMultiPin(b)),
          {
            pointToProjection,
            entityNumber,
            activeIds,
          },
        ),
        getMultipinFeatures(multiPinsBioEntities, {
          pointToProjection,
          entityNumber,
          activeIds,
        }),
        getMarkersFeatures(markersEntities, { pointToProjection }),
      ].flat(),
    [
      bioEntities,
      pointToProjection,
      entityNumber,
      activeIds,
      multiPinsBioEntities,
      markersEntities,
      isMultiPin,
    ],
  );

  const vectorSource = useMemo(() => new VectorSource(), []);

  useEffect(() => {
    vectorSource.clear();
    vectorSource.addFeatures(elementsFeatures);
  }, [elementsFeatures, vectorSource]);

  return useMemo(
    () =>
      new VectorLayer({
        source: vectorSource,
      }),
    [vectorSource],
  );
};
