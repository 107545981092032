/* eslint-disable no-magic-numbers */
import { MapInstance } from '@/types/map';
import { useOlMapAdditionalLayers } from '@/components/Map/MapViewer/MapViewerVector/utils/config/additionalLayers/useOlMapAdditionalLayers';
import { useMemo } from 'react';
import { useOlMapReactionsLayer } from './reactionsLayer/useOlMapReactionsLayer';
import { MapConfig } from '../../MapViewerVector.types';

interface UseOlMapLayersInput {
  mapInstance: MapInstance;
}

export const useOlMapVectorLayers = ({ mapInstance }: UseOlMapLayersInput): MapConfig['layers'] => {
  const reactionsLayer = useOlMapReactionsLayer({ mapInstance });
  const additionalLayers = useOlMapAdditionalLayers(mapInstance);

  return useMemo(() => {
    return [reactionsLayer, ...additionalLayers];
  }, [reactionsLayer, additionalLayers]);
};
