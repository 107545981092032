import { ZERO } from '@/constants/common';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@/shared/Accordion';
import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';

type CollapsibleSectionProps = {
  title: string;
  children: React.ReactNode;
  onOpened?(): void;
  dangerouslySetExpanded?: boolean;
};

export const CollapsibleSection = ({
  title,
  children,
  onOpened,
  dangerouslySetExpanded,
}: CollapsibleSectionProps): React.ReactNode => {
  const handleOnChange = (ids: ID[]): void => {
    const hasBeenOpened = ids.length > ZERO;

    if (hasBeenOpened && onOpened) {
      onOpened();
    }
  };

  return (
    <Accordion allowZeroExpanded onChange={handleOnChange}>
      <AccordionItem dangerouslySetExpanded={dangerouslySetExpanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{title}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{children}</AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
