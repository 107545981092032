import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { resultListSelector, stepTypeDrawerSelector } from '@/redux/drawer/drawer.selectors';
import { DrawerHeadingBackwardButton } from '@/shared/DrawerHeadingBackwardButton';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { displayGroupedSearchResults } from '@/redux/drawer/drawer.slice';
import { PinsList } from './PinsList';
import { mapStepTypeToHeading } from './ResultsList.component.utils';

export const ResultsList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(resultListSelector);
  const stepType = useAppSelector(stepTypeDrawerSelector);

  const navigateToGroupedSearchResults = (): void => {
    dispatch(displayGroupedSearchResults());
  };

  return (
    <div className="h-full">
      <DrawerHeadingBackwardButton backwardFunction={navigateToGroupedSearchResults}>
        <span data-testid="drawer-heading-text">{mapStepTypeToHeading(stepType)}</span>
      </DrawerHeadingBackwardButton>
      {data && <PinsList pinsList={data} type={stepType} />}
    </div>
  );
};
