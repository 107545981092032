import { Icon } from '@/shared/Icon';
import { twMerge } from 'tailwind-merge';
import { LOCATION_BTN_ID } from './MappAdditionalActions.constants';
import { useAddtionalActions } from './utils/useAdditionalActions';

export const MapAdditionalActions = (): JSX.Element => {
  const { zoomIn, zoomOut, zoomInToBioEntities } = useAddtionalActions();

  return (
    <div
      className={twMerge(
        'absolute bottom-6 right-6 z-10 flex flex-col gap-4',
        'drop-shadow-primary',
      )}
    >
      <button
        type="button"
        className="flex h-12 w-12 items-center justify-center rounded-full bg-white"
        onClick={zoomInToBioEntities}
        data-testid="location-button"
        title="Center map"
        id={LOCATION_BTN_ID}
      >
        <Icon className="h-[28px] w-[28px]" name="location" />
      </button>
      <div className="flex h-auto w-12 flex-col items-center justify-center rounded-full bg-white py-2">
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center"
          onClick={zoomIn}
          data-testid="zoom-in-button"
          title="Zoom in"
        >
          <Icon className="h-[24px] w-[24px]" name="magnifier-zoom-in" />
        </button>
        <div className="h-px w-12 bg-[#F1F1F1]" />
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center"
          onClick={zoomOut}
          data-testid="zoom-out-button"
          title="Zoom out"
        >
          <Icon className="h-[24px] w-[24px]" name="magnifier-zoom-out" />
        </button>
      </div>
    </div>
  );
};
