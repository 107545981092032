import { searchedBioEntitesSelectorOfCurrentMap } from '@/redux/bioEntity/bioEntity.selectors';
import { searchedChemicalsBioEntitesOfCurrentMapSelector } from '@/redux/chemicals/chemicals.selectors';
import { searchedDrugsBioEntitesOfCurrentMapSelector } from '@/redux/drugs/drugs.selectors';
import { markersPinsOfCurrentMapDataSelector } from '@/redux/markers/markers.selectors';
import { store } from '@/redux/store';
import { GetShownElementsPluginMethodResult } from './getShownElements.types';

export const getShownElements = (): GetShownElementsPluginMethodResult => {
  const { getState } = store;

  const content = searchedBioEntitesSelectorOfCurrentMap(getState());
  const chemicals = searchedChemicalsBioEntitesOfCurrentMapSelector(getState());
  const drugs = searchedDrugsBioEntitesOfCurrentMapSelector(getState());
  const markers = markersPinsOfCurrentMapDataSelector(getState());

  return {
    content,
    chemicals,
    drugs,
    markers,
  };
};
