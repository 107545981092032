import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '@/redux/root/root.selectors';
import { currentModelIdSelector } from '@/redux/models/models.selectors';

export const modelElementsSelector = createSelector(rootSelector, state => state.modelElements);

export const modelElementsStateForCurrentModelSelector = createSelector(
  modelElementsSelector,
  currentModelIdSelector,
  (state, currentModelId) => state[currentModelId],
);

export const modelElementsLoadingSelector = createSelector(
  modelElementsStateForCurrentModelSelector,
  state => state?.loading,
);

export const modelElementsForCurrentModelSelector = createSelector(
  modelElementsStateForCurrentModelSelector,
  state => state?.data || [],
);
