/* eslint-disable no-magic-numbers */
import { Coordinate } from 'ol/coordinate';

export default function getCenter(coords: Array<Coordinate>): Coordinate {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  coords.forEach(([x, y]) => {
    if (x < minX) {
      minX = x;
    }
    if (x > maxX) {
      maxX = x;
    }
    if (y < minY) {
      minY = y;
    }
    if (y > maxY) {
      maxY = y;
    }
  });

  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  return [centerX, centerY];
}
