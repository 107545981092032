import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '../root/root.selectors';

export const contextMenuSelector = createSelector(rootSelector, state => state.contextMenu);

export const isContextMenuOpenSelector = createSelector(contextMenuSelector, state => state.isOpen);

export const currentSelectedBioEntityIdSelector = createSelector(
  contextMenuSelector,
  state => state.currentSelectedBioEntityId,
);
