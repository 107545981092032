import { openBioEntityDrawerById, selectTab } from '@/redux/drawer/drawer.slice';
import { AppDispatch } from '@/redux/store';
import { getMultiBioEntityByIds } from '@/redux/bioEntity/thunks/getMultiBioEntity';
import { FeatureLike } from 'ol/Feature';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { searchFitBounds } from '@/services/pluginsManager/map/triggerSearch/searchFitBounds';
import { FEATURE_TYPE } from '@/constants/features';

/* prettier-ignore */
export const leftClickHandleAlias =
  (dispatch: AppDispatch, hasFitBounds = false) =>
    async (feature: FeatureLike, modelId: number): Promise<void> => {
      const id = feature.get('id');
      const bioEntities = await dispatch(
        getMultiBioEntityByIds({
          elementsToFetch: [{ elementId: id, type: FEATURE_TYPE.ALIAS, modelId, addNumbersToEntityNumber: true }],
        }),
      ).unwrap();
      dispatch(selectTab(`${id}`));
      dispatch(openBioEntityDrawerById(id));

      PluginsEventBus.dispatchEvent('onSearch', {
        type: 'bioEntity',
        searchValues: [{ id, modelId, type: FEATURE_TYPE.ALIAS }],
        results: [
          bioEntities.map(bioEntity => {
            return { perfect: true, bioEntity };
          }),
        ],
      });

      if (hasFitBounds) {
        searchFitBounds();
      }
    };
