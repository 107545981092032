/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { MapInstance } from '@/types/map';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import {
  BLACK_COLOR,
  COMPARTMENT_SQUARE_POINTS,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import getPolygonCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getPolygonCoords';
import Compartment from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/Compartment';
import { Color } from '@/types/models';
import VectorSource from 'ol/source/Vector';
import { MapSize } from '@/redux/map/map.types';

export type CompartmentSquareProps = {
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  sboTerm: string;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  fillColor?: Color;
  borderColor?: Color;
  fontColor?: Color;
  innerWidth?: number;
  outerWidth?: number;
  thickness?: number;
  text?: string;
  fontSize?: number;
  nameX: number;
  nameY: number;
  nameHeight: number;
  nameWidth: number;
  nameVerticalAlign?: VerticalAlign;
  nameHorizontalAlign?: HorizontalAlign;
  overlaysVisible: boolean;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  vectorSource: VectorSource;
  mapBackgroundType: number;
  mapSize: MapSize;
};

export default class CompartmentSquare extends Compartment {
  constructor({
    id,
    complexId,
    compartmentId,
    sboTerm,
    x,
    y,
    width,
    height,
    zIndex,
    fillColor = WHITE_COLOR,
    borderColor = BLACK_COLOR,
    fontColor = BLACK_COLOR,
    innerWidth = 1,
    outerWidth = 2,
    thickness = 12,
    text = '',
    fontSize = 12,
    nameX,
    nameY,
    nameHeight,
    nameWidth,
    nameVerticalAlign = 'MIDDLE',
    nameHorizontalAlign = 'CENTER',
    overlaysVisible,
    pointToProjection,
    mapInstance,
    vectorSource,
    mapBackgroundType,
    mapSize,
  }: CompartmentSquareProps) {
    super({
      id,
      complexId,
      compartmentId,
      sboTerm,
      x,
      y,
      width,
      height,
      thickness,
      outerWidth,
      innerWidth,
      zIndex,
      text,
      fontSize,
      nameX,
      nameY,
      nameWidth,
      nameHeight,
      fontColor,
      nameVerticalAlign,
      nameHorizontalAlign,
      fillColor,
      borderColor,
      overlaysVisible,
      pointToProjection,
      mapInstance,
      vectorSource,
      mapBackgroundType,
      mapSize,
    });
  }

  protected getCompartmentCoords(): void {
    this.outerCoords = getPolygonCoords({
      points: COMPARTMENT_SQUARE_POINTS,
      x: this.x,
      y: this.y,
      height: this.height,
      width: this.width,
      pointToProjection: this.pointToProjection,
    });
    this.innerCoords = getPolygonCoords({
      points: COMPARTMENT_SQUARE_POINTS,
      x: this.x + this.thickness,
      y: this.y + this.thickness,
      height: this.height - 2 * this.thickness,
      width: this.width - 2 * this.thickness,
      pointToProjection: this.pointToProjection,
    });
  }
}
