import { Icon } from '@/shared/Icon';
import { IconButton } from '@/shared/IconButton';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { OverlayDataAxis } from '../OverlayData.types';
import { GeneVariantsTable } from './GeneVariantsTable';
import { getOverlayAxisData } from './utils/getOverlayAxisProps';

interface Props {
  axis: OverlayDataAxis;
}

export const OverlayAxis = ({ axis }: Props): JSX.Element => {
  const [showGeneVariants, setShowGeneVariants] = useState<boolean>(false);
  const { title, background, bar, value } = getOverlayAxisData(axis);

  const toggleShowGeneVariants = (): void => {
    setShowGeneVariants(v => !v);
  };

  return (
    <>
      <div className="flex items-center gap-2 text-xs">
        <div className="flex w-48 items-center justify-between gap-2 font-semibold">
          <div>{title}</div>
          {axis.geneVariants && (
            <IconButton
              icon={showGeneVariants ? 'chevron-up' : 'chevron-down'}
              data-testid="overlay-axis-icon"
              className="h-6 w-6 flex-shrink-0 bg-transparent p-0"
              onClick={toggleShowGeneVariants}
            />
          )}
        </div>
        <div
          className="relative h-6 w-full overflow-hidden rounded"
          style={{ background: background.color }}
          data-testid="overlay-axis-bg"
        >
          <div
            className={twMerge(
              'absolute h-full',
              value.isPositive && 'left-1/2 rounded-r',
              value.isNegative && 'right-1/2 rounded-l',
              value.isUndefined && 'w-full',
            )}
            style={{ background: bar.color, width: `${bar.percentage}%` }}
          />
        </div>
        <div
          className="flex h-6 w-12 flex-shrink-0 items-center justify-center rounded border border-divide p-1 text-center font-semibold"
          title={
            axis.geneVariants
              ? 'Number of variants mapped to this gene. See their details in the tabular view below.'
              : undefined
          }
          data-testid="overlay-axis-value"
        >
          {value.title}
          {axis.geneVariants && (
            <span className="ml-[2px] flex">
              <Icon name="info" className="h-3 w-3 fill-black" />
            </span>
          )}
        </div>
      </div>
      {axis.geneVariants && showGeneVariants && <GeneVariantsTable data={axis.geneVariants} />}
    </>
  );
};
