import {
  overlaysDataSelector,
  userOverlaysDataSelector,
} from '@/redux/overlays/overlays.selectors';
import { store } from '@/redux/store';
import { MapOverlay } from '@/types/models';

export const getDataOverlays = (): MapOverlay[] => {
  const overlays = overlaysDataSelector(store.getState());
  const userOverlays = userOverlaysDataSelector(store.getState()) || [];

  return [...overlays, ...userOverlays];
};
