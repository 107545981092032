/* eslint-disable no-magic-numbers */
export default function getCoordsX(
  x: number,
  absoluteX: number,
  relativeX: number,
  relativeHeightForX: number | null,
  height: number,
  width: number,
): number {
  return x + absoluteX + (width * relativeX) / 100 + (height * (relativeHeightForX || 0)) / 100;
}
