import { addSingleMarker } from './addSingleMarker';
import { clearAllElements } from './clearAllElements';
import { getAllChemicals } from './getAllChemicals';
import { getAllBioEntities } from './getAllContent';
import { getAllDrugs } from './getAllDrugs';
import { getAllMarkers } from './getAllMarkers';
import { getShownElements } from './getShownElements';
import { removeAllMarkers } from './removeAllMarkers';
import { removeSingleMarker } from './removeSingleMarker';

export const bioEntitiesMethods = {
  getAllBioEntities,
  getAllChemicals,
  getAllDrugs,
  getAllMarkers,
  getShownElements,
  addSingleMarker,
  removeSingleMarker,
  removeAllMarkers,
  clearAllElements,
};

export type BioEntitiesMethods = typeof bioEntitiesMethods;
