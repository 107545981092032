import { useEffect, useState } from 'react';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { setSortOrderAndColumn } from '@/redux/publications/publications.slice';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { Icon } from '@/shared/Icon';
import {
  searchValueSelector,
  sortColumnSelector,
} from '@/redux/publications/publications.selectors';
import { SortColumn, SortOrder } from '@/redux/publications/publications.types';
import { getPublications } from '@/redux/publications/publications.thunks';
import { DEFAULT_PAGE_SIZE } from '../PublicationsTable.constants';

type SortByHeaderProps = {
  columnName: SortColumn;
  children: React.ReactNode;
};

export const SortByHeader = ({ columnName, children }: SortByHeaderProps): JSX.Element => {
  const activeColumn = useAppSelector(sortColumnSelector);
  const [sortDirection, setSortDirection] = useState<SortOrder | undefined>();
  const searchValue = useAppSelector(searchValueSelector);
  const dispatch = useAppDispatch();
  // if columnName is the same as the current sortColumn, then sort in the opposite direction

  const handleSortBy = (): void => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    dispatch(
      setSortOrderAndColumn({
        sortColumn: columnName,
        sortOrder: newSortDirection,
      }),
    );

    dispatch(
      getPublications({
        params: {
          page: 0,
          length: DEFAULT_PAGE_SIZE,
          sortColumn: columnName,
          sortOrder: newSortDirection,
          search: searchValue,
        },
      }),
    );
  };

  useEffect(() => {
    if (activeColumn === columnName) {
      setSortDirection('asc');
    } else {
      setSortDirection(undefined);
    }
  }, [activeColumn, columnName]);

  return (
    <div className="flex flex-row items-center px-3">
      <button type="button" onClick={handleSortBy}>
        {children}
      </button>
      <div className="relative ml-2 flex h-6 w-4 flex-col">
        {sortDirection !== 'desc' && (
          <Icon name="arrow" className="absolute top-0 h-4 w-4 rotate-[270deg] fill-font-500" />
        )}
        {sortDirection !== 'asc' && (
          <Icon name="arrow" className="absolute bottom-0 h-4 w-4 rotate-90 fill-font-500" />
        )}
      </div>
    </div>
  );
};
