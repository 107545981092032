import { ZERO } from '@/constants/common';
import { expandHexToFullFormatIfItsShorthanded } from './hexToRgb';

const HEX_RADIX = 16;
const EXPECTED_HEX_LENGTH = 2;
const MAX_RGB_VALUE = 255;
const DEFAULT_ALPHA = 1;
const NORMALIZED_HEX_LENGTH = 6; // example: FFFFFF

export const addAlphaToHexString = (hexString: string, alpha: number = DEFAULT_ALPHA): string => {
  const hexStringWithoutHash = hexString.replace('#', '');
  const hexStringNormalized = hexStringWithoutHash.slice(ZERO, NORMALIZED_HEX_LENGTH);
  const fullHexString = expandHexToFullFormatIfItsShorthanded(hexStringNormalized);

  const alphaHex = Math.round(alpha * MAX_RGB_VALUE)
    .toString(HEX_RADIX)
    .padStart(EXPECTED_HEX_LENGTH, '0');

  return `${fullHexString}${alphaHex}`;
};
