/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { perfectMatchSelector, searchValueSelector } from '@/redux/search/search.selectors';
import { setPerfectMatch } from '@/redux/search/search.slice';
import { getSearchData } from '@/redux/search/search.thunks';
import { ChangeEvent } from 'react';

export const PerfectMatchSwitch = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isChecked = useAppSelector(perfectMatchSelector);
  const searchValue = useAppSelector(searchValueSelector);

  const setChecked = (value: boolean): void => {
    dispatch(setPerfectMatch(value));
  };

  const setCheckedAndRefreshSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const isCheckedNewValue = event.target.checked;

    setChecked(isCheckedNewValue);
    dispatch(resetReactionsData());
    dispatch(getSearchData({ searchQueries: searchValue, isPerfectMatch: isCheckedNewValue }));
  };

  return (
    <div className="mr-6 flex items-center">
      <span className="mr-2 text-sm font-bold">Perfect match</span>
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          value=""
          className="peer sr-only"
          checked={isChecked}
          onChange={setCheckedAndRefreshSearch}
        />
        <div className="peer h-6 w-11 rounded-full bg-greyscale-500 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-med-sea-green peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none rtl:peer-checked:after:-translate-x-full" />
      </label>
    </div>
  );
};
