import { formatsHandlersSelector } from '@/redux/configuration/configuration.selectors';
import { Button } from '@/shared/Button';
import { useSelect } from 'downshift';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import spinnerIcon from '@/assets/vectors/icons/spinner.svg';
import { useState } from 'react';
import { downloadFileFromUrl } from '@/redux/export/export.utils';
import { Icon } from '@/shared/Icon';
import { SUBMAP_DOWNLOAD_HANDLERS_NAMES } from './DownloadSubmap.constants';
import { useGetSubmapDownloadUrl } from './utils/useGetSubmapDownloadUrl';

export const DownloadSubmap = (): React.ReactNode => {
  const formatsHandlers = useSelector(formatsHandlersSelector);
  const formatsHandlersItems = Object.entries(formatsHandlers);
  const getSubmapDownloadUrl = useGetSubmapDownloadUrl();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { isOpen, getToggleButtonProps, getMenuProps, closeMenu } = useSelect({
    items: formatsHandlersItems,
  });

  const downloadSubmap = (handler: string) => {
    return function () {
      closeMenu();
      setIsDownloading(true);
      downloadFileFromUrl(getSubmapDownloadUrl({ handler })).finally(function () {
        setIsDownloading(false);
      });
    };
  };

  return (
    <div className="relative">
      <Button
        data-testid="download-submap-button"
        variantStyles="quiet"
        className="mr-4 p-0"
        {...getToggleButtonProps()}
        title="Download"
        disabled={isDownloading}
      >
        {isDownloading && (
          <Image
            src={spinnerIcon}
            alt="spinner icon"
            height={12}
            width={12}
            className="mr-5 animate-spin"
          />
        )}
        {!isDownloading && <Icon name="download" className="h-6 w-6 fill-font-500" />}
      </Button>
      <ul
        data-testid="download-submap-list"
        className={`absolute right-[-50%] z-10 max-h-80 w-48 overflow-scroll rounded-sm border bg-white p-0 ps-0 ${
          !isOpen && 'hidden'
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          formatsHandlersItems.map(([formatId, handler]) => (
            <li key={formatId}>
              <Button
                variantStyles="ghost"
                className="flex w-full flex-col border-t px-4 py-2 shadow-sm"
                onClick={downloadSubmap(handler)}
              >
                {SUBMAP_DOWNLOAD_HANDLERS_NAMES[formatId]}
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};
