import SimpleGeometry from 'ol/geom/SimpleGeometry';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { useMemo } from 'react';
import { useOverlayFeatures } from './useOverlayFeatures';

/**
 * Prerequisites: "view" button triggers opening overlays -> it triggers downloading overlayBioEntityData for given overlay for ALL available submaps(models)
 *
 * 1. For each active overlay
 * 2. get overlayBioEntity data (current map data passed by selector)
 * 3. based on nOverlays, calculate coordinates for given overlayBioEntity to render Polygon from extend
 * 4. Calculate coordinates in following steps:
 *   - polygonWidth = width/nOverlays
 *   - xMin = xMin + polygonWidth * overlayIndexBasedOnOrder
 *   - xMax = xMin + polygonWidth
 *   - yMin,yMax -> is const taken from store
 * 5. generate Feature(xMin,yMin,xMax,yMax)
 */

export const useOlMapOverlaysLayer = (): VectorLayer<VectorSource<Feature<SimpleGeometry>>> => {
  const overlaysFeatures = useOverlayFeatures();

  const vectorSource = useMemo(() => {
    return new VectorSource({
      features: overlaysFeatures,
    });
  }, [overlaysFeatures]);

  const overlaysLayer = useMemo(
    () =>
      new VectorLayer({
        source: vectorSource,
      }),
    [vectorSource],
  );

  return overlaysLayer;
};
