import { SIZE_OF_ARRAY_WITH_FOUR_ELEMENTS } from '@/constants/common';
import { OverviewImageLink } from '@/types/models';
import { OverviewImageLinkConfigSize } from '../OverviewImageModal.types';
import { DEFAULT_OVERVIEW_IMAGE_LINK_CONFIG } from '../OverviewImagesModal.constants';

export const getOverviewImageLinkSize = (
  { polygon }: Pick<OverviewImageLink, 'polygon'>,
  {
    sizeFactor,
  }: {
    sizeFactor: number;
  },
): OverviewImageLinkConfigSize => {
  // valid polygon needs to have four points
  if (polygon.length < SIZE_OF_ARRAY_WITH_FOUR_ELEMENTS) {
    return DEFAULT_OVERVIEW_IMAGE_LINK_CONFIG;
  }

  const polygonScaled = polygon.map(({ x, y }) => ({ x: x * sizeFactor, y: y * sizeFactor }));
  const [pointTopLeft, , pointBottomRight] = polygonScaled;
  const width = pointBottomRight.x - pointTopLeft.x;
  const height = pointBottomRight.y - pointTopLeft.y;
  const { x, y } = pointTopLeft;

  return {
    top: y,
    left: x,
    width,
    height,
  };
};
