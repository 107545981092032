import { isActiveLegendSelector } from '@/redux/legend/legend.selectors';
import { removePluginLegend, setDefaultLegendId } from '@/redux/legend/legend.slice';
import { store } from '@/redux/store';

export const removeLegend = (pluginId: string): void => {
  const isActivePluginLegend = isActiveLegendSelector(store.getState(), pluginId);
  const { dispatch } = store;

  if (isActivePluginLegend) {
    dispatch(setDefaultLegendId());
  }

  dispatch(removePluginLegend(pluginId));
};
