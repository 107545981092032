import { FitOptions } from 'ol/View';
import { boundingExtent } from 'ol/extent';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { store } from '@/redux/store';
import { MapManager } from '../mapManager';
import { pointToProjection } from './fitBounds.utils';
import { DEFAULT_PADDING } from './fitBounds.constants';

type FitBoundsArgs = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export const fitBounds = ({ x1, y1, x2, y2 }: FitBoundsArgs): void => {
  const mapInstance = MapManager.getMapInstance();

  if (!mapInstance) return;

  const mapSize = mapDataSizeSelector(store.getState());

  const points = [
    {
      x: x1,
      y: y2,
    },
    {
      x: x2,
      y: y1,
    },
  ];

  const coordinates = points.map(point => pointToProjection(point, mapSize));

  const extent = boundingExtent(coordinates);

  const options: FitOptions = {
    size: mapInstance.getSize(),
    padding: DEFAULT_PADDING,
    maxZoom: mapSize.maxZoom,
  };

  window.requestAnimationFrame(() => {
    mapInstance.getView().fit(extent, options);
  });
};
