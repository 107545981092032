/* eslint-disable no-magic-numbers */
import { DEFAULT_EXTENT_PADDING, OPTIONS, ZOOM_RESCALING_FACTOR } from '@/constants/map';
import { mapDataInitialPositionSelector, mapDataSizeSelector } from '@/redux/map/map.selectors';
import { MapInstance, Point } from '@/types/map';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import { View } from 'ol';
import { Extent, boundingExtent } from 'ol/extent';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MapConfig } from '../../MapViewer.types';

interface UseOlMapViewInput {
  mapInstance: MapInstance;
}

export const useOlMapView = ({ mapInstance }: UseOlMapViewInput): MapConfig['view'] => {
  const mapInitialPosition = useSelector(mapDataInitialPositionSelector);
  const mapSize = useSelector(mapDataSizeSelector);
  const pointToProjection = usePointToProjection();

  const extent = useMemo((): Extent => {
    let widthPadding = 0;
    let heightPadding = 0;
    let mapInstanceWidthToHeightRatio = 2.15;
    const mapInstanceSize = mapInstance?.getSize();
    if (mapInstanceSize) {
      mapInstanceWidthToHeightRatio = mapInstanceSize[0] / mapInstanceSize[1];
    }
    const mapWidthToHeightRatio = mapSize.width / mapSize.height;

    if (mapWidthToHeightRatio < mapInstanceWidthToHeightRatio) {
      widthPadding = mapSize.height * mapInstanceWidthToHeightRatio - mapSize.width;
    } else {
      heightPadding = mapSize.width / mapInstanceWidthToHeightRatio - mapSize.height;
    }
    const topLeftPoint: Point = {
      x: mapSize.width + widthPadding / 2 + DEFAULT_EXTENT_PADDING,
      y: mapSize.height + heightPadding / 2 + DEFAULT_EXTENT_PADDING,
    };

    const bottomRightPoint: Point = {
      x: -widthPadding / 2 - DEFAULT_EXTENT_PADDING,
      y: -heightPadding / 2 - DEFAULT_EXTENT_PADDING,
    };

    return boundingExtent([topLeftPoint, bottomRightPoint].map(pointToProjection));
  }, [mapSize.width, mapSize.height, mapInstance, pointToProjection]);

  const center = useMemo((): Point => {
    const centerPoint: Point = {
      x: mapInitialPosition.x,
      y: mapInitialPosition.y,
    };

    const [x, y] = pointToProjection(centerPoint);

    return {
      x,
      y,
    };
  }, [mapInitialPosition, pointToProjection]);

  const viewConfig = useMemo(
    () => ({
      center: [center.x, center.y],
      zoom: mapInitialPosition.z,
      showFullExtent: OPTIONS.showFullExtent,
      zoomFactor: 2 ** (1 / ZOOM_RESCALING_FACTOR),
      originalMaxZoom: mapSize.maxZoom * ZOOM_RESCALING_FACTOR,
      maxZoom:
        mapSize.width < 1.6 * mapSize.tileSize || mapSize.height < 1.6 * mapSize.tileSize
          ? Math.max(15, mapSize.maxZoom * ZOOM_RESCALING_FACTOR)
          : mapSize.maxZoom * ZOOM_RESCALING_FACTOR,
      minZoom: mapSize.minZoom * ZOOM_RESCALING_FACTOR,
      extent,
    }),
    [center, mapInitialPosition.z, mapSize, extent],
  );

  const view = useMemo(() => new View(viewConfig), [viewConfig]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    mapInstance.setView(view);
  }, [view, mapInstance]);

  return view;
};
