import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';

export const compartmentPathwaysSelector = createSelector(
  rootSelector,
  state => state.compartmentPathways,
);

export const compartmentPathwaysDataSelector = createSelector(
  compartmentPathwaysSelector,
  state => state.data,
);

export const loadingCompartmentPathwaysSelector = createSelector(
  compartmentPathwaysSelector,
  state => state.loading,
);
