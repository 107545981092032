import { CheckboxItem } from '../../CheckboxFilter/CheckboxFilter.types';
import { extractAndParseNumberIdFromCompartment } from './extractAndParseNumberIdFromCompartment';

type DownloadBodyRequest = {
  columns: string[];
  submaps: number[];
  annotations: string[];
  includedCompartmentIds: number[];
  excludedCompartmentIds: number[];
};

type GetDownloadBodyRequestProps = {
  columns: string[];
  modelIds: number[];
  annotations: CheckboxItem[];
  includedCompartmentPathways: CheckboxItem[];
  excludedCompartmentPathways: CheckboxItem[];
};

export const getNetworkDownloadBodyRequest = ({
  columns,
  modelIds,
  annotations,
  includedCompartmentPathways,
  excludedCompartmentPathways,
}: GetDownloadBodyRequestProps): DownloadBodyRequest => ({
  columns,
  submaps: modelIds,
  annotations: annotations.map(annotation => annotation.id),
  includedCompartmentIds: includedCompartmentPathways.map(extractAndParseNumberIdFromCompartment),
  excludedCompartmentIds: excludedCompartmentPathways.map(extractAndParseNumberIdFromCompartment),
});
