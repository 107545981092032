import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentEditedOverlaySelector } from '@/redux/modal/modal.selector';
import { closeModal } from '@/redux/modal/modal.slice';
import {
  getAllUserOverlaysByCreator,
  removeOverlay,
  updateOverlays,
} from '@/redux/overlays/overlays.thunks';
import { loginUserSelector } from '@/redux/user/user.selectors';
import { MapOverlay } from '@/types/models';
import { useState } from 'react';

type UseEditOverlayReturn = {
  name: string | undefined;
  description: string | undefined;
  handleCancelEdit: () => void;
  handleRemoveOverlay: () => void;
  handleSaveEditedOverlay: () => Promise<void>;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

type UpdatedOverlay = {
  editedOverlay: MapOverlay;
  overlayName: string;
  overlayDescription: string;
};

export const useEditOverlay = (): UseEditOverlayReturn => {
  const currentEditedOverlay = useAppSelector(currentEditedOverlaySelector);
  const login = useAppSelector(loginUserSelector);
  const dispatch = useAppDispatch();
  const [name, setName] = useState(currentEditedOverlay?.name);
  const [description, setDescription] = useState(currentEditedOverlay?.description);

  const handleCancelEdit = (): void => {
    dispatch(closeModal());
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setDescription(e.target.value);
  };

  const handleRemoveOverlay = (): void => {
    if (!login || !currentEditedOverlay) return;
    dispatch(removeOverlay({ overlayId: currentEditedOverlay.idObject }));
  };

  const handleUpdateOverlay = async ({
    editedOverlay,
    overlayDescription,
    overlayName,
  }: UpdatedOverlay): Promise<void> => {
    await dispatch(
      updateOverlays([
        {
          ...editedOverlay,
          name: overlayName,
          description: overlayDescription,
        },
      ]),
    );
  };

  const getUserOverlaysByCreator = async (): Promise<void> => {
    await dispatch(getAllUserOverlaysByCreator());
  };

  const handleCloseModal = (): void => {
    dispatch(closeModal());
  };

  const handleSaveEditedOverlay = async (): Promise<void> => {
    if (!currentEditedOverlay || !name || !login) return;
    await handleUpdateOverlay({
      editedOverlay: currentEditedOverlay,
      overlayDescription: description || '',
      overlayName: name,
    });

    await getUserOverlaysByCreator();

    handleCloseModal();
  };

  return {
    handleCancelEdit,
    handleRemoveOverlay,
    handleSaveEditedOverlay,
    handleNameChange,
    handleDescriptionChange,
    name,
    description,
  };
};
