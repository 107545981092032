import { PinTypeWithNone } from '@/components/Map/Drawer/SearchDrawerWrapper/ResultsList/PinsList/PinsList.types';
import { PinType } from '@/types/pin';

export const PIN_PATH2D =
  'M12.3077 0C6.25641 0 0 4.61538 0 12.3077C0 19.5897 11.0769 30.9744 11.5897 31.4872C11.7949 31.6923 12 31.7949 12.3077 31.7949C12.6154 31.7949 12.8205 31.6923 13.0256 31.4872C13.5385 30.9744 24.6154 19.6923 24.6154 12.3077C24.6154 4.61538 18.359 0 12.3077 0Z';

export const PIN_COVER_PATH2D =
  'M12.5 2C7.26267 2 2 5.93609 2 12.3871C2 13.7597 2.54385 15.5184 3.55264 17.5238C4.54347 19.4936 5.89191 21.5252 7.29136 23.3952C9.35206 26.1488 11.4511 28.4566 12.5022 29.569C13.5556 28.4621 15.6525 26.1723 17.7106 23.4313C19.1091 21.5689 20.4565 19.5418 21.4465 17.569C22.4535 15.5627 23 13.7891 23 12.3871C23 5.93609 17.7373 2 12.5 2ZM0 12.3871C0 4.64516 6.35417 0 12.5 0C18.6458 0 25 4.64516 25 12.3871C25 19.8194 13.75 31.1742 13.2292 31.6903C13.0208 31.8968 12.8125 32 12.5 32C12.1875 32 11.9792 31.8968 11.7708 31.6903C11.25 31.1742 0 19.7161 0 12.3871Z';

export const PIN_SIZE = {
  width: 25,
  height: 32,
};

export const PINS_COLORS: Record<PinType, string> = {
  drugs: '#F48C41',
  chemicals: '#008325',
  bioEntity: '#106AD7',
  comment: '#106AD7',
};

export const PINS_COLOR_WITH_NONE: Record<PinTypeWithNone, string> = {
  ...PINS_COLORS,
  none: '#000000',
};

export const LINE_COLOR = '#00AAFF';

export const TEXT_COLOR = '#FFFFFF';

export const LINE_WIDTH = 6;

export const MULTIICON_RATIO = 0.2;
