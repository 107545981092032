/* eslint-disable no-magic-numbers */
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { Feature, Map, MapBrowserEvent } from 'ol';
import { updateLastRightClick } from '@/redux/map/map.slice';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { handleDataReset } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/handleDataReset';
import { FEATURE_TYPE } from '@/constants/features';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { openContextMenu } from '@/redux/contextMenu/contextMenu.slice';
import { ModelElement, NewReaction } from '@/types/models';
import { rightClickHandleAlias } from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/mouseRightClick/rightClickHandleAlias';
import { clickHandleReaction } from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/clickHandleReaction';
import { VECTOR_MAP_LAYER_TYPE } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';

/* prettier-ignore */
export const onMapRightClick =
  (mapSize: MapSize, modelId: number, dispatch: AppDispatch, modelElements: Array<ModelElement>, reactions: Array<NewReaction>) =>
    async ({ coordinate, pixel }: Pick<MapBrowserEvent<UIEvent>, 'coordinate' | 'pixel'>, mapInstance: Map): Promise<void> => {

      const [lng, lat] = toLonLat(coordinate);
      const point = latLngToPoint([lat, lng], mapSize);
      dispatch(updateLastRightClick({ coordinates: point, modelId }));
      dispatch(handleDataReset);
      dispatch(openContextMenu(pixel));

      let foundFeature: Feature | undefined;
      mapInstance.getAllLayers().forEach(layer => {
        if(layer.isVisible() && layer instanceof VectorLayer) {
          if (layer.get('type') === VECTOR_MAP_LAYER_TYPE) {
            const source = layer.getSource();
            if (source instanceof VectorSource) {
              foundFeature = source.getClosestFeatureToCoordinate(coordinate, (feature) => {
                return (
                  feature.get('type') === FEATURE_TYPE.COMPARTMENT && feature.get('filled') ||
                  [
                    FEATURE_TYPE.ALIAS,
                    FEATURE_TYPE.REACTION,
                    FEATURE_TYPE.GLYPH
                  ].includes(feature.get('type'))
                ) && feature.get('zIndex') >= 0 && !feature.get('hidden');
              });
            }
          }
        }
      });
      if(!foundFeature) {
        return;
      }

      const type = foundFeature.get('type');
      const id = foundFeature.get('id');
      if([FEATURE_TYPE.ALIAS, FEATURE_TYPE.GLYPH, FEATURE_TYPE.COMPARTMENT].includes(type)) {
        const modelElement = modelElements.find(element => element.id === id);
        if(!modelElement) {
          return;
        }
        await rightClickHandleAlias(dispatch)(id, modelElement);
      } else if (type === FEATURE_TYPE.REACTION) {
        clickHandleReaction(dispatch)(modelElements, reactions, id,  modelId);
      }
    };
