import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import lensIcon from '@/assets/vectors/icons/lens.svg';
import { useDebounce } from 'use-debounce';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { getPublications } from '@/redux/publications/publications.thunks';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  isLoadingSelector,
  selectedModelIdSelector,
  sortColumnSelector,
  sortOrderSelector,
} from '@/redux/publications/publications.selectors';
import Image from 'next/image';
import { setPublicationSearchValue } from '@/redux/publications/publications.slice';
import { DEFAULT_PAGE_SIZE } from '../PublicationsTable/PublicationsTable.constants';

const DEFAULT_DELAY = 500;

export const PublicationsSearch = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoadingSelector);
  const [value, setValue] = useState('');
  const [debouncedValue] = useDebounce<string>(value, DEFAULT_DELAY);
  const sortColumn = useAppSelector(sortColumnSelector);
  const sortOrder = useAppSelector(sortOrderSelector);
  const selectedId = useAppSelector(selectedModelIdSelector);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const handleSearch = useCallback((): void => {
    dispatch(
      getPublications({
        params: {
          page: 0,
          length: DEFAULT_PAGE_SIZE,
          sortColumn,
          sortOrder,
          search: debouncedValue,
        },
        modelId: selectedId,
      }),
    );
  }, [debouncedValue, dispatch]);

  useEffect(() => {
    dispatch(setPublicationSearchValue(debouncedValue));
    handleSearch();
  }, [debouncedValue, dispatch, handleSearch]);

  return (
    <div className="relative mr-4">
      <input
        value={value}
        name="search-input"
        aria-label="search-input"
        data-testid="search-input"
        onChange={handleChange}
        disabled={isLoading}
        className="h-9 w-72 rounded-[64px] border border-transparent bg-cultured px-4 py-2.5 text-xs font-medium text-font-400 outline-none  hover:border-greyscale-600 focus:border-greyscale-600"
      />
      <button disabled={isLoading} type="button" className="bg-transparent">
        <Image
          src={lensIcon}
          alt="lens icon"
          height={16}
          width={16}
          className="absolute right-4 top-2.5"
        />
      </button>
    </div>
  );
};
