export const createCanvas = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};
