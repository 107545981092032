/* eslint-disable no-magic-numbers */
import { Style } from 'ol/style';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import getStroke from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStroke';
import getFill from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getFill';
import Polygon from 'ol/geom/Polygon';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';
import { Color, Modification, Shape } from '@/types/models';
import { MapInstance } from '@/types/map';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import {
  BLACK_COLOR,
  MAP_ELEMENT_TYPES,
  TRANSPARENT_COLOR,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import BaseMultiPolygon from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/BaseMultiPolygon';
import getStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStyle';
import getTextCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextCoords';
import getTextStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextStyle';
import getShapePolygon from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/getShapePolygon';
import { BioShapesDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import { FEATURE_TYPE } from '@/constants/features';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { getPolygonLatitudeCoordinates } from '@/components/Map/MapViewer/utils/config/overlaysLayer/getPolygonLatitudeCoordinates';
import { ZERO } from '@/constants/common';
import { OverlayOrder } from '@/redux/overlayBioEntity/overlayBioEntity.utils';
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import VectorSource from 'ol/source/Vector';
import { MapSize } from '@/redux/map/map.types';

export type MapElementProps = {
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  sboTerm: string;
  shapes: Array<Shape>;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  fillColor?: Color;
  borderColor?: Color;
  fontColor?: Color;
  lineWidth?: number;
  lineType?: string;
  text?: string;
  fontSize?: number;
  nameX: number;
  nameY: number;
  nameHeight: number;
  nameWidth: number;
  nameVerticalAlign?: VerticalAlign;
  nameHorizontalAlign?: HorizontalAlign;
  homodimer?: number;
  activity?: boolean;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  vectorSource: VectorSource;
  bioShapes?: BioShapesDict;
  lineTypes?: LineTypeDict;
  modifications?: Array<Modification>;
  overlays?: Array<OverlayBioEntityRender>;
  overlaysOrder?: Array<OverlayOrder>;
  overlaysVisible: boolean;
  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;
  mapBackgroundType: number;
  mapSize: MapSize;
};

export default class MapElement extends BaseMultiPolygon {
  shapes: Array<Shape>;

  lineWidth: number;

  lineType: string | undefined;

  bioShapes: BioShapesDict;

  lineTypes: LineTypeDict;

  homodimer: number;

  activity: boolean | undefined;

  modifications: Array<Modification>;

  lineDash: Array<number> = [];

  overlays: Array<OverlayBioEntityRender> = [];

  overlaysOrder: Array<OverlayOrder> = [];

  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;

  constructor({
    id,
    complexId,
    compartmentId,
    sboTerm,
    shapes,
    x,
    y,
    width,
    height,
    zIndex,
    fillColor = WHITE_COLOR,
    borderColor = BLACK_COLOR,
    fontColor = BLACK_COLOR,
    lineWidth = 1,
    lineType,
    text = '',
    fontSize = 12,
    nameX,
    nameY,
    nameHeight,
    nameWidth,
    nameVerticalAlign = 'MIDDLE',
    nameHorizontalAlign = 'CENTER',
    homodimer = 1,
    activity,
    pointToProjection,
    mapInstance,
    vectorSource,
    bioShapes = {},
    lineTypes = {},
    modifications = [],
    overlays = [],
    overlaysOrder = [],
    overlaysVisible,
    getOverlayColor,
    mapBackgroundType,
    mapSize,
  }: MapElementProps) {
    super({
      type: FEATURE_TYPE.ALIAS,
      sboTerm,
      id,
      complexId,
      compartmentId,
      x,
      y,
      width,
      height,
      zIndex,
      text,
      fontSize,
      nameX,
      nameY,
      nameWidth,
      nameHeight,
      fontColor,
      nameVerticalAlign,
      nameHorizontalAlign,
      fillColor,
      borderColor,
      pointToProjection,
      vectorSource,
      overlaysVisible,
      mapBackgroundType,
      mapSize,
      mapInstance,
    });
    this.shapes = shapes;
    this.lineWidth = lineWidth;
    this.lineType = lineType;
    this.homodimer = homodimer;
    this.activity = activity;
    this.bioShapes = bioShapes;
    this.lineTypes = lineTypes;
    this.modifications = modifications;
    this.overlays = overlays;
    this.overlaysOrder = overlaysOrder;
    this.getOverlayColor = getOverlayColor;
    this.createPolygons();
    this.drawText();
    this.drawMultiPolygonFeature(mapInstance);
  }

  protected createPolygons(): void {
    if (this.lineType) {
      this.lineDash = this.lineTypes[this.lineType] || [];
    }

    const homodimerOffset = (this.homodimer - 1) * 6;
    for (let i = 0; i < this.homodimer; i += 1) {
      const homodimerShift = (this.homodimer - i - 1) * 6;
      if (this.activity) {
        this.drawActiveBorder(homodimerShift, homodimerOffset);
      }
      this.drawElementPolygon(homodimerShift, homodimerOffset);
    }
    this.drawOverlays();

    this.modifications.forEach(modification => {
      if (modification.state === null) {
        return;
      }

      const shapes = this.bioShapes[modification.sboTerm];
      if (!shapes) {
        return;
      }
      this.drawModification(modification, shapes);
    });
  }

  drawModification(modification: Modification, shapes: Array<Shape>): void {
    shapes.forEach(shape => {
      const modificationPolygon = getShapePolygon({
        shape,
        x: modification.x,
        y: modification.y,
        width: modification.width,
        height: modification.height,
        pointToProjection: this.pointToProjection,
        mirror: modification.direction && modification.direction === 'RIGHT',
      });
      modificationPolygon.set('type', MAP_ELEMENT_TYPES.MODIFICATION);
      const modificationStrokeStyle = getStroke({ color: rgbToHex(modification.borderColor) });
      const modificationStyle = new Style({
        geometry: modificationPolygon,
        stroke: modificationStrokeStyle,
        fill: getFill({ color: rgbToHex(modification.fillColor) }),
        zIndex: modification.z,
      });
      modificationPolygon.set('strokeStyle', modificationStrokeStyle);
      this.polygons.push(modificationPolygon);
      this.styles.push(modificationStyle);
    });

    const modificationText = modification.stateAbbreviation
      ? modification.stateAbbreviation
      : modification.name;
    if (modificationText) {
      const modificationTextCoords = getTextCoords({
        x: modification.nameX,
        y: modification.nameY,
        width: modification.nameWidth,
        height: modification.nameHeight,
        fontSize: modification.fontSize,
        verticalAlign: modification.nameVerticalAlign,
        horizontalAlign: modification.nameHorizontalAlign,
        pointToProjection: this.pointToProjection,
      });
      const modificationTextPolygon = new Polygon([
        [modificationTextCoords, modificationTextCoords],
      ]);
      modificationTextPolygon.set('type', MAP_ELEMENT_TYPES.TEXT);
      const modificationTextStyle = getTextStyle({
        text: modificationText,
        fontSize: modification.fontSize,
        color: rgbToHex(BLACK_COLOR),
        zIndex: modification.z,
        horizontalAlign: modification.nameHorizontalAlign,
      });
      modificationTextStyle.setGeometry(modificationTextPolygon);
      this.styles.push(modificationTextStyle);
      this.polygons.push(modificationTextPolygon);
    }
  }

  drawActiveBorder(homodimerShift: number, homodimerOffset: number): void {
    this.shapes.forEach(shape => {
      const activityBorderPolygon = getShapePolygon({
        shape,
        x: this.x + homodimerShift - 5,
        y: this.y + homodimerShift - 5,
        width: this.width - homodimerOffset + 10,
        height: this.height - homodimerOffset + 10,
        pointToProjection: this.pointToProjection,
      });
      activityBorderPolygon.set('type', MAP_ELEMENT_TYPES.ACTIVITY_BORDER);
      const activityBorderStyle = getStyle({
        geometry: activityBorderPolygon,
        fillColor: TRANSPARENT_COLOR,
        lineDash: [3, 5],
        zIndex: this.zIndex,
      });
      activityBorderPolygon.set(
        'strokeStyle',
        getStroke({
          lineDash: [3, 5],
        }),
      );
      this.polygons.push(activityBorderPolygon);
      this.styles.push(activityBorderStyle);
    });
  }

  drawElementPolygon(homodimerShift: number, homodimerOffset: number): void {
    this.shapes.forEach(shape => {
      const elementPolygon = getShapePolygon({
        shape,
        x: this.x + homodimerShift,
        y: this.y + homodimerShift,
        width: this.width - homodimerOffset,
        height: this.height - homodimerOffset,
        pointToProjection: this.pointToProjection,
      });
      elementPolygon.set('type', MAP_ELEMENT_TYPES.ENTITY);
      const elementStyle = getStyle({
        geometry: elementPolygon,
        borderColor: this.borderColor,
        fillColor: this.overlaysVisible ? TRANSPARENT_COLOR : this.fillColor,
        lineWidth: this.lineWidth,
        lineDash: this.lineDash,
        zIndex: this.zIndex,
      });
      elementPolygon.set(
        'strokeStyle',
        getStroke({
          color: rgbToHex(this.borderColor),
          width: this.lineWidth,
          lineDash: this.lineDash,
        }),
      );
      this.polygons.push(elementPolygon);
      this.styles.push(elementStyle);
    });
  }

  drawOverlays(): void {
    this.overlays.forEach(entity => {
      if (entity.value === Infinity) {
        return;
      }
      const { xMin, xMax } = getPolygonLatitudeCoordinates({
        width: entity.width,
        nOverlays: this.overlaysOrder.length,
        xMin: entity.x1,
        overlayIndexBasedOnOrder:
          this.overlaysOrder.find(({ id }) => id === entity.overlayId)?.index || ZERO,
      });
      const color = this.getOverlayColor(entity);
      const polygon = new Polygon([
        [
          this.pointToProjection({ x: xMin, y: entity.y1 }),
          this.pointToProjection({ x: xMax, y: entity.y1 }),
          this.pointToProjection({ x: xMax, y: entity.y2 }),
          this.pointToProjection({ x: xMin, y: entity.y2 }),
        ],
      ]);
      polygon.set('type', MAP_ELEMENT_TYPES.OVERLAY);
      const style = getStyle({
        geometry: polygon,
        borderColor: color,
        fillColor: color,
        zIndex: this.zIndex,
      });
      polygon.set(
        'strokeStyle',
        getStroke({
          color,
        }),
      );
      this.polygons.push(polygon);
      this.styles.push(style);
    });
  }
}
