import { twMerge } from 'tailwind-merge';

const variants = {
  primary: {
    link: 'bg-primary-500 text-white-pearl hover:bg-primary-600 active:bg-primary-700 disabled:bg-greyscale-700',
  },
} as const;

type VariantStyle = keyof typeof variants;

type LinkButtonProps = {
  variant?: VariantStyle;
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const LinkButton = ({
  variant = 'primary',
  className = '',
  children,
  ...props
}: LinkButtonProps): JSX.Element => {
  return (
    <a
      className={twMerge(
        'group flex w-fit items-center rounded-e rounded-s px-3 py-2 text-xs font-bold',
        variants[variant].link,
        className,
      )}
      {...props}
    >
      {children}
    </a>
  );
};
