import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { rootSelector } from '@/redux/root/root.selectors';
import { ElementId, ElementIdTabObj, Tab } from '@/types/elements';
import { MultiSearchData } from '@/types/fetchDataState';
import { BioEntity, Chemical } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import { currentSelectedSearchElement } from '../drawer/drawer.selectors';
import { currentModelIdSelector } from '../models/models.selectors';

export const chemicalsSelector = createSelector(rootSelector, state => state.chemicals);

export const chemicalsDataSelector = createSelector(chemicalsSelector, chemicals => chemicals.data);

export const chemicalsDataListSelector = createSelector(chemicalsDataSelector, chemicalsData =>
  chemicalsData.map(c => c.data || []).flat(),
);

export const chemicalsForSelectedSearchElementSelector = createSelector(
  chemicalsSelector,
  currentSelectedSearchElement,
  (chemicalsState, currentSearchElement): MultiSearchData<Chemical[]> | undefined =>
    chemicalsState.data.find(
      ({ searchQueryElement }) => searchQueryElement === currentSearchElement,
    ),
);

export const chemicalsBioEntitiesForSelectedSearchElementSelector = createSelector(
  chemicalsSelector,
  currentSelectedSearchElement,
  (chemicalsState, currentSearchElement): BioEntity[] => {
    return (chemicalsState?.data || [])
      .filter(({ searchQueryElement }) =>
        currentSearchElement ? searchQueryElement === currentSearchElement : true,
      )
      .map(({ data }) => data || [])
      .flat()
      .map(({ targets }) => targets.map(({ targetElements }) => targetElements))
      .flat()
      .flat();
  },
);

export const searchedChemicalsBioEntitesOfCurrentMapSelector = createSelector(
  chemicalsBioEntitiesForSelectedSearchElementSelector,
  currentModelIdSelector,
  (chemicalsBioEntities, currentModelId): BioEntity[] => {
    return (chemicalsBioEntities || []).filter(bioEntity => bioEntity.model === currentModelId);
  },
);

export const allChemicalsBioEntitesOfCurrentMapSelector = createSelector(
  chemicalsSelector,
  currentModelIdSelector,
  (chemicalsState, currentModelId): BioEntity[] => {
    return (chemicalsState?.data || [])
      .map(({ data }) => data || [])
      .flat()
      .map(({ targets }) => targets.map(({ targetElements }) => targetElements))
      .flat()
      .flat()
      .filter(bioEntity => bioEntity.model === currentModelId);
  },
);

export const allChemicalsIdTabSelectorOfCurrentMap = createSelector(
  chemicalsSelector,
  currentModelIdSelector,
  (chemicalsState, currentModelId): ElementIdTabObj => {
    if (!chemicalsState) {
      return {};
    }

    return Object.fromEntries(
      (chemicalsState?.data || [])
        .map(({ data, searchQueryElement }): [typeof data, string] => [data, searchQueryElement])
        .map(([data, tab]) =>
          (data || []).map(({ targets }): [ElementId, Tab][] =>
            targets
              .map(({ targetElements }) => targetElements)
              .flat()
              .flat()
              .filter(bioEntity => bioEntity.model === currentModelId)
              .map(bioEntity => [bioEntity.id, tab]),
          ),
        )
        .flat()
        .flat(),
    );
  },
);

export const allChemicalsBioEntitesOfAllMapsSelector = createSelector(
  chemicalsSelector,
  (chemicalsState): BioEntity[] => {
    return (chemicalsState?.data || [])
      .map(({ data }) => data || [])
      .flat()
      .map(({ targets }) => targets.map(({ targetElements }) => targetElements))
      .flat()
      .flat();
  },
);

export const loadingChemicalsStatusSelector = createSelector(
  chemicalsForSelectedSearchElementSelector,
  state => state?.loading,
);

export const numberOfChemicalsSelector = createSelector(
  chemicalsForSelectedSearchElementSelector,
  state => {
    if (!state || !state?.data) {
      return SIZE_OF_EMPTY_ARRAY;
    }

    return state.data.length && state.data.map(e => e.targets.length)?.reduce((a, b) => a + b);
  },
);
