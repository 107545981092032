const DEFAULT_VALID_SIZE = 0;

export const getFontSizeToFit = (
  ctx: CanvasRenderingContext2D,
  text: string,
  fontFace: string,
  maxWidth: number,
): number => {
  ctx.font = `1px ${fontFace}`;
  return maxWidth / ctx.measureText(text).width || DEFAULT_VALID_SIZE;
};
