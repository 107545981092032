import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { Button } from '@/shared/Button';
import { Input } from '@/shared/Input';
import React from 'react';

import { addComment } from '@/redux/comment/thunks/addComment';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { lastRightClickSelector } from '@/redux/models/models.selectors';
import { closeModal } from '@/redux/modal/modal.slice';
import { getComments } from '@/redux/comment/thunks/getComments';
import { showToast } from '@/utils/showToast';

export const AddCommentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const lastClick = useAppSelector(lastRightClickSelector);

  const [data, setData] = React.useState({
    email: '',
    content: '',
    modelId: lastClick.modelId,
    position: lastClick.position,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await dispatch(addComment(data));
    dispatch(closeModal());
    dispatch(getComments());
    showToast({
      type: 'success',
      message:
        'Thank you for your feedback, your comment has been placed on the map. To see all comments, use the “show comments” button in the upper right',
    });
  };

  return (
    <div className="w-[400px] border border-t-[#E1E0E6] bg-white p-[24px]">
      <form onSubmit={handleSubmit}>
        <label className="mb-5 block text-sm font-semibold" htmlFor="email">
          Email (visible only to moderators):
          <Input
            type="text"
            name="email"
            id="email"
            placeholder="Your email here..."
            value={data.email}
            onChange={handleChange}
            className="mt-2.5 text-sm font-medium text-font-400"
          />
        </label>
        <label className="text-sm font-semibold" htmlFor="content">
          Content:
          <Input
            type="textarea"
            name="content"
            id="content"
            placeholder="Message here..."
            value={data.content}
            onChange={handleChange}
            className="mt-2.5 text-sm font-medium text-font-400"
          />
        </label>
        <Button type="submit" className="w-full justify-center text-base font-medium">
          Submit
        </Button>
      </form>
    </div>
  );
};
