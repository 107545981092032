import { ZERO } from '@/constants/common';
import { publicPluginsListSelector } from '@/redux/plugins/plugins.selectors';
import { Button } from '@/shared/Button';
import { MinervaPlugin } from '@/types/models';
import { useSelect } from 'downshift';
import { useSelector } from 'react-redux';
import { LoadPluginElement } from './LoadPluginElement';

export const PluginOpenButton = (): JSX.Element | null => {
  const publicPlugins = useSelector(publicPluginsListSelector);

  const { isOpen, getToggleButtonProps, getMenuProps } = useSelect({
    items: publicPlugins,
  });

  return (
    <div className="relative" data-testid="open-new-plugin-list">
      {publicPlugins.length === ZERO ? null : (
        <>
          <Button icon="plus" isIcon isFrontIcon {...getToggleButtonProps()}>
            Open new plugin
          </Button>
          <ul
            className={`absolute -left-1/2 z-10 max-h-80 w-48 translate-x-1/4 overflow-x-hidden overflow-y-scroll rounded-sm border bg-white p-0 ps-0 ${
              !isOpen && 'hidden'
            }`}
            {...getMenuProps()}
          >
            {publicPlugins.map((plugin: MinervaPlugin) => (
              <LoadPluginElement key={plugin.hash} plugin={plugin} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
