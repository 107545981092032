import { QueryData, QueryDataRouterParams } from '@/types/query';

export const parseQueryToTypes = (query: QueryDataRouterParams): QueryData => ({
  id: query.id,
  searchValue: query.searchValue?.split(';'),
  perfectMatch: query?.perfectMatch === 'true' || false,
  modelId: Number(query.modelId) || undefined,
  backgroundId: Number(query.backgroundId) || undefined,
  initialPosition: {
    x: Number(query.x) || undefined,
    y: Number(query.y) || undefined,
    z: Number(query.z) || undefined,
  },
  overlaysId: query.overlaysId?.split(',').map(Number),
  pluginsId: query.pluginsId?.split(',').map(String),
  oauthLogin: query.oauthLogin,
});
