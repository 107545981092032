/* prettier-ignore */
export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, arr: T[]) => string,
): { [key: string]: T[] } =>
    array.reduce(
      (acc, value, index, arr) => {
        (acc[predicate(value, index, arr)] ||= []).push(value);
        return acc;
      },
    {} as { [key: string]: T[] },
    );
