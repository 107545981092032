import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@/shared/Accordion';
import { PinItem } from '../PinsList/PinsList.types';
import {
  getEntityDescriptions,
  getEntityNames,
  getEntitySynonyms,
} from './AccordionsDetails.utils';

interface AccordionsDetailsProps {
  pinsList: PinItem[];
}

export const AccordionsDetails = ({ pinsList }: AccordionsDetailsProps): JSX.Element => {
  return (
    <div data-testid="accordions-details" className="mb-4">
      <div className="px-6 py-4">
        <span className="font-semibold">Name:</span> {getEntityNames(pinsList)}
      </div>
      <Accordion allowZeroExpanded className="px-6">
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Details</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div data-testid="details-description" className="mb-5">
              <span className="font-semibold">Description:</span> {getEntityDescriptions(pinsList)}
            </div>
            <div>
              <span className="font-semibold">Synonyms:</span> {getEntitySynonyms(pinsList)}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
