import { ZERO } from '@/constants/common';
import { overlaysOpenedSelector } from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GroupedOverlayAxes } from './GroupedOverlayAxes';
import { OverlayAxis } from './OverlayAxis';
import { getAxesSortedByValue } from './utils/getAxesSortedByValue';
import { getUniqueAxes } from './utils/getUniqueAxes';
import { useOverlaysAxes } from './utils/useOverlaysAxes';

export interface Props {
  isShowGroupedOverlays?: boolean;
  isShowOverlayBioEntityName?: boolean;
}

export const OverlayData = ({
  isShowGroupedOverlays = false,
  isShowOverlayBioEntityName = false,
}: Props = {}): JSX.Element | null => {
  const axes = useOverlaysAxes({ isShowOverlayBioEntityName });
  const uniqueAxes = getUniqueAxes(axes);
  const openedOverlays = useSelector(overlaysOpenedSelector);
  const sortedAxes = useMemo(() => getAxesSortedByValue(uniqueAxes), [uniqueAxes]);

  if (uniqueAxes.length === ZERO) {
    return null;
  }

  const overlaysAxesContent = (
    <div className="flex flex-col gap-2 rounded-lg border border-divide p-4">
      {sortedAxes.map(axis => (
        <OverlayAxis key={axis.title} axis={axis} />
      ))}
    </div>
  );

  const groupedOverlayAxesContent = (
    <>
      {openedOverlays.map(overlay => (
        <GroupedOverlayAxes key={overlay.idObject} overlay={overlay} axes={uniqueAxes} />
      ))}
    </>
  );

  return (
    <div className="flex flex-col gap-2">
      <h3 className="mb-2 font-semibold">Overlay data:</h3>
      {isShowGroupedOverlays ? groupedOverlayAxesContent : overlaysAxesContent}
    </div>
  );
};
