import { entityNumberDataSelector } from '@/redux/entityNumber/entityNumber.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { assertNever } from '@/utils/assertNever';
import { AccordionsDetails } from '../AccordionsDetails/AccordionsDetails.component';
import { PinItem, PinTypeWithNone } from './PinsList.types';
import { PinsListItem } from './PinsListItem';
import { getTargetElementsUniqueSorted } from './utils/getTargetElementsUniqueSorted';

interface PinsListProps {
  pinsList: PinItem[];
  type: PinTypeWithNone;
}

export const PinsList = ({ pinsList, type }: PinsListProps): JSX.Element => {
  const entityNumber = useAppSelector(entityNumberDataSelector);

  switch (type) {
    case 'drugs':
    case 'chemicals': {
      const targetElements = getTargetElementsUniqueSorted(pinsList, { entityNumber });

      return (
        <div className="h-[calc(100%-214px)] max-h-[calc(100%-214px)] overflow-auto">
          <AccordionsDetails pinsList={pinsList} />
          <ul className="px-6 py-2" data-testid="pins-list">
            {targetElements.map(({ target, element }) => (
              <PinsListItem
                key={element.elementId}
                name={target.name}
                type={type}
                pin={target}
                element={element}
                number={entityNumber[element.elementId]}
              />
            ))}
          </ul>
        </div>
      );
    }
    case 'bioEntity':
      return <div />;
    case 'comment':
      return <div />;
    case 'none':
      return <div />;
    default:
      return assertNever(type);
  }
};
