import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { CommentWithPinType } from '@/types/comment';
import { getPinFeature } from '@/components/Map/MapViewer/utils/config/pinsLayer/getPinFeature';
import { PinType } from '@/types/pin';
import { PINS_COLORS, TEXT_COLOR } from '@/constants/canvas';
import { getPinStyle } from '@/components/Map/MapViewer/utils/config/pinsLayer/getPinStyle';

export const getCommentFeature = (
  comment: CommentWithPinType,
  {
    pointToProjection,
    type,
    value,
  }: {
    pointToProjection: UsePointToProjectionResult;
    type: PinType;
    value: number;
  },
): Feature => {
  const color = PINS_COLORS[type];

  const textColor = TEXT_COLOR;

  const feature = getPinFeature(
    {
      x: comment.coord.x,
      height: 0,
      id: comment.id,
      width: 0,
      y: comment.coord.y,
    },
    pointToProjection,
    type,
  );
  const style = getPinStyle({
    color,
    value,
    textColor,
  });

  feature.setStyle(style);
  return feature;
};

export const getCommentsFeatures = (
  comments: CommentWithPinType[],
  {
    pointToProjection,
  }: {
    pointToProjection: UsePointToProjectionResult;
  },
): Feature[] => {
  return comments.map((comment, index) =>
    getCommentFeature(comment, { pointToProjection, type: comment.pinType, value: index }),
  );
};
