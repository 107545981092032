import { SearchParams } from './triggerSearch.types';
import { searchByQuery } from './searchByQuery';
import { searchByCoordinates } from './searchByCoordinates';
import {
  ERROR_INVALID_COORDINATES,
  ERROR_INVALID_MODEL_ID_TYPE,
  ERROR_INVALID_QUERY_TYPE,
} from '../../errorMessages';

export async function triggerSearch(params: SearchParams): Promise<void> {
  if ('query' in params) {
    if (typeof params.query !== 'string') {
      throw new Error(ERROR_INVALID_QUERY_TYPE);
    }
    searchByQuery(params.query, params.perfectSearch, params.fitBounds);
  } else {
    const areCoordinatesInvalidType =
      typeof params.coordinates !== 'object' || params.coordinates === null;
    const areCoordinatesMissingKeys = !('x' in params.coordinates) || !('y' in params.coordinates);
    const areCoordinatesValuesInvalid =
      typeof params.coordinates.x !== 'number' || typeof params.coordinates.y !== 'number';

    if (areCoordinatesInvalidType || areCoordinatesMissingKeys || areCoordinatesValuesInvalid) {
      throw new Error(ERROR_INVALID_COORDINATES);
    }

    if (typeof params.modelId !== 'number') {
      throw new Error(ERROR_INVALID_MODEL_ID_TYPE);
    }

    searchByCoordinates(params.coordinates, params.modelId, false, params.fitBounds, params.zoom);
  }
}
