import { OVERVIEW_IMAGE_ERRORS } from '@/constants/errors';
import { openOverviewImagesModalById } from '@/redux/modal/modal.slice';
import {
  projectDefaultOverviewImageIdSelector,
  projectOverviewImagesSelector,
} from '@/redux/project/project.selectors';
import { store } from '@/redux/store';

export const showOverviewImageModal = (imageId?: number): void => {
  const { dispatch, getState } = store;
  const overviewImages = projectOverviewImagesSelector(getState());
  const defaultImageId = projectDefaultOverviewImageIdSelector(getState());
  const selectedImageId = imageId || defaultImageId;

  const foundOverviewImage = overviewImages.find(o => o.id === selectedImageId);
  const isImageIdValid = Boolean(foundOverviewImage);

  if (!isImageIdValid) {
    throw new Error(OVERVIEW_IMAGE_ERRORS.IMAGE_ID_IS_INVALID);
  }

  dispatch(openOverviewImagesModalById(selectedImageId));
};
