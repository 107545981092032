import { DrawerHeading } from '@/shared/DrawerHeading';
import { LoadPluginFromUrl } from './LoadPluginFromUrl';
import { PublicPlugins } from './PublicPlugins';
import { PrivateActivePlugins } from './PrivateActivePlugins';
import { PublicActivePlugins } from './PublicActivePlugins';

export const AvailablePluginsDrawer = (): JSX.Element => {
  return (
    <div className="h-full max-h-full" data-testid="available-plugins-drawer">
      <DrawerHeading title="Available plugins" />
      <div className="flex h-[calc(100%-93px)] max-h-[calc(100%-93px)] flex-col gap-6 overflow-y-auto p-6">
        <LoadPluginFromUrl />
        <PrivateActivePlugins />
        <PublicActivePlugins />
        <PublicPlugins />
      </div>
    </div>
  );
};
