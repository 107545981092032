import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { closeLegend } from '@/redux/legend/legend.slice';
import { IconButton } from '@/shared/IconButton';

export const CLOSE_BUTTON_ROLE = 'close-legend-button';

export const LegendHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleCloseLegend = (): void => {
    dispatch(closeLegend());
  };

  return (
    <div
      data-testid="legend-header"
      className="flex items-center justify-between border-b border-b-divide px-6"
    >
      <div className="py-8 text-xl">
        <span className="font-semibold">Legend</span>
      </div>
      <IconButton
        className="bg-white-pearl"
        classNameIcon="fill-font-500"
        icon="close"
        role={CLOSE_BUTTON_ROLE}
        onClick={handleCloseLegend}
      />
    </div>
  );
};
