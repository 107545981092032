import { OPTIONS } from '@/constants/map';
import { resultDrawerOpen } from '@/redux/drawer/drawer.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { currentModelIdSelector, vectorRenderingSelector } from '@/redux/models/models.selectors';
import { MapInstance } from '@/types/map';
import { unByKey } from 'ol/Observable';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { allCommentsSelectorOfCurrentMap } from '@/redux/comment/comment.selectors';
import { onMapLeftClick } from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/mouseLeftClick/onMapLeftClick';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { Coordinate } from 'ol/coordinate';
import { Pixel } from 'ol/pixel';
import { onMapRightClick } from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/mouseRightClick/onMapRightClick';
import { modelElementsForCurrentModelSelector } from '@/redux/modelElements/modelElements.selector';
import { newReactionsForCurrentModelSelector } from '@/redux/newReactions/newReactions.selectors';

interface UseOlMapVectorListenersInput {
  mapInstance: MapInstance;
}

export const useOlMapVectorListeners = ({ mapInstance }: UseOlMapVectorListenersInput): void => {
  const mapSize = useSelector(mapDataSizeSelector);
  const modelId = useSelector(currentModelIdSelector);
  const isResultDrawerOpen = useSelector(resultDrawerOpen);
  const modelElementsForCurrentModel = useSelector(modelElementsForCurrentModelSelector);
  const newReactionsForCurrentModel = useSelector(newReactionsForCurrentModelSelector);
  const dispatch = useAppDispatch();
  const coordinate = useRef<Coordinate>([]);
  const pixel = useRef<Pixel>([]);

  const comments = useSelector(allCommentsSelectorOfCurrentMap);
  const vectorRendering = useAppSelector(vectorRenderingSelector);

  const handleMapLeftClick = useDebouncedCallback(
    onMapLeftClick(
      mapSize,
      modelId,
      dispatch,
      isResultDrawerOpen,
      comments,
      modelElementsForCurrentModel || [],
      newReactionsForCurrentModel,
    ),
    OPTIONS.clickPersistTime,
    { leading: false },
  );

  const handleRightClick = useDebouncedCallback(
    onMapRightClick(
      mapSize,
      modelId,
      dispatch,
      modelElementsForCurrentModel || [],
      newReactionsForCurrentModel,
    ),
    OPTIONS.clickPersistTime,
    {
      leading: false,
    },
  );

  useEffect(() => {
    if (!mapInstance || !vectorRendering) {
      return;
    }

    const key = mapInstance.on('singleclick', event =>
      handleMapLeftClick({ coordinate: event.coordinate, pixel: event.pixel }, mapInstance),
    );

    // eslint-disable-next-line consistent-return
    return () => unByKey(key);
  }, [mapInstance, handleMapLeftClick, vectorRendering]);

  useEffect(() => {
    if (!mapInstance || !vectorRendering) {
      return;
    }

    const rightClickEvent = (e: MouseEvent): Promise<void> | undefined => {
      e.preventDefault();

      coordinate.current = mapInstance.getEventCoordinate(e);
      pixel.current = mapInstance.getEventPixel(e);

      return handleRightClick(
        { coordinate: coordinate.current, pixel: pixel.current },
        mapInstance,
      );
    };

    mapInstance.getViewport().addEventListener('contextmenu', rightClickEvent);

    // eslint-disable-next-line consistent-return
    return () => mapInstance.getViewport().removeEventListener('contextmenu', rightClickEvent);
  }, [mapInstance, handleRightClick, vectorRendering]);
};
