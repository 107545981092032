import { Reference } from '@/types/models';
import { twMerge } from 'tailwind-merge';

type AnnotationItemProps = Pick<Reference, 'link' | 'type' | 'resource'>;

export const AnnotationItem = ({ link, type, resource }: AnnotationItemProps): JSX.Element => (
  <a
    className={twMerge('pl-3 text-sm font-normal', link ? 'text-blue-800 underline' : '')}
    href={link?.toString()}
    target="_blank"
  >
    <div className="flex justify-between">
      <span className="w-full font-semibold">
        {type} ({resource})
      </span>
    </div>
  </a>
);
