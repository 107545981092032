/* eslint-disable no-magic-numbers */

import { ZOOM_FACTOR } from '@/constants/common';
import { ZOOM_RESCALING_FACTOR } from '@/constants/map';

export const getMaxClickDistance = (
  maxZoom: number,
  zoom: number,
  searchDistance: string,
): number => {
  const distance = parseFloat(searchDistance);

  if (typeof distance !== 'number' || Number.isNaN(distance)) {
    throw new Error('Invalid search distance. Please provide a valid number.');
  }

  const zoomDiff = (ZOOM_RESCALING_FACTOR * maxZoom - zoom) / ZOOM_RESCALING_FACTOR;
  const maxDistance = distance * ZOOM_FACTOR ** zoomDiff;

  return maxDistance;
};
