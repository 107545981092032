/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLoadPlugin } from '@/components/Map/Drawer/AvailablePluginsDrawer/LoadPlugin/hooks/useLoadPlugin';
import { FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { setCurrentDrawerPluginHash } from '@/redux/plugins/plugins.slice';
import { MinervaPlugin } from '@/types/models';

interface LoadPluginButtonProps {
  plugin: MinervaPlugin;
}

export const LoadPluginElement = ({ plugin }: LoadPluginButtonProps): JSX.Element => {
  const { hash, name, urls } = plugin;
  const dispatch = useAppDispatch();

  const { loadPlugin } = useLoadPlugin({
    hash,
    pluginUrl: urls[FIRST_ARRAY_ELEMENT],
    onPluginLoaded: () => {
      if (!plugin.withoutPanel) {
        dispatch(setCurrentDrawerPluginHash(hash));
      }
    },
  });

  return (
    <li
      key={hash}
      className="flex cursor-pointer flex-col border-t px-4 py-2 shadow-sm"
      onClick={loadPlugin}
    >
      {name}
    </li>
  );
};
