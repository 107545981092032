import { LATLNG_FALLBACK } from '@/constants/map';
import { MapSize } from '@/redux/map/map.types';
import { Point } from '@/types/map';
import { pointToLngLat } from '@/utils/map/pointToLatLng';
import { fromLonLat } from 'ol/proj';

export const pointToProjection = (point: Point, mapSize: MapSize): number[] => {
  const [lng, lat] = pointToLngLat(point, mapSize);
  const projection = fromLonLat([lng, lat]);
  const projectionRounded = projection.map(v => Math.round(v));
  const isValid = !projectionRounded.some(v => Number.isNaN(v));

  return isValid ? projectionRounded : LATLNG_FALLBACK;
};
