import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { Map, MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Comment } from '@/types/models';
import { updateLastClick } from '@/redux/map/map.slice';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { getSearchResults } from './getSearchResults';
import { handleDataReset } from './handleDataReset';
import { handleFeaturesClick } from './handleFeaturesClick';
import { handleSearchResultAction } from './handleSearchResultAction';

/* prettier-ignore */
export const onMapSingleClick =
  (mapSize: MapSize, modelId: number, dispatch: AppDispatch, searchDistance: string | undefined, maxZoom: number, zoom: number, isResultDrawerOpen: boolean,
    comments: Comment[],     shouldConsiderZoomLevel:boolean,
    considerZoomLevel?:number,
  ) =>
    async ({ coordinate, pixel }: Pick<MapBrowserEvent<UIEvent>, 'coordinate' | 'pixel'>, mapInstance: Map): Promise<void> => {
      const [lng, lat] = toLonLat(coordinate);
      const point = latLngToPoint([lat, lng], mapSize);

      dispatch(updateLastClick({coordinates:point, modelId}));

      const featuresAtPixel: FeatureLike[] = [];
      mapInstance.forEachFeatureAtPixel(pixel, (feature) => featuresAtPixel.push(feature));

      const { shouldBlockCoordSearch } = handleFeaturesClick(featuresAtPixel, dispatch, comments);

      if (shouldBlockCoordSearch) {
        return;
      }

      // side-effect below is to prevent complications with data update - old data may conflict with new data
      // so we need to reset all the data before updating
      dispatch(handleDataReset);

      const {searchResults} = await getSearchResults({ coordinate, mapSize, modelId,     shouldConsiderZoomLevel,
        considerZoomLevel,
      });
      if (!searchResults || searchResults.length === SIZE_OF_EMPTY_ARRAY) {
        return;
      }
      handleSearchResultAction({ searchResults, dispatch, point, searchDistance, maxZoom, zoom, isResultDrawerOpen });
    };
