import { MarkerWithPosition } from '@/types/models';
import { addAlphaToHexString } from '@/utils/convert/addAlphaToHexString';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { getPinFeature } from './getPinFeature';
import { getPinStyle } from './getPinStyle';

export const getMarkerSingleFeature = (
  marker: MarkerWithPosition,
  {
    pointToProjection,
  }: {
    pointToProjection: UsePointToProjectionResult;
  },
): Feature => {
  const feature = getPinFeature(marker, pointToProjection, 'bioEntity');
  const style = getPinStyle({
    color: addAlphaToHexString(marker.color, marker.opacity),
    value: marker.number,
  });

  feature.setStyle(style);
  return feature;
};
