import { OverviewImageLinkConfig, OverviewImageSize } from '../OverviewImageModal.types';
import { useOverviewImageLinkConfigs } from './useOverviewImageLinkElements';
import { useOverviewImageSize } from './useOverviewImageSize';
import { useOverviewImageUrl } from './useOverviewImageUrl';

interface UseOverviewImageArgs {
  containerRect?: DOMRect;
}

interface UseOverviewImageResults {
  imageUrl: string;
  size: OverviewImageSize;
  linkConfigs: OverviewImageLinkConfig[];
}

export const useOverviewImage = ({
  containerRect,
}: UseOverviewImageArgs): UseOverviewImageResults => {
  const imageUrl = useOverviewImageUrl();
  const size = useOverviewImageSize({ containerRect });
  const linkConfigs = useOverviewImageLinkConfigs({ sizeFactor: size.sizeFactor });

  return {
    size,
    imageUrl,
    linkConfigs,
  };
};
