/* eslint-disable no-magic-numbers */
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import Style from 'ol/style/Style';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { MapInstance } from '@/types/map';
import getTextCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextCoords';
import getTextStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/getTextStyle';
import { Color } from '@/types/models';
import { TEXT_CUTOFF_SCALE } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';

export interface TextProps {
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  text: string;
  fontSize: number;
  fontColor: Color;
  verticalAlign: VerticalAlign;
  horizontalAlign: HorizontalAlign;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
}

export default class Text {
  text: string;

  fontSize: number;

  style: Style;

  point: Point;

  feature: Feature<Point>;

  constructor({
    x,
    y,
    width,
    height,
    zIndex,
    text,
    fontSize,
    fontColor,
    verticalAlign,
    horizontalAlign,
    pointToProjection,
    mapInstance,
  }: TextProps) {
    this.text = text;
    this.fontSize = fontSize;

    const textCoords = getTextCoords({
      x,
      y,
      height,
      width,
      fontSize,
      verticalAlign,
      horizontalAlign,
      pointToProjection,
    });
    const textStyle = getTextStyle({
      text,
      fontSize,
      color: rgbToHex(fontColor),
      zIndex,
      horizontalAlign,
    });
    this.point = new Point(textCoords);
    this.style = textStyle;
    this.style.setGeometry(this.point);

    this.feature = new Feature({
      geometry: this.point,
      getTextScale: (resolution: number): number => {
        const maxZoom = mapInstance?.getView().get('originalMaxZoom');
        if (maxZoom) {
          const minResolution = mapInstance?.getView().getResolutionForZoom(maxZoom);
          if (minResolution) {
            return Math.round((minResolution / resolution) * 100) / 100;
          }
        }
        return 1;
      },
    });

    this.feature.setStyle(this.getStyle.bind(this));
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    const getTextScale = feature.get('getTextScale');
    let textScale = 1;
    if (getTextScale instanceof Function) {
      textScale = getTextScale(resolution);
    }
    if (textScale < TEXT_CUTOFF_SCALE) {
      return undefined;
    }
    this.style.getText()?.setScale(textScale);
    return this.style;
  }
}
