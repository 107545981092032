import { setCurrentSelectedBioEntityId } from '@/redux/contextMenu/contextMenu.slice';
import { AppDispatch } from '@/redux/store';
import { ModelElement } from '@/types/models';
import { setBioEntityContents } from '@/redux/bioEntity/bioEntity.slice';
import { addNumbersToEntityNumberData } from '@/redux/entityNumber/entityNumber.slice';
import { mapModelElementToBioEntity } from '@/utils/bioEntity/mapModelElementToBioEntity';

/* prettier-ignore */
export const rightClickHandleAlias =
  (dispatch: AppDispatch) =>
    async (id: number, modelElement: ModelElement): Promise<void> => {
      const bioEntity = mapModelElementToBioEntity(modelElement);
      dispatch(setBioEntityContents({ bioEntity, perfect: true }));
      dispatch(addNumbersToEntityNumberData([bioEntity.elementId]));
      dispatch(setCurrentSelectedBioEntityId(id));
    };
