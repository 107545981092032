/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ZERO } from '@/constants/common';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { allActivePluginsWithPanelSelector } from '@/redux/plugins/plugins.selectors';
import { useMemo } from 'react';
import { PluginSingleTab } from './PluginSingleTab';

export const PluginsTabs = (): JSX.Element => {
  const allActivePlugins = useAppSelector(allActivePluginsWithPanelSelector);
  const isPluginsEmpty = allActivePlugins.length === ZERO;

  const pluginsTabs = allActivePlugins.map(plugin => (
    <PluginSingleTab plugin={plugin} key={plugin.hash} />
  ));

  const pluginsEmptyInfo = useMemo(
    () => (
      <div className="flex h-10 items-center px-4 text-[#979797]">
        You don&apos;t have any opened plugin yet
      </div>
    ),
    [],
  );

  return (
    <div
      className="flex w-full flex-row flex-nowrap justify-start overflow-x-auto overflow-y-clip border-b border-b-divide bg-white-pearl text-xs"
      data-testid="drawer-plugins-tab"
    >
      {isPluginsEmpty ? pluginsEmptyInfo : pluginsTabs}
    </div>
  );
};
