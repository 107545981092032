import { ZERO } from '@/constants/common';
import { downloadFileFromBlob } from '@/redux/export/export.utils';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modelsNameMapSelector } from '@/redux/models/models.selectors';
import {
  filteredSizeSelector,
  publicationsListDataSelector,
  searchValueSelector,
} from '@/redux/publications/publications.selectors';
import { Publication } from '@/types/models';
import { StandarizedPublication } from '@/types/publications';
import { useState } from 'react';
import { getBasePublications } from './getBasePublications';
import { getStandarizedPublications } from './getStandarizedPublications';
import { mapStandarizedPublicationsToCSVString } from './mapStandarizedPublicationsToCSVString';

export type DownloadPublicationsAsCSVFile = () => Promise<void>;

interface UseDownloadPublicationsAsCSVFileResult {
  downloadPublicationsAsCSVFile: DownloadPublicationsAsCSVFile;
  isLoading: boolean;
}

const CSV_FILE_NAME = 'publications.csv';

export const useDownloadPublicationsAsCSVFile = (): UseDownloadPublicationsAsCSVFileResult => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modelNameIdMap = useAppSelector(modelsNameMapSelector);
  const numberOfPublications = useAppSelector(filteredSizeSelector);
  const searchValue = useAppSelector(searchValueSelector);
  const searchedPublicationsList = useAppSelector(publicationsListDataSelector);

  const getAllBasePublications = async (): Promise<Publication[]> =>
    getBasePublications({
      length: numberOfPublications || ZERO,
    });

  const getPublicationsAsList = async (): Promise<StandarizedPublication[]> => {
    const publications =
      searchValue && searchedPublicationsList
        ? searchedPublicationsList
        : await getAllBasePublications();

    const standarizedPublications = await getStandarizedPublications({
      publications,
      modelNameIdMap,
    });

    return standarizedPublications;
  };

  const downloadPublicationsAsCSVFile = async (): Promise<void> => {
    setIsLoading(true);
    const data = await getPublicationsAsList();
    const dataString = mapStandarizedPublicationsToCSVString(data);

    downloadFileFromBlob(dataString, CSV_FILE_NAME);
    setIsLoading(false);
  };

  return {
    downloadPublicationsAsCSVFile,
    isLoading,
  };
};
