import type { OverlayBioEntityRender } from '@/types/OLrendering';
import type { GroupedSubmapsLinksRectangles } from './useBioEntitiesWithSubmapLinks';

export const groupSubmapLinksRectanglesById = (
  data: OverlayBioEntityRender[],
): GroupedSubmapsLinksRectangles => {
  const submapsLinksRectangles = [...data];
  const groupedSubmapsLinksRectanglesById: GroupedSubmapsLinksRectangles = {};

  submapsLinksRectangles.forEach(submapLinkRectangle => {
    const { id, overlayId } = submapLinkRectangle;
    const groupId = `${id}-${overlayId}`;

    if (!groupedSubmapsLinksRectanglesById[groupId]) {
      groupedSubmapsLinksRectanglesById[groupId] = [];
    }

    const matchedSubmapLinkRectangle = groupedSubmapsLinksRectanglesById[groupId].find(element => {
      const hasAllRequiredValueProperties = element.value && submapLinkRectangle.value;
      const isValueEqual =
        hasAllRequiredValueProperties && element.value === submapLinkRectangle.value;

      const hasAllRequiredColorProperties = element.color && submapLinkRectangle.color;
      const isColorEqual =
        hasAllRequiredColorProperties &&
        element.color?.alpha === submapLinkRectangle?.color?.alpha &&
        element.color?.rgb === submapLinkRectangle?.color?.rgb;

      if (isValueEqual || isColorEqual) return true;

      return false;
    });

    if (!matchedSubmapLinkRectangle) {
      groupedSubmapsLinksRectanglesById[groupId].push({
        ...submapLinkRectangle,
        amount: 1,
      });
    } else {
      matchedSubmapLinkRectangle.amount += 1;
    }
  });

  return groupedSubmapsLinksRectanglesById;
};
