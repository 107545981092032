import { RGBColor } from '@/types/colors';
import { lerpRGBColor } from './lerpRGBColor';

const MIN_VAL = -1;
const MAX_VAL = 1;
const MIDDLE_VAL = 0;

type InterpolateColorProps = {
  leftColor: RGBColor;
  middleColor: RGBColor;
  rightColor: RGBColor;
  position: number;
};

/**
 *
 * @param {position} range [-1,1]
 * function interpolates between linear gradient of 3 colors for given position
 * -1 is value for leftColor
 * 0 is value for middleColor
 * 1 is value for rightColor
 */

export const interpolateThreeColors = ({
  leftColor,
  middleColor,
  rightColor,
  position,
}: InterpolateColorProps): RGBColor => {
  const clampedPosition = Math.max(MIN_VAL, Math.min(MAX_VAL, position)); // make sure value is in [-1,1] range

  if (clampedPosition < MIDDLE_VAL) {
    /**
     * -1 .......|... 0
     *          -0.25  - this is position
     *  BUT function interpolates on positive values so position must not be negative value
     * 0 .............1
     *          0.75
     */
    // eslint-disable-next-line no-magic-numbers
    return lerpRGBColor({ leftColor, rightColor: middleColor, position: 1 + clampedPosition });
  }

  return lerpRGBColor({ leftColor: middleColor, rightColor, position: clampedPosition });
};
