import { twMerge } from 'tailwind-merge';

type TabButtonProps = {
  handleChangeTab: () => void;
  active: boolean;
  label: string;
};

export const TabButton = ({ handleChangeTab, active, label }: TabButtonProps): React.ReactNode => (
  <button
    type="button"
    className={twMerge(
      'text-sm font-normal text-[#979797]',
      active &&
        'relative py-2.5 font-semibold leading-6 text-cetacean-blue before:absolute before:inset-x-0 before:top-0 before:block before:h-1 before:rounded-b before:bg-primary-500 before:content-[""]',
    )}
    aria-current={active}
    onClick={handleChangeTab}
  >
    {label}
  </button>
);
