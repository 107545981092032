/* eslint-disable no-magic-numbers */
import { Coordinate } from 'ol/coordinate';
import { Line } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';

export default function getLineSegments(
  line: Line,
  pointToProjection: UsePointToProjectionResult,
): Array<Coordinate> {
  return line.segments
    .map((segment, index) => {
      if (index === 0) {
        return [
          pointToProjection({ x: segment.x1, y: segment.y1 }),
          pointToProjection({ x: segment.x2, y: segment.y2 }),
        ];
      }
      return [pointToProjection({ x: segment.x2, y: segment.y2 })];
    })
    .flat();
}
