/* eslint-disable no-magic-numbers */
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  loadingUserOverlaysSelector,
  userOverlaysDataSelector,
} from '@/redux/overlays/overlays.selectors';
import { updateOverlays } from '@/redux/overlays/overlays.thunks';
import { MapOverlay } from '@/types/models';
import { useEffect, useState } from 'react';
import { moveArrayElement } from '../UserOverlaysWithoutGroup.utils';

type UseUserOverlaysReturn = {
  isPending: boolean;
  userOverlaysList: MapOverlay[];
  moveUserOverlayListItem: (dragIndex: number, hoverIndex: number) => void;
  updateUserOverlaysOrder: () => void;
};

export const useUserOverlays = (): UseUserOverlaysReturn => {
  const dispatch = useAppDispatch();
  const [userOverlaysList, setUserOverlaysList] = useState<MapOverlay[]>([]);
  const userOverlays = useAppSelector(userOverlaysDataSelector);
  const loadingUserOverlays = useAppSelector(loadingUserOverlaysSelector);
  const isPending = loadingUserOverlays === 'pending';

  useEffect(() => {
    if (userOverlays) {
      setUserOverlaysList(userOverlays);
    }
  }, [userOverlays]);

  const moveUserOverlayListItem = (dragIndex: number, hoverIndex: number): void => {
    const updatedUserOverlays = moveArrayElement(userOverlaysList, dragIndex, hoverIndex);
    setUserOverlaysList(updatedUserOverlays);
  };

  const updateUserOverlaysOrder = (): void => {
    const reorderedUserOverlays = [];
    if (!userOverlays) return;

    for (let index = 0; index < userOverlays.length; index += 1) {
      const userOverlay = userOverlays[index];
      const newOrderedUserOverlay = {
        ...userOverlaysList[index],
        order: index + 1,
      };

      if (userOverlay.idObject !== newOrderedUserOverlay.idObject) {
        reorderedUserOverlays.push(newOrderedUserOverlay);
      }
    }

    dispatch(updateOverlays(reorderedUserOverlays));
  };

  return {
    moveUserOverlayListItem,
    updateUserOverlaysOrder,
    isPending,
    userOverlaysList,
  };
};
