import { handleSetBounds } from '@/utils/map/useSetBounds';
import { getVisibleBioEntitiesPolygonCoordinates } from './getVisibleBioEntitiesPolygonCoordinates';

export const searchFitBounds = (zoom?: number): void => {
  const data = getVisibleBioEntitiesPolygonCoordinates();

  if (data) {
    const { polygonCoordinates, maxZoom, mapInstance } = data;

    if (!mapInstance) return;

    const setBoundsZoom = zoom || maxZoom;
    handleSetBounds(mapInstance, setBoundsZoom, polygonCoordinates);
  }
};
