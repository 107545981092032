import { BioEntitiesAccordion } from '@/components/Map/Drawer/SearchDrawerWrapper/GroupedSearchResults/BioEntitiesAccordion';
import { DrugsAccordion } from '@/components/Map/Drawer/SearchDrawerWrapper/GroupedSearchResults/DrugsAccordion';
import { ChemicalsAccordion } from '@/components/Map/Drawer/SearchDrawerWrapper/GroupedSearchResults/ChemicalsAccordion';
import { Accordion } from '@/shared/Accordion';

export const CLOSE_BUTTON_ROLE = 'close-drawer-button';

export const GroupedSearchResults = (): JSX.Element => {
  return (
    <div
      className="flex h-[calc(100%-124px)] max-h-[calc(100%-124px)] flex-col overflow-auto"
      data-testid="grouped-search-results"
    >
      <div className="px-6">
        <Accordion allowZeroExpanded>
          <BioEntitiesAccordion />
          <DrugsAccordion />
          <ChemicalsAccordion />
        </Accordion>
      </div>
    </div>
  );
};
