import { allVisibleBioEntitiesSelector } from '@/redux/bioEntity/bioEntity.selectors';
import { Point } from '@/types/map';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import { isPointValid } from '@/utils/point/isPointValid';
import { Coordinate } from 'ol/coordinate';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const VALID_POLYGON_COORDINATES_LENGTH = 2;

export const useVisibleBioEntitiesPolygonCoordinates = (): Coordinate[] | undefined => {
  const allVisibleBioEntities = useSelector(allVisibleBioEntitiesSelector);
  const pointToProjection = usePointToProjection();

  const polygonPoints = useMemo((): Point[] => {
    const allX = allVisibleBioEntities.map(({ x }) => x);
    const allY = allVisibleBioEntities.map(({ y }) => y);

    const minX = Math.min(...allX);
    const maxX = Math.max(...allX);

    const minY = Math.min(...allY);
    const maxY = Math.max(...allY);

    const points = [
      {
        x: minX,
        y: maxY,
      },
      {
        x: maxX,
        y: minY,
      },
    ];

    return points.filter(isPointValid);
  }, [allVisibleBioEntities]);

  const polygonCoordinates = useMemo(
    () => polygonPoints.map(point => pointToProjection(point)),
    [polygonPoints, pointToProjection],
  );

  if (polygonCoordinates.length !== VALID_POLYGON_COORDINATES_LENGTH) {
    return undefined;
  }

  return polygonCoordinates;
};
