import { BioEntityContent, Publication } from '@/types/models';
import { StandarizedPublication } from '@/types/publications';
import { getUniqueArray } from '@/utils/array/getUniqueArray';
import { fetchElementData } from '../../utils/fetchElementData';

interface Options {
  modelNameIdMap: Record<number, string>;
}

const JOIN_SEPARATOR = ',';

export const mapBasePublicationToStandarized = async (
  publication: Publication,
  options: Options,
): Promise<StandarizedPublication> => {
  const {
    publication: { article },
    elements,
  } = publication;
  const { modelNameIdMap } = options;
  const { title, authors, journal, year, pubmedId } = article;
  const modelNames = elements.map(({ modelId }) => modelNameIdMap[modelId]);
  const elementsData = await Promise.all(elements.map(async ({ id }) => fetchElementData(`${id}`)));
  const elementsIds = elementsData
    .filter((element): element is BioEntityContent => element !== undefined)
    .map(({ bioEntity }) => bioEntity.elementId);

  return {
    pubmedId,
    journal,
    title,
    year: year ? `${year}` : '',
    authors: authors.join(JOIN_SEPARATOR),
    modelNames: getUniqueArray(modelNames).join(JOIN_SEPARATOR),
    elementsIds: elementsIds.join(JOIN_SEPARATOR),
  };
};
