import { Extent, getArea } from 'ol/extent';

export default function findLargestExtent(extents: Extent[]): Extent | null {
  let largestExtent = null;
  let maxArea = 0;

  extents.forEach(extent => {
    const area = getArea(extent);
    if (area > maxArea) {
      maxArea = area;
      largestExtent = extent;
    }
  });

  return largestExtent;
}
