import { Export } from '../ExportCompound';
import { ANNOTATIONS_TYPE } from '../ExportCompound/ExportCompound.constant';

export const Elements = (): React.ReactNode => {
  return (
    <div data-testid="elements-tab">
      <Export>
        <Export.Annotations type={ANNOTATIONS_TYPE.ELEMENTS} />
        <Export.IncludedCompartmentPathways />
        <Export.ExcludedCompartmentPathways />
        <Export.DownloadElements />
      </Export>
    </div>
  );
};
