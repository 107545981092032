import { IconButton } from '@/shared/IconButton';
import { useUserActions } from '../hooks/useUserActions';

export const UnauthenticatedUser = (): React.ReactNode => {
  const { openModalLogin } = useUserActions();

  return (
    <IconButton
      onClick={openModalLogin}
      icon="admin"
      title="Login"
      className="bg-transparent hover:bg-transparent active:bg-transparent"
      classNameIcon="w-6 h-8"
      data-testid="unauthenticated-button"
    />
  );
};
