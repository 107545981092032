import { MapModel, PinDetailsItem } from '@/types/models';
import { AvailableSubmaps, PinTypeWithNone } from '../PinsList.types';

const MAIN_MAP_ID = 52;

export const getPinColor = (type: PinTypeWithNone): string => {
  const pinColors: Record<PinTypeWithNone, string> = {
    bioEntity: 'fill-primary-500',
    drugs: 'fill-orange',
    chemicals: 'fill-purple',
    comment: 'fill-blue',
    none: 'none',
  };

  return pinColors[type];
};

export const getListOfAvailableSubmaps = (
  pin: PinDetailsItem,
  models: MapModel[],
): AvailableSubmaps[] => {
  const submaps = pin.targetElements.filter((element, index) => {
    return (
      index ===
      pin.targetElements.findIndex(o => element.model === o.model && element.model !== MAIN_MAP_ID)
    );
  });

  const availableSubmaps = submaps.map(submap => {
    const data: AvailableSubmaps = {
      id: submap.id,
      modelId: submap.model,
      name: models.find(model => model.idObject === submap.model)?.name || '',
    };

    return data;
  });

  return availableSubmaps;
};
