import { clearChemicalsData } from '@/redux/chemicals/chemicals.slice';
import { closeContextMenu } from '@/redux/contextMenu/contextMenu.slice';
import { clearDrugsData } from '@/redux/drugs/drugs.slice';
import { clearEntityNumberData } from '@/redux/entityNumber/entityNumber.slice';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { clearSearchData } from '@/redux/search/search.slice';
import { AppDispatch } from '@/redux/store';

export const handleDataReset = (dispatch: AppDispatch): void => {
  // Reset reactions list to prevent keeping the old selected reaction rendered
  dispatch(resetReactionsData());

  // Reset search data to prevent invalid filtering of the click-search ()
  dispatch(clearSearchData());

  // Reset old pins data
  dispatch(clearDrugsData());
  dispatch(clearChemicalsData());
  dispatch(closeContextMenu());
  dispatch(clearEntityNumberData());
};
