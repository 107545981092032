import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { useMemo } from 'react';
import { newReactionsLoadingSelector } from '@/redux/newReactions/newReactions.selectors';
import { modelElementsLoadingSelector } from '@/redux/modelElements/modelElements.selector';
import { vectorRenderingSelector } from '@/redux/models/models.selectors';
import {
  arrowTypesLoadingSelector,
  bioShapesLoadingSelector,
  lineTypesLoadingSelector,
} from '@/redux/shapes/shapes.selectors';
import { layersLoadingSelector } from '@/redux/layers/layers.selectors';
import './MapLoader.styles.css';
import { isDrawerOpenSelector } from '@/redux/drawer/drawer.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';

export const MapLoader = (): JSX.Element => {
  const reactionsFetching = useAppSelector(newReactionsLoadingSelector);
  const modelElementsFetching = useAppSelector(modelElementsLoadingSelector);
  const vectorRendering = useAppSelector(vectorRenderingSelector);
  const bioShapesFetching = useAppSelector(bioShapesLoadingSelector);
  const lineTypesFetching = useAppSelector(lineTypesLoadingSelector);
  const arrowTypesFetching = useAppSelector(arrowTypesLoadingSelector);
  const layersLoading = useAppSelector(layersLoadingSelector);

  const isDrawerOpen = useAppSelector(isDrawerOpenSelector);

  const showLoader = useMemo(() => {
    return [
      reactionsFetching,
      modelElementsFetching,
      bioShapesFetching,
      lineTypesFetching,
      arrowTypesFetching,
      layersLoading,
    ].includes('pending');
  }, [
    reactionsFetching,
    modelElementsFetching,
    bioShapesFetching,
    lineTypesFetching,
    arrowTypesFetching,
    layersLoading,
  ]);

  return (
    <div className={`map-loader transition-all duration-500 ${isDrawerOpen ? 'move-right' : ''}`}>
      {vectorRendering && showLoader && <LoadingIndicator width={48} height={48} />}
    </div>
  );
};
