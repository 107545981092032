/* eslint-disable no-magic-numbers */
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { Map, MapBrowserEvent } from 'ol';
import { Comment, ModelElement, NewReaction } from '@/types/models';
import { updateLastClick } from '@/redux/map/map.slice';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { FeatureLike } from 'ol/Feature';
import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { clearBioEntities } from '@/redux/bioEntity/bioEntity.slice';
import { leftClickHandleAlias } from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/mouseLeftClick/leftClickHandleAlias';
import { handleFeaturesClick } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/handleFeaturesClick';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { handleDataReset } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/handleDataReset';
import { FEATURE_TYPE } from '@/constants/features';
import { clickHandleReaction } from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/clickHandleReaction';

function isFeatureFilledCompartment(feature: FeatureLike): boolean {
  return feature.get('type') === FEATURE_TYPE.COMPARTMENT && feature.get('filled');
}

function isFeatureNotCompartment(feature: FeatureLike): boolean {
  return (
    [...Object.values(FEATURE_TYPE)].includes(feature.get('type')) &&
    feature.get('type') !== FEATURE_TYPE.COMPARTMENT
  );
}

/* prettier-ignore */
export const onMapLeftClick =
  (
    mapSize: MapSize,
    modelId: number,
    dispatch: AppDispatch,
    isResultDrawerOpen: boolean,
    comments: Comment[],
    modelElements: Array<ModelElement>,
    reactions: Array<NewReaction>,
  ) =>
    async (
      { coordinate, pixel }: Pick<MapBrowserEvent<UIEvent>, 'coordinate' | 'pixel'>,
      mapInstance: Map,
    ): Promise<void> => {
      const [lng, lat] = toLonLat(coordinate);
      const point = latLngToPoint([lat, lng], mapSize);

      dispatch(updateLastClick({ coordinates: point, modelId }));

      let featureAtPixel: FeatureLike | undefined;
      mapInstance.forEachFeatureAtPixel(
        pixel,
        feature => {
          const featureZIndex = feature.get('zIndex');
          if (
            (isFeatureFilledCompartment(feature) || isFeatureNotCompartment(feature)) &&
            (featureZIndex === undefined || featureZIndex >= 0) &&
            !feature.get('hidden')
          ) {
            featureAtPixel = feature;
            return true;
          }
          return false;
        },
        { hitTolerance: 10 },
      );

      if (featureAtPixel) {
        const { shouldBlockCoordSearch } = handleFeaturesClick([featureAtPixel], dispatch, comments);
        if (shouldBlockCoordSearch) {
          return;
        }
      }

      dispatch(handleDataReset);

      if (!featureAtPixel) {
        if (isResultDrawerOpen) {
          dispatch(closeDrawer());
        }

        dispatch(resetReactionsData());
        dispatch(clearBioEntities());
        return;
      }

      const type = featureAtPixel.get('type');
      const id = featureAtPixel.get('id');
      if ([FEATURE_TYPE.ALIAS, FEATURE_TYPE.GLYPH, FEATURE_TYPE.COMPARTMENT].includes(type)) {
        await leftClickHandleAlias(dispatch)(featureAtPixel, modelId);
      } else if (type === FEATURE_TYPE.REACTION) {
        clickHandleReaction(dispatch)(modelElements, reactions, id, modelId);
      }
    };
