import { RGBColor } from '@/types/colors';

const MIN_RGB_VALUE = 0;
const MAX_RGB_VALUE = 255;

const isCorrectRgbValue = ({ r, g, b }: RGBColor): boolean =>
  !Number.isInteger(r) ||
  !Number.isInteger(g) ||
  !Number.isInteger(b) ||
  r < MIN_RGB_VALUE ||
  g < MIN_RGB_VALUE ||
  b < MIN_RGB_VALUE ||
  r > MAX_RGB_VALUE ||
  g > MAX_RGB_VALUE ||
  b > MAX_RGB_VALUE;

export const rgbToHex = ({ r, g, b }: RGBColor): string => {
  if (isCorrectRgbValue({ r, g, b })) {
    throw new Error('Invalid RGB values. Values must be integers between 0 and 255.');
  }
  // eslint-disable-next-line no-bitwise, no-magic-numbers
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};
