/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  getDefaultSearchTab,
  getSearchValuesArrayAndTrimToSeven,
} from '@/components/FunctionalArea/TopBar/SearchBar/SearchBar.utils';
import { openSearchDrawerWithSelectedTab } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapModelIdSelector, mapOpenedMapsSelector } from '@/redux/map/map.selectors';
import { openMapAndSetActive, setActiveMap } from '@/redux/map/map.slice';
import { closeModal } from '@/redux/modal/modal.slice';
import { modelsNameMapSelector } from '@/redux/models/models.selectors';
import { getSearchData } from '@/redux/search/search.thunks';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { BioEntityContent, TargetElement } from '@/types/models';
import { useEffect, useState } from 'react';
import { fetchElementData } from '../../../utils/fetchElementData';

interface Props {
  target: TargetElement;
}

export const ElementLink = ({ target }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const openedMaps = useAppSelector(mapOpenedMapsSelector);
  const currentModelId = useAppSelector(mapModelIdSelector);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<BioEntityContent | undefined>(undefined);
  const elementId = data?.bioEntity.elementId;
  const mapsNames = useAppSelector(modelsNameMapSelector);

  const isMapAlreadyOpened = (modelId: number): boolean =>
    openedMaps.some(map => map.modelId === modelId);

  const getElementLinkData = async (searchQuery: string): Promise<void> => {
    const fetchedData = await fetchElementData(searchQuery).finally(() => setIsLoading(false));

    if (fetchedData) {
      setData(fetchedData);
    }
  };

  const searchForElementAndOpenDrawer = (): void => {
    if (!elementId) return;

    const searchValues = getSearchValuesArrayAndTrimToSeven(elementId);
    dispatch(getSearchData({ searchQueries: searchValues, isPerfectMatch: false }));
    dispatch(openSearchDrawerWithSelectedTab(getDefaultSearchTab(searchValues)));
  };

  const openSubmap = (): void => {
    if (isMapAlreadyOpened(target.modelId)) {
      dispatch(setActiveMap({ modelId: target.modelId }));
    } else {
      dispatch(
        openMapAndSetActive({ modelId: target.modelId, modelName: mapsNames[target.modelId] }),
      );
    }

    if (currentModelId !== target.modelId) {
      PluginsEventBus.dispatchEvent('onSubmapClose', currentModelId);
      PluginsEventBus.dispatchEvent('onSubmapOpen', target.modelId);
    }
  };

  const handleElementLinkClick = (): void => {
    dispatch(closeModal());
    searchForElementAndOpenDrawer();
    openSubmap();
  };

  useEffect(() => {
    getElementLinkData(`${target.id}`);
  }, [target.id]);

  if (isLoading || !elementId) {
    return <LoadingIndicator />;
  }

  return (
    <button
      type="button"
      className="inline-block cursor-pointer underline"
      onClick={handleElementLinkClick}
    >
      {elementId}
    </button>
  );
};
