/* eslint-disable no-magic-numbers */
export default function getWrappedTextWithFontSize({
  text,
  maxWidth,
  maxHeight,
  minFontSize = 1,
  maxFontSize = 12,
}: {
  text: string;
  maxWidth: number;
  maxHeight: number;
  minFontSize?: number;
  maxFontSize?: number;
}): { text: string; fontSize: number } {
  const result = {
    text,
    fontSize: maxFontSize,
  };
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return result;
  }

  let resultFontSize = maxFontSize;
  let resultText = text;
  for (let testFontSize = maxFontSize; testFontSize >= minFontSize; testFontSize -= 1) {
    context.font = `${testFontSize}px Arial`;
    let currentLine = '';
    let splittedText = '';
    resultFontSize = testFontSize;
    text.split(' ').forEach((word: string) => {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const testWidth = context.measureText(testLine).width;

      if (testWidth > maxWidth && currentLine) {
        splittedText += `\n${currentLine}`;
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });
    if (currentLine) {
      splittedText += `\n${currentLine}`;
    }
    const lines = splittedText.split('\n');
    const maxLineWidth = lines.reduce(
      (maxFoundWidth, line) => Math.max(maxFoundWidth, context.measureText(line).width),
      0,
    );
    if (maxLineWidth <= maxWidth && testFontSize * lines.length <= maxHeight) {
      resultText = splittedText;
      break;
    }
  }
  result.text = resultText;
  result.fontSize = resultFontSize;
  return result;
}
