import { FIRST_ARRAY_ELEMENT, SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { currentSelectedSearchElement } from '@/redux/drawer/drawer.selectors';
import { selectTab } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { searchValueSelector } from '@/redux/search/search.selectors';
import { twMerge } from 'tailwind-merge';

export const SearchDrawerTabs = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const searchValue = useAppSelector(searchValueSelector);
  const selectedSearchValue = useAppSelector(currentSelectedSearchElement);

  const isActive = (value: string): boolean => selectedSearchValue === value;

  const onTabClick = (value: string): void => {
    dispatch(selectTab(value));
  };

  if (searchValue.length === SIZE_OF_EMPTY_ARRAY || searchValue[FIRST_ARRAY_ELEMENT] === '') {
    return null;
  }

  return (
    <div className="flex items-center justify-between border-b border-b-divide px-6">
      <div className="text-center text-sm">
        <ul className="-mb-px flex flex-wrap">
          {searchValue.map(value => (
            <li className="me-2" key={value}>
              <button
                type="button"
                className={twMerge(
                  'inline-block rounded-t-lg border-x border-t border-[#070130] border-b-transparent px-2 py-1 font-semibold leading-6',
                  isActive(value) ? 'bg-[#F7F7F8]' : 'border-[#EEE] font-normal text-[#6A6977]',
                )}
                onClick={(): void => onTabClick(value)}
              >
                {value}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
