import { LOCATION_BTN_ID } from '@/components/Map/MapAdditionalActions/MappAdditionalActions.constants';
import { ZERO } from '@/constants/common';
import { displayBioEntitiesList } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapModelIdSelector, mapOpenedMapsSelector } from '@/redux/map/map.selectors';
import { openMapAndSetActive, setActiveMap } from '@/redux/map/map.slice';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { Icon } from '@/shared/Icon';
import { BioEntityContent } from '@/types/models';

export interface BioEntitiesSubmapItemProps {
  mapName: string;
  mapId: number;
  numberOfEntities: string | number;
  bioEntities: BioEntityContent[];
}

export const BioEntitiesSubmapItem = ({
  mapName,
  mapId,
  numberOfEntities,
  bioEntities,
}: BioEntitiesSubmapItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const openedMaps = useAppSelector(mapOpenedMapsSelector);
  const currentModelId = useAppSelector(mapModelIdSelector);

  const isMapAlreadyOpened = (modelId: number): boolean =>
    openedMaps.some(map => map.modelId === modelId);

  const openSubmap = (): void => {
    if (isMapAlreadyOpened(mapId)) {
      dispatch(setActiveMap({ modelId: mapId }));
    } else {
      dispatch(openMapAndSetActive({ modelId: mapId, modelName: mapName }));
    }
    if (currentModelId !== mapId) {
      PluginsEventBus.dispatchEvent('onSubmapClose', currentModelId);
      PluginsEventBus.dispatchEvent('onSubmapOpen', mapId);
    }
  };

  const onSubmapClick = (): void => {
    openSubmap();
    dispatch(displayBioEntitiesList(bioEntities));

    const locationButton = document.querySelector<HTMLButtonElement>(`#${LOCATION_BTN_ID}`);
    if (locationButton) {
      setTimeout(() => locationButton?.click(), ZERO);
    }
  };

  return (
    <button
      onClick={onSubmapClick}
      type="button"
      className="flex flex-row flex-nowrap items-center justify-between pl-6 [&:not(:last-of-type)]:pb-4"
      data-testid="bio-entites-submap-button"
    >
      <p className="text-sm font-normal">
        {mapName} ({numberOfEntities})
      </p>
      <Icon name="arrow" className="h-6 w-6 fill-font-500" data-testid="arrow-icon" />
    </button>
  );
};
