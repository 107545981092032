import { ImageSize, ModelAspectRatios } from './ImageSize.types';

export const DEFAULT_IMAGE_WIDTH = 600;
export const DEFAULT_IMAGE_HEIGHT = 200;

export const DEFAULT_IMAGE_SIZE: ImageSize = {
  width: DEFAULT_IMAGE_WIDTH,
  height: DEFAULT_IMAGE_HEIGHT,
};

export const DEFAULT_MODEL_ASPECT_RATIOS: ModelAspectRatios = {
  vertical: DEFAULT_IMAGE_HEIGHT / DEFAULT_IMAGE_WIDTH,
  horizontal: DEFAULT_IMAGE_WIDTH / DEFAULT_IMAGE_HEIGHT,
};
