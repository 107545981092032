import { ONE } from '@/constants/common';
import { BioEntityWithPinType, MultiPinBioEntity } from '@/types/bioEntity';
import { BioEntity } from '@/types/models';
import { PinType } from '@/types/pin';

interface Args {
  bioEntities: MultiPinBioEntity;
}

const SEPARATOR = '-';
const POSITION_PRESCISION_SEPERATOR = '.';

const getUniqueKey = (element: Pick<BioEntity, 'x' | 'y'>): string => {
  const [x] = `${element.x}`.split(POSITION_PRESCISION_SEPERATOR);
  const [y] = `${element.y}`.split(POSITION_PRESCISION_SEPERATOR);

  return [x, y].join(SEPARATOR);
};

const groupByPosition = (
  accumulator: Record<string, MultiPinBioEntity>,
  element: BioEntityWithPinType,
): Record<string, MultiPinBioEntity> => {
  const key = getUniqueKey(element);

  return {
    ...accumulator,
    [key]: accumulator[key] ? [...accumulator[key], element] : [element],
  };
};

const toUniqueTypeMultipin = (multipin: MultiPinBioEntity): MultiPinBioEntity => {
  const allTypes: PinType[] = multipin.map(pin => pin.type);
  const uniqueTypes = [...new Set(allTypes)];

  return uniqueTypes
    .map(type => multipin.find(pin => pin.type === type))
    .filter((value): value is BioEntityWithPinType => value !== undefined);
};

export const getMultipinsBioEntities = ({ bioEntities }: Args): MultiPinBioEntity[] => {
  const multipiledBioEntities = bioEntities.filter(
    baseElement =>
      bioEntities.filter(element => getUniqueKey(baseElement) === getUniqueKey(element)).length >
      ONE,
  );

  const duplicatedMultipinsGroupedByPosition = multipiledBioEntities.reduce(
    groupByPosition,
    {} as Record<string, MultiPinBioEntity>,
  );

  const allGroupedMultipins = Object.values(duplicatedMultipinsGroupedByPosition);
  const uniqueTypeGroupedMultipins = allGroupedMultipins.map(toUniqueTypeMultipin);
  const multipiledMultiPins = uniqueTypeGroupedMultipins.filter(multipin => multipin.length > ONE);

  return multipiledMultiPins;
};
