import { NewReaction } from '@/types/models';
import { ReferenceFiltered } from '@/types/reference';

type InputReaction = Pick<NewReaction, 'references'>;

export const getReferencesWithoutEmptyLink = (
  reaction: InputReaction | undefined,
): ReferenceFiltered[] =>
  (reaction?.references || []).filter(
    (ref): ref is ReferenceFiltered => ref.link !== null && ref.link !== undefined,
  );
