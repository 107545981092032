import { StandarizedPublication } from '@/types/publications';

export const mapStandarizedPublicationsToCSVString = (
  publications: StandarizedPublication[],
): string =>
  publications
    .map(({ pubmedId, title, authors, journal, year, elementsIds, modelNames }) =>
      [pubmedId, title, authors, journal, year, elementsIds, modelNames]
        .map(text => `"${text}"`)
        .join(','),
    )
    .join('\n');
