import { clearBioEntities } from '@/redux/bioEntity/bioEntity.slice';
import { clearChemicalsData } from '@/redux/chemicals/chemicals.slice';
import { clearDrugsData } from '@/redux/drugs/drugs.slice';
import { setMarkersData } from '@/redux/markers/markers.slice';
import { store } from '@/redux/store';

type ElementName = 'drugs' | 'chemicals' | 'content' | 'marker';

export const clearAllElements = (elements: ElementName[]): void => {
  const { dispatch } = store;

  if (elements.includes('content')) {
    dispatch(clearBioEntities());
  }

  if (elements.includes('chemicals')) {
    dispatch(clearChemicalsData());
  }

  if (elements.includes('drugs')) {
    dispatch(clearDrugsData());
  }

  if (elements.includes('marker')) {
    dispatch(setMarkersData([]));
  }
};
