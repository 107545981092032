import { FunctionalArea } from '@/components/FunctionalArea';
import { Map } from '@/components/Map';
import { manrope } from '@/constants/font';
import { useReduxBusQueryManager } from '@/utils/query-manager/useReduxBusQueryManager';
import { twMerge } from 'tailwind-merge';
import { useEffect } from 'react';
import { PluginsManager } from '@/services/pluginsManager';
import { useInitializeStore } from '../../utils/initialize/useInitializeStore';
import { Modal } from '../FunctionalArea/Modal';
import { ContextMenu } from '../FunctionalArea/ContextMenu';
import { CookieBanner } from '../FunctionalArea/CookieBanner';

export const MinervaSPA = (): JSX.Element => {
  useInitializeStore();
  useReduxBusQueryManager();

  useEffect(() => {
    const unsubscribe = PluginsManager.init();

    return () => unsubscribe();
  }, []);

  return (
    <div className={twMerge('relative', manrope.variable)}>
      <FunctionalArea />
      <Map />
      <Modal />
      <ContextMenu />
      <CookieBanner />
    </div>
  );
};
