import { DEFAULT_OVERVIEW_IMAGE_SIZE } from '@/constants/project';
import { currentOverviewImageSelector } from '@/redux/project/project.selectors';
import { useSelector } from 'react-redux';
import { OverviewImageSize } from '../OverviewImageModal.types';
import { getFinalImageSize } from './getFinalImageSize';

interface UseOverviewImageArgs {
  containerRect?: DOMRect;
}

interface UseOverviewImageResult extends OverviewImageSize {
  sizeFactor: number;
}

export const useOverviewImageSize = ({
  containerRect,
}: UseOverviewImageArgs): UseOverviewImageResult => {
  const currentImage = useSelector(currentOverviewImageSelector);

  if (!currentImage || !containerRect) return DEFAULT_OVERVIEW_IMAGE_SIZE;

  const maxImageSize = {
    width: currentImage.width,
    height: currentImage.height,
  };

  return getFinalImageSize(containerRect, maxImageSize);
};
