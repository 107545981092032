import { PinItem, PinTypeWithNone } from '../PinsList/PinsList.types';

export const getEntityNames = (pinsList: PinItem[]): string => {
  let name = '';

  pinsList.forEach(element => {
    name += element.data.name;
  });

  return name;
};

export const getEntityDescriptions = (pinsList: PinItem[]): string => {
  let description = '';

  pinsList.forEach(element => {
    if ('description' in element.data) {
      description += element.data.description;
    }
  });

  return description;
};

export const getEntitySynonyms = (pinsList: PinItem[]): string => {
  let synonyms = '';

  pinsList.forEach(element => {
    if ('synonyms' in element.data) {
      synonyms += element.data.synonyms.join(', ');
    }
  });

  return synonyms;
};

export const getAdditionalInfo = (pinsList: PinItem[], type: PinTypeWithNone): string => {
  if (type === 'drugs') {
    return pinsList
      .map(element => ('bloodBrainBarrier' in element.data ? element.data.bloodBrainBarrier : ''))
      .join(', ');
  }

  if (type === 'chemicals') {
    return pinsList
      .map(element =>
        'directEvidence' in element.data && element.data.directEvidence
          ? element.data.directEvidence
          : 'No annotations',
      )
      .join(', ');
  }

  return '';
};
