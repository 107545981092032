import React from 'react';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { loginUserSelector } from '@/redux/user/user.selectors';
import { openLoginModal } from '@/redux/modal/modal.slice';
import { MINUS_ONE, ZERO } from '@/constants/common';
import { Button } from '@/shared/Button';
import { adminEmailValSelector } from '@/redux/configuration/configuration.selectors';
import { projectsSelector } from '@/redux/projects/projects.selectors';
import { getOAuth } from '@/redux/oauth/oauth.thunks';

export const AccessDeniedModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const login = useAppSelector(loginUserSelector);
  const projects = useAppSelector(projectsSelector);

  const adminEmail = useAppSelector(adminEmailValSelector);

  const isAnonymousLogin = !login;

  const isProjectsAvailable = projects.length > ZERO;

  const isAdminEmail = adminEmail !== '' && adminEmail !== undefined;

  const handleGoBack = async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    window.history.go(MINUS_ONE);
  };

  const handleLogin = async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    dispatch(getOAuth());
    dispatch(openLoginModal());
  };

  const handleContactAmin = async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    window.location.href = `mailto:${adminEmail}`;
  };

  const openProject = (e: React.FormEvent<HTMLButtonElement>): void => {
    window.location.href = `?id=${e.currentTarget.value}`;
  };

  return (
    <div className="w-[400px] border border-t-[#E1E0E6] bg-white p-[24px]">
      {isAnonymousLogin && (
        <div className="grid grid-cols-2 gap-2">
          <div>
            <Button
              className="ring-transparent hover:ring-transparent"
              variantStyles="secondary"
              onClick={handleGoBack}
            >
              Go back to previous page
            </Button>
          </div>
          <div className="text-center">
            <Button className="block w-full" onClick={handleLogin}>
              Login to your account
            </Button>
          </div>
        </div>
      )}
      {isProjectsAvailable && (
        <div>
          <div className="mb-1 mt-5 text-sm">Switch to another map</div>
          <div className="grid grid-cols-3 gap-2">
            {projects.map(project => (
              <Button
                key={project.projectId}
                value={project.projectId}
                variantStyles="ghost"
                className="text-center text-gray-500"
                onClick={openProject}
              >
                {project.name} ({project.projectId})
              </Button>
            ))}
          </div>
        </div>
      )}
      {isAdminEmail && (
        <div className="mt-1 text-center">
          <Button
            className="block w-full ring-transparent hover:ring-transparent"
            variantStyles="secondary"
            onClick={handleContactAmin}
          >
            Contact admin
          </Button>
        </div>
      )}
    </div>
  );
};
