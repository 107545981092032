import { useCallback } from 'react';
import { emptyBackgroundIdSelector } from '@/redux/backgrounds/background.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { setMapBackground } from '@/redux/map/map.slice';

type UseEmptyBackgroundReturn = {
  setBackgroundtoEmptyIfAvailable: () => void;
};

export const useEmptyBackground = (): UseEmptyBackgroundReturn => {
  const dispatch = useAppDispatch();
  const emptyBackgroundId = useAppSelector(emptyBackgroundIdSelector);

  const setBackgroundtoEmptyIfAvailable = useCallback(() => {
    if (emptyBackgroundId) {
      dispatch(setMapBackground(emptyBackgroundId));
    }
  }, [dispatch, emptyBackgroundId]);

  return { setBackgroundtoEmptyIfAvailable };
};
