import spinnerIcon from '@/assets/vectors/icons/spinner.svg';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { closeModal } from '@/redux/modal/modal.slice';
import { filteredSizeSelector } from '@/redux/publications/publications.selectors';
import { Button } from '@/shared/Button';
import { Icon } from '@/shared/Icon';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import { PublicationsSearch } from '../PublicationsSearch';
import { MODAL_ROLE } from './PublicationsModalLayout.constants';
import { useDownloadPublicationsAsCSVFile } from './utils/useDownloadPublicationsAsCSVFile';

type ModalLayoutProps = {
  children: React.ReactNode | null;
};

export const PublicationsModalLayout = ({ children }: ModalLayoutProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const numberOfPublications = useAppSelector(filteredSizeSelector);
  const { downloadPublicationsAsCSVFile, isLoading } = useDownloadPublicationsAsCSVFile();

  const handleCloseModal = (): void => {
    dispatch(closeModal());
  };

  return (
    <div
      className={twMerge('absolute left-0 top-0 z-[11] h-full w-full bg-cetacean-blue/[.48]')}
      role={MODAL_ROLE}
    >
      <div className="flex h-full w-full items-center justify-center">
        <div className={twMerge('flex h-5/6 w-10/12	flex-col	overflow-hidden rounded-lg')}>
          <div className="flex items-center  justify-between bg-white p-[24px] text-xl">
            <div className="flex items-center gap-4">
              <div className="font-semibold">
                <div>Publications ({numberOfPublications} results)</div>
              </div>
              <Button
                onClick={downloadPublicationsAsCSVFile}
                disabled={isLoading || !numberOfPublications}
                data-testid="download-csv-button"
              >
                {isLoading && (
                  <Image
                    src={spinnerIcon}
                    alt="spinner icon"
                    height={12}
                    width={12}
                    className="animate-spin"
                  />
                )}
                Download CSV
              </Button>
            </div>
            <div className="flex flex-row flex-nowrap items-center">
              <PublicationsSearch />
              <button type="button" onClick={handleCloseModal} aria-label="close button">
                <Icon name="close" className="fill-font-500" />
              </button>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
