export const mapStepTypeToHeading = (stepType: string): string => {
  switch (stepType) {
    case 'drugs':
      return 'Drug targets';
    case 'chemicals':
      return 'Small molecule targets';
    default:
      return stepType;
  }
};
