import { BASE_API_URL } from '@/constants';
import { apiPath } from '@/redux/apiPath';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { openEditOverlayModal } from '@/redux/modal/modal.slice';
import { MapOverlay } from '@/types/models';
import { ACTION_TYPES } from '../UserOverlayActions.constants';

type UseUserOverlayActionsReturn = {
  handleActionClick: (action: string) => void;
};

export const useUserOverlayActions = (overlay: MapOverlay): UseUserOverlayActionsReturn => {
  const dispatch = useAppDispatch();

  const handleDownloadOverlay = (): void => {
    window.open(`${BASE_API_URL}/${apiPath.downloadOverlay(overlay.idObject)}`, '_blank');
  };

  const handleEditOverlay = (): void => {
    dispatch(openEditOverlayModal(overlay));
  };

  const handleActionClick = (action: string): void => {
    switch (action) {
      case ACTION_TYPES.DOWNLOAD:
        handleDownloadOverlay();
        break;
      case ACTION_TYPES.EDIT:
        handleEditOverlay();
        break;
      default:
        throw new Error(`${action} is not valid`);
    }
  };

  return {
    handleActionClick,
  };
};
