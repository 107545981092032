const BEGINNING = 0;
const END = 6;
const SEPARATOR = ';';

export const getSearchValuesArrayAndTrimToSeven = (searchString: string): string[] =>
  searchString.split(SEPARATOR).slice(BEGINNING, END);

export const getDefaultSearchTab = (searchValues: string[]): string => {
  const FIRST = 0;
  const defaultSearchTab = searchValues[FIRST];
  return defaultSearchTab;
};
