import { createSelector } from '@reduxjs/toolkit';
import { ZERO } from '@/constants/common';
import { rootSelector } from '../root/root.selectors';

export const publicationsSelector = createSelector(rootSelector, state => state.publications);

export const publicationsDataSelector = createSelector(
  publicationsSelector,
  publications => publications?.data,
);

export const publicationsListDataSelector = createSelector(
  publicationsDataSelector,
  data => data?.data,
);

/** totalSize is number of pages */
export const totalSizeSelector = createSelector(publicationsDataSelector, data => data?.totalSize);

export const filteredSizeSelector = createSelector(
  publicationsDataSelector,
  data => data?.filteredSize,
);

export const currentPageSelector = createSelector(publicationsDataSelector, data => data?.page);
export const paginationSelector = createSelector(publicationsDataSelector, data => ({
  pageIndex: data?.page || ZERO,
  pageSize: 10,
}));

export const isLoadingSelector = createSelector(
  publicationsSelector,
  publications => publications.loading === 'pending',
);

export const sortColumnSelector = createSelector(
  publicationsSelector,
  publications => publications.sortColumn,
);
export const sortOrderSelector = createSelector(
  publicationsSelector,
  publications => publications.sortOrder,
);

export const selectedModelIdSelector = createSelector(
  publicationsSelector,
  publications => publications.selectedModelId,
);

export const searchValueSelector = createSelector(
  publicationsSelector,
  publications => publications.searchValue,
);
