import { MarkerWithPosition } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { getMarkerSingleFeature } from './getMarkerSingleFeature';

export const getMarkersFeatures = (
  markers: MarkerWithPosition[],
  {
    pointToProjection,
  }: {
    pointToProjection: UsePointToProjectionResult;
  },
): Feature[] => {
  return markers.map(marker =>
    getMarkerSingleFeature(marker, {
      pointToProjection,
    }),
  );
};
