import { setMapPosition } from '@/redux/map/map.slice';
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { toLonLat } from 'ol/proj';
import { ObjectEvent } from 'openlayers';

/* prettier-ignore */
export const onMapPositionChange =
  (mapSize: MapSize, dispatch: AppDispatch) =>
    (e: ObjectEvent): void => {
      // eslint-disable-next-line no-underscore-dangle
      const { center, zoom } = e.target.values_;
      const [lng, lat] = toLonLat(center);
      const { x, y } = latLngToPoint([lat, lng], mapSize, { rounded: true });

      dispatch(
        setMapPosition({
          x,
          y,
          z: zoom,
        }),
      );
    };
