import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { legendSelector, pluginLegendsSelector } from '@/redux/legend/legend.selectors';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { ZERO } from '@/constants/common';
import { LEGEND_ROLE } from './Legend.constants';
import { LegendHeader } from './LegendHeader';
import { LegendImages } from './LegendImages';
import { LegendTabs } from './LegendTabs';

export const Legend: React.FC = () => {
  const { isOpen } = useAppSelector(legendSelector);
  const allPluginLegends = useAppSelector(pluginLegendsSelector);
  const isAnyPluginLegendExists = React.useMemo(
    () => Object.values(allPluginLegends).length > ZERO,
    [allPluginLegends],
  );

  return (
    <div
      className={twMerge(
        'absolute bottom-0 left-[88px] z-30 w-[calc(100%-88px)] -translate-y-[-100%] transform border border-divide bg-white-pearl text-font-500 transition-all duration-500',
        isOpen && 'translate-y-0',
      )}
      role={LEGEND_ROLE}
    >
      <LegendHeader />
      {isAnyPluginLegendExists ? <LegendTabs /> : null}
      <LegendImages />
    </div>
  );
};
