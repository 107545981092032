/* eslint-disable no-magic-numbers */
import Polygon from 'ol/geom/Polygon';
import { Coordinate } from 'ol/coordinate';
import getPolygonCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getPolygonCoords';
import getEllipseCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getEllipseCoords';
import { Shape } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import getCenter from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getCenter';

export default function getShapePolygon({
  shape,
  x,
  y,
  width,
  height,
  mirror,
  pointToProjection,
}: {
  shape: Shape;
  x: number;
  y: number;
  width: number;
  height: number;
  mirror?: boolean;
  pointToProjection: UsePointToProjectionResult;
}): Polygon {
  let coords: Array<Coordinate> = [];
  if (shape.type === 'POLYGON') {
    coords = getPolygonCoords({ points: shape.points, x, y, height, width, pointToProjection });
  } else if (shape.type === 'ELLIPSE') {
    coords = getEllipseCoords({
      x,
      y,
      center: shape.center,
      radius: shape.radius,
      height,
      width,
      pointToProjection,
    });
  }

  if (mirror) {
    const centroid = getCenter(coords);

    coords = coords.map(coord => {
      const mirroredX = 2 * centroid[0] - coord[0];

      return [mirroredX, coord[1]];
    });
  }
  coords.push(coords[0]);

  return new Polygon([coords]);
}
