import { createSelector } from '@reduxjs/toolkit';
import { BASE_MAP_IMAGES_URL } from '@/constants';
import { defaultLegendImagesSelector } from '../configuration/configuration.selectors';
import { rootSelector } from '../root/root.selectors';
import { activePluginsDataSelector } from '../plugins/plugins.selectors';
import { DEFAULT_LEGEND_ID, DEFAULT_LEGEND_TAB } from './legend.constants';

export const legendSelector = createSelector(rootSelector, state => state.legend);

export const isLegendOpenSelector = createSelector(legendSelector, state => state.isOpen);

export const pluginLegendsSelector = createSelector(legendSelector, state => state.pluginLegend);

export const currentLegendImagesSelector = createSelector(
  legendSelector,
  defaultLegendImagesSelector,
  ({ activeLegendId, pluginLegend }, defaultImages) => {
    if (activeLegendId === DEFAULT_LEGEND_ID)
      return defaultImages.map(image => `${BASE_MAP_IMAGES_URL}/minerva/${image}`);

    if (activeLegendId) {
      return pluginLegend?.[activeLegendId] || [];
    }

    return [];
  },
);

export const allLegendsNamesAndIdsSelector = createSelector(
  activePluginsDataSelector,
  pluginLegendsSelector,
  (activePlugins, pluginLegends) => {
    const allPluginLegendsNamesAndIds = Object.keys(pluginLegends).map(hash => {
      const plugin = Object.values(activePlugins).find(activePlugin => activePlugin.hash === hash);

      return {
        name: plugin?.name || '',
        id: plugin?.hash || '',
      };
    });

    return [DEFAULT_LEGEND_TAB, ...allPluginLegendsNamesAndIds];
  },
);

export const activeLegendIdSelector = createSelector(legendSelector, state => state.activeLegendId);

export const isActiveLegendSelector = createSelector(
  [activeLegendIdSelector, (_, legendId: string): string => legendId],
  (activeLegendId, legendId) => activeLegendId === legendId,
);
