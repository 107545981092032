export const FEATURE_TYPE = {
  PIN_ICON_BIOENTITY: 'PIN_ICON_BIOENTITY',
  PIN_ICON_MARKER: 'PIN_ICON_MARKER',
  SURFACE_OVERLAY: 'SURFACE_OVERLAY',
  SURFACE_MARKER: 'SURFACE_MARKER',
  PIN_ICON_COMMENT: 'PIN_ICON_COMMENT',
  ALIAS: 'ALIAS',
  REACTION: 'REACTION',
  GLYPH: 'GLYPH',
  COMPARTMENT: 'COMPARTMENT',
} as const;

export const PIN_ICON_ANY = [
  FEATURE_TYPE.PIN_ICON_BIOENTITY,
  FEATURE_TYPE.PIN_ICON_MARKER,
  FEATURE_TYPE.PIN_ICON_COMMENT,
];

export const SURFACE_ANY = [FEATURE_TYPE.SURFACE_OVERLAY, FEATURE_TYPE.SURFACE_MARKER];
