import { FEATURE_TYPE } from '@/constants/features';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import isUUID from 'is-uuid';
import Feature from 'ol/Feature';
import Polygon, { fromExtent } from 'ol/geom/Polygon';

export const createFeatureFromExtent = (
  [xMin, yMin, xMax, yMax]: number[],
  entityId: OverlayBioEntityRender['id'],
): Feature<Polygon> => {
  const isMarker = isUUID.anyNonNil(`${entityId}`);

  return new Feature({
    geometry: fromExtent([xMin, yMin, xMax, yMax]),
    id: entityId,
    type: isMarker ? FEATURE_TYPE.SURFACE_MARKER : FEATURE_TYPE.SURFACE_OVERLAY,
  });
};
