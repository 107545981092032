import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@/shared/Accordion';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UserOverlayListItem } from './UserOverlayListItem';
import { useUserOverlays } from './hooks/useUserOverlays';

export const UserOverlaysWithoutGroup = (): React.ReactNode => {
  const { moveUserOverlayListItem, updateUserOverlaysOrder, isPending, userOverlaysList } =
    useUserOverlays();

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="mt-2.5">
        <Accordion allowZeroExpanded>
          <AccordionItem className="border-b-0">
            <AccordionItemHeading>
              <AccordionItemButton className="px-6 text-sm font-semibold">
                Without group
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {isPending ? (
                <span className="py-4 pl-10 pr-5">Loading...</span>
              ) : (
                <ul>
                  {userOverlaysList?.map((userOverlay, index) => (
                    <UserOverlayListItem
                      moveUserOverlay={moveUserOverlayListItem}
                      key={userOverlay.idObject}
                      index={index}
                      userOverlay={userOverlay}
                      updateUserOverlaysOrder={updateUserOverlaysOrder}
                    />
                  ))}
                </ul>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </DndProvider>
  );
};
