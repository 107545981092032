import { DEFAULT_CURRENT_VIEW } from '@/components/Map/Drawer/ExportDrawer/ExportCompound/CurrentView/CurrentView.constants';
import { ExportContextType } from './ExportCompound.types';
import { DEFAULT_IMAGE_SIZE } from './ImageSize/ImageSize.constants';

export const ANNOTATIONS_TYPE = {
  ELEMENTS: 'Elements',
  NETWORK: 'Network',
} as const;

export const COLUMNS_TYPE = {
  ELEMENTS: 'Elements',
  NETWORK: 'Network',
} as const;

export const ELEMENTS_COLUMNS = [
  'id',
  'type',
  'name',
  'symbol',
  'abbreviation',
  'fullName',
  'synonyms',
  'formerSymbols',
  'complexId',
  'complexName',
  'compartmentId',
  'compartmentName',
  'modelId',
  'mapName',
  'description',
  'references',
  'charge',
  'formula',
  'linkedSubmodelId',
  'elementId',
];

export const NETWORK_COLUMNS = [
  'id',
  'type',
  'reactantIds',
  'productIds',
  'modifierIds',
  'description',
  'reactionId',
  'references',
  'modelId',
  'mapName',
];

export const EXPORT_CONTEXT_DEFAULT_VALUE: ExportContextType = {
  setAnnotations: () => {},
  setIncludedCompartmentPathways: () => {},
  setExcludedCompartmentPathways: () => {},
  setModels: () => {},
  setImageSize: () => {},
  setImageFormats: () => {},
  setCurrentView: () => {},
  handleDownloadElements: () => Promise.resolve(),
  handleDownloadNetwork: () => Promise.resolve(),
  handleDownloadGraphics: () => {},
  data: {
    annotations: [],
    includedCompartmentPathways: [],
    excludedCompartmentPathways: [],
    models: [],
    imageFormats: [],
    imageSize: DEFAULT_IMAGE_SIZE,
    currentView: DEFAULT_CURRENT_VIEW,
  },
};
