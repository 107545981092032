import { MapInstance } from '@/types/map';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import Map from 'ol/Map';
import { Zoom } from 'ol/control';
import React, { MutableRefObject, useEffect, useMemo } from 'react';
import { useOlMapVectorLayers } from '@/components/Map/MapViewer/MapViewerVector/utils/config/useOlMapVectorLayers';
import LayerGroup from 'ol/layer/Group';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { vectorRenderingSelector } from '@/redux/models/models.selectors';
import { defaults, MouseWheelZoom } from 'ol/interaction';
import { useOlMapVectorListeners } from '@/components/Map/MapViewer/MapViewerVector/listeners/useOlMapVectorListeners';
import { useOlMapCommonLayers } from '@/components/Map/MapViewer/utils/config/useOlMapCommonLayers';
import { useOlMapLayers } from './config/useOlMapLayers';
import { useOlMapView } from './config/useOlMapView';
import { useOlMapListeners } from './listeners/useOlMapListeners';

interface UseOlMapInput {
  target?: HTMLElement;
}
interface UseOlMapOutput {
  mapRef: MutableRefObject<null | HTMLDivElement>;
  mapInstance: MapInstance;
}

type UseOlMap = (input?: UseOlMapInput) => UseOlMapOutput;

export const useOlMap: UseOlMap = ({ target } = {}) => {
  const vectorRendering = useAppSelector(vectorRenderingSelector);
  const mapRef = React.useRef<null | HTMLDivElement>(null);
  const { mapInstance, handleSetMapInstance } = useMapInstance();
  const view = useOlMapView({ mapInstance });

  const rasterLayers = useOlMapLayers();
  const rasterLayersGroup = useMemo(() => {
    return new LayerGroup({
      layers: rasterLayers,
    });
  }, [rasterLayers]);

  const vectorLayers = useOlMapVectorLayers({ mapInstance });
  const vectorLayersGroup = useMemo(() => {
    return new LayerGroup({
      layers: vectorLayers,
    });
  }, [vectorLayers]);

  const commonLayers = useOlMapCommonLayers();
  const commonLayersGroup = useMemo(() => {
    return new LayerGroup({
      layers: commonLayers,
    });
  }, [commonLayers]);

  useOlMapListeners({ view, mapInstance });
  useOlMapVectorListeners({ mapInstance });

  useEffect(() => {
    // checking if innerHTML is empty due to possibility of target element cloning by OpenLayers map instance
    if (!mapRef.current || mapRef.current.innerHTML !== '') {
      return;
    }

    const map = new Map({
      interactions: defaults({
        mouseWheelZoom: false,
      }).extend([
        new MouseWheelZoom({
          duration: 250,
          timeout: 80,
        }),
      ]),
      target: target || mapRef.current,
    });

    // remove zoom controls as we are using our own
    map.getControls().forEach(mapControl => {
      if (mapControl instanceof Zoom) {
        map.removeControl(mapControl);
      }
    });

    handleSetMapInstance(map);
  }, [target, handleSetMapInstance]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    mapInstance.setLayers([vectorLayersGroup, rasterLayersGroup, commonLayersGroup]);
  }, [mapInstance, rasterLayersGroup, vectorLayersGroup, commonLayersGroup]);

  useEffect(() => {
    if (vectorRendering) {
      rasterLayersGroup.setVisible(false);
      vectorLayersGroup.setVisible(true);
    } else {
      vectorLayersGroup.setVisible(false);
      rasterLayersGroup.setVisible(true);
    }
  }, [rasterLayersGroup, vectorLayersGroup, vectorRendering]);

  return {
    mapRef,
    mapInstance,
  };
};
