import { WHITE_HEX_OPACITY_0 } from '@/constants/hexColors';
import { interpolateThreeColors } from '../lerp/interpolateThreeColors';
import { addAlphaToHexString } from './addAlphaToHexString';
import { hexToRgb } from './hexToRgb';
import { rgbToHex } from './rgbToHex';

export type GetHexTricolorGradientColorWithAlphaProps = {
  leftColor: string;
  middleColor: string;
  rightColor: string;
  alpha: number | undefined;
  position: number;
};

export const getHexTricolorGradientColorWithAlpha = ({
  leftColor,
  middleColor,
  rightColor,
  alpha,
  position,
}: GetHexTricolorGradientColorWithAlphaProps): string => {
  const leftRgb = hexToRgb(leftColor);
  const middleRgb = hexToRgb(middleColor);
  const rightRgb = hexToRgb(rightColor);

  if (!leftRgb || !middleRgb || !rightRgb) {
    return WHITE_HEX_OPACITY_0; // white, opacity 0
  }

  const interpolatedColor = interpolateThreeColors({
    leftColor: leftRgb,
    middleColor: middleRgb,
    rightColor: rightRgb,
    position,
  });

  const interpolatedHexColor = rgbToHex(interpolatedColor);
  const interpolatedColorWithAlpha = addAlphaToHexString(interpolatedHexColor, alpha);

  return interpolatedColorWithAlpha;
};
