import { EntityNumber } from '@/redux/entityNumber/entityNumber.types';
import { BioEntityWithPinType } from '@/types/bioEntity';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { getBioEntitySingleFeature } from './getBioEntitySingleFeature';

export const getBioEntitiesFeatures = (
  bioEntites: BioEntityWithPinType[],
  {
    pointToProjection,
    entityNumber,
    activeIds,
  }: {
    pointToProjection: UsePointToProjectionResult;
    entityNumber: EntityNumber;
    activeIds: (string | number)[];
  },
): Feature[] => {
  return bioEntites.map(bioEntity =>
    getBioEntitySingleFeature(bioEntity, {
      pointToProjection,
      type: bioEntity.type,
      // pin's index number
      value: entityNumber?.[bioEntity.elementId],
      isActive: activeIds.includes(bioEntity.id),
    }),
  );
};
