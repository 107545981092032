import { ZERO } from '@/constants/common';
import { QueryDataParams } from '@/types/query';
import { createSelector } from '@reduxjs/toolkit';
import { mapDataSelector } from '../map/map.selectors';
import { activeOverlaysIdSelector } from '../overlayBioEntity/overlayBioEntity.selector';
import { activePluginsIdSelector } from '../plugins/plugins.selectors';
import { projectMainIdSelector } from '../project/project.selectors';
import { perfectMatchSelector, searchValueSelector } from '../search/search.selectors';

export const queryDataParamsSelector = createSelector(
  searchValueSelector,
  perfectMatchSelector,
  mapDataSelector,
  activeOverlaysIdSelector,
  activePluginsIdSelector,
  projectMainIdSelector,
  (
    searchValue,
    perfectMatch,
    { modelId, backgroundId, position },
    activeOverlaysId,
    activePluginsId,
    id,
  ): QueryDataParams => {
    const joinedSearchValue = searchValue.join(';');
    const shouldIncludeSearchValue = searchValue.length > ZERO && joinedSearchValue;

    const shouldIncludeOverlaysId = activeOverlaysId.length > ZERO;
    const shouldIncludePluginsId = activePluginsId.length > ZERO;

    const queryDataParams: QueryDataParams = {
      id,
      perfectMatch,
      modelId,
      backgroundId,
      ...position.last,
      ...(shouldIncludeSearchValue ? { searchValue: joinedSearchValue } : {}),
      ...(shouldIncludeOverlaysId ? { overlaysId: activeOverlaysId.join(',') } : {}),
      ...(shouldIncludePluginsId ? { pluginsId: activePluginsId.join(',') } : {}),
    };

    return queryDataParams;
  },
);
