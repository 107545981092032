import { OverlayBioEntityRender } from '@/types/OLrendering';

export default function findMatchingSubmapLinkRectangle(
  elements: Array<OverlayBioEntityRender & { amount: number }>,
  overlayBioEntity: OverlayBioEntityRender,
): (OverlayBioEntityRender & { amount: number }) | undefined {
  return elements.find(element => {
    const hasAllRequiredValueProperties = element.value && overlayBioEntity.value;
    const isValueEqual = hasAllRequiredValueProperties && element.value === overlayBioEntity.value;

    const hasAllRequiredColorProperties = element.color && overlayBioEntity.color;
    const isColorEqual =
      hasAllRequiredColorProperties &&
      element.color?.alpha === overlayBioEntity?.color?.alpha &&
      element.color?.rgb === overlayBioEntity?.color?.rgb;

    return Boolean(isValueEqual || isColorEqual);
  });
}
