import { useState, ChangeEvent } from 'react';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { projectIdSelector } from '@/redux/project/project.selectors';
import { addOverlay } from '@/redux/overlays/overlays.thunks';
import { loadingAddOverlay } from '@/redux/overlays/overlays.selectors';
import { DEFAULT_GROUP, DEFAULT_TYPE, OVERLAY_TYPES } from '../UserOverlayForm.constants';
import { SelectorItem } from '../UserOverlayForm.types';
import { processOverlayContentChange } from '../UserOverlayForm.utils';

type ReturnType = {
  name: string;
  type: SelectorItem;
  group: SelectorItem;
  description: string;
  uploadedFile: File | null;
  elementsList: string;
  overlayContent: string;
  projectId?: string;
  isPending: boolean;
  handleChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeDescription: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleChangeType: (value: SelectorItem) => void;
  handleChangeGroup: (value: SelectorItem) => void;
  handleChangeUploadedFile: (value: File) => void;
  handleChangeOverlayContent: (value: string) => void;
  handleChangeElementsList: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: () => Promise<void>;
  updateUserOverlayForm: (nameType: string, value: string) => void;
};

export const useUserOverlayForm = (): ReturnType => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(projectIdSelector);
  const loadingAddOverlayStatus = useAppSelector(loadingAddOverlay);
  const isPending = loadingAddOverlayStatus === 'pending';

  const [name, setName] = useState('');
  const [type, setType] = useState<SelectorItem>(DEFAULT_TYPE);
  const [group, setGroup] = useState<SelectorItem>(DEFAULT_GROUP);
  const [description, setDescription] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [elementsList, setElementsList] = useState('');
  const [overlayContent, setOverlayContent] = useState('');

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setDescription(e.target.value);
  };

  const handleChangeType = (value: SelectorItem): void => {
    setType(value);
  };

  const handleChangeGroup = (value: SelectorItem): void => {
    setGroup(value);
  };

  const handleChangeUploadedFile = (value: File): void => {
    setUploadedFile(value);
  };

  const handleChangeOverlayContent = (value: string): void => {
    setOverlayContent(value);
  };

  const updateUserOverlayForm = (nameType: string, value: string): void => {
    switch (nameType) {
      case 'NAME':
        setName(value);
        break;
      case 'DESCRIPTION':
        setDescription(value);
        break;
      case 'TYPE': {
        const foundType = OVERLAY_TYPES.find(el => el.id === value);
        if (foundType) {
          setType(foundType);
        }
        break;
      }
      default:
        break;
    }
  };

  const handleChangeElementsList = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    processOverlayContentChange(e.target.value, updateUserOverlayForm); // When user change elements list we have to analyze content. If it contains overlay info like e.g NAME we need to update field NAME in form
    setOverlayContent(e.target.value);
    setElementsList(e.target.value);
  };

  const handleSubmit = async (): Promise<void> => {
    let filename = uploadedFile?.name;

    if (!filename) {
      filename = 'unknown.txt'; // Elements list is sent to the backend as a file, so we need to create a filename for the elements list.
    }

    if (!overlayContent || !projectId || !name) return;

    dispatch(
      addOverlay({
        content: overlayContent,
        description: description || '',
        filename,
        name,
        projectId,
        type: type.id,
      }),
    );

    setName('');
    setDescription('');
    setElementsList('');
    setOverlayContent('');
    setUploadedFile(null);
  };

  return {
    name,
    type,
    group,
    description,
    uploadedFile,
    elementsList,
    overlayContent,
    projectId,
    isPending,
    handleChangeName,
    handleChangeDescription,
    handleChangeType,
    handleChangeGroup,
    handleChangeElementsList,
    handleSubmit,
    updateUserOverlayForm,
    handleChangeUploadedFile,
    handleChangeOverlayContent,
  };
};
