/* eslint-disable no-magic-numbers */
import { useOlMapPinsLayer } from '@/components/Map/MapViewer/utils/config/pinsLayer/useOlMapPinsLayer';
import { useOlMapReactionsLayer } from '@/components/Map/MapViewer/utils/config/reactionsLayer/useOlMapReactionsLayer';
import { useOlMapCommentsLayer } from '@/components/Map/MapViewer/utils/config/commentsLayer/useOlMapCommentsLayer';
import { useMemo } from 'react';
import { MapConfig } from '../../MapViewer.types';

export const useOlMapCommonLayers = (): MapConfig['layers'] => {
  const pinsLayer = useOlMapPinsLayer();
  const reactionsLayer = useOlMapReactionsLayer();
  const commentsLayer = useOlMapCommentsLayer();

  return useMemo(() => {
    return [pinsLayer, reactionsLayer, commentsLayer];
  }, [pinsLayer, reactionsLayer, commentsLayer]);
};
