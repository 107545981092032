import { Export } from '../ExportCompound';
import { ANNOTATIONS_TYPE } from '../ExportCompound/ExportCompound.constant';

export const Network = (): React.ReactNode => {
  return (
    <div data-testid="export-tab">
      <Export>
        <Export.Annotations type={ANNOTATIONS_TYPE.NETWORK} />
        <Export.IncludedCompartmentPathways />
        <Export.ExcludedCompartmentPathways />
        <Export.DownloadNetwork />
      </Export>
    </div>
  );
};
