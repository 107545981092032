import { ZERO } from '@/constants/common';
import { ImageContainerSize, OverviewImageSize } from '../OverviewImageModal.types';

interface GetFinalImageSizeResult extends OverviewImageSize {
  sizeFactor: number;
}

export const getFinalImageSize = (
  containerSize: ImageContainerSize,
  maxImageSize: OverviewImageSize,
): GetFinalImageSizeResult => {
  const maxHeight = Math.min(containerSize.height, maxImageSize.height);
  const maxWidth = Math.min(containerSize.width, maxImageSize.width);

  const heightSizeFactor = maxHeight / maxImageSize.height;
  const widthSizeFactor = maxWidth / maxImageSize.width;
  const sizeFactor = Math.min(heightSizeFactor, widthSizeFactor);

  const width = maxImageSize.width * sizeFactor;
  const height = maxImageSize.height * sizeFactor;

  return {
    height: height || ZERO,
    width: width || ZERO,
    sizeFactor: sizeFactor || ZERO,
  };
};
