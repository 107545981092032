/* eslint-disable no-magic-numbers */
import { Coordinate } from 'ol/coordinate';

export default function getBezierCurve({
  p0,
  p1,
  p2,
  p3,
  numPoints = 3,
}: {
  p0: Coordinate;
  p1: Coordinate;
  p2: Coordinate;
  p3: Coordinate;
  numPoints?: number;
}): Array<Coordinate> {
  const curve: Array<Coordinate> = [];

  for (let i = 0; i < numPoints; i += 1) {
    const t = parseFloat((i / (numPoints - 1)).toFixed(3));

    const x =
      (1 - t) ** 3 * p0[0] +
      3 * (1 - t) ** 2 * t * p1[0] +
      3 * (1 - t) * t ** 2 * p2[0] +
      t ** 3 * p3[0];

    const y =
      (1 - t) ** 3 * p0[1] +
      3 * (1 - t) ** 2 * t * p1[1] +
      3 * (1 - t) * t ** 2 * p2[1] +
      t ** 3 * p3[1];

    curve.push([x, y]);
  }
  curve.push(p3);

  return curve;
}
