import { useSelect } from 'downshift';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modelsIdsAndNamesSelector } from '@/redux/models/models.selectors';
import {
  searchValueSelector,
  sortColumnSelector,
  sortOrderSelector,
} from '@/redux/publications/publications.selectors';
import { getPublications } from '@/redux/publications/publications.thunks';
import { setSelectedModelId } from '@/redux/publications/publications.slice';
import { Icon } from '@/shared/Icon';
import { DEFAULT_PAGE_SIZE } from '../PublicationsTable.constants';

export const FilterBySubmapHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const models = useAppSelector(modelsIdsAndNamesSelector);
  const sortColumn = useAppSelector(sortColumnSelector);
  const sortOrder = useAppSelector(sortOrderSelector);
  const searchValue = useAppSelector(searchValueSelector);

  const handleChange = (modelId: number | undefined): void => {
    const newModelId = modelId ? String(modelId) : undefined;

    dispatch(setSelectedModelId(newModelId));

    dispatch(
      getPublications({
        params: {
          page: 0,
          length: DEFAULT_PAGE_SIZE,
          sortColumn,
          sortOrder,
          search: searchValue,
        },
        modelId: newModelId,
      }),
    );
  };

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: models,
    initialSelectedItem: null,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => handleChange(newSelectedItem?.id),
  });

  return (
    <div className="relative">
      <div
        className="flex cursor-pointer flex-row items-center justify-between bg-white px-3"
        {...getToggleButtonProps()}
        data-testid="background-dropdown-button"
      >
        <span data-testid="background-dropdown-button-name" className="truncate font-semibold">
          {selectedItem?.name || 'Submaps'}
        </span>
        <Icon
          name="chevron-down"
          className={twMerge('arrow-button h-6 w-6 fill-primary-500', isOpen && 'rotate-180')}
        />
      </div>
      <ul
        {...getMenuProps()}
        className={twMerge(
          'absolute top-full   mt-2 h-60 w-full overflow-y-scroll  bg-white shadow-lg',
          !isOpen && 'hidden',
        )}
        data-testid="background-dropdown-list"
      >
        {isOpen &&
          models.map((item, index) => (
            <li
              key={item.id}
              {...getItemProps({ item, index })}
              className={twMerge(
                'w-full truncate border-t text-left font-normal',
                highlightedIndex === index && 'text-primary-500',
                selectedItem?.id === item.id && 'font-bold',
                'flex flex-col px-4 py-2 shadow-sm',
              )}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};
