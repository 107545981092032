import { AccordionItemPanel as AIP } from 'react-accessible-accordion';
import { twMerge } from 'tailwind-merge';

interface AccordionItemPanelProps {
  className?: string;
  children: React.ReactNode;
}

export const AccordionItemPanel = ({
  className,
  children,
}: AccordionItemPanelProps): JSX.Element => (
  <AIP className={twMerge('pb-4', className)}>{children}</AIP>
);
