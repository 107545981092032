import { twMerge } from 'tailwind-merge';
import { AccordionItemButton as AIB } from 'react-accessible-accordion';
import './AccordionItemButton.style.css';
import { Variant } from './AccordionItemButton.types';
import { getIcon } from './AccordionItemButton.utils';

type AccordionItemButtonProps = {
  children: React.ReactNode;
  variant?: Variant;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
};

export const AccordionItemButton = ({
  children,
  variant = 'expandable',
  onClick,
  disabled,
  className,
}: AccordionItemButtonProps): JSX.Element => {
  const ButtonIcon = getIcon(variant);

  return (
    <AIB className="accordion-button flex flex-row flex-nowrap justify-between">
      <button
        onClick={onClick}
        disabled={disabled}
        className={twMerge('flex w-full flex-row flex-nowrap justify-between text-sm', className)}
        type="button"
        data-testid="accordion-item-button"
      >
        {children}
        {ButtonIcon}
      </button>
    </AIB>
  );
};
