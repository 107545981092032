import { ZERO } from '@/constants/common';
import { currentSearchedBioEntityChemicalsSelector } from '@/redux/drawer/drawer.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { BioEntitiesPinsListItem } from '../../SearchDrawerWrapper/BioEntitiesResultsList/BioEntitiesPinsList/BioEntitiesPinsListItem';

export const ChemicalsList = (): JSX.Element => {
  const chemicals = useAppSelector(currentSearchedBioEntityChemicalsSelector);
  const chemicalsData = chemicals.data || [];
  const isPending = chemicals.loading === 'pending';

  if (isPending) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      {chemicalsData.map(chemical => (
        <BioEntitiesPinsListItem key={`${chemical.id}`} pin={chemical} name={chemical.name} />
      ))}
      {chemicalsData.length === ZERO && 'List is empty'}
    </div>
  );
};
