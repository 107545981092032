import { ZERO } from '@/constants/common';

export const numberToSafeInt = (num: number): number => {
  // zero or NaN
  if (!num) {
    return ZERO;
  }

  return Number(num.toFixed(ZERO));
};
