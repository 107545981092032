import { mapDataLastPositionSelector } from '@/redux/map/map.selectors';
import { store } from '@/redux/store';
import { Point } from '@/types/map';

export const getCenter = (): Point => {
  const { getState } = store;
  const lastPosition = mapDataLastPositionSelector(getState());

  return lastPosition;
};
