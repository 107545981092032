import {
  bioEntityDataListSelector,
  bioEntityLoadingSelector,
} from '@/redux/bioEntity/bioEntity.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import React from 'react';
import { BioEntitiesPinsListItem } from '../../SearchDrawerWrapper/BioEntitiesResultsList/BioEntitiesPinsList/BioEntitiesPinsListItem';

export const ConnectedBioEntitiesList = (): React.ReactNode => {
  const bioEntityLoading = useAppSelector(bioEntityLoadingSelector);
  const bioEntityData = useAppSelector(bioEntityDataListSelector);
  const isPending = bioEntityLoading === 'pending';

  if (isPending) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <h3 className="mb-1 font-semibold">Reaction elements:</h3>
      {bioEntityData &&
        bioEntityData.map(item => (
          <BioEntitiesPinsListItem
            name={item.bioEntity.name}
            pin={item.bioEntity}
            key={item.bioEntity.name}
          />
        ))}
    </div>
  );
};
