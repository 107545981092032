import { FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { AppDispatch } from '@/redux/store';
import { ElementSearchResult } from '@/types/models';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { Point } from '@/types/map';
import { handleAliasResults } from './handleAliasResults';
import { handleReactionResults } from './handleReactionResults';

interface HandleSearchResultActionInput {
  searchResults: ElementSearchResult[];
  dispatch: AppDispatch;
  point: Point;
  searchDistance?: string;
  maxZoom: number;
  zoom: number;
  hasFitBounds?: boolean;
  isResultDrawerOpen: boolean;
}

export const handleSearchResultAction = async ({
  searchResults,
  dispatch,
  point,
  searchDistance,
  maxZoom,
  zoom,
  hasFitBounds,
  isResultDrawerOpen,
}: HandleSearchResultActionInput): Promise<void> => {
  const closestSearchResult = searchResults[FIRST_ARRAY_ELEMENT];
  const { type } = closestSearchResult;
  const action = {
    ALIAS: handleAliasResults,
    REACTION: handleReactionResults,
  }[type];

  await action(dispatch, closestSearchResult, {
    point,
    searchDistance,
    maxZoom,
    zoom,
    hasFitBounds,
    isResultDrawerOpen,
  })(closestSearchResult);

  if (type === 'ALIAS') {
    PluginsEventBus.dispatchEvent('onBioEntityClick', closestSearchResult);
  }
};
