import { EntityNumber } from '@/redux/entityNumber/entityNumber.types';
import { PinItem, TargetElement } from '../PinsList.types';

interface Options {
  entityNumber: EntityNumber;
}

export const getTargetElementsUniqueSorted = (
  pinsList: PinItem[],
  { entityNumber }: Options,
): TargetElement[] => {
  const targets = pinsList.map(p => p.data.targets).flat();
  const targetsElementsKeyValue: [string, TargetElement][] = targets
    .map((target): [string, TargetElement][] =>
      target.targetElements.map(element => [element.elementId, { target, element }]),
    )
    .flat();
  const targetElementsDict = Object.fromEntries(targetsElementsKeyValue);
  const targetElementsUnique = Object.values(targetElementsDict);

  return targetElementsUnique.sort(
    (a, b) => entityNumber[a.element.elementId] - entityNumber[b.element.elementId],
  );
};
