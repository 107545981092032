import { twMerge } from 'tailwind-merge';
import { AccordionItem as AccItem } from 'react-accessible-accordion';

interface AccordionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  uuid?: string | number;
  activeClassName?: string;
  dangerouslySetExpanded?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const AccordionItem = ({
  uuid: customUuid,
  dangerouslySetExpanded,
  children,
  className,
  ...rest
}: AccordionItemProps): JSX.Element => (
  <AccItem
    uuid={customUuid}
    dangerouslySetExpanded={dangerouslySetExpanded}
    className={twMerge('border-b', className)}
    {...rest}
  >
    {children}
  </AccItem>
);
