/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useOverviewImage } from './utils/useOverviewImage';

export const OverviewImagesModal: React.FC = () => {
  const [containerRect, setContainerRect] = useState<DOMRect>();
  const { imageUrl, size, linkConfigs } = useOverviewImage({ containerRect });
  const { width, height } = size;
  const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null);

  const handleContainerRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setContainerRect(node.getBoundingClientRect());
      setContainerNode(node);
    }
  }, []);

  useLayoutEffect(() => {
    const updateContainerSize = (): void => {
      if (containerNode !== null) {
        setContainerRect(containerNode.getBoundingClientRect());
      }
    };

    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, [containerNode]);

  if (!imageUrl) {
    return null;
  }

  return (
    <div
      data-testid="overview-images-modal"
      className="flex h-full w-full items-center justify-center bg-white"
      ref={handleContainerRef}
    >
      <div className="relative" style={{ width, height }}>
        <img alt="overview" className="block h-full w-full" src={imageUrl} />
        {linkConfigs.map(({ size: linkSize, onClick, idObject }) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            role="button"
            tabIndex={0}
            key={idObject}
            className="cursor-pointer"
            style={{
              height: linkSize.height,
              width: linkSize.width,
              top: linkSize.top,
              left: linkSize.left,
              position: 'absolute',
            }}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
};
