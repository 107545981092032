import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapModelIdSelector, mapOpenedMapsSelector } from '@/redux/map/map.selectors';
import { openMapAndSetActive, setActiveMap } from '@/redux/map/map.slice';
import { modelsDataSelector } from '@/redux/models/models.selectors';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { useCallback } from 'react';

type UseOpenSubmapProps = {
  modelId: number | undefined;
  modelName: string | undefined;
};

type UseOpenSubmapReturnType = {
  openSubmap: () => void;
  isItPossibleToOpenMap: boolean;
};

export const useOpenSubmap = ({
  modelId,
  modelName,
}: UseOpenSubmapProps): UseOpenSubmapReturnType => {
  const openedMaps = useAppSelector(mapOpenedMapsSelector);
  const models = useAppSelector(modelsDataSelector);
  const dispatch = useAppDispatch();
  const currentModelId = useAppSelector(mapModelIdSelector);

  const isMapAlreadyOpened = openedMaps.some(map => map.modelId === modelId);
  const isMapExist = models.some(model => model.idObject === modelId);
  const isItPossibleToOpenMap = modelId && modelName && isMapExist;

  const openSubmap = useCallback(() => {
    if (!isItPossibleToOpenMap) {
      return;
    }

    if (isMapAlreadyOpened) {
      dispatch(setActiveMap({ modelId }));
    } else {
      dispatch(openMapAndSetActive({ modelId, modelName }));
    }

    if (currentModelId !== modelId) {
      PluginsEventBus.dispatchEvent('onSubmapClose', currentModelId);
      PluginsEventBus.dispatchEvent('onSubmapOpen', modelId);
    }
  }, [dispatch, isItPossibleToOpenMap, isMapAlreadyOpened, modelId, modelName, currentModelId]);

  return { openSubmap, isItPossibleToOpenMap: Boolean(isItPossibleToOpenMap) };
};
