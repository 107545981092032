import { SIZE_OF_EMPTY_ARRAY, ZERO } from '@/constants/common';
import {
  numberOfChemicalsSelector,
  loadingChemicalsStatusSelector,
  chemicalsSelector,
} from '@/redux/chemicals/chemicals.selectors';
import { displayChemicalsList } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { AccordionItem, AccordionItemHeading, AccordionItemButton } from '@/shared/Accordion';
import { currentSelectedSearchElement } from '@/redux/drawer/drawer.selectors';

export const ChemicalsAccordion = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const chemicalsNumber = useAppSelector(numberOfChemicalsSelector);
  const chemicalsState = useAppSelector(loadingChemicalsStatusSelector);
  const currentSearchElement = useAppSelector(currentSelectedSearchElement);
  const chemicals = useAppSelector(chemicalsSelector);

  const isPending = chemicalsState === 'pending';
  const isSucceeded = chemicalsState === 'succeeded';
  const isChemicalsEmpty = chemicalsNumber === SIZE_OF_EMPTY_ARRAY;

  const onAccordionClick = (): void => {
    dispatch(displayChemicalsList());
  };

  const currentChemical = chemicals.data.find(
    ({ searchQueryElement }) => searchQueryElement === currentSearchElement,
  );
  const list = (currentChemical?.data || []).map(chemical =>
    chemical.targets.filter(target => target.targetElements.length > ZERO),
  );

  let existingChemicalTargets = 0;
  list.forEach(function (drugTargetList) {
    existingChemicalTargets += drugTargetList.length;
  });

  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton
          variant="non-expandable"
          onClick={onAccordionClick}
          disabled={isPending || isChemicalsEmpty}
        >
          Small molecule targets
          {isPending && ' (Loading...)'}
          {isSucceeded && ` (${existingChemicalTargets} of ${chemicalsNumber} found in the map)`}
        </AccordionItemButton>
      </AccordionItemHeading>
    </AccordionItem>
  );
};
