import { AppDispatch } from '@/redux/store';
import { ElementSearchResult } from '@/types/models';
import { FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { handleBioEntityResults } from './handleBioEntityResults';
import { handleReactionResults } from '../mapSingleClick/handleReactionResults';

interface HandleSearchResultActionInput {
  searchResults: ElementSearchResult[];
  dispatch: AppDispatch;
}

export const handleSearchResultForRightClickAction = async ({
  searchResults,
  dispatch,
}: HandleSearchResultActionInput): Promise<void> => {
  const closestSearchResult = searchResults[FIRST_ARRAY_ELEMENT];
  const { type } = closestSearchResult;
  const action = {
    ALIAS: handleBioEntityResults,
    REACTION: handleReactionResults,
  }[type];

  await action(dispatch, closestSearchResult)(closestSearchResult);
};
