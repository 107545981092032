/* eslint-disable no-magic-numbers */
import Style from 'ol/style/Style';
import { Geometry } from 'ol/geom';
import getStroke from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStroke';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';
import {
  BLACK_COLOR,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import getFill from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getFill';
import { Color } from '@/types/models';

export default function getStyle({
  geometry,
  borderColor = BLACK_COLOR,
  fillColor = WHITE_COLOR,
  lineWidth = 1,
  lineDash = [],
  zIndex = 1,
}: {
  geometry?: Geometry;
  borderColor?: Color | string;
  fillColor?: Color | string;
  lineWidth?: number;
  lineDash?: Array<number>;
  zIndex?: number;
}): Style {
  return new Style({
    geometry,
    stroke: getStroke({
      color: typeof borderColor === 'string' ? borderColor : rgbToHex(borderColor),
      width: lineWidth,
      lineDash,
    }),
    fill: getFill({ color: typeof fillColor === 'string' ? fillColor : rgbToHex(fillColor) }),
    zIndex,
  });
}
