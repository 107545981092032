/* eslint-disable no-magic-numbers */
import { MapOverlay } from '@/types/models';

export const moveArrayElement = (
  arr: MapOverlay[],
  dragIndex: number,
  hoverIndex: number,
): MapOverlay[] => {
  const arrayClone = [...arr];

  const lastIndex = arr.length - 1;
  if (hoverIndex > lastIndex || dragIndex > lastIndex) return arrayClone;

  const [removedElement] = arrayClone.splice(dragIndex, 1);

  arrayClone.splice(hoverIndex, 0, removedElement);

  return arrayClone;
};
