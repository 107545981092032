import { FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { modelsDataSelector } from '@/redux/models/models.selectors';
import { MapModel } from '@/types/models';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ExportContext } from '../../ExportCompound.context';

export const useExportGraphicsSelectedModel = (): MapModel | undefined => {
  const { data } = useContext(ExportContext);
  const currentSelectedModelId = data.models?.[FIRST_ARRAY_ELEMENT]?.id;
  const models = useSelector(modelsDataSelector);

  if (!currentSelectedModelId) {
    return undefined;
  }

  return models.find(model => model.idObject === Number(currentSelectedModelId));
};
