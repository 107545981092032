import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Login } from '@/types/models';
import { ErrorData } from '@/utils/error-report/ErrorData';
import { apiPath } from '@/redux/apiPath';
import { showToast } from '@/utils/showToast';

export const sendReport = createAsyncThunk('error/report', async (errorData: ErrorData) => {
  try {
    await axiosInstance.post<Login>(apiPath.submitError(), errorData);
    showToast({ type: 'success', message: 'Error report sent successfully.' });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    showToast({
      type: 'error',
      message: 'Unexpected error. More information can be found in the console.',
    });
  }
});
