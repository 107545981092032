import logoImg from '@/assets/vectors/branding/logo.svg';
import luxembourgLogoImg from '@/assets/vectors/branding/luxembourg-logo.svg';
import { MINERVA_WEBSITE_URL } from '@/constants';
import { closeDrawer, openDrawer } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { closeLegend, openLegend } from '@/redux/legend/legend.slice';
import { openPluginsDrawer } from '@/redux/plugins/plugins.slice';
import { IconButton } from '@/shared/IconButton';
import { store } from '@/redux/store';

import Image from 'next/image';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { projectIdSelector } from '@/redux/project/project.selectors';
import { Switch } from '@/shared/Switch';
import { currentModelIdSelector, vectorRenderingSelector } from '@/redux/models/models.selectors';
import { setModelVectorRendering } from '@/redux/models/models.slice';

export const NavBar = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const projectId = useAppSelector(projectIdSelector);
  const vectorRendering = useAppSelector(vectorRenderingSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);

  const toggleDrawerInfo = (): void => {
    if (store.getState().drawer.isOpen && store.getState().drawer.drawerName === 'project-info') {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer('project-info'));
    }
  };

  const toggleDrawerPlugins = (): void => {
    if (
      store.getState().drawer.isOpen &&
      store.getState().drawer.drawerName === 'available-plugins'
    ) {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer('available-plugins'));
    }
    dispatch(openPluginsDrawer());
  };

  const toggleDrawerExport = (): void => {
    if (store.getState().drawer.isOpen && store.getState().drawer.drawerName === 'export') {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer('export'));
    }
  };

  const toggleDrawerLayers = (): void => {
    if (store.getState().drawer.isOpen && store.getState().drawer.drawerName === 'layers') {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer('layers'));
    }
  };

  const toggleDrawerLegend = (): void => {
    if (store.getState().legend.isOpen) {
      dispatch(closeLegend());
    } else {
      dispatch(openLegend());
    }
  };

  const configuration = store.getState().configuration.main.data;
  const version = configuration ? `(v${configuration.version})` : '';

  const constant = store.getState().constant.main.data;

  const apiDocsUrl = constant ? constant.apiDocsUrl : '';

  const oldUrl = `old_index.xhtml?id=${projectId}`;

  return (
    <div className="flex min-h-full w-[88px] flex-col items-center justify-between overflow-y-auto bg-cultured py-8">
      <div data-testid="nav-buttons">
        <div className="mb-8 flex flex-col gap-[10px]">
          <IconButton icon="info" onClick={toggleDrawerInfo} title="Project info" />
          <a href={apiDocsUrl} target="_blank">
            <IconButton icon="page" title="API Doc" />
          </a>
          <IconButton icon="plugin" onClick={toggleDrawerPlugins} title="Available plugins" />
          <IconButton icon="export" onClick={toggleDrawerExport} title="Export" />
          <IconButton icon="layers" onClick={toggleDrawerLayers} title="Layers" />
        </div>
        <div className="flex flex-col gap-[10px]">
          <IconButton icon="legend" onClick={toggleDrawerLegend} title="Legend" />
        </div>
      </div>
      <div className="flex flex-col items-center gap-[10px] text-center text-[12px]">
        <span>Vector rendering</span>
        <Switch
          isChecked={vectorRendering}
          onToggle={value =>
            dispatch(setModelVectorRendering({ vectorRendering: value, mapId: currentModelId }))
          }
        />
      </div>
      <div className="flex flex-col items-center gap-[20px]" data-testid="nav-logos-and-powered-by">
        <a href="https://www.uni.lu/en/" target="_blank">
          <Image
            className="rounded rounded-e rounded-s bg-white-pearl pb-[7px]"
            src={luxembourgLogoImg}
            alt="University of Luxembourg logo"
            height={41}
            width={48}
          />
        </a>
        <a href="https://minerva.uni.lu/" target="_blank">
          <Image src={logoImg} alt="Minerva logo" height={48} width={48} />
        </a>
        <span className="h-16 w-14 text-center text-[8px] leading-4">
          Powered by: MINERVA Platform{' '}
          <a href={MINERVA_WEBSITE_URL} target="_blank">
            {version}
          </a>
        </span>
        <span className="text-center text-[8px] leading-4">
          <a href={oldUrl} target="_old_interface">
            Old interface
          </a>
        </span>
      </div>
    </div>
  );
};
