import { ZERO } from '@/constants/common';
import { currentOverviewImageSelector } from '@/redux/project/project.selectors';
import { useSelector } from 'react-redux';
import { OverviewImageLinkConfig } from '../OverviewImageModal.types';
import { getOverviewImageLinkSize } from './getOverviewImageLinkSize';
import { useOverviewImageLinkActions } from './useOverviewImageLinkActions';

interface UseOverviewImageLinksArgs {
  sizeFactor: number;
}

export const useOverviewImageLinkConfigs = ({
  sizeFactor,
}: UseOverviewImageLinksArgs): OverviewImageLinkConfig[] => {
  const { handleLinkClick } = useOverviewImageLinkActions();
  const currentImage = useSelector(currentOverviewImageSelector);

  if (!currentImage || sizeFactor === ZERO) return [];

  return currentImage.links.map(link => ({
    idObject: link.id,
    size: getOverviewImageLinkSize(link, { sizeFactor }),
    onClick: () => handleLinkClick(link),
  }));
};
