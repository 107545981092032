import { OVERVIEW_IMAGE_ERRORS } from '@/constants/errors';
import { setOverviewImageId } from '@/redux/modal/modal.slice';
import { projectOverviewImagesSelector } from '@/redux/project/project.selectors';
import { store } from '@/redux/store';

export const selectOverviewImage = (imageId: number): void => {
  const { dispatch, getState } = store;
  const overviewImages = projectOverviewImagesSelector(getState());
  const foundOverviewImage = overviewImages.find(o => o.id === imageId);
  const isImageIdValid = Boolean(foundOverviewImage);

  if (!isImageIdValid) {
    throw new Error(OVERVIEW_IMAGE_ERRORS.IMAGE_ID_IS_INVALID);
  }

  dispatch(setOverviewImageId(imageId));
};
