import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { IconButton } from '@/shared/IconButton';
import { CLOSE_BUTTON_ROLE } from '../DrawerHeadingBackwardButton/DrawerHeadingBackwardButton.constants';

interface DrawerHeadingProps {
  title: string | React.ReactNode;
}

export const DrawerHeading = ({ title }: DrawerHeadingProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const onCloseButtonClick = (): void => {
    dispatch(closeDrawer());
  };

  return (
    <div className="flex items-center justify-between border-b border-b-divide px-6">
      <div className=" py-8 text-xl">
        <span className="font-semibold">{title}</span>
      </div>
      <IconButton
        className="bg-white-pearl"
        classNameIcon="fill-font-500"
        icon="close"
        role={CLOSE_BUTTON_ROLE}
        onClick={onCloseButtonClick}
      />
    </div>
  );
};
