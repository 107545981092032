import { LinePoint } from '@/types/reactions';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { LineString, SimpleGeometry } from 'ol/geom';

export const getLineFeature = (
  linePoints: LinePoint,
  pointToProjection: UsePointToProjectionResult,
): Feature<SimpleGeometry> => {
  const points = linePoints.map(pointToProjection);

  return new Feature({
    geometry: new LineString(points),
  });
};
